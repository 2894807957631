import React, { FC } from 'react';
import { UserEditForm } from './user/UserEditForm';

type Prop = {};

export const Profile: FC<Prop> = () => {
    return (
        <div>
            <div className="app-main user">
                <h2 className="header-name">プロフィール編集</h2>
                <UserEditForm />
            </div>
        </div>
    );
};
