import { RefObject, useEffect, useState } from 'react';
import Player from 'video.js/dist/types/player';
const events = ['ended', 'durationchange', 'timeupdate'];

export function useVideoScrubTime(playerRef: RefObject<Player | null>) {
    const [scrubTime, setScrubTime] = useState(0);
    useEffect(() => {
        const player = playerRef.current;
        if (!player) return;
        const updateTime = () => {
            setScrubTime(player.currentTime());
        };
        player.on(events, updateTime);
        return () => {
            player.off(events, updateTime);
        };
    }, [playerRef.current]);
    return scrubTime;
}
