import { MouseEvent, createContext, useContext } from 'react';
import { Anchor } from '../ReelAnchors';
import { makeAutoObservable } from 'mobx';
import { Point, Rect, TagID } from '../../../utils/ServerClientModel';

export interface PointDrag {
    time: number;
    tag: TagID;
    e: Point;
    pointId: string;
    shapeId?: string;
    point: Point;
}

export interface LegendState {
    point: Point;
    open: boolean;
}

const DOUBLE_CLICK_THRESHOLD = 250;

export class LocalState {
    drag: MouseEvent | null = null;
    resize: {
        e: MouseEvent;
        dir: Anchor | 'center';
        handler: (xDiff: number, yDiff: number) => void;
    } | null = null;
    select: Point | null = null;
    tempSelection: Rect | null = null;
    pointDrag: PointDrag | null = null;
    previousPointDrag: PointDrag | null = null;
    legend: LegendState = { point: { x: 10, y: 10 }, open: false };

    constructor() {
        makeAutoObservable(
            this,
            {},
            {
                deep: true,
                autoBind: true,
            }
        );
    }

    get isDragging() {
        return !!(this.resize || this.pointDrag || this.drag);
    }

    clear() {
        this.tempSelection = null;
        this.select = null;
        this.drag = null;
        this.resize = null;
        this.previousPointDrag = this.pointDrag;
        this.pointDrag = null;
    }

    isDoubleClick(
        tagId: string,
        shapeId: string | undefined,
        pointId: string,
        time: number
    ) {
        const { previousPointDrag: prev } = this;
        if (!prev) return false;
        if (prev.tag !== tagId) return false;
        if (prev.shapeId !== shapeId) return false;
        if (prev.pointId !== pointId) return false;
        const timeBetweenClicks = time - prev.time;
        return timeBetweenClicks < DOUBLE_CLICK_THRESHOLD;
    }
}

export const LocalStateContext = createContext(new LocalState());
export const useLocalState = () => useContext(LocalStateContext);
