import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { useRafLoop } from 'react-use';

export interface UseJIWScrollReturn<E extends HTMLElement> {
    x: number;
    y: number;
    isTop: boolean;
    isBottom: boolean;
    ref: MutableRefObject<E | null>;
}

export interface UseJIWScrollProps {
    bottomMargin?: number;
    topMargin?: number;
}
export const useJIWScroll = <E extends HTMLElement>(
    opts?: UseJIWScrollProps
): UseJIWScrollReturn<E> => {
    const ref = useRef<E>(null);
    const bottomMargin = opts?.bottomMargin ?? 0;
    const topMargin = opts?.topMargin ?? 0;

    let [state, setState] = useState<UseJIWScrollReturn<E>>({
        x: 0,
        y: 0,
        isTop: true,
        isBottom: true,
        ref,
    });

    useRafLoop(() => {
        const { current } = ref;
        if (!current) return;
        const { scrollTop, scrollHeight, clientHeight } = current;
        const next = {
            x: current.scrollLeft,
            y: current.scrollTop,
            isTop: scrollTop <= topMargin,
            isBottom: scrollTop + clientHeight + bottomMargin >= scrollHeight,
            ref,
        };
        if (
            state.x === next.x &&
            state.y === next.y &&
            state.isBottom === next.isBottom &&
            state.isTop === next.isTop
        ) {
            return;
        }
        state = next;
        setState(next);
    });

    return state;
};
