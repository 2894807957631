import React, { useCallback, useMemo, useState } from 'react';
import { DialogProps } from '../../hooks/useDialog';
import { DialogBox } from '../atomic/dialog';
import { SelectField, TextField } from '../atomic/forms';
import { AiAnalysisType } from '../../models/AiAnalysisType';
import { CreateTemplateRequest } from '../../APIs/templateApi';

export type AddTemplateDialogProps = DialogProps<
    {
        aiAnalysisTypes: AiAnalysisType[];
    },
    CreateTemplateRequest
>;
export const AddTemplateDialog = ({
    options: { aiAnalysisTypes },
    onOK: onCommit,
    ...rest
}: AddTemplateDialogProps) => {
    const [request, setRequest] = useState<CreateTemplateRequest>({
        name: '新規テンプレート',
        aiAnalysisTypeId: aiAnalysisTypes[0].aiAnalysisTypeId,
    });
    const setName = useCallback(
        (name: string) => setRequest((request) => ({ ...request, name })),
        [setRequest]
    );
    const setAiAnalysisTypeId = useCallback(
        (aiAnalysisTypeId: string) =>
            setRequest((request) => ({ ...request, aiAnalysisTypeId })),
        [setRequest]
    );
    const aiOptions = useMemo(
        () =>
            aiAnalysisTypes.map(({ aiAnalysisTypeId, nameJa }) => ({
                text: nameJa,
                value: aiAnalysisTypeId,
            })),
        [aiAnalysisTypes]
    );
    const onOK = useCallback(() => onCommit(request), [onCommit, request]);
    return (
        <DialogBox
            title={'テンプレートの追加'}
            okText={'追加'}
            {...rest}
            onOK={onOK}
        >
            <ul className="fields">
                <li>
                    <TextField
                        label="名前"
                        _onChange={setName}
                        value={request.name}
                        required={true}
                    />
                </li>
                <li>
                    <SelectField
                        label="AIの種別"
                        _onChange={setAiAnalysisTypeId}
                        value={request.aiAnalysisTypeId}
                        options={aiOptions}
                    />
                </li>
            </ul>
        </DialogBox>
    );
};
