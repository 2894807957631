import React, {
    Dispatch,
    JSXElementConstructor,
    ReactNode,
    SetStateAction,
    DependencyList,
    createElement,
    useEffect,
    useRef,
    useMemo,
    useState,
    useCallback,
} from 'react';
import {
    DialogCancelHandler,
    DialogErrorObject,
} from '../components/atomic/dialog';
import { createPortal } from 'react-dom';
import { AbortError, isAbortError } from '../utils/abort';

const modalRoot = document.querySelector('#modal-root')! as HTMLDivElement;
const appRoot = document.querySelector('#app-root')! as HTMLDivElement;

let modalCount = 0;
let previouslyFocussed: HTMLElement | undefined;
const revertFocus = (e: FocusEvent) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    const elem: HTMLElement =
        previouslyFocussed ?? modalRoot.querySelector('button') ?? modalRoot;
    elem.focus?.();
    return false;
};
const memoPrevious = (e: FocusEvent) => {
    previouslyFocussed = e.target as HTMLElement;
};
let itemBeforeOpen: HTMLElement | undefined;
function modalEffect() {
    modalCount += 1;
    if (modalCount === 1) {
        itemBeforeOpen = appRoot.querySelector(':focus') as
            | HTMLElement
            | undefined;
        appRoot.addEventListener('focusin', revertFocus);
        window.addEventListener('focusin', memoPrevious);
        appRoot.classList.add('behind-modal');
    }
    return () => {
        modalCount -= 1;
        if (modalCount === 0) {
            appRoot.classList.remove('behind-modal');
            appRoot.removeEventListener('focusin', revertFocus);
            window.removeEventListener('focusin', memoPrevious);
            itemBeforeOpen?.focus();
            itemBeforeOpen = undefined;
            previouslyFocussed = undefined;
        }
    };
}
interface ModalProps {
    children?: ReactNode[] | ReactNode;
    onClose?: () => void;
}

const Modal = ({ children, onClose }: ModalProps) => {
    const currentRoot = useMemo(() => document.createElement('div'), []);
    useEffect(modalEffect, []);
    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.code === 'Escape') onClose?.();
        };
        modalRoot.append(currentRoot);
        window.addEventListener('keydown', onKeyDown);

        const elem: HTMLElement | null =
            currentRoot.querySelector(
                "*[tabindex='0'], *[tabindex='1'], input, input:not([type='hidden']), button, a"
            ) ?? null;
        elem?.focus();
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            modalRoot.removeChild(currentRoot);
        };
    }, [currentRoot, onClose]);
    return createPortal(<>{children}</>, currentRoot);
};

export type UseDialogOKHandler<Options = undefined> = Options extends void
    ? () => void
    : (options: Options) => void;

export interface DialogProps<Options, Output = Options> {
    options: Options;
    disabled: boolean;
    loading: boolean;
    onCancel: DialogCancelHandler;
    onOK: UseDialogOKHandler<Output>;
    errors: DialogErrorObject[];
}
export type SimpleDialogProps = DialogProps<{}, void>;
export type ShowDialogHandler<Context> = Context extends undefined
    ? () => void
    : (context: Context) => void;
export interface UseDialog<Context = undefined> {
    dialog?: ReactNode;
    show: ShowDialogHandler<Context>;
    hide: () => void;
}
export interface DialogCommitOptions {
    signal: AbortSignal;
}
export interface DialogSession<Options, Output> {
    options: Options;
    keepOpen?: boolean;
    onCommit?: (
        output: Output,
        commitOpts: DialogCommitOptions
    ) => Promise<void>;
    onError?: string | ((error: Error) => unknown);
}
export type DialogInit<Options, Output, Context> = (
    context: Context
) => DialogSession<Options, Output>;

function createDialog<Options, Output, Context>(
    init: DialogInit<Options, Output, Context>,
    context: Context,
    setActive: Dispatch<
        SetStateAction<DialogState<Options, Output> | undefined>
    >
): DialogState<Options, Output> {
    let controller: AbortController | undefined;
    const session = init(context);
    const setDialog = (
        handler: (
            input: DialogProps<Options, Output>
        ) => DialogProps<Options, Output> | undefined
    ) => {
        setActive((input) => {
            if (!input) return;
            const dialog = handler(input.dialog);
            if (!dialog) return;
            return {
                ...input,
                dialog,
            };
        });
    };
    let shown = true;
    return {
        hide() {
            if (controller) {
                controller.abort();
                controller = undefined;
            }
            setActive(undefined);
            shown = false;
        },
        dialog: {
            options: session.options,
            errors: [],
            onOK: ((output: Output | undefined) => {
                if (!session.onCommit) {
                    setActive(undefined);
                    return;
                }
                if (!shown) {
                    console.warn('Stale hook used in OK dialog.');
                    return;
                }
                controller = new AbortController();
                setDialog((input) => ({
                    ...input,
                    errors: [],
                    disabled: true,
                    loading: true,
                }));
                session
                    .onCommit(output!, { signal: controller.signal })
                    .catch((err) => {
                        if (!controller) return;
                        if (isAbortError(err)) {
                            return;
                        }
                        if (!session.onError) {
                            return Promise.reject(err);
                        }
                        if (typeof session.onError === 'string') {
                            console.warn(err);
                            return Promise.reject(session.onError);
                        }
                        return Promise.reject(session.onError(err));
                    })
                    .then(
                        () => {
                            if (!controller) return;
                            setDialog((input) => {
                                if (!session.keepOpen) {
                                    return undefined;
                                }
                                return {
                                    ...input,
                                    loading: false,
                                    disabled: false,
                                };
                            });
                        },
                        (error: DialogErrorObject) => {
                            if (!controller) return;
                            setDialog((input) => ({
                                ...input,
                                errors: [...input.errors, error],
                                disabled: false,
                                loading: false,
                            }));
                        }
                    )
                    .finally(() => {
                        controller = undefined;
                    });
            }) as UseDialogOKHandler<Output>,
            disabled: false,
            loading: false,
            onCancel: () => {
                if (controller) {
                    controller.abort();
                    controller = undefined;
                    setDialog((input) => ({
                        ...input,
                        loading: false,
                        disabled: false,
                        errors: [...input.errors, new AbortError()],
                    }));
                } else {
                    setActive(undefined);
                }
            },
        },
    };
}

interface DialogState<Options, Output> {
    dialog: DialogProps<Options, Output>;
    hide: () => void;
}

function showWarning() {
    console.warn(
        'Trying to show a dialog even though the current dialog is already open.'
    );
}

export function useDialog<Options, Output = Options, Context = undefined>(
    ui: JSXElementConstructor<DialogProps<Options, Output>>,
    init: DialogInit<Options, Output, Context>,
    deps: DependencyList
): UseDialog<Context> {
    const defaultState = useMemo<UseDialog<Context>>(
        () => ({
            dialog: undefined,
            hide() {},
            show: ((context: Context) =>
                setActive(() =>
                    createDialog(init, context, setActive)
                )) as ShowDialogHandler<Context>,
        }),
        [ui, ...deps]
    );
    const [active, setActive] = useState<
        DialogState<Options, Output> | undefined
    >(undefined);
    const closeRef = useRef<() => void>();
    closeRef.current = active?.hide;
    useEffect(
        () => () => {
            closeRef.current?.();
        },
        []
    );
    return !active
        ? defaultState
        : {
              dialog: (
                  <Modal onClose={active.dialog.onCancel}>
                      {createElement(ui, active.dialog)}
                  </Modal>
              ),
              show: showWarning as ShowDialogHandler<Context>,
              hide: active.hide,
          };
}

export function useSimpleDialog<Context>(
    ui: JSXElementConstructor<DialogProps<Context, void>>,
    init: DialogInit<Context, void, Context>,
    deps: DependencyList
) {
    return useDialog(ui, init, deps);
}
