import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { IconButton } from '../atomic/buttons';
import { DialogBox } from '../atomic/dialog';
import { Icon } from '../atomic/icons';
import { Loading } from '../atomic/effects';
import { TextField, SelectField } from '../atomic/forms';
import {
    TenantTagModel,
    TagTypeModel,
    TagTypeNames,
    TagType,
} from '../../models/TagModel';
import {
    getTenantTag,
    getTagTypes,
    storeTagTypes,
    storeTenantTag,
    deleteTenantTag,
    deleteTagType,
    addTagType,
} from '../../APIs/tagApi';
import { AdminDetailPageHeader } from './AdminUtils';
import { ErrorDisplay } from '../Errors';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { ErrorContext } from '../../contexts';

type TypeDialogData = {
    tagTypeId: string | null;
    name: string;
    type: number;
    list: string;
};

type DeleteTypeDialogData = {
    tagTypeId: string;
    name: string;
};

export const AdminTag = () => {
    const [typeDialogData, setTypeDialogData] =
        React.useState<TypeDialogData | null>(null);
    const [tagTypes, setTagTypes] = React.useState<Array<TagTypeModel> | null>(
        null
    );
    const [snapShot, setSnapShot] = React.useState<Array<TagTypeModel>>([]);
    const [tenantTag, setTenantTag] = React.useState<TenantTagModel | null>(
        null
    );
    const [dragIndex, setDragIndex] = React.useState<number>(-1);
    const { tenantTagId } = useParams() as { tenantTagId: string };
    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());
    const { setErrors } = React.useContext(ErrorContext);
    const [error, setError] = React.useState<Error | null>(null);
    const [deleteTypeDialogData, setDeleteTypeDialogData] =
        React.useState<DeleteTypeDialogData | null>(null);

    const updateList = async () => {
        getTenantTag(tenantTagId)
            .then((resp) => {
                console.log(resp);
                if (resp.success && resp.data) {
                    setTenantTag(resp.data);
                } else {
                    setError(
                        Error(resp.message || 'タグ一覧の取得に失敗しました')
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
        getTagTypes()
            .then((resp) => {
                console.log(resp);
                if (resp.success && resp.data) {
                    setTagTypes(
                        resp.data.filter(
                            (item) => item.tenantTagId == tenantTagId
                        )
                    );
                } else {
                    setError(
                        Error(resp.message || 'タグタイプの取得に失敗しました')
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            })
            .finally(() => {});
    };

    React.useEffect(() => {
        updateList();
    }, []);

    const dragStart = React.useCallback(
        (index) => {
            setSnapShot(JSON.parse(JSON.stringify(tagTypes)));
            setDragIndex(index);
        },
        [tagTypes]
    );

    const dragEnter = React.useCallback(
        (index) => {
            if (index === dragIndex) return;
            setTagTypes((prevState) => {
                const nextState = JSON.parse(JSON.stringify(prevState));
                const tmpOrder = nextState[index].sortOrder;
                nextState[index].sortOrder = nextState[dragIndex].sortOrder;
                nextState[dragIndex].sortOrder = tmpOrder;
                return nextState;
            });
            setDragIndex(index);
        },
        [dragIndex]
    );

    const modifyTagDetailsWrapper = (
        items: Array<TagTypeModel>,
        onSuccess: () => void,
        onError: (message: string) => void
    ) => {
        setLoading(true);
        storeTagTypes(
            items.map((item) => ({
                tagTypeId: item.tagTypeId,
                name: item.name,
                sortOrder: item.sortOrder,
                list: item.list ? item.list.join(',') : null,
            }))
        )
            .then((resp) => {
                if (resp.success && resp.data) {
                    const items = resp.data;
                    setTagTypes((prevState) => {
                        const nextState = JSON.parse(JSON.stringify(prevState));
                        items.forEach((tag) => {
                            const item = nextState.find(
                                (item) => item.tagTypeId == tag.tagTypeId
                            );
                            if (item) {
                                for (let key in tag) {
                                    if (key in item) {
                                        item[key] = tag[key];
                                    }
                                }
                            }
                        });
                        return nextState;
                    });
                    onSuccess();
                } else {
                    onError(resp.message || 'タグの更新に失敗しました');
                }
            })
            .catch((err) => {
                console.warn(err);
                onError(err.message || 'タグの更新に失敗しました');
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const dragEnd = React.useCallback(() => {
        // スナップショットと現在の状態を比較して、sortOrderに変更のあったラベルのみ抽出する
        const sortOrders = {};
        snapShot.forEach((item) => {
            sortOrders[item.tagTypeId] = item.sortOrder;
        });
        const items = tagTypes?.filter(
            (item) => item.sortOrder != sortOrders[item.tagTypeId]
        );
        if (items && items.length > 0) {
            modifyTagDetailsWrapper(
                items,
                () => {},
                (message: string) =>
                    setErrors((prevState) => [...prevState, message])
            );
        }
        setDragIndex(-1);
        setSnapShot([]);
    }, [tagTypes, snapShot]);

    const onEditType = React.useCallback(
        (tagTypeId) => {
            if (!tagTypes) return;
            const data: TagTypeModel | undefined = tagTypes.find(
                (item) => item.tagTypeId == tagTypeId
            );
            if (data) {
                setTypeDialogData({
                    tagTypeId: tagTypeId,
                    name: data.name,
                    type: data.type,
                    list: data.type == 3 ? data.list?.join(',') || '' : '',
                });
            }
        },
        [tagTypes]
    );

    const onRemoveType = React.useCallback((tagTypeId, name) => {
        setDeleteTypeDialogData({ tagTypeId, name });
    }, []);
    const onDeleteTypeDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            if (!deleteTypeDialogData) return;
            const { tagTypeId } = deleteTypeDialogData;
            setLoading(true);
            deleteTagType(tagTypeId)
                .then((resp) => {
                    console.log(resp);
                    if (resp.success) {
                        setTagTypes((prevState) => {
                            const nextState = JSON.parse(
                                JSON.stringify(prevState)
                            );
                            return nextState.filter(
                                (item) => item.tagTypeId != tagTypeId
                            );
                        });
                        setDeleteTypeDialogData(null);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err.message || 'タグの削除に失敗しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [tagTypes, deleteTypeDialogData]
    );
    const onDeleteTypeDialogCancel = React.useCallback(() => {
        setDeleteTypeDialogData(null);
    }, []);

    const onAddTypeToTag = React.useCallback(() => {
        setTypeDialogData({
            tagTypeId: null,
            name: '',
            type: 1,
            list: '',
        });
    }, []);

    const getDialogValue = React.useCallback((name, value) => {
        setTypeDialogData((prevState) => {
            const nextState = JSON.parse(JSON.stringify(prevState));
            nextState[name] = value;
            return nextState;
        });
    }, []);

    const textToList = (text) => {
        return (text || '')
            .split(',')
            .map((text) => text.replace(/^[\s　]+|[\s　]+$/g, ''));
    };

    const onDeleteTag = React.useCallback(
        (dialog: DialogBox, closeDialog: () => void) => {
            setLoading(true);
            deleteTenantTag(tenantTagId)
                .then((resp) => {
                    if (resp.success) {
                        navigate('/app/admin/tags');
                    } else {
                        dialog.addError(
                            resp.message || 'タグの削除に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err.message || 'タグの削除に失敗しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    const onEditTagName = React.useCallback(
        (newName: string, closeDialog: () => void, dialog: DialogBox) => {
            setLoading(true);
            storeTenantTag(tenantTagId, {
                tenantTagId: tenantTagId,
                name: newName,
                tenantId: userInfo?.tenantId ?? '',
            })
                .then((resp) => {
                    console.log(resp);
                    if (resp.success && resp.data) {
                        setTenantTag((prevState) => {
                            const nextState = JSON.parse(
                                JSON.stringify(prevState)
                            );
                            nextState.name = newName;
                            return nextState;
                        });
                        closeDialog();
                    } else {
                        dialog.addError(
                            resp.message || 'タグ名の更新に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'タグ名の更新に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [userInfo]
    );

    const onTypeDialogCancelHandler = React.useCallback(() => {
        setTypeDialogData(null);
    }, []);

    const onTypeDialogOklHandler = React.useCallback(
        (dialog: DialogBox) => {
            if (!typeDialogData) {
                return false;
            }
            if (typeDialogData.tagTypeId) {
                // 既存のタグタイプの修正
                const nextState = JSON.parse(JSON.stringify(tagTypes));
                const tag = nextState.find(
                    (item) => item.tagTypeId == typeDialogData.tagTypeId
                );
                if (tag) {
                    modifyTagDetailsWrapper(
                        [
                            {
                                ...tag,
                                name: typeDialogData.name,
                                type: typeDialogData.type,
                                list:
                                    typeDialogData.type == TagType.List
                                        ? textToList(typeDialogData.list)
                                        : null,
                            },
                        ],
                        () => {
                            setTypeDialogData(null);
                        },
                        (message: string) => {
                            dialog.addError(message);
                        }
                    );
                }
            } else if (tagTypes) {
                // 新しいタグタイプの追加
                let sortOrder = 0;
                tagTypes.forEach((item) => {
                    if (item.sortOrder >= sortOrder) {
                        sortOrder = item.sortOrder + 1;
                    }
                });
                setLoading(true);
                addTagType({
                    tenantTagId: tenantTagId,
                    name: typeDialogData.name,
                    type: typeDialogData.type,
                    list:
                        typeDialogData.type == TagType.List
                            ? textToList(typeDialogData.list)
                            : null,
                    sortOrder: sortOrder,
                })
                    .then((resp) => {
                        console.log(resp);
                        if (resp.success && resp.data) {
                            setTagTypes((prevState) => {
                                const nextState = JSON.parse(
                                    JSON.stringify(prevState)
                                );
                                nextState.push(resp.data);
                                return nextState;
                            });
                            setTypeDialogData(null);
                        } else {
                            dialog.addError(
                                resp.message || 'タグの追加に失敗しました'
                            );
                        }
                    })
                    .catch((err) => {
                        console.warn(err);
                        dialog.addError(
                            err.message || 'タグの追加に失敗しました'
                        );
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        },
        [typeDialogData, tagTypes]
    );

    if (error) {
        return <ErrorDisplay error={error} />;
    }
    if (!userInfo || !tagTypes || !tenantTag) {
        return <Loading />;
    }
    return (
        <>
            <div className="app-main admin-tag">
                <AdminDetailPageHeader
                    parentUrl="/app/admin/tags"
                    name={tenantTag.name}
                    onDelete={onDeleteTag}
                    onEdit={onEditTagName}
                    title="属性名"
                    annotation="属性情報設定を削除すると、フォルダに付与済みの属性情報も削除されます。"
                />
                <table className="tag-detail-table">
                    <thead>
                        <tr>
                            <th>項目名</th>
                            <th>型</th>
                            <th>編集</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tagTypes
                            .sort((a, b) => {
                                return a.sortOrder - b.sortOrder;
                            })
                            .map((item, index) => (
                                <tr
                                    key={item.tagTypeId}
                                    draggable={true}
                                    onDragStart={() => dragStart(index)}
                                    onDragEnter={() => dragEnter(index)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDragEnd={dragEnd}
                                >
                                    <td>{item.name}</td>
                                    <td>
                                        {TagTypeNames.find(
                                            (obj) => obj.name == item.type
                                        )?.label || '未知の型'}
                                    </td>
                                    <td>
                                        <IconButton
                                            icon="fa-solid fa-pencil"
                                            onClick={() =>
                                                onEditType(item.tagTypeId)
                                            }
                                        >
                                            編集
                                        </IconButton>
                                        <IconButton
                                            icon="fa-solid fa-trash"
                                            onClick={() =>
                                                onRemoveType(
                                                    item.tagTypeId,
                                                    item.name
                                                )
                                            }
                                        >
                                            削除
                                        </IconButton>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                <div>
                    <Link to="#" onClick={() => onAddTypeToTag()}>
                        <Icon icon="fa-solid fa-folder-plus" />
                        項目を追加
                    </Link>
                </div>
            </div>
            {typeDialogData && (
                <DialogBox
                    title={
                        typeDialogData.tagTypeId
                            ? '属性定義の編集'
                            : '属性定義の追加'
                    }
                    okText={typeDialogData.tagTypeId ? '更新' : '追加'}
                    onCancel={onTypeDialogCancelHandler}
                    onOK={onTypeDialogOklHandler}
                >
                    <ul className="fields">
                        <li>
                            <TextField
                                label="項目名"
                                _onChange={(value) =>
                                    getDialogValue('name', value)
                                }
                                value={typeDialogData.name}
                                required={true}
                            />
                        </li>
                        {!typeDialogData.tagTypeId && (
                            <li>
                                <SelectField
                                    label="型"
                                    _onChange={(value) =>
                                        getDialogValue('type', value)
                                    }
                                    value={String(typeDialogData.type)}
                                    options={TagTypeNames.map(
                                        ({ name, label }) => {
                                            return {
                                                value: String(name),
                                                text: label,
                                            };
                                        }
                                    )}
                                />
                            </li>
                        )}
                        {typeDialogData.type == TagType.List && (
                            <li>
                                <TextField
                                    label="リスト"
                                    _onChange={(value) =>
                                        getDialogValue('list', value)
                                    }
                                    value={typeDialogData.list}
                                    required={true}
                                />
                            </li>
                        )}
                    </ul>
                </DialogBox>
            )}
            {deleteTypeDialogData && (
                <DialogBox
                    title="確認"
                    onOK={onDeleteTypeDialogOK}
                    onCancel={onDeleteTypeDialogCancel}
                >
                    {deleteTypeDialogData.name} を削除しますか？
                </DialogBox>
            )}
            {loading && <Loading />}
        </>
    );
};
