import React, { FC } from 'react';
import { TaskLogModel } from '../../models/TaskModel';
import { difMinutes, reformat } from '../../utils/DateUtils';
import { Link } from 'react-router-dom';
import { safeUrl } from '../../utils/safeUrl';
import { User, useTenant } from '../../contexts/TenantContext';
import { UserMail, UserName, UserTenantName } from '../atomic/text';
import { useAuth } from '../../contexts/AuthContext';
import { getTenantsForSuperAdmin } from '../../APIs/tenants';
import { TenantModel } from '../../models/TenantModel';
import { UserModel } from '../../models/UserModel';

export type TaskLogProp = {
    tasks: TaskLogModel[] | null;
    tenants;
};

export const TaskLogs: FC<TaskLogProp> = ({ tasks, tenants }) => {
    const { isSuperAdmin } = useAuth();

    return (
        <div className="log-list">
            <table className="atomic table">
                <thead>
                    <tr>
                        {isSuperAdmin() ? <th>テナント名</th> : ''}
                        <th>プロジェクト名</th>
                        <th>AI名</th>
                        <th>フォルダ名</th>
                        <th>ステータス</th>
                        <th>作成日時</th>
                        <th>更新日時</th>
                        <th>実行時間(分)</th>
                        <th>実行ユーザー</th>
                        <th>メールアドレス</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks?.map((task) => {
                        return (
                            <Row
                                key={task.aiTaskId}
                                task={task}
                                tenants={tenants}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

type RowLinkProp = {
    to: string;
    name: string;
};

const RowLink: FC<RowLinkProp> = ({ to, name }) => {
    const { isSuperAdmin } = useAuth();
    if (isSuperAdmin()) {
        return <>{name}</>;
    }
    return <Link to={to}>{name}</Link>;
};

type RowProp = {
    task: TaskLogModel;
    tenants?: Array<TenantModel>;
};

const Row: FC<RowProp> = (props) => {
    const { task, tenants } = props;

    const { getTenantUser } = useTenant();
    const { isSuperAdmin } = useAuth();

    const user = getTenantUser(task.createdUserId);

    return (
        <tr key={task.aiTaskId}>
            {isSuperAdmin() ? (
                <td>
                    {tenants ? (
                        <UserTenantName user={user} tenants={tenants} />
                    ) : (
                        <UserTenantName user={user} />
                    )}
                </td>
            ) : (
                ''
            )}
            <td>
                {task.project && task.folder ? (
                    <RowLink
                        to={safeUrl`/app/f/${
                            task.folderId
                        }/p/${task.projectId!}`}
                        name={task.project.name}
                    />
                ) : (
                    <>
                        {task.project
                            ? task.project.name
                            : '(削除されたプロジェクト)'}
                    </>
                )}
            </td>
            <td>
                {task.project
                    ? task.project.aiAnalysisTypeNameJa
                    : '(削除されたプロジェクト)'}
            </td>
            <td>
                {task.folder ? (
                    <RowLink
                        to={safeUrl`/app/folderlist/${task.folderId}`}
                        name={task.folder.folderName}
                    />
                ) : (
                    '(削除されたフォルダ)'
                )}
            </td>
            <td className="app-status">
                <p className={task.status.toLowerCase()}>{task.status}</p>
            </td>
            <td>{reformat(task.createdAt)}</td>
            <td>{reformat(task.updatedAt)}</td>
            <td>
                {task.status !== 'QUEUEING'
                    ? difMinutes(task.createdAt, task.updatedAt)
                    : '-'}
            </td>
            <td>
                <UserName user={getTenantUser(task.createdUserId)} />
            </td>
            <td>
                <UserMail user={getTenantUser(task.createdUserId)} />
            </td>
        </tr>
    );
};
