import React from 'react';
import classNames from 'classnames';

import { TenantTagModel, TagTypeModel, TagType } from '../../models/TagModel';
import { TagIcon, Icon } from '../atomic/icons';
import {
    TextField,
    NumberField,
    DateField,
    SelectField,
} from '../atomic/forms';
import { FilterState, FilterStateItem } from '../FolderSearch';

interface TagDetailInputProp {
    field: TagTypeModel;
    filter: FilterStateItem;
    value: string | undefined;
    onChange: (data: FilterStateItem) => void;
}
const TagDetailInput = (props: TagDetailInputProp) => {
    const { field, value, onChange } = props;
    const { name, type, list } = field;
    const _onChange = (value) => {
        onChange({ field, value });
    };
    const fieldProps = {
        label: `${name}:`,
        value: value ?? '',
        _onChange: _onChange,
    };
    if (type == TagType.Location) {
        return <></>;
    }
    return (
        <li
            className={classNames(
                'tag-detail-input',
                TagType[type].toLowerCase()
            )}
        >
            {type == TagType.Number ? (
                <NumberField {...fieldProps} />
            ) : type == TagType.Date ? (
                <DateField {...fieldProps} />
            ) : type == TagType.List ? (
                <SelectField
                    {...fieldProps}
                    options={(
                        list?.map((text) => ({
                            value: text,
                            text: text,
                        })) ?? []
                    ).concat({
                        value: '',
                        text: '',
                    })}
                />
            ) : (
                <TextField {...fieldProps} />
            )}
        </li>
    );
};

interface TagDetailFilterFormProp {
    className?: string;
    tag: TenantTagModel;
    filters: FilterState;
    onChange: (data: FilterStateItem) => void;
}
const TagDetailFilterForm = (props: TagDetailFilterFormProp) => {
    const { className, tag, filters, onChange } = props;
    const { name, tagTypes } = tag;

    return (
        <div className={classNames('tag-detail-filter', className)}>
            <div className="tag-head">
                <label className="tag-name">
                    <TagIcon /> {name}
                </label>
            </div>
            <ul className="fields">
                {tagTypes.map((field, i) => {
                    const { tagTypeId } = field;
                    const filter = filters[tagTypeId];
                    const { value } = filter || { value: undefined };
                    return (
                        <TagDetailInput
                            field={field}
                            filter={filter}
                            onChange={onChange}
                            value={value}
                            key={i}
                        />
                    );
                })}
            </ul>
        </div>
    );
};

export interface SearchResultFilterProp {
    className?: string;
    tags: TenantTagModel[];
    filters: FilterState;
    onChange: (data: FilterStateItem) => void;
}

export const SearchResultFilter = (props: SearchResultFilterProp) => {
    const { className, tags, filters, onChange } = props;

    return (
        <div className={classNames('search-result-filter', className)}>
            <h3 className="filter-title">
                <Icon icon="fa-solid fa-filter" />
                属性フィルター
            </h3>
            {tags.map((tag) => (
                <TagDetailFilterForm
                    tag={tag}
                    key={tag.tenantTagId}
                    filters={filters}
                    onChange={onChange}
                />
            ))}
        </div>
    );
};
