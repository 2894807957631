import React, { FC, useState, useContext, useCallback, useEffect } from 'react';
import { ErrorDisplay, NotFound } from './Errors';
import { useAuth } from '../contexts/AuthContext';
import { userApi } from '../APIs/userApi';
import { Button } from './atomic/buttons';
import { Loading } from './atomic/effects';
import { DialogBox } from './atomic/dialog';
import { ErrorContext } from '../contexts';
import { getAsync, useGet } from '../hooks/useApi';
import { useToast } from './atomic/toast';
import { useTenant } from '../contexts/TenantContext';
import { UserName } from './atomic/text';

interface APIKeyDialogProps {
    onDone: (key: string) => void;
    onCancel: () => void;
}
type DevUserNameProp = {
    userId: string;
};
const DevUserName: FC<DevUserNameProp> = (props) => {
    const { getTenantUser } = useTenant();

    const creator = getTenantUser(props.userId);
    return <UserName user={creator} />;
};

export interface DeveloperProps {}
export const Developer: FC<DeveloperProps> = () => {
    const [removeKey, setRemoveKey] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setErrors } = useContext(ErrorContext);
    const toast = useToast();
    const { isExternalApi } = useTenant();

    const apiKeys = useGet(userApi.get.apiKeys);
    console.log(apiKeys);

    if (!isExternalApi) {
        return <NotFound />;
    }

    if (apiKeys && apiKeys.error) {
        return <ErrorDisplay error={apiKeys.error} />;
    }

    if (!apiKeys || apiKeys.isLoading || !apiKeys.data) {
        return <Loading />;
    }

    const onCreateNewApi = () => {
        setIsLoading(true);
        getAsync(userApi.get.apiKey)
            .then((resp) => {
                console.log(resp);
                apiKeys.mutate();
            })
            .catch((err) => {
                console.warn(err);
                setErrors((prevState) => [
                    ...prevState,
                    err?.message || 'APIの削除に失敗しました',
                ]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const isClippable = !!global?.navigator?.clipboard?.writeText;
    const copyKey = (key) => {
        global?.navigator?.clipboard?.writeText(key);
        toast('クリップボードにコピーしました');
    };
    const onRemoveAPIKey = (dialog: DialogBox) => {
        setIsLoading(true);
        userApi.delete
            .apiKey(removeKey)
            .then((resp) => {
                console.log(resp);
                apiKeys.mutate();
                setRemoveKey('');
            })
            .catch((err) => {
                console.warn(err);
                dialog.addError(err, 'APIの削除に失敗しました');
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="app-main app-developer">
            <h2 className="header-name">開発者機能</h2>
            <section>
                <h3>有効なAPI Key</h3>
                {!apiKeys.data.data?.length ? (
                    <div className="alert">存在しません</div>
                ) : (
                    <div>
                        <table className="api-key-table">
                            <thead>
                                <tr>
                                    <th>API Key</th>
                                    <th>有効期限</th>
                                    <th>作成者</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                {apiKeys.data.data.map(
                                    ({ apiKey, expireAt, userId }) => (
                                        <tr key={apiKey}>
                                            <td>{apiKey}</td>
                                            <td>{expireAt}</td>
                                            <td>
                                                <DevUserName userId={userId} />
                                            </td>
                                            <td>
                                                <div className="button-group">
                                                    <Button
                                                        onClick={() =>
                                                            setRemoveKey(apiKey)
                                                        }
                                                    >
                                                        削除
                                                    </Button>
                                                    {isClippable && (
                                                        <Button
                                                            onClick={() =>
                                                                copyKey(apiKey)
                                                            }
                                                        >
                                                            コピー
                                                        </Button>
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    </div>
                )}
                <Button onClick={onCreateNewApi}>発行する</Button>
            </section>
            {!!removeKey && (
                <DialogBox
                    onOK={onRemoveAPIKey}
                    onCancel={() => setRemoveKey('')}
                >
                    {removeKey}
                    <br />
                    を削除しますか？
                </DialogBox>
            )}
            {isLoading && <Loading />}
        </div>
    );
};
