import { LatLng } from 'leaflet';

export const zoomToRound = (zoom) => 0.0002 * Math.max(2 ** (18 - zoom), 1);

export const getRoundedLocation = (
    lat: number,
    lng: number,
    round: number = 0.0001
): LatLng => {
    return new LatLng(
        Math.round(lat / round) * round,
        Math.round(lng / round) * round
    );
};
