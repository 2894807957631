import { computed } from 'mobx';
import { AiAnalysisLabelType } from './AiAnalysisType';
import { MouseEvent } from 'react';
import { fixPHPArrayFilter } from '../utils/fixPHPArrayFilter';
import type {
    ActionTag,
    CompressedShapeOptionalId,
    FileMeta,
    Point,
    Rect,
    Tag,
    TagID,
    Transform,
} from '../utils/ServerClientModel';

export interface TagType {
    id: number;
    name: string;
    color: string;
}

export interface FileMetaCompiledContent {
    lastProjectUpdateId: string | null;
    fileMeta: FileMeta;
}

export interface PointWithId extends Point {
    id: string;
}

const PointZero: Point = {
    x: 0,
    y: 0,
} as const;

export interface CompiledPoint extends PointWithId {
    next: CompiledPoint;
    shapeId: string | undefined;
    addPoint: (e: MouseEvent) => void;
    dragPoint: (e: MouseEvent) => void;
}
export interface CompiledShape {
    shapeId: string | undefined;
    points: CompiledPoint[];
}
export interface CompiledTag {
    id: TagID;
    typeId: number;
    tag: Tag;
    strokeColor: string;
    fillColor: string;
    sum: number;
    shapes: CompiledShape[];
    box: Box;
    path: string;
    score?: number | null;
}

export interface CompiledFileMeta {
    tags: CompiledTag[];
    selection: Rect | null;
}

function scaleBox(size: Box, transform: Transform): Box {
    const { moveX, moveY, scaleX, scaleY } = transform;
    size.tl.x = (size.tl.x + moveX) * scaleX;
    size.tl.y = (size.tl.y + moveY) * scaleY;
    size.br.x = (size.br.x + moveX) * scaleX;
    size.br.y = (size.br.y + moveY) * scaleY;
    return size;
}

export function boundsForShapes(shapes: CompressedShapeOptionalId[]): Box {
    const tl: Point = {
        x: Number.POSITIVE_INFINITY,
        y: Number.POSITIVE_INFINITY,
    };
    const br: Point = {
        x: Number.NEGATIVE_INFINITY,
        y: Number.NEGATIVE_INFINITY,
    };
    for (const { points } of shapes) {
        for (const point of points) {
            const x = point[1];
            const y = point[2];
            if (x < tl.x) {
                tl.x = x;
            }
            if (y < tl.y) {
                tl.y = y;
            }
            if (x > br.x) {
                br.x = x;
            }
            if (y > br.y) {
                br.y = y;
            }
        }
    }
    return { tl, br };
}

export interface Box {
    tl: Point;
    br: Point;
}

function parseColorComponent(oneDigit?: string, twoDigit?: string): number {
    if (oneDigit !== undefined) {
        twoDigit = oneDigit + oneDigit;
    }
    return parseInt(twoDigit!, 16);
}

function setAlpha(color: string, alpha: number) {
    const parts = /^\#((.)(.)(.)|(..)(..)(..))$/.exec(color);
    if (!parts) {
        return color;
    }
    const r = parseColorComponent(parts[2], parts[5]);
    const g = parseColorComponent(parts[3], parts[6]);
    const b = parseColorComponent(parts[4], parts[7]);
    return `rgba(${r} ${g} ${b} / ${alpha})`;
}

export function boundsForTag(tag: ActionTag) {
    const shapes: CompressedShapeOptionalId[] =
        tag.dataType === 'shapes'
            ? tag.shapes
            : [
                  {
                      id: undefined,
                      points: tag.points,
                  },
              ];
    return boundsForShapes(shapes);
}

function compileShape(
    tag: Tag,
    offset: Point,
    zoom: number,
    addPoint: (this: CompiledPoint, e: MouseEvent) => void,
    dragPoint: (this: CompiledPoint, e: MouseEvent) => void
) {
    const { x, y } = offset;
    let shapes: CompressedShapeOptionalId[] =
        tag.dataType === 'shapes' ? tag.shapes : [{ points: tag.points }];
    let box = boundsForShapes(shapes);
    const { transform } = tag;
    if (transform) {
        shapes = shapes.map(({ id, points }) => {
            return {
                id,
                points: points.map((point) => [
                    point[0],
                    (point[1] + transform.moveX) * transform.scaleX,
                    (point[2] + transform.moveY) * transform.scaleY,
                ]),
            };
        });
        box = scaleBox(box, transform);
    }
    const compiledShapes: CompiledShape[] = [];
    for (const { id: shapeId, points } of shapes) {
        if (points.length === 0) {
            continue;
        }
        const compiled: CompiledPoint[] = [];
        let prev: CompiledPoint | undefined;
        let first: CompiledPoint | undefined;
        for (const point of points) {
            const current: CompiledPoint = {
                id: point[0],
                shapeId,
                x: (point[1] + x) * zoom,
                y: (point[2] + y) * zoom,
                next: null as unknown as CompiledPoint,
                addPoint,
                dragPoint,
            };
            current.addPoint = current.addPoint.bind(current);
            current.dragPoint = current.dragPoint.bind(current);
            if (prev) {
                prev.next = current;
            }
            if (!first) {
                first = current;
            }
            prev = current;
            compiled.push(current);
        }
        if (prev) {
            prev.next = first!;
        }
        compiledShapes.push({
            shapeId,
            points: compiled,
        });
    }
    return {
        box: scaleBox(box, { moveX: x, moveY: y, scaleX: zoom, scaleY: zoom }),
        shapes: compiledShapes,
    };
}

export function compileFileMeta(
    aiLabels: AiAnalysisLabelType[],
    fileMeta: FileMeta | undefined,
    offset: Point = PointZero,
    zoom: number = 1,
    addPoint?: (
        shapeId: string | undefined,
        afterPointId: string,
        beforePointId: string,
        x: number,
        y: number
    ) => string | undefined,
    dragPoint?: (
        e: MouseEvent,
        shapeId: string | undefined,
        pointId: string
    ) => void
): CompiledFileMeta {
    const result: CompiledFileMeta = {
        selection: null,
        tags: [],
    };
    if (!fileMeta) {
        return result;
    }
    offset = {
        x: offset.x / zoom,
        y: offset.y / zoom,
    };
    if (fileMeta.selection) {
        result.selection = {
            x: offset.x + fileMeta.selection.x,
            y: offset.y + fileMeta.selection.y,
            width: fileMeta.selection.width,
            height: fileMeta.selection.height,
        };
    }
    for (const tag of fixPHPArrayFilter(fileMeta.tags)) {
        const type = aiLabels.find((type) => type.id == tag.type)!;
        if (!type) {
            continue;
        }
        const addPointHandler = function (this: CompiledPoint, e: MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            if (!addPoint) return;
            const pointId = addPoint(
                this.shapeId,
                this.id,
                this.next.id,
                e.nativeEvent.offsetX,
                e.nativeEvent.offsetY
            );
            if (!pointId) return;
            dragPoint?.(e, this.shapeId, pointId);
        };
        const dragPointHandler = function (this: CompiledPoint, e: MouseEvent) {
            e.preventDefault();
            e.stopPropagation();
            dragPoint?.(e, this.shapeId, this.id);
        };
        const shapes = computed(() =>
            compileShape(tag, offset, zoom, addPointHandler, dragPointHandler)
        );
        const path = computed(() => {
            const paths: string[] = [];
            for (const { points } of shapes.get().shapes) {
                paths.push(
                    `M ${points.map((p) => `${p.x} ${p.y}`).join(' L')} Z`
                );
            }
            return paths.join(' ');
        });
        result.tags.push({
            id: tag.id,
            typeId: type.id,
            sum: tag.sum,
            tag,
            get shapes() {
                return shapes.get().shapes;
            },
            get box() {
                return shapes.get().box;
            },
            get path() {
                return path.get();
            },
            strokeColor: setAlpha(type.color, 0.5),
            fillColor: setAlpha(type.color, 0.5),
            score: tag?.score ?? null,
        });
    }
    return result;
}
