import { ulid } from 'ulidx';
import { AiAnalysisType } from '../../models/AiAnalysisType';
import {
    ExportTemplateLabelModel,
    ExportTemplateModel,
    Label,
} from '../../models/AiTemplateModel';
import { CreateTemplateRequest } from '../templateApi';

function assertAdminTemplateLabel(
    json: any
): asserts json is ExportTemplateLabelModel {
    if (typeof json !== 'object' && json !== null) {
        throw new Error('データ形式が不正です');
    }
    if (typeof json.label !== 'string') {
        throw new Error('データ形式が不正です');
    }
    if (json.label.length === 0) {
        throw new Error('データ形式が不正です');
    }
    if (json.children === null || json.children === undefined) {
        return;
    }
    if (!Array.isArray(json.children)) {
        throw new Error('データ形式が不正です');
    }
    for (const child of json.children) {
        assertAdminTemplateLabel(child);
    }
}

export function assertAdminTemplate(
    aiAnalysisTypes: AiAnalysisType[],
    json: any
): asserts json is ExportTemplateModel {
    if (typeof json !== 'object' || json === null) {
        throw new Error('不正なJSONです');
    }
    const name = json.name;
    if (typeof name != 'string') {
        throw new Error('テンプレート名が未設定です');
    }
    const aiAnalysisTypeId = json.aiAnalysisTypeId;
    if (typeof aiAnalysisTypeId != 'string') {
        throw new Error('AI種別が未設定です');
    }
    if (
        !aiAnalysisTypes.find(
            (type) => type.aiAnalysisTypeId == aiAnalysisTypeId
        )
    ) {
        throw new Error(`不正なAI種別です: ${aiAnalysisTypeId}`);
    }
    if (!Array.isArray(json.labels)) {
        throw new Error('不正なラベル情報です');
    }
    for (const item of json.labels) {
        assertAdminTemplateLabel(item);
    }
}

export function importAdminTemplates(
    aiAnalysisTypes: AiAnalysisType[],
    json: any
): CreateTemplateRequest {
    assertAdminTemplate(aiAnalysisTypes, json);
    const labels: Label[] = [];
    const { name, aiAnalysisTypeId } = json;
    const pushChildren = (
        parentId: string | null,
        items: ExportTemplateLabelModel[]
    ) => {
        items.forEach((item, index) => {
            const id = ulid();
            const label: Label = {
                aiTemplateLabelId: id,
                name: item.label,
                order: index,
            };
            if (parentId) {
                label.parentAiTemplateLabelId = parentId;
            }
            labels.push(label);
            if (item.children) {
                pushChildren(id, item.children);
            }
        });
    };
    pushChildren(null, json.labels);
    return {
        aiAnalysisTypeId,
        name,
        labels,
    };
}
