import { useCallback, useState } from 'react';
import { useCyclicEffect } from './useCyclicEffect';
import deepEquals from 'fast-deep-equal';

export function useCyclicMemo<T>(
    everyMs: number,
    memo: () => T,
    compare: (a: T, b: T) => boolean = deepEquals
) {
    const [data, setData] = useState<T>(memo());
    const handler = useCallback(() => {
        const newData = memo();
        if (compare(data, newData)) return;
        setData(newData);
    }, [data, memo]);
    useCyclicEffect(everyMs, handler);
    return data;
}
