import React, { useCallback, useState } from 'react';
import { DialogProps } from '../../hooks/useDialog';
import { DialogBox } from '../atomic/dialog';
import { ImportTagRequest } from '../../APIs/tag/importTenantTag';
import { TextField } from '../atomic/forms';
export type ImportTenantTagsProp = DialogProps<
    {
        request: ImportTagRequest;
    },
    ImportTagRequest
>;
export const ImportTenantTagsDialog = ({
    options,
    onOK: onCommit,
    ...rest
}: ImportTenantTagsProp) => {
    const [request, setRequest] = useState<ImportTagRequest>(options.request);
    const setName = useCallback(
        (name: string) => setRequest((request) => ({ ...request, name })),
        [setRequest]
    );
    const onOK = useCallback(() => onCommit(request), [onCommit, request]);
    return (
        <DialogBox
            title={'属性のインポート'}
            okText={'インポート'}
            {...rest}
            onOK={onOK}
        >
            <p>この内容で属性をインポートしますか？</p>
            <ul className="fields">
                <li>
                    <TextField
                        label="名前"
                        _onChange={setName}
                        value={request.name}
                        required={true}
                    />
                </li>
            </ul>
        </DialogBox>
    );
};
