export const UserRole = {
    SuperAdmin: 'システム管理者',
    Admin: 'テナント管理者',
    User: 'ユーザ',
    External: '協力会社ユーザ',
};

export interface UserModel {
    userId: string;
    familyName: string;
    givenName: string;
    tenantId: string;
    role: string;
    email: string;
    phoneNumber: string;
    createdAt: string;
}

export interface UserOneModel {
    familyName: string;
    givenName: string;
    tenantId: string;
    email: string;
    phoneNumber: string;
    password: string;
    createdAt: string;
}
