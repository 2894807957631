import classNames from 'classnames';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
    DeleteIcon,
    FolderIcon,
    FileIcon,
    VideoFileIcon,
    ProjectIcon,
    Icon,
    Icons,
    MosaicPendingIcon,
    MosaicRunningIcon,
    MosaicErrorIcon,
    MovieSliceIcon,
} from '../atomic/icons';
import { Pager } from './Pager';
import { getProjectItemLink } from '../Project';
import { useGet } from '../../hooks/useApi';
import { projectApi } from '../../APIs/projectApi';

const CellMenu = (props) => {
    const { item, projects = [], onClickDelete, onClickCopy, isAccess } = props;
    const navigate = useNavigate();
    const navToReel = (proj) => {
        const { folderId, fileId } = item;
        const { projectId } = proj;
        navigate(getProjectItemLink({ projectId, folderId, fileId }));
    };

    return (
        <div className="cell-menu">
            <ul className="menu-list">
                {isAccess.showAiResult == 1 && (
                    <>
                        {projects.map((proj, i) => {
                            return (
                                <li
                                    className="menu-item reel"
                                    title={proj.name}
                                    onClick={() => navToReel(proj)}
                                    key={i}
                                >
                                    <ProjectIcon />
                                    <label>{proj.name}</label>
                                </li>
                            );
                        })}
                    </>
                )}
                {!item?.isFile && (
                    <li
                        className="menu-item copy"
                        onClick={() => onClickCopy(item)}
                    >
                        <Icon icon={Icons.Copy} />
                        <label>コピー</label>
                    </li>
                )}
                {isAccess.write == 1 && (
                    <>
                        <li
                            className="menu-item delete"
                            onClick={() => onClickDelete(item)}
                        >
                            <DeleteIcon />
                            <label>削除</label>
                        </li>
                    </>
                )}
            </ul>
        </div>
    );
};

const Cell = (props) => {
    const {
        className,
        item,
        children,
        link,
        openInNewTab = false,
        onClickDelete,
        onClickCopy,
        projects,
        showMenu,
        setVisibleMenu,
        isAccess,
    } = props;
    const { name } = item;
    const _onClickDelete = (args) => {
        setVisibleMenu(undefined);
        onClickDelete(args);
    };
    const _onClickCopy = (args) => {
        setVisibleMenu(undefined);
        if (!!onClickCopy) {
            onClickCopy(args);
        }
    };
    const showMenuToggler = () => {
        if (item.fileId) {
            return isAccess.showAiResult == 1 || isAccess.write == 1;
        }
        return isAccess.write == 1;
    };

    const sliceTasks = useGet(
        item.folder
            ? projectApi.get.getVideoSliceStatus(item.folder.folderId)
            : null
    );

    const isSlice = (id, sliceTasks) => {
        if (!sliceTasks || !sliceTasks.data?.data.length) {
            return false;
        }
        return sliceTasks.data?.data.some((data) => {
            return data.request.files.some((file_id) => file_id === id);
        });
    };
    return (
        <li className={classNames('cell', className)}>
            <div className="visual-outer">
                <Link to={link} target={openInNewTab ? 'blank' : undefined}>
                    <div className="visual">{children}</div>
                </Link>
                {showMenuToggler() && (
                    <div
                        className="cell-menu-toggler"
                        onClick={() =>
                            setVisibleMenu(showMenu ? undefined : item.id)
                        }
                    >
                        <i className="fa-solid fa-ellipsis-vertical" />
                    </div>
                )}
                {showMenu && (
                    <CellMenu
                        item={item}
                        projects={projects}
                        onClickDelete={_onClickDelete}
                        onClickCopy={_onClickCopy}
                        isAccess={isAccess}
                    />
                )}
            </div>
            <div className="name">
                {item.mosaic === 'PENDING' ? (
                    <MosaicPendingIcon />
                ) : item.mosaic === 'RUNNING' ? (
                    <MosaicRunningIcon />
                ) : item.mosaic === 'ERROR' ? (
                    <MosaicErrorIcon />
                ) : isSlice(item.fileId, sliceTasks) ? (
                    <MovieSliceIcon />
                ) : (
                    <></>
                )}
                {name}
            </div>
        </li>
    );
};

const Folder = (props) => {
    const {
        item,
        onClickDelete,
        showMenu,
        setVisibleMenu,
        isAccess,
        onClickCopy,
    } = props;
    const { folderId } = item;

    return (
        <Cell
            className="folder"
            item={item}
            link={`/app/folderlist/${folderId}`}
            onClickDelete={onClickDelete}
            onClickCopy={onClickCopy}
            {...{ showMenu, setVisibleMenu }}
            isAccess={isAccess}
        >
            <FolderIcon />
        </Cell>
    );
};

export const ThumbImage = (props) => {
    const { src, sizePx } = props;
    const params = {
        width: sizePx,
        hight: sizePx,
    };
    const urlWithParams = src + '?' + new URLSearchParams(params).toString();
    return <img src={urlWithParams} />;
};

const VideoThumbnail = (props) => {
    const { file, sizePx } = props;
    if (!file.thumbUrl) {
        return <VideoFileIcon className="type-video" />;
    } else {
        return (
            <div className="mov-thumb-wrapper">
                <ThumbImage src={file.thumbUrl} sizePx={sizePx} />
                <VideoFileIcon />
            </div>
        );
    }
};

const File = (props) => {
    const {
        item,
        onClickDelete,
        projects,
        showMenu,
        setVisibleMenu,
        isAccess,
    } = props;
    const { url, thumbUrl, mimeType = '' } = item;
    const isImage = mimeType.startsWith('image/');
    const isVideo = mimeType.startsWith('video/');

    return (
        <Cell
            className="file"
            item={item}
            link={url}
            openInNewTab
            onClickDelete={onClickDelete}
            projects={projects}
            {...{ showMenu, setVisibleMenu }}
            isAccess={isAccess}
        >
            {isImage && <ThumbImage src={thumbUrl} sizePx={128} />}
            {isVideo && <VideoThumbnail file={item} sizePx={128} />}
            {!isImage && !isVideo && <FileIcon className="type-unknown" />}
        </Cell>
    );
};

export const FolderThumbnails = (props) => {
    const {
        currentFolder,
        pagination,
        setPage,
        setLimit,
        onClickDelete,
        onClickCopy,
        frameMap,
        isAccess,
    } = props;
    //console.log('FolderThumbnails', { currentFolder, pagination, frameMap });
    const { projects, items } = currentFolder;

    const [visibleMenuId, setVisibleMenu] = React.useState();

    const list = items.map((d) => ({
        ...d,
        name: d.fileId ? d.fileName : d.folderName,
        isFile: d.fileId ? true : false,
        trClass: d.fileId ? 'file' : 'folder',
        id: d.fileId ? d.fileId : d.folderId,
    }));
    // console.log({ list });

    const cells = list.map((item, i) => {
        const { isFile } = item;
        return isFile ? (
            <File
                item={item}
                onClickDelete={onClickDelete}
                projects={projects}
                showMenu={visibleMenuId === item.id}
                setVisibleMenu={setVisibleMenu}
                key={i}
                isAccess={isAccess}
            />
        ) : (
            <Folder
                item={item}
                onClickDelete={onClickDelete}
                onClickCopy={onClickCopy}
                showMenu={visibleMenuId === item.id}
                setVisibleMenu={setVisibleMenu}
                key={i}
                isAccess={isAccess}
            />
        );
    });

    const pager = React.useMemo(
        () => (
            <Pager
                pagination={pagination}
                setPage={setPage}
                setLimit={setLimit}
            />
        ),
        [pagination]
    );
    return (
        <div className="folder-thumbnails">
            {pager}
            <ul className="outer">{cells}</ul>
            {pager}
        </div>
    );
};
