export function waitFor(delay: number, signal?: AbortSignal): Promise<void> {
    return new Promise((resolve) => {
        const handler = () => {
            clearTimeout(timeout);
            signal?.removeEventListener('abort', handler);
            resolve();
        };
        signal?.addEventListener('abort', handler);
        const timeout = setTimeout(handler, delay);
    });
}
export function createTimeDelay(delay: number): () => Promise<void> {
    let nextCheck = Date.now() + delay;
    return async () => {
        const timeout = nextCheck - Date.now();
        if (timeout > 0) {
            await waitFor(timeout);
        }
        nextCheck = Date.now() + delay;
    };
}
