import React, { FC } from 'react';
import { getUsers, postUser } from '../APIs/userApi';
import { UserModel } from '../models/UserModel';
import { UserOneModel } from '../models/UserModel';
import { useAuth, User } from '../contexts/AuthContext';
import { Auth } from 'aws-amplify';
import { AmplifyUser } from '@aws-amplify/ui';
import { StringLiteral } from 'typescript';

export const useUser = () => {
    const [userList, setUserList] = React.useState<
        Array<UserModel> | undefined
    >(undefined);

    const errorMessage = (msg) => {
        alert(msg || '未知のエラーが発生しました');
    };

    React.useEffect(() => {
        getUsers()
            .then((resp) => {
                if (resp.success) {
                    console.log('resp.data', resp.data);
                    setUserList(resp.data);
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                errorMessage(err.message);
            });
    }, []);

    return userList;
};

export const useUserOne = () => {
    const [user, setUser] = React.useState<UserOneModel | undefined>(undefined);
    const { fetchUser } = useAuth();

    React.useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            setUser({
                givenName: user.attributes.given_name,
                familyName: user.attributes.family_name,
                tenantId: user.attributes['custom:tenant_id'],
                email: user.attributes.email,
                phoneNumber: user.attributes.phone_number,
                password: '',
                createdAt: '',
            });
        });
    }, []);

    return user;
};

export const postUserOne = (userId: string) => {
    const errorMessage = (msg) => {
        alert(msg || '未知のエラーが発生しました');
    };

    console.log('userId Post', userId);

    postUser(userId)
        .then((resp) => {
            if (resp.success) {
                console.log('resp.data', resp.data);
            } else {
            }
        })
        .catch((err) => {
            console.log(err);
            errorMessage(err.message);
        });

    return;
};

export const useFetchUserSelf = (user?: AmplifyUser) => {
    if (!user) return undefined;
    const [userInfo, setUserInfo] = React.useState<User>();

    React.useEffect(() => {
        const fetchUserData = async () => {
            const attributes = await Auth.userAttributes(user);
            const userInfo: User = attributes.reduce(
                (acc, attribute) => {
                    switch (attribute.Name) {
                        case 'sub':
                            return { ...acc, userId: attribute.Value };
                        case 'phone_number':
                            return { ...acc, phoneNumber: attribute.Value };
                        case 'custom:tenant_id':
                            return { ...acc, tenantId: attribute.Value };
                        case 'given_name':
                            return { ...acc, lastName: attribute.Value };
                        case 'family_name':
                            return { ...acc, firstName: attribute.Value };
                        case 'email':
                            return { ...acc, email: attribute.Value };
                        case 'custom:role':
                            return { ...acc, role: attribute.Value };
                        default:
                            return acc;
                    }
                },
                {
                    userId: '',
                    phoneNumber: '',
                    tenantId: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: '',
                }
            );
            setUserInfo(userInfo);
        };

        fetchUserData();
    }, [user]);

    return userInfo;
};
export interface UpdateUserSelfRequest {
    familyName: string;
    givenName: string;
    phoneNumber: string;
    oldPassword?: string;
    newPassword?: string;
}
export const postUserProfile = async ({
    familyName,
    givenName,
    phoneNumber,
    oldPassword,
    newPassword,
}: UpdateUserSelfRequest) => {
    const user = await Auth.currentAuthenticatedUser();
    return [
        await Auth.updateUserAttributes(user, {
            family_name: familyName,
            given_name: givenName,
            phone_number: phoneNumber,
        }),
        oldPassword && newPassword
            ? await Auth.changePassword(user, oldPassword, newPassword)
            : null,
    ];
};
