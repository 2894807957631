import React, { useMemo } from 'react';

import {
    FolderIcon,
    SearchIcon,
    ProjectIcon,
    Icon,
    Icons,
    HistoryIcon,
} from './atomic/icons';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTenant } from '../contexts/TenantContext';
import { useGet } from '../hooks/useApi';
import { userTask } from '../APIs/userApi';
import { projectApi } from '../APIs/projectApi';
import { Link } from 'react-router-dom';
import { TaskModel, TaskState } from '../models/TaskModel';

const BigLink = (props) => {
    const { className, text, path, children } = props;
    return (
        <div className="big-link-outer">
            <NavLink to={path}>
                <div className={classNames('big-link', className)}>
                    <div title={text} role="icon">
                        {children}
                    </div>
                    <div role="title">{text}</div>
                </div>
            </NavLink>
        </div>
    );
};

const TaskListItem = ({ projectId, folderId, status }) => {
    const project = useGet(
        projectId ? projectApi.get.project(projectId) : null
    );
    if (projectId) {
        if (project?.isLoading || project?.error || !project?.data?.data) {
            return <></>;
        }
    }

    const taskName = projectId ? project.data?.data?.name : '動画分割';
    const url = projectId
        ? `/app/project/${projectId}`
        : `/app/folderlist/${folderId}`;

    return (
        <p>
            <Link to={url} className="project-name">
                {taskName}
            </Link>
            を{status == 'RUNNING' ? '実行中' : '実行待ち中'}です
        </p>
    );
};
const TaskList = () => {
    const task = useGet(userTask);

    if (task?.isLoading || task?.error || !task?.data?.data?.length) {
        return <></>;
    }

    const taskMap = new Map(
        task.data.data.map((task) => [
            task.projectId ? task.projectId : task.folderId,
            task,
        ])
    );
    const tasks: TaskModel[] = [...taskMap.values()];
    return (
        <div className="task-info">
            {tasks.map(({ projectId, folderId, status }) => (
                <TaskListItem
                    projectId={projectId}
                    folderId={folderId}
                    key={projectId}
                    status={status}
                />
            ))}
        </div>
    );
};
/**
 * ホーム画面 (仮)
 */
export const Home = () => {
    // const notifications = [];
    const { isAdmin, isSuperAdmin } = useAuth();
    const { tenantOptions } = useTenant();

    return (
        <div className="app-main home">
            {/* {notifications.length > 0 && (
                <div className="notifications">
                    <p className="info">
                        トップレベルお知らせ 配置予定 (なければ非表示)
                    </p>
                </div>
            )} */}
            {!isSuperAdmin() && <TaskList />}
            {isSuperAdmin() && (
                <section>
                    <h2>テナント管理ツール</h2>
                    <ul className="home-menus">
                        <li className="home-menu-item">
                            <BigLink
                                text="テナント管理"
                                path="/app/superadmin/tenants"
                            >
                                <FolderIcon />
                            </BigLink>
                        </li>
                        <li className="home-menu-item">
                            <BigLink
                                text="履歴"
                                path="/app/superadmin/logs/projects"
                            >
                                <HistoryIcon />
                            </BigLink>
                        </li>
                    </ul>
                </section>
            )}

            {!isSuperAdmin() && (
                <section>
                    <h2>ユーザツール</h2>
                    <ul className="home-menus">
                        <li className="home-menu-item">
                            <BigLink
                                text="フォルダーリスト"
                                path="/app/folderlist"
                            >
                                <FolderIcon />
                            </BigLink>
                        </li>
                        <li className="home-menu-item">
                            <BigLink text="詳細検索" path="/app/foldersearch">
                                <SearchIcon />
                            </BigLink>
                        </li>
                    </ul>
                </section>
            )}

            {isAdmin() && (
                <section>
                    <h2>管理者ツール</h2>
                    <ul className="home-menus">
                        <li className="home-menu-item">
                            <BigLink text="ユーザ管理" path="/app/admin/users">
                                <Icon icon="fa-solid fa-users-gear" />
                            </BigLink>
                        </li>
                        <li className="home-menu-item">
                            <BigLink text="属性値管理" path="/app/admin/tags">
                                <Icon icon="fa-solid fa-tags" />
                            </BigLink>
                        </li>
                        <li className="home-menu-item">
                            <BigLink
                                text="テンプレート管理"
                                path="/app/admin/templates"
                            >
                                <ProjectIcon />
                            </BigLink>
                        </li>
                        <li className="home-menu-item">
                            <BigLink
                                text="履歴"
                                path="/app/admin/logs/projects"
                            >
                                <HistoryIcon />
                            </BigLink>
                        </li>
                        {tenantOptions != null &&
                            'kintone' in tenantOptions && (
                                <>
                                    <li className="home-menu-item">
                                        <BigLink
                                            text="kintone連携"
                                            path="/app/admin/kintone"
                                        >
                                            <Icon icon={Icons.Link} />
                                        </BigLink>
                                    </li>
                                </>
                            )}
                    </ul>
                </section>
            )}
        </div>
    );
};
