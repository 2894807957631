// injected on build

export const APP_VERSION: string = import.meta.env.NPM_PACKAGE_VERSION;
export const BUILD_TIME = new Date(import.meta.env.BUILD_TIME);
export const BUILD_COMMIT: string =
    import.meta.env.VITE_BUILD_COMMIT ?? 'unknown';

export const ENABLE_MOCK_API = getBoolean(import.meta.env.VITE_ENABLE_MOCK_API);
export const SHOW_EXTRA_INFO_IN_HEADER = getBoolean(
    import.meta.env.VITE_SHOW_EXTRA_INFO_IN_HEADER
);
export const API_BASE_URL: string = '/api/';

function getBoolean(env: string | null | undefined) {
    if (typeof env === 'string') {
        return /^(true|1|on)$/i.test(env);
    }
    if (typeof env === 'boolean') {
        return env;
    }
    if (typeof env === 'number') {
        return env === 1;
    }
    return false;
}
