import { ForwardedRef } from 'react';

export function fillRef<T>(ref: ForwardedRef<T>, value: T | null): void {
    if (!ref) return;
    if (typeof ref === 'function') {
        ref(value);
        return;
    }
    ref.current = value;
}
