import React, { memo, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { TaskModel, TaskResult, TaskState } from '../../models/TaskModel';
import { Button, ButtonProps, IconButton } from '../atomic/buttons';
import { Icon, Icons, WarningIcon } from '../atomic/icons';
import { CancelTaskDialog } from '../atomic/dialog';
import { isPhoto } from '../../models/FileModel';
import {
    isTaskRunning,
    projectApi,
    useProjectAiType,
    useProjectTask,
} from '../../APIs/projectApi';
import * as userApi from '../../APIs/userApi';
import { toLogStatement } from './model/ProjectTaskSync';
import { useCyclicMemo } from '../../hooks/useCyclicMemo';
import { differenceInSeconds, format } from 'date-fns';
import update from 'lodash/update';
import { useGet, useMutation } from '../../hooks/useApi';
import { useMutationDialog } from '../../hooks/useMutationDialog';
import { CLEAR_TASK_INTERVAL } from '../../consts';
import { useClickable } from '../../hooks/useClickable';
import { FoldersAPI } from '../../APIs/foldersApi';
import { ReelFileLink } from './ReelFileLink';
import { useJIWScroll } from '../../hooks/useJIWScroll';

interface TaskStateDisplayProps {
    fileId: string;
    task: TaskModel;
}

function formatDuration(seconds: number): string {
    if (seconds === 0) return '00:00';
    const allSecs = Math.abs(seconds);
    const secs = allSecs % 60;
    const mins = (allSecs / 60) | 0;
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
}

const TaskTimeDisplay = ({ task }: { task: TaskModel }) => {
    const createdAt = useMemo(() => new Date(task.createdAt), [task.createdAt]);
    const updatedAt = useMemo(() => new Date(task.updatedAt), [task.updatedAt]);
    const status = useMemo(() => task.status, [task.status]);
    const [runningTime] = useCyclicMemo(
        250,
        useCallback(() => {
            const base = isTaskRunning(status) ? new Date() : updatedAt;
            return [
                differenceInSeconds(createdAt, base),
                differenceInSeconds(updatedAt, base),
            ];
        }, [task, status, createdAt, update])
    );

    return (
        <div className="reel--task--timebar">{formatDuration(runningTime)}</div>
    );
};

const TaskStateOtherFilesDisplay = ({ task }: TaskStateDisplayProps) => {
    if (!isTaskRunning(task.status)) {
        return <></>;
    }
    const fileIds = task.request.files?.map((file) => file.file_id) ?? [];
    return (
        <div className="reel--task-others">
            <div className="reel--task-others--title">
                <WarningIcon />
                {fileIds.length > 0
                    ? '以下のファイルに対して解析処理を実施中です。'
                    : '現在実行中の解析処理が完了するまでお待ちください'}
            </div>
            <ul>
                {fileIds.map((fileId) => (
                    <li key={fileId}>
                        <ReelFileLink fileId={fileId} />
                    </li>
                ))}
            </ul>
        </div>
    );
};

type TaskStateSummaryProps = {
    task: TaskModel;
    onClick: () => void;
};
const TaskStateSummary = memo(({ task, onClick }: TaskStateSummaryProps) => {
    const cancelTask = useMutationDialog(
        projectApi.mutate.cancelProcess,
        CancelTaskDialog,
        { onError: 'AIの停止に失敗しました' }
    );
    return (
        <div
            className="reel--task-log--header-box"
            {...useClickable(onClick, [onClick])}
            title={task.aiTaskId}
        >
            <div className="reel--task-log--header">
                <TaskTimeDisplay task={task} />
                {isTaskRunning(task.status) ? (
                    <IconButton
                        className="reel--cancel-button no-decoration"
                        icon={Icons.Cancel}
                        title="キャンセル"
                        onClick={(e) => {
                            e.stopPropagation();
                            cancelTask.show({
                                projectId: task.projectId,
                                taskId: task.aiTaskId,
                            });
                        }}
                    />
                ) : null}
            </div>
            <svg className="reel--task--status" width="100%" height="2">
                <line x1="0" x2="100%" y1="50%" y2="50%" strokeWidth="100%" />
            </svg>
            {cancelTask.dialog}
        </div>
    );
});

type LogRowProps = {
    result: TaskResult;
    createdAt: Date;
    subTaskIds: string[];
};
const LogRow = memo(({ result, createdAt, subTaskIds }: LogRowProps) => {
    const entry = toLogStatement(result, subTaskIds);
    if (!entry) {
        return <></>;
    }
    return (
        <tr
            className={`reel--task-log-item reel--task-log-item--${entry.type}`}
        >
            <th title={result.createdAt}>
                {formatDuration(
                    differenceInSeconds(createdAt, new Date(result.createdAt))
                )}
            </th>
            <td>{entry.message}</td>
        </tr>
    );
});

interface TaskLogListProps {
    task: TaskModel;
    isBottom: boolean;
    createdAt: Date;
}
const TaskLogList = memo(({ task, isBottom, createdAt }: TaskLogListProps) => {
    const { log, hasBefore } = useProjectTask(task, isBottom);
    return (
        <>
            {log
                .concat()
                .reverse()
                .map((result) => (
                    <LogRow
                        key={result.aiResultId}
                        result={result}
                        createdAt={createdAt}
                        subTaskIds={task.subTaskIds}
                    />
                ))}
            {hasBefore ? (
                <tr>
                    <th>
                        <Icon icon={Icons.Spinner} />
                    </th>
                    <td>
                        {task.status == TaskState.queueing ? (
                            <>AI実行待ち</>
                        ) : (
                            <>読込み中...</>
                        )}
                    </td>
                </tr>
            ) : (
                <tr className={`reel--task-log-item reel--task-log-item--info`}>
                    <th>00:00</th>
                    <td>タスクリクエストしました。</td>
                </tr>
            )}
        </>
    );
});

type TaskLogDisplayProps = {
    task: TaskModel;
};
const TaskLogDisplay = ({ task }: TaskLogDisplayProps) => {
    const { ref, isBottom } = useJIWScroll<HTMLDivElement>({
        bottomMargin: 10,
    });
    const createdAt = useMemo(() => new Date(task.createdAt), [task.createdAt]);
    return (
        <div className="reel--task-log--log-box" ref={ref}>
            <table className="reel--task-log-info">
                <thead>
                    <tr className="reel--task-log-item reel--task-log-item--task">
                        <th colSpan={2}>{task.aiTaskId}</th>
                    </tr>
                    <tr className="reel--task-log-item reel--task-log-item--time">
                        <th colSpan={2}>
                            {format(createdAt, 'yyyy/MM/dd HH:mm:ss')}
                        </th>
                    </tr>
                </thead>
            </table>
            <table className="reel--task-log-items">
                <tbody>
                    <TaskLogList
                        task={task}
                        isBottom={isBottom}
                        createdAt={createdAt}
                    />
                </tbody>
            </table>
        </div>
    );
};

const TaskStateDisplay = memo(({ task, fileId }: TaskStateDisplayProps) => {
    const [open, setOpen] = useState(false);
    const fileIds = task.request.files?.map((file) => file.file_id) ?? [];
    if (!fileIds.includes(fileId) && task.type !== 'folder') {
        return <TaskStateOtherFilesDisplay task={task} fileId={fileId} />;
    }
    return (
        <div
            className={classNames('reel--task-info', {
                'reel--task-info--success': task.status === TaskState.success,
                'reel--task-info--running': task.status === TaskState.running,
                'reel--task-info--error': task.status === TaskState.error,
                'reel--task-info--open': open,
                'reel--task-info--closed': !open,
            })}
        >
            <div className="reel--task-log">
                <TaskStateSummary
                    task={task}
                    onClick={() => setOpen((open) => !open)}
                />
                {open ? <TaskLogDisplay task={task} /> : null}
            </div>
        </div>
    );
});

export interface ReelTaskUIProps {
    fileId: string;
    projectId: string;
}

type AiButtonProps = ButtonProps & {
    projectId: string;
};
const AiButton = ({ projectId, ...props }: AiButtonProps) => {
    const aiAnalysisType = useProjectAiType(projectId);
    return (
        <Button icon={Icons.RunAi} {...props}>
            {aiAnalysisType.data?.nameJa ?? '...'}
        </Button>
    );
};

export const ReelTaskUI = memo(({ projectId, fileId }: ReelTaskUIProps) => {
    const file = useGet(FoldersAPI.get.file(fileId));
    const task = useMutation(projectApi.mutate.startAiProcess(projectId), {
        swr: { refreshInterval: 5000, keepPreviousData: true },
    });
    const scheduleAi = useCallback(() => {
        task.send({
            fileIds: [fileId],
        });
    }, [task.send, fileId]);
    const userTask = useGet(userApi.userTask);
    const lockReason = useMemo(() => {
        if (task.isSending) {
            return 'sending';
        }
        if (task.data?.data && isTaskRunning(task.data.data?.status)) {
            return 'project-task';
        }
        if (task.isLoading) {
            return 'loading';
        }
        if (userTask.isLoading) {
            return 'loading';
        }
        if (userTask.error) {
            return 'load-error';
        }
        return null;
    }, [
        task.isLoading,
        task.isSending,
        task.data?.data?.status,
        userTask.isLoading,
        userTask.error,
        userTask.data?.data,
    ]);
    const projectTask = task.data?.data;
    if (file.isLoading || file.error || task.isLoading || userTask.isLoading) {
        return <></>;
    }
    return (
        <div className="reel--task--status">
            {isPhoto(file.data) && (
                <AiButton
                    disabled={lockReason !== null}
                    projectId={projectId}
                    onClick={scheduleAi}
                    title={lockReason ?? ''}
                />
            )}
            {projectTask ? (
                <TaskStateDisplay
                    key={projectTask.aiTaskId}
                    task={projectTask}
                    fileId={fileId}
                />
            ) : null}
        </div>
    );
});
