import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
    KeyboardEvent,
    MouseEvent,
    useMemo,
    ChangeEventHandler,
} from 'react';
import classNames from 'classnames';
import { Label } from './forms';

export interface CheckBoxProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    label?: string;
    className?: string;
    indeterminate?: boolean;
    _onChange?: (checked: boolean) => void;
}
export const CheckBox = (props: CheckBoxProps) => {
    const {
        className,
        indeterminate,
        label,
        onChange,
        _onChange,
        disabled,
        ...rest
    } = props;
    const ref = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        if (!ref.current) return;
        ref.current.indeterminate = !!indeterminate;
    }, [ref.current, indeterminate]);
    const actualOnChange = useMemo((): ChangeEventHandler<HTMLInputElement> => {
        if (onChange) {
            if (_onChange) {
                return (event) => {
                    const checked = event.target.checked;
                    onChange(event);
                    _onChange(checked);
                };
            }
            return onChange;
        }
        if (_onChange) {
            return (event) => {
                const checked = event.target.checked;
                _onChange(checked);
            };
        }
        return () => {};
    }, [onChange, _onChange]);
    const onLabelClick = useCallback(
        (event) => {
            if (!ref.current) return;
            ref.current.focus();
            ref.current.click();
        },
        [ref]
    );
    const checkbox = (
        <div
            className={classNames('atomic checkbox', className, {
                checked: rest.checked && !indeterminate,
                indeterminate,
            })}
        >
            <input
                type="checkbox"
                ref={ref}
                onChange={actualOnChange}
                disabled={!!disabled}
                {...rest}
            />
            <div
                className="checkbox-box"
                onClick={() => {
                    ref.current?.click();
                    ref.current?.focus();
                }}
            >
                {rest.checked && (
                    <div className="checkbox-check">
                        <i className="fa-solid fa-check" />
                    </div>
                )}
                {indeterminate && (
                    <div className="checkbox-check">
                        <i className="fa-solid fa-minus" />
                    </div>
                )}
            </div>
        </div>
    );

    if (!label) {
        return checkbox;
    }

    return (
        <div
            className={classNames('atomic checkbox-group', {
                disabled: !!disabled,
            })}
        >
            {checkbox}
            <Label
                text={label}
                htmlFor={rest.name}
                onClickCapture={onLabelClick}
            />
        </div>
    );
};

export type SlideSwitchProps<Name extends string | undefined> = {
    className?: string;
    text?: string;
    textPosition?: 'right' | 'left';
    defaultValue?: boolean;
    disabled?: boolean;
    onChange?: (change: { name: Name; value: boolean }) => void;
    _onChange?: (value: boolean) => void;
} & (Name extends string ? { name: Name } : { name?: undefined });

function isKeyboardEvent(e: { type: string }): e is KeyboardEvent {
    return e.type === 'keydown';
}
export const SlideSwitch = <Name extends string | undefined>(
    props: SlideSwitchProps<Name>
) => {
    const {
        className,
        name,
        text,
        textPosition = 'right',
        defaultValue = false,
        disabled = false,
        onChange,
        _onChange,
    } = props;
    let [on, setOn] = useState<boolean>(defaultValue);
    const onClick = useCallback(
        (e: MouseEvent<HTMLDivElement> | KeyboardEvent) => {
            if (disabled) {
                return false;
            }
            if (isKeyboardEvent(e)) {
                if (e.code !== 'Enter' && e.code !== 'Space') return;
            } else if (e.target instanceof HTMLElement) {
                e.target.focus();
                e.target.blur();
            }
            setOn((on = !on));
            onChange?.({ name: name as Name, value: on });
            _onChange?.(on);
            return false;
        },
        [disabled, onChange, _onChange]
    );
    return (
        <div
            className={classNames('atomic slide-switch-box-wrapper', {
                disabled,
            })}
        >
            <div
                className={classNames('atomic slide-switch-box', className, {
                    on,
                })}
                onClick={onClick}
                onKeyDown={onClick}
                {...(disabled ? {} : { tabIndex: 0 })}
            >
                <input name={name} type="hidden" />
                <div className={classNames('atomic slide-switch-pin')} />
            </div>
            {text && (
                <label
                    htmlFor={name}
                    className={classNames('atomic slide-switch-box-label', {
                        left: textPosition === 'left',
                        right: textPosition === 'right',
                    })}
                >
                    {text}
                </label>
            )}
        </div>
    );
};
