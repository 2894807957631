import * as fsa from 'file-system-access';
import { WriteChunk } from 'file-system-access/lib/interfaces';
import stringify from 'json-stringify-deterministic';
import { ProgressReporter } from './progress';

export function getFileParts(fileName: string): {
    defName: string;
    ext: string;
} {
    const res = /^(.*)(\.[^.]+)$/.exec(fileName);
    return res
        ? {
              defName: res[1],
              ext: res[2],
          }
        : {
              defName: fileName,
              ext: '',
          };
}

export async function fileExists(
    dirHandle: FileSystemDirectoryHandle,
    name: string
): Promise<boolean> {
    for await (const key of dirHandle.keys()) {
        if (key == name) {
            return true;
        }
    }
    return false;
}

export async function findAvailableFileName(
    dirHandle: FileSystemDirectoryHandle,
    fileName: string
): Promise<string> {
    const { defName, ext } = getFileParts(fileName);
    let name = `${defName}${ext}`;
    let i = 1;
    while (await fileExists(dirHandle, name)) {
        name = `${defName}(${i++})${ext}`;
    }
    return name;
}

export async function saveFile(
    dirHandle: FileSystemDirectoryHandle,
    fileName: string,
    chunk: WriteChunk
) {
    const fileHandle = await dirHandle.getFileHandle(fileName, {
        create: true,
    });
    const writable = await fileHandle.createWritable();
    await writable.write(chunk);
    await writable.close();
}

export async function saveJsonFile(
    dirHandle: FileSystemDirectoryHandle,
    fileName: string,
    data: any
) {
    const cleanJSON = stringify(data);
    const cleanObj = JSON.parse(cleanJSON);
    return await saveFile(
        dirHandle,
        fileName,
        JSON.stringify(cleanObj, null, 2)
    );
}

function concatUint8Array(
    array: undefined | Uint8Array[] | (() => void)
): Uint8Array {
    if (array === undefined) {
        return new Uint8Array();
    }
    if (!Array.isArray(array)) {
        throw new Error('Unexpected hhandler');
    }
    if (array.length === 1) {
        return array[0];
    }
    const len = array.reduce((total, { byteLength }) => total + byteLength, 0);
    const res = new Uint8Array(len);
    let offset = 0;
    for (const buf of array) {
        res.set(buf, offset);
        offset += buf.byteLength;
    }
    return res;
}

export async function downloadFile(
    filesDirHandle: FileSystemDirectoryHandle,
    url: string,
    fileName: string,
    message?: string,
    progress?: ProgressReporter,
    signal?: AbortSignal
): Promise<string> {
    message = message || url;
    progress?.report(0.00001, `${message} ダウンロード中...`);
    let writeBuffer: undefined | Uint8Array[] | ((buf?: Uint8Array[]) => void);
    let writing = true;
    let reading = true;
    let contentLength = 0;
    const [name] = await Promise.all([
        (async () => {
            try {
                const name: string = await findAvailableFileName(
                    filesDirHandle,
                    fileName
                );
                const fileHandle = await filesDirHandle.getFileHandle(name, {
                    create: true,
                });
                const writable = await fileHandle.createWritable({
                    keepExistingData: false,
                });
                try {
                    const startTime = Date.now();
                    let prevTime = startTime;
                    let prevPercent = 0;
                    let received = 0;

                    while (reading || writeBuffer) {
                        if (!writeBuffer) {
                            writeBuffer = await new Promise<
                                Uint8Array[] | undefined
                            >((resolve) => {
                                writeBuffer = resolve;
                            });
                        }
                        if (!writeBuffer) {
                            continue;
                        }
                        const buf = writeBuffer as Uint8Array[];
                        writeBuffer = undefined;
                        const combined = concatUint8Array(buf);
                        received += combined.byteLength;
                        await writable.write(combined);
                        const time = Date.now();
                        const diffTime = time - prevTime;
                        if (diffTime < 1000) {
                            // 最低一様の間に一つのログを書き出す。
                            continue;
                        }
                        const percentage = received / contentLength;
                        const percent = Math.round(percentage * 100);
                        if (percent === prevPercent) {
                            continue;
                        }
                        const elapsed = (time - startTime) / 1000;

                        prevPercent = percent;
                        prevTime = time;

                        progress?.report(
                            percentage,
                            `${message} ${percent}% 完了 （残り：${Math.round(
                                elapsed / percentage - elapsed
                            )}秒）`
                        );
                    }
                    return name;
                } finally {
                    await writable.close().catch((err) => {
                        console.warn(
                            'File closing due to error unsuccessful:',
                            err
                        );
                    });
                }
            } finally {
                writing = false;
            }
        })(),
        (async () => {
            try {
                const res = await fetch(url, {
                    redirect: 'follow',
                    signal,
                });
                if (res.status !== 200) {
                    throw new Error(
                        `${message} のダウンロードに失敗しました (${res.status})`
                    );
                }
                const reader = res.body?.getReader();
                if (!reader) {
                    throw new Error(
                        `${message} 応答データの読み込みに失敗しました`
                    );
                }
                contentLength = parseInt(
                    res.headers.get('Content-Length') ?? '0',
                    10
                );
                if (!contentLength) {
                    throw new Error(
                        `${message} 応答データのサイズの取得に失敗しました`
                    );
                }
                let done = false;
                try {
                    while (writing) {
                        const curr = await reader.read();
                        if (curr.value) {
                            if (writeBuffer === undefined) {
                                writeBuffer = [curr.value];
                            } else if (Array.isArray(writeBuffer)) {
                                writeBuffer.push(curr.value);
                            } else {
                                writeBuffer([curr.value]);
                            }
                        }
                        if (curr.done) {
                            done = true;
                            return;
                        }
                    }
                } finally {
                    if (!done) {
                        await reader.cancel().catch((err) => {
                            console.warn(
                                'Request cancellation due to error unsuccessful:',
                                err
                            );
                        });
                    }
                }
            } finally {
                reading = false;
                if (typeof writeBuffer === 'function') {
                    writeBuffer(undefined);
                }
            }
        })(),
    ]);
    progress?.report(1, `${message} を保存しました`);
    return name;
}

export async function createDirectory(
    dirHandle: FileSystemDirectoryHandle,
    driName: string
): Promise<FileSystemDirectoryHandle> {
    return await dirHandle.getDirectoryHandle(
        await findAvailableFileName(dirHandle, driName),
        {
            create: true,
        }
    );
}

export async function directoryPicker(): Promise<FileSystemDirectoryHandle> {
    return await fsa.showDirectoryPicker();
}
