import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Loading } from '../atomic/effects';
import { useAuth } from '../../contexts/AuthContext';
import { NotFound } from '../Errors';
import { AUTO_SIGNOUT_INTERVAL } from '../../consts';
import { Auth } from 'aws-amplify';

type Prop = {
    pageType?: 'admin' | 'user' | 'superadmin';
};

export const AuthLayout: FC<Prop> = ({ pageType = 'user' }) => {
    const { isLoading, isAdmin, isSuperAdmin, isAuthenticated } = useAuth();
    const timer = React.useRef<NodeJS.Timeout | null>(null);

    const onClick = React.useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        timer.current = setTimeout(() => {
            console.log('inactivity timeout.');
            Auth.signOut();
        }, AUTO_SIGNOUT_INTERVAL);
    }, [timer.current]);

    if (isLoading) return <Loading />;

    if (!isAuthenticated) return <Navigate to="login" replace />;

    if (pageType === 'admin' && !isAdmin()) return <NotFound />;

    if (pageType === 'superadmin' && !isSuperAdmin()) return <NotFound />;

    return (
        <div onClick={onClick}>
            <Outlet />
        </div>
    );
};
