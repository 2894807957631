import { APIGetRequest, useGet } from './useApi';

export function useRefresh(
    getApi: APIGetRequest<any>,
    refreshInterval?: number | null
) {
    useGet(
        refreshInterval !== null ? getApi : null,
        refreshInterval !== null ? { refreshInterval } : undefined
    );
}
