import { JSXElementConstructor } from 'react';
import {
    APIMutateRequest,
    MutationResponse,
    RequestOfMutation,
    ResponseOfMutation,
    mutateAsync,
} from './useApi';
import { DialogProps, DialogSession, useDialog } from './useDialog';

export interface UseMutationDialogOptions<
    Mutation extends APIMutateRequest<any, any>,
> extends Omit<
        DialogSession<RequestOfMutation<Mutation>, RequestOfMutation<Mutation>>,
        'onCommit' | 'options'
    > {
    onSuccess?: (
        response: MutationResponse<ResponseOfMutation<Mutation>>,
        request: RequestOfMutation<Mutation>
    ) => any | Promise<any>;
}

export function useMutationDialog<Mutation extends APIMutateRequest<any, any>>(
    mutation: Mutation,
    ui: JSXElementConstructor<DialogProps<RequestOfMutation<Mutation>, void>>,
    opts?: UseMutationDialogOptions<Mutation>
) {
    return useDialog<
        RequestOfMutation<Mutation>,
        void,
        RequestOfMutation<Mutation>
    >(
        ui,
        (options: RequestOfMutation<Mutation>) => ({
            options,
            async onCommit(output, requestOpts) {
                const response = await mutateAsync(
                    mutation,
                    options,
                    requestOpts
                );
                await opts?.onSuccess?.(response, options);
            },
            ...opts,
        }),
        [mutation]
    );
}

export function useMutationContextDialog<
    Mutation extends APIMutateRequest<any, any>,
    Input,
>(
    mutation: Mutation,
    ui: JSXElementConstructor<DialogProps<Input, RequestOfMutation<Mutation>>>,
    opts?: UseMutationDialogOptions<Mutation>
) {
    return useDialog(
        ui,
        (options: Input) => ({
            options,
            async onCommit(output, requestOpts) {
                const response = await mutateAsync(
                    mutation,
                    output,
                    requestOpts
                );
                await opts?.onSuccess?.(response, output);
            },
            ...opts,
        }),
        [mutation]
    );
}
