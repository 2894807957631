import React, { FC, useState } from 'react';

import { useFetchUserSelf } from '../../../hooks/useUser';
import { useAuth } from '../../../contexts/AuthContext';
import { DialogBox } from '../../atomic/dialog';
import { Button } from '../../atomic/buttons';
import { TextField } from '../../atomic/forms';
import { Loading } from '../../atomic/effects';
import { CreateTenantModel } from '../../../models/TenantModel';
import { createTenantForSuperAdmin } from '../../../APIs/tenants';
import { inviteUser } from '../../../APIs/userApi';
import { validateEmail } from '../../user/lib/validate';

type Prop = {
    isOpen: any;
    setIsOpen: any;
};

export const TenantCreate: FC<Prop> = (props) => {
    const { isOpen, setIsOpen } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const ref = React.useRef<DialogBox>();
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());

    // Forms
    const [tenantName, setTenantName] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const openModal = () => {
        setIsOpen(true);
    };

    function createAdminUser(email: string, tenantId: string) {
        inviteUser({
            email: email,
            role: 'Admin',
            tenantId: tenantId,
        })
            .then((resp) => {
                if (resp.success) {
                    setIsOpen(false);
                } else {
                    ref?.current?.addError(resp, 'ユーザの招待に失敗しました');
                }
            })
            .catch((err) => {
                console.warn(err);
                ref?.current?.addError(err, 'ユーザの招待に失敗しました');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const onOK = React.useCallback(() => {
        if (!userInfo === undefined) {
            ref?.current?.addError('テナントIDの取得に失敗しました');
            return false;
        }
        if (validateEmail(email) === false) {
            ref?.current?.addError('メールアドレスのフォーマットが不正です');
            return false;
        }
        setLoading(true);
        const inputTenant: CreateTenantModel = {
            email: email,
            companyName: tenantName,
        };

        createTenantForSuperAdmin(inputTenant)
            .then((resp) => {
                if (resp.success) {
                    createAdminUser(inputTenant.email, resp.data.tenantId);
                } else {
                    ref?.current?.addError(resp, 'エラーが発生しました');
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.warn(err);
                ref?.current?.addError(err, 'エラーが発生しました');
                setLoading(false);
            });
    }, [email, tenantName]);

    const onCancel = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <>
            <div className="create_tenant_button">
                <Button className="primary" onClick={openModal}>
                    テナント作成
                </Button>
                {isOpen && (
                    <DialogBox
                        title="テナント作成"
                        onOK={onOK}
                        onCancel={onCancel}
                        ref={ref}
                    >
                        <ul className="fields">
                            <li>
                                <TextField
                                    label="テナント名"
                                    _onChange={setTenantName}
                                    value={tenantName}
                                    required={true}
                                />
                                <TextField
                                    label="メールアドレス"
                                    _onChange={setEmail}
                                    value={email}
                                    required={true}
                                />
                            </li>
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
