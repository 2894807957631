import {
    ExportTenantTagModel,
    ExportTenantTagsModel,
} from '../../models/AiTemplateModel';
import { TagType } from '../../models/TagModel';
import { AddTagTypeModel } from '../tagApi';

export interface ImportTagRequest {
    name: string;
    tagTypes: Omit<AddTagTypeModel, 'tenantTagId'>[];
    tenantId: string;
}

function assertTenantTag(json: any): asserts json is ExportTenantTagModel {
    if (json === null || typeof json !== 'object') {
        throw new Error('不正な形式です');
    }
    if (typeof json.name !== 'string') {
        throw new Error('不正な形式です');
    }
    if (typeof json.type !== 'number' || isNaN(json.type)) {
        throw new Error('不正な形式です');
    }
    if (!TagType[json.type]) {
        throw new Error('不正な形式です');
    }
    if (json.list === null || json.list === undefined) {
        return;
    }
    if (!Array.isArray(json.list)) {
        throw new Error('不正な形式です');
    }
    for (const entry of json.list) {
        if (typeof entry !== 'string') {
            throw new Error('不正な形式です');
        }
    }
}

export function assertTenantTags(
    json: any
): asserts json is ExportTenantTagsModel {
    if (json === null || typeof json !== 'object') {
        throw new Error('不正な形式です');
    }
    if (typeof json.name !== 'string') {
        throw new Error('不正な形式です');
    }
    if (json.name.length === 0) {
        throw new Error('不正な形式です');
    }
    if (!Array.isArray(json.tags)) {
        throw new Error('不正な形式です');
    }
    for (const tag of json.tags) {
        assertTenantTag(tag);
    }
}

export const importTenantTags = (
    tenantId: string,
    json: any
): ImportTagRequest => {
    assertTenantTags(json);
    return {
        name: json.name,
        tagTypes: json.tags.map(({ type, list, name }, sortOrder) => ({
            type,
            list: list ?? [],
            name,
            sortOrder,
        })),
        tenantId,
    };
};
