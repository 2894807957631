import React, { FC, useCallback, FormEvent } from 'react';
import {
    TextField,
    PasswordField,
    InputField,
    PlainField,
    PhoneNumberField,
} from '../atomic/forms';
import { Button } from '../atomic/buttons';
import { useUserOne, postUserProfile } from '../../hooks/useUser';
import { useParams } from 'react-router-dom';
import { Loading } from '../atomic/effects';
import { DialogBox } from '../atomic/dialog';
import { CheckBox } from '../atomic/switches';

type Prop = {};

export const UserEditForm: FC<Prop> = () => {
    const { userId } = useParams();
    const [familyName, setFamilyName] = React.useState<string>('');
    const [givenName, setGivenName] = React.useState<string>('');
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const [mail, setMail] = React.useState<string>('');
    const [oldPassword, setOldPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [newConfirmPassword, setNewConfirmPassword] =
        React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [showDialog, setShowDialog] = React.useState<boolean>(false);
    const [changePassword, setChangePassword] = React.useState<boolean>(false);
    const user = useUserOne();
    const refs = {};
    [
        'familyName',
        'givenName',
        'phoneNumber',
        'oldPassword',
        'newPassword',
        'newConfirmPassword',
    ].forEach((name) => {
        refs[name] = React.useRef<InputField>();
    });

    const resetValues = () => {
        setChangePassword(false);
        setFamilyName(user?.familyName ?? '');
        setGivenName(user?.givenName ?? '');
        setPhoneNumber(user?.phoneNumber ?? '');
        setMail(user?.email ?? '');
        setOldPassword('');
        setNewPassword('');
        setNewConfirmPassword('');
    };

    React.useEffect(() => {
        resetValues();
    }, [user]);

    const handleSubmitReset = React.useCallback(() => {
        resetValues();
    }, [user]);

    const openConfirmDialog = () => {
        let validate = true;
        for (let key in refs) {
            const ref = refs[key];
            if (ref && ref.current && !ref.current.checkValue()) {
                validate = false;
            }
        }
        if (newPassword) {
            if (!oldPassword) {
                validate = false;
                refs.oldPassword?.current?.setError(
                    '元のパスワードを入力してください'
                );
            }
            if (newPassword != newConfirmPassword) {
                validate = false;
                refs.newConfirmPassword?.current?.setError(
                    '新しいパスワードと一致しません'
                );
            }
        }

        if (!validate) {
            return false;
        }
        setShowDialog(true);
    };

    const onSubmit = (dialog: DialogBox) => {
        dialog.clearError();
        setLoading(true);
        postUserProfile({
            familyName,
            givenName,
            phoneNumber,
            oldPassword,
            newPassword,
        })
            .then((resp) => {
                console.log(resp);
                setShowDialog(false);
            })
            .catch((err) => {
                console.warn(err);
                dialog.addError(err, 'ユーザ情報の更新に失敗しました');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <div className="edit-forms">
                <div className="form">
                    <ul className="fields">
                        <li>
                            <TextField
                                label="姓"
                                _onChange={setFamilyName}
                                value={familyName}
                                required={true}
                                ref={refs.familyName}
                            />
                        </li>
                        <li>
                            <TextField
                                label="名"
                                _onChange={setGivenName}
                                value={givenName}
                                required={true}
                                ref={refs.givenName}
                            />
                        </li>
                        <li>
                            <PlainField label="メールアドレス" value={mail} />
                        </li>
                        <li>
                            <PhoneNumberField
                                label="電話番号"
                                _onChange={setPhoneNumber}
                                value={phoneNumber}
                                required={true}
                                ref={refs.phoneNumber}
                            />
                        </li>
                        {!changePassword && (
                            <li>
                                <CheckBox
                                    label="パスワードを変更する"
                                    onChange={() => setChangePassword(true)}
                                />
                            </li>
                        )}
                        {changePassword && [
                            <li key="oldPassword">
                                <PasswordField
                                    label="元のパスワード"
                                    _onChange={setOldPassword}
                                    value={oldPassword}
                                    ref={refs.oldPassword}
                                />
                            </li>,
                            <li key="newPassword">
                                <PasswordField
                                    label="新パスワード"
                                    _onChange={setNewPassword}
                                    value={newPassword}
                                    ref={refs.newPassword}
                                />
                            </li>,
                            <li key="newConfirmPassword">
                                <PasswordField
                                    label="新パスワード（確認）"
                                    _onChange={setNewConfirmPassword}
                                    value={newConfirmPassword}
                                    ref={refs.newConfirmPassword}
                                />
                            </li>,
                        ]}
                    </ul>
                    <div className="update-buttons">
                        <Button
                            className="atomic btn secondary"
                            onClick={handleSubmitReset}
                        >
                            元の状態に戻す
                        </Button>
                        <Button
                            className="atomic btn primary"
                            onClick={openConfirmDialog}
                        >
                            完了
                        </Button>
                    </div>
                </div>
            </div>
            {showDialog && (
                <DialogBox
                    onOK={onSubmit}
                    onCancel={() => setShowDialog(false)}
                    title="確認"
                >
                    <p>プロフィールを変更しますか？</p>
                    {newConfirmPassword != '' && (
                        <p>パスワードの変更は１時間に５回まで実施可能です。</p>
                    )}
                </DialogBox>
            )}
            {loading && <Loading />}
        </>
    );
};
