import React, { FC } from 'react';
import { Button, IconButton } from '../atomic/buttons';
import { DialogBox } from '../atomic/dialog';
import { Table, columnDefiner } from '../atomic/table';
import { Loading } from '../atomic/effects';
import * as DateUtils from '../../utils/DateUtils';
import { getUsers, removeUser, userApi } from '../../APIs/userApi';
import { Link } from 'react-router-dom';
import { UserModel, UserRole } from '../../models/UserModel';
import { ErrorDisplay } from '../../components/Errors';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { useTenant } from '../../contexts/TenantContext';

type Prop = {};
interface UserData extends UserModel {
    trClass: string;
}
const definer = columnDefiner<UserData>();

const columns = [
    definer.accessor('userId', {
        header: 'ID',
        cell: (d) => {
            const { userId } = d.row.original;
            const { userList } = d.table.options;
            return (
                <>
                    <Link
                        to={`/app/admin/user/edit`}
                        state={{
                            user: userList?.find(
                                (item) => item.userId == userId
                            ),
                        }}
                    >
                        <div className="folder name link-area">
                            <span>{d.getValue()}</span>
                        </div>
                    </Link>
                </>
            );
        },
    }),
    {
        header: '名前',
        cell: (d) => {
            const { familyName, givenName } = d.row.original;
            if (!familyName) {
                return '';
            }
            return `${familyName} ${givenName}`;
        },
    },
    definer.accessor('role', {
        header: '権限',
        cell: (d) => UserRole[d.getValue()] ?? 'unknown',
    }),
    definer.accessor('email', {
        header: 'メールアドレス',
        cell: (d) => d.getValue(),
    }),
    definer.accessor('phoneNumber', {
        header: '電話番号',
        cell: (d) => d.getValue(),
    }),
    definer.accessor('createdAt', {
        header: '登録日',
        cell: (d) => DateUtils.reformat(d.getValue()),
    }),
    {
        header: 'ユーザ削除',
        cell: (d) => {
            const { userId, email, givenName, familyName } = d.row.original;
            const { onRemoveUser, userInfo } = d.table.options;
            const target = familyName
                ? `${familyName} ${givenName}さん`
                : email;
            return (
                <Button
                    className="danger"
                    onClick={() => {
                        onRemoveUser(userId, target);
                    }}
                    disabled={userInfo.email == email}
                >
                    ユーザ削除
                </Button>
            );
        },
    },
];

export const UserList: FC<Prop> = () => {
    const [userList, setUserList] = React.useState<Array<UserModel>>([]);
    const [loadUserError, setLoadUserError] = React.useState<string>('');
    const [removeUserId, setRemoveUserId] = React.useState<string>('');
    const [isRemoving, setIsRemoving] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());

    const { isExternalApi } = useTenant();
    const [removeApiKeyUserId, setRemoveApiKeyUserId] =
        React.useState<string>('');
    const [targetUser, setTargetUser] = React.useState<string>('');

    React.useEffect(() => {
        const add = columns.some((item, index) => {
            //console.log(item);
            return item.header === 'APIキー削除';
        });
        if (isExternalApi && add == false) {
            columns.splice(-2, 0, {
                header: 'APIキー削除',
                cell: (d) => {
                    const { userId, role, email, givenName, familyName } =
                        d.row.original;
                    const { onRemoveApiKey } = d.table.options;
                    if (role === 'External' || role === 'SuperAdmin') {
                        return <></>;
                    }
                    const target = familyName
                        ? `${familyName} ${givenName}さん`
                        : email;
                    return (
                        <Button
                            className="danger"
                            onClick={() => {
                                onRemoveApiKey(userId, target);
                            }}
                        >
                            キー削除
                        </Button>
                    );
                },
            });
        }
    }, [isExternalApi]);

    React.useEffect(() => {
        getUsers()
            .then((resp) => {
                //console.log(resp);
                if (resp.success && resp.data) {
                    setUserList(
                        resp.data.sort(
                            (a, b) =>
                                DateUtils.getTime(b.createdAt) -
                                DateUtils.getTime(a.createdAt)
                        )
                    );
                } else {
                    setLoadUserError(
                        resp.message || 'ユーザリストの取得に失敗しました'
                    );
                }
            })
            .catch((err) => {
                //console.warn(err);
                setLoadUserError(
                    err?.response?.data?.message ||
                        err.message ||
                        'ユーザリストの取得に失敗しました'
                );
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const onRemoveApiKey = React.useCallback(
        (userId: string, target: string) => {
            //console.log('remove api key');
            setTargetUser(target);
            setRemoveApiKeyUserId(userId);
        },
        [removeApiKeyUserId]
    );

    const onRemoveApiKeyDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            setIsRemoving(true);
            userApi.delete
                .userApiKey(removeApiKeyUserId)
                .then((resp) => {
                    if (resp.success) {
                        setRemoveApiKeyUserId('');
                    } else {
                        dialog?.addError(resp, 'APIキーの削除に失敗しました');
                    }
                })
                .catch((err) => {
                    //console.warn(err);
                    dialog?.addError(err, 'APIキーの削除に失敗しました');
                })
                .finally(() => {
                    setIsRemoving(false);
                });
        },
        [removeApiKeyUserId]
    );

    const onRemoveApiKeyDialogCancel = () => {
        setRemoveApiKeyUserId('');
    };

    const onRemoveUser = React.useCallback((id: string, target: string) => {
        setTargetUser(target);
        setRemoveUserId(id);
    }, []);

    const onRemoveUserDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            setIsRemoving(true);
            removeUser(removeUserId)
                .then((resp) => {
                    //console.log(resp);
                    if (resp.success && resp.data) {
                        const { userId } = resp.data;
                        setUserList((prevState) => {
                            return prevState?.filter(
                                (item) => item.userId != userId
                            );
                        });
                        setRemoveUserId('');
                    } else {
                        dialog.addError(resp, 'ユーザの削除に失敗しました');
                    }
                })
                .catch((err) => {
                    //console.warn(err);
                    dialog.addError(err, 'ユーザの削除に失敗しました');
                })
                .finally(() => {
                    setIsRemoving(false);
                });
        },
        [removeUserId]
    );

    const onRemoveUserDialogCancel = () => {
        setRemoveUserId('');
    };

    const options = {
        onRemoveUser,
        userList,
        userInfo,
        onRemoveApiKey,
    };

    if (isLoading || !userInfo) {
        return <Loading />;
    }
    if (loadUserError) {
        return <ErrorDisplay error={Error(loadUserError)} />;
    }
    return (
        <>
            <div className="edit-forms">
                <Table
                    className="user-table"
                    data={userList}
                    columns={columns}
                    options={options}
                />
            </div>
            {removeUserId != '' && (
                <DialogBox
                    title="ユーザ削除"
                    onOK={onRemoveUserDialogOK}
                    onCancel={onRemoveUserDialogCancel}
                >
                    {targetUser}のアカウントを削除しても良いですか？
                </DialogBox>
            )}
            {isRemoving && <Loading />}

            {removeApiKeyUserId != '' && (
                <DialogBox
                    title="APIキー削除"
                    onOK={onRemoveApiKeyDialogOK}
                    onCancel={onRemoveApiKeyDialogCancel}
                >
                    {targetUser}のAPIキーを削除しても良いですか？
                </DialogBox>
            )}
            {isRemoving && <Loading />}
        </>
    );
};
