import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { User, useTenant } from '../../contexts/TenantContext';
import { FolderUserRoles } from '../../models/FolderModel';
import classNames from 'classnames';
import { SlideSwitch } from '../atomic/switches';
import { TagList } from '../atomic/tags';
import { Link } from 'react-router-dom';
import { DialogBox } from '../atomic/dialog';
import { Button } from '../atomic/buttons';
import { DeleteIcon, Icons, UserIcon, Icon } from '../atomic/icons';
import { FoldersAPI } from '../../APIs/foldersApi';
import { useGet } from '../../hooks/useApi';
import { Loading } from '../atomic/effects';
import { TextField } from '../atomic/forms';
import { useAuth } from '../../contexts/AuthContext';

interface FolderUser extends User, FolderUserRoles {}
interface SelectedUserModel {
    name: string;
    email: string;
    userId: string;
}
const roleLabels: Array<{
    label: string;
    key: string;
}> = [
    {
        label: '属性値の表示',
        key: 'showTags',
    },
    {
        label: 'プロジェクトの表示',
        key: 'showAiResult',
    },
    {
        label: 'ファイル・フォルダの編集',
        key: 'write',
    },
];
const CollaboratorSelector = ({
    onSelected,
    tenantUsers,
    folderUsers,
}: {
    onSelected: (value: SelectedUserModel) => void;
    tenantUsers: User[];
    folderUsers: FolderUser[];
}) => {
    // 選択可能なユーザリスト（テナントのユーザの中で、フォルダユーザの中に登録されていないゲストユーザ）
    const userList: SelectedUserModel[] = useMemo(
        () =>
            tenantUsers
                .filter(
                    ({ userId, role }) =>
                        role == 'External' &&
                        !folderUsers.find((item) => item.userId == userId)
                )
                .map(({ userId, email, familyName, givenName }) => ({
                    name: familyName + ' ' + givenName,
                    userId,
                    email,
                })),
        [tenantUsers, folderUsers]
    );
    const [candidate, setCandidate] = useState<SelectedUserModel[]>([]);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        setCandidate(
            searchText?.length
                ? userList.filter(
                      ({ email, name }) =>
                          email.indexOf(searchText) >= 0 ||
                          name.indexOf(searchText) >= 0
                  )
                : []
        );
    }, [searchText]);
    return (
        <div>
            <TextField
                value={searchText}
                _onChange={setSearchText}
                placeholder="ユーザ名、メールアドレス"
                autoComplete="off"
                spellCheck={false}
            />
            {candidate.length > 0 && (
                <div className="candidate-list">
                    <ul className="collaborator-list">
                        {candidate.map((user) => (
                            <li key={user.userId} className="user-info">
                                <div className="name">
                                    <Link
                                        to="#"
                                        onClick={() => onSelected(user)}
                                    >
                                        <UserIcon /> {user.name}
                                    </Link>
                                </div>
                                <div className="email">{user.email}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};
const CollaboratorDialog = ({
    folderId,
    user,
    onUpdated,
    onClosed,
    tenantUsers,
    folderUsers,
}: {
    user?: FolderUser;
    onUpdated: () => void;
    onClosed: () => void;
    tenantUsers: User[];
    folderUsers: FolderUser[];
    folderId: string;
}) => {
    const defRoles: FolderUserRoles = roleLabels.reduce((prev, cur) => {
        prev[cur.key] = 0;
        return prev;
    }, {} as FolderUserRoles);

    const [roles, setRoles] = useState<FolderUserRoles | null>(null);
    const [selectedUser, setSelectedUser] = useState<SelectedUserModel | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!user) {
            setSelectedUser(null);
            setRoles(defRoles);
        } else {
            setSelectedUser({
                name: user.familyName + ' ' + user.givenName,
                email: user.email,
                userId: user.userId,
            });
            setRoles((prevState) => {
                const nextState = prevState
                    ? { ...prevState }
                    : { ...defRoles };
                roleLabels.forEach(({ key }) => {
                    console.log(key, user[key]);
                    if (key in user) {
                        nextState[key] = user[key];
                    }
                });
                return nextState;
            });
        }
    }, [user?.userId]);

    const onOK = useCallback(
        (dialog: DialogBox) => {
            if (!folderId) {
                dialog.addError('不正なフォルダIDです');
                return;
            }
            if (!selectedUser?.userId) {
                dialog.addError('ユーザIDが設定されていません');
                return;
            }
            if (!roles) {
                dialog.addError('ロールの設定が不正です');
                return;
            }
            setLoading(true);
            FoldersAPI.users
                .post(
                    folderId,
                    selectedUser?.userId,
                    !!roles.showTags,
                    !!roles.showAiResult,
                    !!roles.write
                )
                .then((resp) => {
                    if (resp.success) {
                        onUpdated();
                        onClosed();
                    } else {
                        console.warn(resp);
                        dialog.addError(
                            resp.message,
                            'コラボレータの設定に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err, 'コラボレータの設定に失敗しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [selectedUser?.userId, roles, folderId]
    );

    const setRoleValue = useCallback(
        ({ value, name }: { value: boolean; name: string }) => {
            setRoles((prevState) => {
                const nextState = prevState
                    ? { ...prevState }
                    : { ...defRoles };
                nextState[name] = value ? 1 : 0;
                return nextState;
            });
        },
        []
    );

    const roleSwitches = useMemo(
        () =>
            roles
                ? roleLabels.map(({ label, key }) => (
                      <div className="atomic checkbox-group" key={key}>
                          <SlideSwitch
                              defaultValue={!!roles[key]}
                              name={key}
                              onChange={setRoleValue}
                          />
                          <div className="atomic slide-switch-pin">{label}</div>
                      </div>
                  ))
                : null,
        [roles]
    );

    return (
        <>
            <DialogBox
                title={`コラボレータの${user ? '追加' : '編集'}`}
                onOK={onOK}
                onCancel={onClosed}
                className="collaborator-dialog"
                disabled={!selectedUser}
            >
                {selectedUser ? (
                    <div className="user-info">
                        <div className="name">
                            {selectedUser.name}
                            {!user && (
                                <Link
                                    to="#"
                                    onClick={() => {
                                        setSelectedUser(null);
                                    }}
                                    style={{ marginLeft: '1em' }}
                                >
                                    <Icon icon={Icons.Delete} />
                                </Link>
                            )}
                        </div>
                        <div className="email">{selectedUser.email}</div>
                    </div>
                ) : (
                    <CollaboratorSelector
                        onSelected={setSelectedUser}
                        tenantUsers={tenantUsers}
                        folderUsers={folderUsers}
                    />
                )}
                <div className="role-list">{roleSwitches}</div>
            </DialogBox>
            {loading && <Loading />}
        </>
    );
};
const FolderUserRow = ({
    user,
    editable,
    onRemoveUser,
    onEditUser,
}: {
    user: FolderUser;
    editable: boolean;
    onRemoveUser: (user: FolderUser) => void;
    onEditUser: (user: FolderUser) => void;
}) => {
    return (
        <>
            <tr className="user" key={user.userId}>
                <td className="user-info">
                    <div className="name">
                        {editable ? (
                            <Link
                                className="edit"
                                to="#"
                                onClick={() => onEditUser(user)}
                            >
                                {user.familyName} {user.givenName}
                            </Link>
                        ) : (
                            <>
                                {user.familyName} {user.givenName}
                            </>
                        )}
                    </div>
                    <div className="email">{user.email}</div>
                </td>
                {roleLabels.map(({ label, key }, index) => (
                    <td className="status" key={key}>
                        {user[key] ? (
                            <i className="fa-solid fa-check on" />
                        ) : (
                            <i className="fa-solid fa-minus off" />
                        )}
                    </td>
                ))}
                {editable && (
                    <td className="controls">
                        <Button onClick={() => onRemoveUser(user)}>
                            <DeleteIcon />
                            削除
                        </Button>
                    </td>
                )}
            </tr>
        </>
    );
};

const FolderUsers = ({
    folderId,
    tenantUsers,
    folderUsers,
    onUpdated,
    editable,
}: {
    folderId: string;
    tenantUsers: User[];
    folderUsers: FolderUser[];
    onUpdated: () => void;
    editable: boolean;
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [removeUser, setRemoveUser] = useState<FolderUser | null>(null);
    const [editUser, setEditUser] = useState<FolderUser | null>(null);

    const onRemoveConfirmOK = useCallback(
        (dialog: DialogBox) => {
            if (!removeUser?.userId) {
                dialog.addError('不正なユーザIDです');
                return;
            }
            setLoading(true);
            FoldersAPI.users
                .delete(folderId, removeUser.userId)
                .then((resp) => {
                    if (resp.success) {
                        setRemoveUser(null);
                        onUpdated();
                    } else {
                        dialog.addError(
                            resp.message,
                            'コラボレータの削除に失敗しました'
                        );
                        console.warn(resp);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err, 'コラボレータの削除に失敗しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [removeUser, folderId]
    );
    const onRemoveConfirmCancel = useCallback(() => {
        setRemoveUser(null);
    }, []);
    return (
        <section>
            {(folderUsers.length ? true : false) && (
                <table className="atomic table collaborator-list">
                    <thead>
                        <tr>
                            <th>氏名</th>
                            {roleLabels.map(({ label }, index) => (
                                <th key={index} className="status">
                                    {label}
                                </th>
                            ))}
                            {editable && <th>操作</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {folderUsers.map((user, i) => (
                            <FolderUserRow
                                user={user}
                                editable={editable}
                                key={user.userId}
                                onRemoveUser={setRemoveUser}
                                onEditUser={setEditUser}
                            />
                        ))}
                    </tbody>
                </table>
            )}
            {removeUser != null && (
                <DialogBox
                    title="確認"
                    onOK={onRemoveConfirmOK}
                    onCancel={onRemoveConfirmCancel}
                >
                    {removeUser.familyName} {removeUser.givenName}{' '}
                    をコラボレータから削除しますか？
                </DialogBox>
            )}
            {editUser != null && (
                <CollaboratorDialog
                    user={editUser}
                    folderUsers={folderUsers}
                    tenantUsers={tenantUsers}
                    onUpdated={onUpdated}
                    onClosed={() => setEditUser(null)}
                    folderId={folderId}
                />
            )}
            {loading && <Loading />}
        </section>
    );
};
export const FolderCollaboratorView = (props: {
    users: any;
    folder: any;
    defaultVisible?: false | true;
    isWrite: boolean;
    mutate;
}) => {
    const { folder, defaultVisible = false, users, isWrite, mutate } = props;
    const [visible, setVisible] = React.useState(defaultVisible);
    const [showDialog, setShowDialog] = React.useState(false);
    const { tenantUsers } = useTenant();

    const { isUser } = useAuth();

    const folderUsers: FolderUser[] = React.useMemo(() => {
        if (!users) {
            return [];
        }
        const retVal: FolderUser[] = [];
        users.forEach((_user) => {
            const _tenantUser = tenantUsers.find((_tenantUser) => {
                return _user.userId == _tenantUser.userId;
            });
            if (_tenantUser)
                retVal.push({
                    ..._tenantUser,
                    showTags: _user.showTags,
                    showAiResult: _user.showAiResult,
                    write: _user.write,
                });
        });
        return retVal;
    }, [users, tenantUsers]);

    const onOpen = useCallback(() => {
        setShowDialog(true);
    }, []);

    if (users.isLoading) {
        return <Loading />;
    }
    return (
        <>
            {tenantUsers &&
                folderUsers &&
                (isUser() ? (folderUsers.length ? true : false) : true) && (
                    <div
                        className={classNames('details-outer collaborators', {
                            visible,
                        })}
                    >
                        <div className={classNames('detail-head', { visible })}>
                            <TagList tags={[]} />
                            <div className="controls">
                                <SlideSwitch
                                    name="detailVisibility"
                                    defaultValue={visible}
                                    onChange={({ value }) => setVisible(value)}
                                    text="コラボレーター表示"
                                    textPosition="left"
                                />
                            </div>
                        </div>
                        {visible && (
                            <>
                                <div className="details">
                                    {folder.breadcrumbs.length == 1 &&
                                        isWrite && (
                                            <Link
                                                className="add"
                                                to="#"
                                                onClick={() => onOpen()}
                                            >
                                                <UserIcon />
                                                コラボレーター追加
                                            </Link>
                                        )}
                                </div>
                                {folderUsers && (
                                    <FolderUsers
                                        folderId={folder.folderId}
                                        folderUsers={folderUsers}
                                        tenantUsers={tenantUsers}
                                        editable={
                                            folder.breadcrumbs.length == 1 &&
                                            isWrite
                                        }
                                        onUpdated={mutate}
                                    />
                                )}
                            </>
                        )}
                    </div>
                )}
            {showDialog && (
                <CollaboratorDialog
                    folderUsers={folderUsers}
                    tenantUsers={tenantUsers}
                    onUpdated={mutate}
                    onClosed={() => setShowDialog(false)}
                    folderId={folder.folderId}
                />
            )}
        </>
    );
};
