import React, { memo, useEffect, useRef, useState } from 'react';
import { useNavigateToItem } from '../atomic/links';
import { useGet } from '../../hooks/useApi';
import { FoldersAPI } from '../../APIs/foldersApi';
import { formatVideoFileTime } from '../../utils/formatVideoFileTime';
import { useIntersection } from 'react-use';
type ReelFileLinkProps = {
    fileId: string;
};

type ActiveLinkProps = {
    fileId: string;
};
const ActiveLink = memo(({ fileId }: ActiveLinkProps) => {
    const file = useGet(FoldersAPI.get.file(fileId));
    const source = useGet(
        file.data?.sourceId ? FoldersAPI.get.file(file.data.sourceId) : null
    );
    const navigateToItem = useNavigateToItem();
    if (!file.data) {
        return <span>ID:{fileId}</span>;
    }
    return (
        <a
            href="#"
            onClick={(event) => {
                event.preventDefault();
                console.log({ fileId, sourceId: file.data?.sourceId });
                navigateToItem({ fileId, sourceId: file.data?.sourceId });
            }}
        >
            {source.data
                ? `${source.data.fileName} @ ${formatVideoFileTime(file.data)}`
                : file.data.fileName}
        </a>
    );
});
export const ReelFileLink = memo(({ fileId }: ReelFileLinkProps) => {
    const [active, setActive] = useState(false);
    const ref = useRef<HTMLAnchorElement>(null);
    const intersection = useIntersection(ref, {});
    useEffect(() => {
        if (active) return;
        if (!intersection?.isIntersecting) return;
        setActive(true);
    }, [active, intersection?.isIntersecting]);
    if (!active) {
        return <span ref={ref}>ID:{fileId}</span>;
    }
    return <ActiveLink fileId={fileId} />;
});
