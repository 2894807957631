// see https://developer.mozilla.org/ja/docs/Web/HTML/Attributes/accept
export const UPLOADABLE_CONTENT_TYPES = ['image/*', 'video/mp4'];
export const MAX_UPLOAD_SIZE = 50 * 1024 * 1024;
export const SHOW_UPLOAD_ALERT_SIZE = 1024 * 1024 * 1024;
export const CLEAR_TASK_INTERVAL = 10 * 60 * 1000;
export const AUTO_SIGNOUT_INTERVAL = 8 * 60 * 60 * 1000;

export const ROOT_FOLDER_NAME = 'ポータルトップ';

export const cognitoConstants = {
    aws_project_region: import.meta.env.VITE_AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: import.meta.env
        .VITE_AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
    aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_CLIENT_ID,
    cookieStorage: {
        //domain: ,
        path: '/',
        expires: 1,
        sameSite: 'lax',
        secure: true,
    },
    authenticationFlowType: 'CUSTOM_AUTH',
};

export const PAGER_LIMITS = [10, 20, 30];
