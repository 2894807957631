import React from 'react';
import { IconButton } from '../atomic/buttons';
import { Link } from 'react-router-dom';
import { Icon } from '../atomic/icons';
import { DialogBox } from '../atomic/dialog';
import { TextField } from '../atomic/forms';

export const AdminListTag = ({ to, name, onDelete }) => {
    return (
        <div className="admin-link-tip">
            <div role="container">
                <div role="name">
                    <Link to={to}>{name}</Link>
                </div>
                <div role="buttons">
                    <IconButton icon="fa-solid fa-trash" onClick={onDelete}>
                        削除
                    </IconButton>
                </div>
            </div>
        </div>
    );
};
export interface AdimnListTagsProp {
    items: Array<{
        url: string;
        id: string;
        name: string;
    }>;
    onDelete: (id: string, dialog: DialogBox, closeDialog: () => void) => void;
    onExport?: (id: string) => void;
    annotation?: string;
}
export const AdminListTags = ({
    items,
    onDelete,
    annotation,
    onExport,
}: AdimnListTagsProp) => {
    type DeleteDialogData = {
        id: string;
        name: string;
    };
    const [deleteDialogData, setDeleteDialogData] =
        React.useState<DeleteDialogData | null>(null);
    const onDeleteDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            onDelete(deleteDialogData?.id, dialog, () => {
                setDeleteDialogData(null);
            });
        },
        [deleteDialogData, onDelete]
    );
    const onDeleteDialogCancel = React.useCallback(() => {
        setDeleteDialogData(null);
    }, []);
    return (
        <>
            {items.map(({ url, id, name }) => (
                <div className="admin-link-tip" key={id}>
                    <div role="container">
                        <div role="name">
                            <Link to={url}>{name}</Link>
                        </div>
                        <div role="buttons">
                            {onExport && (
                                <IconButton
                                    icon="fa-solid fa-download"
                                    onClick={() => onExport(id)}
                                >
                                    エクスポート
                                </IconButton>
                            )}
                            <IconButton
                                icon="fa-solid fa-trash"
                                onClick={() =>
                                    setDeleteDialogData({ id, name })
                                }
                            >
                                削除
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))}
            {deleteDialogData && (
                <DialogBox
                    title="確認"
                    onOK={onDeleteDialogOK}
                    onCancel={onDeleteDialogCancel}
                >
                    {annotation && <p>{annotation}</p>}
                    {deleteDialogData.name} を削除しますか？
                </DialogBox>
            )}
        </>
    );
};

export interface AdminDetailPageHeaderProps {
    parentUrl: string;
    name: string;
    onDelete: (dialog: DialogBox, closeDialog: () => void) => void;
    onEdit: (value: string, closeDialog: () => void, dialog: DialogBox) => void;
    title: string;
    annotation?: string;
}
export const AdminDetailPageHeader = ({
    parentUrl,
    name,
    onDelete,
    onEdit,
    title,
    annotation,
}: AdminDetailPageHeaderProps) => {
    const [inputText, setInputText] = React.useState<string | null>(null);
    const ref = React.useRef<DialogBox>(null);
    const [showDeleteDialog, setShowDeleteDialog] =
        React.useState<boolean>(false);

    if (!title) {
        title = '名前';
    }
    const onDeleteDialogOK = React.useCallback(
        (dialog: DialogBox) => {
            onDelete(dialog, () => {
                setShowDeleteDialog(false);
            });
        },
        [onDelete]
    );
    return (
        <>
            <Link to={parentUrl}>
                <Icon icon="fa-solid fa-list" />
                一覧に戻る
            </Link>
            <div className="admin-detail-page-header">
                <h2 role="title">{name}</h2>
                <div role="buttons">
                    <IconButton
                        icon="fa-solid fa-pencil"
                        onClick={(e) => {
                            e.stopPropagation();
                            setInputText(name);
                        }}
                    >
                        編集
                    </IconButton>
                    <IconButton
                        icon="fa-solid fa-trash"
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowDeleteDialog(true);
                        }}
                    >
                        削除
                    </IconButton>
                </div>
            </div>
            {inputText !== null && (
                <DialogBox
                    title={`${title}の変更`}
                    okText={'更新'}
                    onCancel={() => {
                        setInputText(null);
                    }}
                    onOK={(dialog: DialogBox) => {
                        onEdit(inputText, () => setInputText(null), dialog);
                    }}
                    ref={ref}
                >
                    <ul className="fields">
                        <li>
                            <TextField
                                label={title}
                                _onChange={setInputText}
                                value={inputText}
                                required={true}
                            />
                        </li>
                    </ul>
                </DialogBox>
            )}
            {showDeleteDialog && (
                <DialogBox
                    title="確認"
                    onOK={onDeleteDialogOK}
                    onCancel={() => setShowDeleteDialog(false)}
                >
                    {annotation && <p>{annotation}</p>}
                    {name} を削除しても良いですか？
                </DialogBox>
            )}
        </>
    );
};
