import {
    CreateTenantModel,
    TenantModel,
    TenantOptionsModel,
} from '../models/TenantModel';
import { HttpClient } from '../utils/HttpUilts';

export const getTenantsForSuperAdmin = (): Promise<{ data: TenantModel[] }> => {
    return HttpClient.get('/tenants');
};

export const createTenantForSuperAdmin = (data: CreateTenantModel) => {
    return HttpClient.post('/tenant/create', data);
};

export const getTenantForSuperAdmin = (tenantId: string) => {
    return HttpClient.get(`/tenant/${encodeURIComponent(tenantId)}`);
};

export const getTenantAiTypes = (tenantId: string) => {
    return HttpClient.get(
        `/admin/ai-analysis-types-tenants/${encodeURIComponent(tenantId)}`
    );
};

export type GetTenantOptionModel = {
    tenantId: string;
    category: string;
    keys?: Array<string>;
    array?: Array<string>;
};

export const getTenantOptions = (params: GetTenantOptionModel) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(params.tenantId)}/options`,
        params
    );
};
export const getIsExternalApi = () => {
    return HttpClient.get(`/tenant/options/isExternalApi`);
};

export const getTenantForKintoneAppFields = (
    tenantId: string,
    appId: number
) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(
            tenantId
        )}/options/kintone/${encodeURIComponent(appId)}/fields`
    );
};

export const getTenantForKintoneApp = (tenantId: string, appId: number) => {
    return HttpClient.get(
        `/admin/tenant/${encodeURIComponent(
            tenantId
        )}/options/kintone/app/${encodeURIComponent(appId)}`
    );
};

export const putTenantOptions = (
    tenantId: string,
    options: TenantOptionsModel | null
) => {
    return HttpClient.put(`/admin/tenant/options`, {
        tenantId: tenantId,
        options: options,
    });
};
