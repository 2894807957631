import React, { MouseEvent, useCallback } from 'react';
import { ReelMode, ReelSession } from './model/ReelSession';
import { ButtonGroup, IconButton } from '../atomic/buttons';
import { Icons } from '../atomic/icons';
import { observer } from 'mobx-react-lite';
import { useFileType } from '../../hooks/useFileType';
import { useIsSyncing } from '../../SyncProcess';

export interface ReelPhotoModeMenuProps {
    fileId: string;
    session: ReelSession;
}

export const ReelPhotoModeMenu = observer(
    ({ fileId, session }: ReelPhotoModeMenuProps) => {
        const { isPhoto } = useFileType(fileId);
        const onModeChange = useCallback(
            (data: MouseEvent<HTMLButtonElement>) =>
                (session.photoMode = data.currentTarget.value as ReelMode),
            [session]
        );
        const syncing = useIsSyncing();
        const undo = () => {
            session.fileMeta.undo();
        };

        if (!isPhoto) return <></>;
        return (
            <div className="reel--toolbox">
                <ButtonGroup>
                    <IconButton
                        icon={Icons.ZoomIn}
                        disabled={!session.canZoomIn}
                        onClick={session.zoomIn}
                        title="拡大"
                    />
                    <IconButton
                        icon={Icons.Maximize}
                        onClick={session.maximize}
                        title="画面に合わせる"
                    />
                    <IconButton
                        icon={Icons.ZoomOut}
                        disabled={!session.canZoomOut}
                        onClick={session.zoomOut}
                        title="縮小"
                    />
                </ButtonGroup>
                {!session.readonly && (
                    <ButtonGroup
                        value={session.photoMode}
                        onChange={onModeChange}
                    >
                        <IconButton
                            value={ReelMode.navigate}
                            icon={Icons.ReelNavigate}
                            title="移動"
                        />
                        <IconButton
                            value={ReelMode.edit}
                            icon={Icons.ReelEdit}
                            title="修正"
                        />
                        <IconButton
                            value={ReelMode.select}
                            icon={Icons.ReelSelect}
                            title="トリミング"
                        />
                    </ButtonGroup>
                )}
                <ButtonGroup>
                    <IconButton
                        disabled={syncing || !session.fileMeta.undoAble}
                        onClick={undo}
                        icon={Icons.Undo}
                        title="やり直し"
                    />
                </ButtonGroup>
            </div>
        );
    }
);
