import React, { useMemo } from 'react';
import { Button } from '../atomic/buttons';
import {
    SelectField,
    SelectFieldOption,
    SelectFieldOptionGroup,
} from '../atomic/forms';
import { Icons } from '../atomic/icons';
import { useGet } from '../../hooks/useApi';
import { formatName } from '../../models/FileModel';
import { getFolderLink, useNavigateToItem } from '../atomic/links';
import { FilesAPI } from '../../APIs/filesApi';
import {
    DeleteFilesFileInfo,
    useFileDeleteDialog,
} from '../folderview/DeleteFilesDialog';
import { useNavigate } from 'react-router';

const limit = 10;

export interface ReelFileMenuProps {
    fileId: string;
    folderId: string;
}

export const ReelFileMenu = ({ fileId, folderId }: ReelFileMenuProps) => {
    const fileIds = useGet(FilesAPI.fileIds({ folderId }));
    const item = useGet(FilesAPI.item(fileId));
    const [index, page, offset] = useMemo(() => {
        if (!fileIds.data) return [-2, null, undefined];
        const index = fileIds.data.data.indexOf(fileId);
        if (index === -1) return [-1, null, undefined];
        const page = (index / limit) | 0;
        return [index, page, page * limit];
    }, [fileIds.data, fileId]);
    const pageFiles = useGet(
        offset !== null
            ? FilesAPI.items({
                  parentFolderId: folderId,
                  idsOnly: false,
                  file: true,
                  folder: false,
                  frame: false,
                  limit,
                  offset,
              })
            : null,
        {
            keepPreviousData: false,
            refreshInterval: 5000,
        }
    );
    const pageIds =
        fileIds.data?.data.slice(offset ?? 0, (offset ?? 0) + limit) ?? [];
    const pageIdsStr = pageIds.join(',');
    const navigateToItem = useNavigateToItem();
    const navigate = useNavigate();
    const deleteFile = () => {};
    const [next, prev, nextPage, prevPage] = useMemo(() => {
        if (index < 0) return [];
        if (!fileIds.data) return [];
        const ids = fileIds.data.data;
        const nextFile = ids[index + 1];
        const prevFile = ids[index - 1];
        const next = nextFile
            ? () => navigateToItem({ fileId: nextFile })
            : undefined;
        const prev = prevFile
            ? () => navigateToItem({ fileId: prevFile })
            : undefined;
        const prevPage = offset !== undefined ? ids[offset - 1] : null;
        const nextPage = offset !== undefined ? ids[offset + limit] : null;
        return [next, prev, nextPage, prevPage];
    }, [fileIds.data?.data, index, offset]);
    const selectOptions = useMemo(() => {
        const files = pageFiles.data?.data;
        const result: Array<SelectFieldOptionGroup | SelectFieldOption> = [];
        const pages: SelectFieldOption[] = pageIds.map((fileId, index) => ({
            value: fileId,
            text: files?.[index] ? formatName(files?.[index]) : ' …',
        }));
        result.push({
            label: `ページ ${(page ?? 0) + 1}`,
            options: pages,
        });
        result.push({
            text: '前のページ',
            value: prevPage ?? '',
            disabled: !prevPage,
        });
        result.push({
            text: '次のページ',
            value: nextPage ?? '',
            disabled: !nextPage,
        });
        return result;
    }, [pageIdsStr, pageFiles.data?.data, page, prevPage, nextPage]);
    const deleteDialog = useFileDeleteDialog(
        {
            onPreDelete() {
                if (next) next();
                else if (prev) prev();
                else navigate(getFolderLink(folderId));
            },
        },
        [next, prev, folderId]
    );
    const fileInfo: DeleteFilesFileInfo = {
        id: fileId,
        isFile: true,
        name: `${item.data?.data?.name ?? '...'}`,
    };
    return (
        <div className="reel--navigation">
            <Button icon={Icons.Left} disabled={!prev} onClick={prev} />
            {selectOptions ? (
                <SelectField
                    options={selectOptions}
                    value={fileId}
                    _onChange={(fileId: string) => navigateToItem({ fileId })}
                />
            ) : (
                <div className="reel--filelist--loading">...</div>
            )}
            <Button
                icon={Icons.Delete}
                onClick={() => deleteDialog.show({ files: [fileInfo] })}
            />
            <Button icon={Icons.Right} disabled={!next} onClick={next} />
            {deleteDialog.dialog}
        </div>
    );
};
