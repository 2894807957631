import React from 'react';
import classNames from 'classnames';

import { CheckBoxProps, CheckBox as _CheckBox } from './switches';

import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';

export interface ExTable {
    trClass: string;
}

export function columnDefiner<T extends ExTable>() {
    return createColumnHelper<T>();
}

export const Table = (props) => {
    const { className, columns, data, options, ...rest } = props;

    const table = useReactTable<{ trClass: string }>({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        ...options,
    });

    return (
        <table className={classNames('atomic table', className)} {...rest}>
            <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                      )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody>
                {table.getRowModel().rows.map((row) => {
                    // console.log(row);
                    const { trClass } = row.original; // TODO:;
                    return (
                        <tr key={row.id} className={trClass}>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export const CheckBox = (props: CheckBoxProps) => {
    const { className, ...rest } = props;
    return (
        <_CheckBox
            className={classNames('table-checkbox', className)}
            {...rest}
        />
    );
};
