import React, { useEffect } from 'react';
import {
    InputField,
    InputFieldProps,
    SelectField,
    SelectFieldOption,
    SelectFieldOptionGroup,
    TextField,
} from '../../atomic/forms';
import {
    FieldType,
    KintoneApp,
    KintoneField,
    TenantOptionsModel,
} from '../../../models/TenantModel';
import { Link, useParams } from 'react-router-dom';
import { useTenant } from '../../../contexts/TenantContext';
import {
    getTenantForKintoneApp,
    getTenantForKintoneAppFields,
    getTenantOptions,
    putTenantOptions,
} from '../../../APIs/tenants';
import { getAiAnalysisTypes } from '../../../APIs/templateApi';
import { Table, columnDefiner } from '../../atomic/table';
import { Button } from '../../atomic/buttons';
import { DialogBox } from '../../atomic/dialog';
import { Loading } from '../../atomic/effects';
import { Icons, Icon } from '../../atomic/icons';

interface AppData extends KintoneAppFiled {
    trClass: string;
}
const definer = columnDefiner<AppData>();

type Label = {
    aiAnalysisTypeId: number;
    aiName: string;
    aiNameJa: string;
    labelId: number;
    labelName: string;
    labelNameJa: string;
    color: string;
};

type KintoneAppFiled = {
    label: string;
    code: string;
};

export const AdminKintoneEdit: React.FC = () => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [showConfirm, setShowConfirm] = React.useState(false);

    const { tenantId } = useTenant();

    const { appId } = useParams();
    const [app, setApLocal] = React.useState<KintoneApp>();
    const [kintoneOptions, setKintoneOptions] =
        React.useState<TenantOptionsModel>({});

    const [appList, setAppList] = React.useState<Array<SelectFieldOption>>([]);
    const [selectedAppId, setSelectedAppId] = React.useState<string>('');
    const [showAppIdCopyConfirm, setAppIdCopyShowConfirm] =
        React.useState(false);

    const [labels, setLabels] = React.useState<Label[]>([]);
    const [fields, setFields] = React.useState<KintoneAppFiled[]>([]);

    const [selectOption, setSelectOption] = React.useState<
        Array<SelectFieldOptionGroup | SelectFieldOption>
    >([]);

    interface InputRef {
        appDescription: React.MutableRefObject<InputField<InputFieldProps>>;
    }
    const refs: InputRef = ['appDescription'].reduce((ret, key) => {
        ret[key] = React.useRef<InputField>();
        return ret;
    }, {} as InputRef);

    const setApp = (_app) => {
        setApLocal(_app);
        const _kintoneOptions = { ...kintoneOptions };
        if (!kintoneOptions.kintone) {
            return;
        }

        if (_kintoneOptions.kintone) {
            const _apps = [_app];
            _kintoneOptions.kintone.apps = _apps;
            setKintoneOptions(_kintoneOptions);
        }
    };

    const setAppDescription = (val: string) => {
        const _app = app;
        if (_app) {
            _app.appDescription = val;
            setApp(_app);
        }
    };

    const updateLabels = (types: any[]) => {
        const _labels: Array<Label> = [];
        const _selectOption: Array<SelectFieldOptionGroup | SelectFieldOption> =
            [
                {
                    text: '',
                    value: '',
                },
            ];
        _selectOption.push({
            label: 'ファイル',
            options: [
                {
                    text: '緯度',
                    value: 'LATITUDE',
                },
                {
                    text: '経度',
                    value: 'LONGITUDE',
                },
            ],
        });
        types.map((type) => {
            const group = {
                label: type.nameJa,
                options: [
                    {
                        text: `URL(${type.nameJa})`,
                        value: `REELURL_${type.aiAnalysisTypeId}`,
                    },
                ],
            };
            type.labels.forEach((label) => {
                const data: Label = {
                    aiAnalysisTypeId: type.aiAnalysisTypeId,
                    aiName: type.name,
                    aiNameJa: type.nameJa,
                    labelId: label.id,
                    labelName: label.name,
                    labelNameJa: label.nameJa,
                    color: label.color,
                };
                _labels.push(data);

                group.options.push({
                    text: label.nameJa,
                    value: `LABEL_${type.aiAnalysisTypeId}_${label.id}`,
                });
            });
            _selectOption.push(group);
        });
        setLabels(_labels);
        setSelectOption(_selectOption);
    };

    React.useEffect(() => {
        getAiAnalysisTypes()
            .then((resp) => {
                updateLabels(resp.data);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, []);
    React.useEffect(() => {
        getTenantForKintoneAppFields(tenantId, Number(appId))
            .then((resp) => {
                if (resp.success) {
                    const _fileds: KintoneAppFiled[] = resp.data.map(
                        (filed) => {
                            return filed;
                        }
                    );
                    setFields(_fileds);
                } else {
                    console.warn(resp);
                }
            })
            .catch((err) => {
                console.warn(err);
            });
    }, [tenantId, appId]);

    useEffect(() => {
        if (tenantId) {
            const keyName = `apps_${appId}`;
            getTenantOptions({
                tenantId: tenantId,
                category: 'kintone',
                keys: [keyName],
                //array: ['apps'],
            })
                .then((resp) => {
                    if (resp.data) {
                        if (
                            resp.data.hasOwnProperty('kintone') &&
                            resp.data.kintone.hasOwnProperty(keyName)
                        ) {
                            const _apps = [resp.data.kintone[keyName]];
                            const _option = {
                                kintone: {
                                    apps: _apps,
                                },
                            };
                            setKintoneOptions(_option);

                            const _app = resp.data.kintone[keyName];
                            if (_app) {
                                setApp(_app);
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });

            getTenantOptions({
                tenantId: tenantId,
                category: 'kintone',
                array: ['apps'],
            })
                .then((resp) => {
                    if (resp.data) {
                        if (
                            resp.data.hasOwnProperty('kintone') &&
                            resp.data.kintone.hasOwnProperty('apps')
                        ) {
                            const _apps = resp.data.kintone.apps;
                            setAppList(
                                _apps
                                    .filter((_app) => {
                                        return (
                                            Number(_app.appId) !== Number(appId)
                                        );
                                    })
                                    .map((_app) => {
                                        return {
                                            text: `${_app.appId}:${
                                                _app.appDescription ?? ''
                                            }`,
                                            value: _app.appId,
                                        };
                                    })
                            );
                        }
                    }
                })
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [tenantId, appId]);

    useEffect(() => {
        if (app && app.appDescription === null) {
            // デフォルトの詳細を設定
            getTenantForKintoneApp(tenantId, Number(appId))
                .then((resp) => {
                    if (resp.success) {
                        const _app = { ...app };
                        _app.appDescription = resp.data.name;
                        setApp(_app);
                    } else {
                        console.warn(resp);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [app, tenantId]);

    const inputApp = (code, value: string, overlaap: boolean) => {
        if (app === undefined) {
            return;
        }
        const _app: KintoneApp = { ...app };
        const optionValues = getOptionValues(value);

        if (!_app.fields) {
            return;
        }

        const isSome = _app.fields.some((field) => {
            return field.kintoneFieldName === code;
        });
        if (isSome) {
            const updateFields = _app.fields
                .filter((field) => field)
                .map((field) => {
                    if (field.kintoneFieldName === code) {
                        if (overlaap) {
                            field.aiAnalysisOverlapLabelId =
                                optionValues.labelId;
                        } else {
                            field.aiAnalysisLabelId = optionValues.labelId;
                            field.aiAnalysisOverlapLabelId = '';
                            field.type = optionValues.type;
                            field.aiAnalysisTypeId =
                                optionValues.aiAnalysisTypeId;
                        }
                    }
                    return field;
                });
            _app.fields = updateFields;
        } else {
            _app.fields.push({
                kintoneFieldName: code,
                type: optionValues.type,
                aiAnalysisTypeId: optionValues.aiAnalysisTypeId,
                aiAnalysisLabelId: optionValues.labelId,
                aiAnalysisOverlapLabelId: '',
            });
        }
        setApp(_app);
    };

    const getKintoneFieldToOptionValue = (
        field: KintoneField,
        overlaap: boolean
    ) => {
        if (overlaap) {
            return (
                `${field.type}` +
                (field.aiAnalysisTypeId ? `_${field.aiAnalysisTypeId}` : '') +
                (field.aiAnalysisOverlapLabelId
                    ? `_${field.aiAnalysisOverlapLabelId}`
                    : '')
            );
        }
        return (
            `${field.type}` +
            (field.aiAnalysisTypeId ? `_${field.aiAnalysisTypeId}` : '') +
            (field.aiAnalysisLabelId ? `_${field.aiAnalysisLabelId}` : '')
        );
    };

    const getDefaultOptionValue = React.useCallback(
        (kintoneFieldName: string, overlaap: boolean) => {
            if (!app?.fields) {
                return '';
            }
            const field = app?.fields.find((field) => {
                if (!field) {
                    return false;
                }
                return field.kintoneFieldName === kintoneFieldName;
            });
            if (!field) {
                return '';
            }

            return getKintoneFieldToOptionValue(field, overlaap);
        },
        [app]
    );

    const getSelectedItemByCode = React.useCallback(
        (code: string) => {
            if (!app?.fields) {
                return undefined;
            }
            const fields = app.fields;

            const field = fields.find((field) => {
                if (!field) {
                    return false;
                }
                return field.kintoneFieldName === code;
            });
            return field;
        },
        [app]
    );

    const getSelectedAiTypeByCode = React.useCallback(
        (code: string) => {
            const field = getSelectedItemByCode(code);
            if (field?.type !== 'LABEL') {
                return '';
            }
            return field?.aiAnalysisTypeId;
        },
        [getSelectedItemByCode]
    );

    const getSelectedAiLabelIdByCode = React.useCallback(
        (code: string) => {
            const field = getSelectedItemByCode(code);
            if (field?.type !== 'LABEL') {
                return '';
            }
            return field?.aiAnalysisLabelId;
        },
        [getSelectedItemByCode]
    );

    const options = {
        labels,
        fields,
        app,
        inputApp,
        getDefaultOptionValue,
        getSelectedAiTypeByCode,
    };

    type OptionValues = {
        type: FieldType;
        aiAnalysisTypeId: string;
        labelId: string;
    };

    const getOptionValues = React.useCallback(
        (value) => {
            const result: string[] = value.split('_');

            return {
                type: result.length >= 1 ? result[0] : '',
                aiAnalysisTypeId: result.length >= 2 ? result[1] : '',
                labelId: result.length >= 3 ? result[2] : '',
            } as OptionValues;
        },
        [app]
    );

    const columns = [
        {
            header: 'Kintoneラベル名',
            cell: (d: { row: { original: KintoneAppFiled } }) => {
                return (
                    <div>
                        <div>{d.row.original.label}</div>
                        <div className="sub-title">{d.row.original.code}</div>
                    </div>
                );
            },
        },
        {
            header: '対象AIラベル',
            cell: (d: { row: { original: KintoneAppFiled } }) => {
                return (
                    <>
                        <SelectField
                            options={selectOption}
                            _onChange={(value) => {
                                inputApp(d.row.original.code, value, false);
                            }}
                            value={getDefaultOptionValue(
                                d.row.original.code,
                                false
                            )}
                            required={true}
                        />
                    </>
                );
            },
        },
        {
            header: '重なりチェック',
            cell: (d: { row: { original: KintoneAppFiled } }) => {
                const selectedAiAnalysisTypeId = getSelectedAiTypeByCode(
                    d.row.original.code
                );
                const selectedAiAnalysisLabelId = getSelectedAiLabelIdByCode(
                    d.row.original.code
                );
                const selectFilterOption = [
                    {
                        text: '',
                        value: '',
                    },
                    ...(selectOption
                        .find(({ options }) => {
                            if (!options) {
                                return false;
                            }
                            for (let option of options) {
                                if (option.value === '') {
                                    return true;
                                }
                                const optionValue = getOptionValues(
                                    option.value
                                );
                                if (
                                    optionValue.type === 'LABEL' &&
                                    selectedAiAnalysisTypeId ===
                                        optionValue.aiAnalysisTypeId
                                ) {
                                    return true;
                                }
                            }
                            return false;
                        })
                        ?.options?.filter(({ text, value }) => {
                            const regex = /^URL/;
                            return (
                                !regex.test(text) &&
                                getOptionValues(value)?.labelId !=
                                    selectedAiAnalysisLabelId
                            );
                        }) ?? []),
                ];
                return (
                    <>
                        <SelectField
                            options={selectFilterOption}
                            _onChange={(value) => {
                                inputApp(d.row.original.code, value, true);
                            }}
                            value={getDefaultOptionValue(
                                d.row.original.code,
                                true
                            )}
                            disabled={selectFilterOption.length < 2}
                        />
                    </>
                );
            },
        },
    ];

    const openConfirmDialog = React.useCallback(() => {
        let ok = true;
        for (let key in refs) {
            if (!refs[key].current?.checkValue()) {
                ok = false;
            }
        }
        if (ok) {
            setShowConfirm(true);
        }
    }, []);

    const onConfirmOK = React.useCallback(
        (dialog: DialogBox) => {
            setIsLoading(true);
            putTenantOptions(tenantId, kintoneOptions)
                .then((resp) => {
                    if (resp.success) {
                        setShowConfirm(false);
                    } else {
                        console.warn(resp);
                        dialog.addError(
                            resp.message ||
                                'kintoneアプリ連携情報の更新に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.response?.data?.message ||
                            err.message ||
                            'kintoneアプリ連携情報の更新に失敗しました'
                    );
                })
                .finally(() => {
                    setIsLoading(false);
                });
        },
        [app, kintoneOptions]
    );

    const onConfirmCancel = () => {
        setShowConfirm(false);
    };

    const openConfirmAppCopyDialog = () => {
        if (selectedAppId) {
            setAppIdCopyShowConfirm(true);
        }
    };

    const appCopy = (_orgnalApp) => {
        if (!_orgnalApp) {
            setAppIdCopyShowConfirm(false);
            return;
        }

        // コピー元からアプリのフィールドのものだけ抽出
        const tpmCopyFields = fields.map((field) => {
            return _orgnalApp?.fields.find((_appField) => {
                return _appField.kintoneFieldName === field.code;
            });
        });
        const copyFields = tpmCopyFields.filter((tpmCopyField) => tpmCopyField);
        // コピー元にない設定は現在の設定を引き継ぎ
        const _app = { ...app };
        _app.fields?.map((field) => {
            if (
                copyFields.some((_copyField) => {
                    return (
                        _copyField?.kintoneFieldName === field.kintoneFieldName
                    );
                }) === false
            ) {
                copyFields.push(field);
            }
        });
        _app.fields = copyFields as KintoneField[];
        setApp(_app);
    };

    const showAppIdCopyConfirmOk = React.useCallback(() => {
        // コピー元の設定を取得
        const keyName = `apps_${selectedAppId}`;
        getTenantOptions({
            tenantId: tenantId,
            category: 'kintone',
            keys: [keyName],
        })
            .then((resp) => {
                if (resp.data) {
                    if (
                        resp.data.hasOwnProperty('kintone') &&
                        resp.data.kintone.hasOwnProperty(keyName)
                    ) {
                        appCopy(resp.data.kintone[keyName]);
                    }
                }
            })
            .catch((err) => {
                console.warn(err);
            })
            .finally(() => {
                setAppIdCopyShowConfirm(false);
                setShowCopyBlock(false);
                setIsLoading(false);
            });
    }, [fields, selectedAppId]);

    const showAppIdCopyConfirmCancel = () => {
        setAppIdCopyShowConfirm(false);
    };

    // 設定コピーダイアログ
    const [showCopyBlock, setShowCopyBlock] = React.useState(false);
    const openCopyDialog = () => {
        setShowCopyBlock(true);
    };
    const closeCopyDialog = () => {
        setShowCopyBlock(false);
    };
    return (
        <div className="app-main admin-kintone">
            {fields && app && (
                <>
                    <div className="edit-forms">
                        <div className="form">
                            <h2>Kintone連携パラメータの管理</h2>
                            <div>アプリID:{app.appId}</div>
                            <div>
                                <Link onClick={openCopyDialog} to={'#'}>
                                    <Icon icon={Icons.Copy} />{' '}
                                    他のアプリから設定をコピーする
                                </Link>
                            </div>
                            <div>
                                <TextField
                                    label={'詳細'}
                                    value={app.appDescription}
                                    _onChange={setAppDescription}
                                    placeholder={app.appDescription}
                                    required={true}
                                    ref={refs.appDescription}
                                />
                            </div>
                            <Table
                                className="field-table"
                                data={fields}
                                columns={columns}
                                options={options}
                            />
                            <div className="update-buttons">
                                <Button
                                    className="primary"
                                    onClick={openConfirmDialog}
                                >
                                    保存
                                </Button>
                            </div>
                            <Link to="/app/admin/kintone/apps">
                                kintoneアプリ設定へ戻る
                            </Link>
                        </div>
                    </div>
                </>
            )}
            {showConfirm && (
                <DialogBox
                    title="確認"
                    onOK={onConfirmOK}
                    onCancel={onConfirmCancel}
                >
                    Kintone連携の設定を変更しますか？
                </DialogBox>
            )}
            {showCopyBlock && (
                <DialogBox
                    title="他のアプリから設定をコピーする"
                    onOK={openConfirmAppCopyDialog}
                    onCancel={closeCopyDialog}
                    disabled={selectedAppId == ''}
                >
                    <SelectField
                        label={'コピー元を選択'}
                        options={appList}
                        _onChange={setSelectedAppId}
                        value={selectedAppId}
                    />
                </DialogBox>
            )}
            {showAppIdCopyConfirm && (
                <DialogBox
                    title="確認"
                    onOK={showAppIdCopyConfirmOk}
                    onCancel={showAppIdCopyConfirmCancel}
                >
                    Kintoneアプリ"{selectedAppId}"をロードしますか？
                </DialogBox>
            )}
            {isLoading && <Loading />}
        </div>
    );
};
