import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AuthenticatorFormFields } from './login/AuthenticatorFormFields';
import { AuthenticatorComponent } from './login/AuthenticatorComponent';
import { Auth } from 'aws-amplify';
import { useSWRConfig } from 'swr';

type Prop = {};

export const Login: FC<Prop> = () => {
    const { route, user } = useAuthenticator((context) => [context.route]);
    const { mutate } = useSWRConfig();
    mutate((key) => true, undefined, { revalidate: false });

    const services = {
        // サインイン時の処理
        async handleSignIn({ username, password }): Promise<any> {
            await Auth.signOut();
            let signInResult = await Auth.signIn({
                username,
                password,
            });
            if (signInResult.challengeName === 'CUSTOM_CHALLENGE') {
                // react-uiの画面遷移用にmfaのチャレンジ名に上書きしている
                signInResult.challengeName = 'SMS_MFA';
            }
            return signInResult;
        },

        // mfa認証時の処理
        async handleConfirmSignIn({ user, code }): Promise<any> {
            try {
                let signInResult = await Auth.sendCustomChallengeAnswer(
                    user,
                    code
                );
                return signInResult;
            } catch (error) {
                console.log('Error responding to the custom challenge:', error);
                window.location.reload();
            }
        },
    };

    if (
        route === 'authenticated' &&
        user?.attributes?.['custom:role'] === 'SuperAdmin'
    ) {
        return <Navigate to="/app/home" replace />;
    }

    if (route === 'authenticated' && user) {
        return <Navigate to="/app/home" replace />;
    }

    return (
        <>
            <Authenticator
                services={services}
                formFields={AuthenticatorFormFields}
                components={AuthenticatorComponent}
                hideSignUp={true}
            />
        </>
    );
};
