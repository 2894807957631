import React, { useRef } from 'react';
import {
    getAiAnalysisTypes,
    getTemplates,
    deleteTemplate,
    templateApi,
} from '../../APIs/templateApi';
import { AdminListTags } from './AdminUtils';
import { Icon } from '../atomic/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Loading } from '../atomic/effects';
import { DialogBox } from '../atomic/dialog';
import { ErrorDisplay } from '../Errors';
import { AiAnalysisType } from '../../models/AiAnalysisType';
import { AiTemplateModel } from '../../models/AiTemplateModel';
import { ErrorContext } from '../../contexts';
import { useTabable } from '../../hooks/useClickable';
import { safeUrl } from '../../utils/safeUrl';
import { useMutationContextDialog } from '../../hooks/useMutationDialog';
import { AddTemplateDialog } from './AddTemplateDialog';
import { ImportTemplateDialog } from './ImportTemplateDialog';
import { exportAiTemplate } from '../../APIs/template/exportAiTemplate';
import { mutateAsync } from '../../hooks/useApi';
import { importAdminTemplates } from '../../APIs/template/importAiTemplate';
import { asyncReadJsonFile } from '../../utils/asyncReadFile';

export const AdminTemplates = () => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [templateList, setTemplateList] =
        React.useState<Array<AiTemplateModel> | null>(null);
    const [aiAnalysisTypes, setAiAnarysisTypes] = React.useState<
        Array<AiAnalysisType>
    >([]);
    const [error, setError] = React.useState<Error | null>(null);
    const { setErrors } = React.useContext(ErrorContext);

    const navigate = useNavigate();

    React.useEffect(() => {
        getAiAnalysisTypes()
            .then((resp) => {
                console.log(resp);
                //if (resp.success) {
                setAiAnarysisTypes(resp.data);
                //}
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
        getTemplates()
            .then((resp) => {
                console.log(resp);
                //if (resp.success) {
                setTemplateList(resp.data);
                //}
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
    }, []);

    const newTemplateOptions = {
        onError: 'テンプレートの作成に失敗しました',
        onSuccess: ({ data: { data } }) => {
            navigate(safeUrl`/app/admin/template/${data.aiTemplateId}`);
        },
    };
    const addTemplate = useMutationContextDialog(
        templateApi.mutate.createTemplate,
        AddTemplateDialog,
        newTemplateOptions
    );
    const importTemplate = useMutationContextDialog(
        templateApi.mutate.createTemplate,
        ImportTemplateDialog,
        newTemplateOptions
    );

    const onDeleteTemplateHandle = React.useCallback(
        (aiTemplateId: string, dialog: DialogBox, closeDialog: () => void) => {
            setLoading(true);
            deleteTemplate(aiTemplateId)
                .then((resp) => {
                    console.log(resp);
                    setTemplateList((prevState) => {
                        const nextState = JSON.parse(JSON.stringify(prevState));
                        return nextState.filter(
                            (item) => item.aiTemplateId != aiTemplateId
                        );
                    });
                    closeDialog();
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'テンプレートの削除に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    const onExport = React.useCallback((aiTemplateId: string) => {
        setLoading(true);
        mutateAsync(exportAiTemplate, { aiTemplateId })
            .catch((err) => {
                console.warn(err);
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const onChangeImportFile = React.useCallback(
        (event) => {
            const file = event.target.files[0];
            if (!file) return;
            asyncReadJsonFile(file)
                .then((json) => importAdminTemplates(aiAnalysisTypes, json))
                .then(
                    (request) =>
                        importTemplate.show({
                            aiAnalysisTypes,
                            request,
                        }),
                    (err) => {
                        console.warn(err);
                        setErrors((errors) => [
                            ...errors,
                            err.message ||
                                'テンプレートのインポートに失敗しました',
                        ]);
                    }
                );
        },
        [aiAnalysisTypes, importTemplate.show]
    );

    const importRef = useRef<HTMLInputElement | null>(null);
    const importButton = useTabable(() => {
        importRef.current?.click();
    }, []);

    if (error) {
        return <ErrorDisplay error={error} />;
    }
    if (!templateList) {
        return <Loading />;
    }

    return (
        <>
            <div className="app-main admin-templates">
                <h2>テンプレート一覧</h2>
                <AdminListTags
                    items={templateList.map((template) => {
                        return {
                            url: safeUrl`/app/admin/template/${template.aiTemplateId}`,
                            name: template.name,
                            id: template.aiTemplateId,
                        };
                    })}
                    onDelete={onDeleteTemplateHandle}
                    onExport={onExport}
                    annotation="テンプレートを削除すると、関連するプロジェクトも削除されます。"
                />
                <div>
                    <div className="admin-tags-footer">
                        <Link
                            to="#"
                            onClick={() =>
                                addTemplate.show({ aiAnalysisTypes })
                            }
                        >
                            <Icon icon="fa-solid fa-folder-plus" />{' '}
                            テンプレートを追加する
                        </Link>

                        <label
                            htmlFor="import-template"
                            className="import-button"
                            {...importButton}
                        >
                            <Icon icon="fa-solid fa-upload" /> インポートする
                        </label>
                    </div>
                    <input
                        type="file"
                        ref={importRef}
                        style={{ display: 'none' }}
                        onChange={onChangeImportFile}
                        id="import-template"
                        accept=".json"
                    />
                </div>
            </div>
            {addTemplate.dialog}
            {importTemplate.dialog}
            {loading && <Loading />}
        </>
    );
};
