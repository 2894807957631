import React from 'react';
import { TextField, InputField, PhoneNumberField } from '../atomic/forms';
import { Button } from '../atomic/buttons';
import { useLocation } from 'react-router-dom';
import { Loading } from '../atomic/effects';
import { useNavigate } from 'react-router-dom';
import { UserModel } from '../../models/UserModel';
import { updateUser } from '../../APIs/userApi';
import { DialogBox } from '../atomic/dialog';

type Prop = {};
export const AdminUserEditForm: React.FC<Prop> = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const refs: Array<React.RefObject<TextField>> = [];
    const [familyName, setFamilyName] = React.useState<string>('');
    const [givenName, setGivenName] = React.useState<string>('');
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<UserModel | null>(null);
    const [showConfirmDialog, setShowConfirmDialog] =
        React.useState<boolean>(false);

    const appendRef = () => {
        const ref = React.useRef<InputField>();
        refs.push(ref);
        return ref;
    };

    const resetValues = () => {
        setFamilyName(user?.familyName ?? '');
        setGivenName(user?.givenName ?? '');
        setPhoneNumber(user?.phoneNumber ?? '');
    };

    React.useEffect(() => {
        setUser(location?.state?.user);
    }, []);

    React.useEffect(() => {
        resetValues();
    }, [user]);

    const onBackClick = React.useCallback(() => {
        navigate('/app/admin/users');
    }, []);

    const onResetClick = React.useCallback(() => {
        resetValues();
    }, [user]);

    const onSubmitClick = React.useCallback(() => {
        let validate = true;
        refs.forEach((ref) => {
            if (ref && ref.current && !ref.current.checkValue()) {
                validate = false;
            }
        });
        if (!validate) {
            return false;
        }
        setShowConfirmDialog(true);
    }, []);

    const onOKHandle = (dialog: DialogBox) => {
        dialog.clearError();
        const userId: string = user?.userId ?? '';
        if (!userId) {
            dialog.addError('ユーザIDの取得に失敗しました');
            return false;
        }
        setLoading(true);
        updateUser(userId, {
            familyName,
            givenName,
            phoneNumber,
        })
            .then((resp) => {
                console.log(resp);
                if (resp.success) {
                    setShowConfirmDialog(false);
                } else {
                    dialog.addError(
                        resp.message || 'ユーザ情報の更新に失敗しました'
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
                dialog.addError(
                    err?.response?.data?.message ||
                        err.message ||
                        'ユーザ情報の更新に失敗しました'
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onCancelHandle = React.useCallback(() => {
        setShowConfirmDialog(false);
    }, []);

    return (
        <div className="app-main user">
            <h2>ユーザ情報の編集</h2>
            <div className="edit-forms">
                <div className="form">
                    <ul className="fields">
                        <li>
                            <TextField
                                label="姓"
                                _onChange={setFamilyName}
                                value={familyName}
                                required={true}
                                ref={appendRef()}
                            />
                        </li>
                        <li>
                            <TextField
                                label="名"
                                _onChange={setGivenName}
                                value={givenName}
                                required={true}
                                ref={appendRef()}
                            />
                        </li>
                        <li>
                            <PhoneNumberField
                                label="電話番号"
                                _onChange={setPhoneNumber}
                                value={phoneNumber}
                                required={true}
                                ref={appendRef()}
                            />
                        </li>
                    </ul>
                    <div className="update-buttons">
                        <Button onClick={onBackClick}>一覧に戻る</Button>
                        <Button onClick={onResetClick}>リセット</Button>
                        <Button className="primary" onClick={onSubmitClick}>
                            更新
                        </Button>
                    </div>
                </div>
            </div>
            {showConfirmDialog && (
                <DialogBox
                    title="確認"
                    onOK={onOKHandle}
                    onCancel={onCancelHandle}
                >
                    ユーザ情報のを変更しますか？
                </DialogBox>
            )}
            {loading && <Loading />}
        </div>
    );
};
