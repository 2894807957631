export function asyncReadTextFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
            let text = e.target?.result as string | null | undefined;
            resolve(text ?? '');
        };
        reader.onerror = () => {
            reject(new Error('ファイル読み込み中にエラーがおこなりました。'));
        };
        reader.readAsText(file);
    });
}

export async function asyncReadJsonFile(file: File): Promise<any> {
    const text = await asyncReadTextFile(file);
    try {
        return JSON.parse(text);
    } catch (Err) {
        throw new Error(`JSON フォーマットエラー:\n${text}`);
    }
}
