import { IComputedValue, IObservableValue, action, reaction } from 'mobx';
import { useEffect, useState } from 'react';

export type MobxValue<T = any> = IObservableValue<T> | IComputedValue<T>;

const helperCache = new WeakMap<
    MobxValue,
    { get: () => any; set: (data: any) => void }
>();

export function useMobxValue<T>(value: MobxValue<T>): [T, (data: T) => void] {
    let helper = helperCache.get(value);
    if (!helper) {
        helper = {
            get: () => value.get(),
            set: action((data: T) => value.set(data)),
        };
        helperCache.set(value, helper);
    }
    const [_, setState] = useState(0);
    useEffect(
        () =>
            reaction(helper!.get, () => setState(Date.now()), {
                fireImmediately: false,
            }),
        []
    );
    return [value.get(), helper.set];
}
