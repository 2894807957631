import React, {
    FC,
    ReactNode,
    createContext,
    useMemo,
    useContext,
} from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AmplifyUser } from '@aws-amplify/ui';

export interface User {
    tenantId: string;
    userId: string;
    email: string;
    firstName: string;
    lastName: string;
    role: string;
    phoneNumber: string;
}

type AuthContextType = {
    isLoading: boolean;
    isAuthenticated: boolean;
    isAdmin: () => boolean;
    isUser: () => boolean;
    isSuperAdmin: () => boolean;
    isExternalUser: () => boolean;
    fetchUser: () => AmplifyUser | undefined;
    signOut: () => void;
};

const AuthContext = createContext<AuthContextType>({
    isLoading: false,
    isAuthenticated: false,
    fetchUser: () => undefined,
    isAdmin: () => false,
    isUser: () => false,
    isSuperAdmin: () => false,
    isExternalUser: () => false,
    signOut: () => {},
});

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const { authStatus, user, signOut: cognitoLogout } = useAuthenticator();

    const role = user?.attributes?.['custom:role'];

    const fetchUser = () => user;
    const isAdmin = () => role === 'Admin';
    const isUser = () => role === 'User';
    const isSuperAdmin = () => role === 'SuperAdmin';
    const isExternalUser = () => role === 'External';
    const signOut = () => cognitoLogout();

    return (
        <AuthContext.Provider
            value={{
                isLoading: authStatus === 'configuring',
                isAuthenticated: authStatus === 'authenticated',
                fetchUser,
                isAdmin,
                isUser,
                isSuperAdmin,
                isExternalUser,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
