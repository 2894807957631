import { Hub, Auth } from 'aws-amplify';
import { mutate } from 'swr';
import { API_BASE_URL } from '../config';

const sessionEvents = new Set([
    'signIn', // 'user signed in
    'signIn_failure', // user sign in failed
    'signUp', // user signed up
    'signUp_failure', // 'user sign up failed
    'autoSignIn', // auto sign in successful
    'autoSignIn_failure', // 'auto sign in failed
    'confirmSignUp', // user confirmation successful
    'tokenRefresh', // token refresh succeeded
    'tokenRefresh_failure', // 'token refresh failed
    'userDeleted', // user deletion successful
    'verify', // TOTP token verification successful
] as const);
const knownEvents = new Set([
    ...sessionEvents,
    'configured',
    'completeNewPassword_failure', // 'user did not complete new password flow
    'forgotPassword', // password recovery initiated
    'forgotPassword_failure', // 'password recovery failed
    'forgotPasswordSubmit', // password confirmation successful
    'forgotPasswordSubmit_failure', // 'password confirmation failed
    'cognitoHostedUI', // Cognito Hosted UI sign in successful
    'cognitoHostedUI_failure', // 'Cognito Hosted UI sign in failed
    'customOAuthState', // custom state returned from CognitoHosted UI
    'customState_failure', // 'custom state failure
    'parsingCallbackUrl', // Cognito Hosted UI OAuth url parsing initiated
    'updateUserAttributes', // user attributes update successful
    'updateUserAttributes_failure', // user attributes update failed
    'signOut', // user signed out
] as const);

type KnownEvents = typeof knownEvents extends Set<infer T> ? T : unknown;
type SessionEvents = typeof sessionEvents extends Set<infer T> ? T : unknown;

function isSessionEvent(event: string): event is SessionEvents {
    return sessionEvents.has(event as any);
}

function isKnownEvent(event: string): event is KnownEvents {
    return knownEvents.has(event as any);
}

export type AuthHeader = {
    [key: string]: string;
};

const emptyHeader = Object.freeze({});

Hub.listen('auth', ({ payload }) => {
    const { event } = payload;
    if (!isKnownEvent(event)) {
        console.warn(`Unknown event: ${event}`);
        return;
    }
    if (isSessionEvent(event)) {
        console.log('session was updated');
    }
});

const ROOT_URL = new URL('/', document.location.href).href;

export function isAuthorizedURL(url: string): boolean {
    if (url.startsWith(ROOT_URL)) {
        return true;
    }
    return /^\//.test(url) || /^https:\/\/.*-api/.test(url);
}

async function currentSession() {
    const session = await Auth.currentSession();
    console.debug(
        'exp:' + new Date(session.getAccessToken().payload.exp * 1000)
    );
    // expireの時間が近くなったらセッションを更新してexpireを強制更新する
    const exp = session.getAccessToken().payload.exp;
    const time = Date.now() / 1000;
    const diff = exp - time;
    if (diff < 1800) {
        const user = await Auth.currentAuthenticatedUser();
        await user.refreshSession(session.getRefreshToken(), () => {
            console.debug('refreshed auth session');
        });
        return await Auth.currentSession();
    }
    return session;
}

export async function getAuthHeader(): Promise<AuthHeader> {
    const TRY_COUNT = 5;
    for (let i = 0; i < TRY_COUNT; i++) {
        try {
            const session = await currentSession();
            return {
                Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
            };
        } catch (err) {
            console.warn('couldnt get new JWTToken', err);
            // 少しずつ待つ時間は長くしていく
            if (i < TRY_COUNT - 1) {
                await new Promise((resolve) =>
                    setTimeout(resolve, (i + 1) * 100)
                );
            }
        }
    }

    return emptyHeader;
}
