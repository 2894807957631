import React from 'react';

export type ErrorSetter = (
    input: string[] | ((errors: string[]) => string[])
) => void;
export interface ErrorContextValue {
    errors: string[];
    setErrors: ErrorSetter;
}
export const ErrorContext = React.createContext<ErrorContextValue>({
    errors: [],
    setErrors: (input) => {
        if (typeof input === 'function') {
            input([]);
        }
    },
});

export const StorageContext = React.createContext([]);
