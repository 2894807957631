export function safeUrl(
    strParts: TemplateStringsArray,
    ...args: Array<URLSearchParams | string | number | undefined | null>
): string {
    let result = strParts[0];
    let i = 0;
    while (i < args.length) {
        const arg = args[i];

        const part =
            arg === null || arg === undefined
                ? ''
                : typeof arg === 'string'
                ? encodeURIComponent(arg)
                : arg.toString();
        result += part + strParts[++i];
    }
    return result;
}
