import { HttpClient, BaseApiResponse, useFetch } from '../utils/HttpUilts';
import { UserModel } from '../models/UserModel';
import { UserOneModel } from '../models/UserModel';
import { createGet, simpleGet } from '../hooks/useApi';
import { TaskModel } from '../models/TaskModel';
import { FolderPermission } from '../models/FolderModel';

export type UserRole = 'SuperAdmin' | 'Admin' | 'User' | 'External';

export interface InviteUserRequest {
    tenantId: string;
    email: string;
    role: UserRole;
}

export interface UpdateUserRequest {
    familyName: string;
    givenName: string;
    phoneNumber: string;
}
export const getUsers = (): Promise<BaseApiResponse<Array<UserModel>>> => {
    return HttpClient.get('/users');
};

export const getUser = (
    userId: string
): Promise<BaseApiResponse<UserOneModel>> => {
    return HttpClient.get('/user/' + encodeURIComponent(userId));
};

export const postUser = (userId: string): Promise<BaseApiResponse<void>> => {
    return HttpClient.post(`/user/${userId}`, {});
};

export const updateUser = (
    userId: string,
    data: UpdateUserRequest
): Promise<BaseApiResponse<void>> => {
    return HttpClient.patch(`/user/${userId}`, data);
};

export const removeUser = (
    userId: string
): Promise<BaseApiResponse<{ userId: string }>> => {
    return HttpClient.del(`/user/${userId}`);
};

export const inviteUser = (data: InviteUserRequest) => {
    return HttpClient.post('/user', data);
};

export const userTask = createGet<{ data: Array<TaskModel> }>(
    '/user/task',
    undefined,
    { refreshInterval: 5000 }
);

export interface UserKeyModel {
    apiKey: string;
    expireAt: string;
}
export type UserKeysModel = Array<{
    userId: string;
    apiKey: string;
    expireAt: string;
}>;
export const userApi = {
    get: {
        userTask,
        itemPermission: (itemId: string) =>
            simpleGet<FolderPermission>`/item/${itemId}/isAccess`,
        apiKey: simpleGet<UserKeyModel>`/user/apikey`,
        apiKeys: simpleGet<UserKeysModel>`/user/apikeys`,
    },
    delete: {
        apiKey: (key: string) =>
            HttpClient.del(`/user/apikey/${encodeURIComponent(key)}`),
        userApiKey: (userId: string) =>
            HttpClient.del(`/user/${encodeURIComponent(userId)}/apikeys`),
    },
};
