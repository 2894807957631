import React, { useCallback } from 'react';
import { CheckBox } from '../atomic/switches';
import { LocalAspectConfig } from './LocalConfig';
import { SelectField } from '../atomic/forms';

const oneToTwenty = [...Array(20)].map((_value: any, index: number) => ({
    value: String(index + 1),
    text: String(index + 1),
}));

export interface LocalConfigAspectFieldsProps {
    aspect: LocalAspectConfig;
    setAspect: (fn: (aspect: LocalAspectConfig) => LocalAspectConfig) => void;
}
export function useLocalConfigAspectFields({
    aspect,
    setAspect,
}: LocalConfigAspectFieldsProps) {
    const setW = useCallback(
        (w: string) =>
            setAspect((aspect) => ({
                ...aspect,
                w: parseInt(w, 10),
            })),
        []
    );
    const setH = useCallback(
        (h: string) =>
            setAspect((aspect) => ({
                ...aspect,
                h: parseInt(h, 10),
            })),
        []
    );
    const setUseAspect = useCallback(
        (useAspect: boolean) =>
            setAspect((aspect) => ({
                ...aspect,
                use: useAspect,
            })),
        []
    );
    return (
        <>
            <CheckBox
                label="トリミング範囲のアスペクト比を指定する"
                name="aspect-enabled"
                checked={aspect.use}
                _onChange={setUseAspect}
            />
            <div className="aspect-group">
                <div className="select-container">
                    <SelectField
                        name="aspect-width"
                        className="aspect-select"
                        value={String(aspect.w)}
                        _onChange={setW}
                        options={oneToTwenty}
                        disabled={!aspect.use}
                    />
                </div>
                <div className="separator">:</div>
                <div className="select-container">
                    <SelectField
                        name="aspect-height"
                        className="aspect-select"
                        value={String(aspect.h)}
                        _onChange={setH}
                        options={oneToTwenty}
                        disabled={!aspect.use}
                    />
                </div>
            </div>
        </>
    );
}
