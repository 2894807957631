import { useMemo, useState } from 'react';
import {
    ProgressController,
    ProgressHandler,
    ProgressLogEntry,
    ProgressReporter,
} from '../utils/progress';

export interface ReporterState {
    progress: number | undefined;
    logs: ProgressLogEntry[];
}
export interface UseReporterReturn {
    reporter: ProgressReporter;
    onProgress: ProgressHandler;
    reset: () => void;
    state: ReporterState;
}
export interface UseReporterOpts {
    working: boolean;
}
export function useReporter({ working }: UseReporterOpts): UseReporterReturn {
    const [state, setState] = useState<ReporterState>({
        logs: [],
        progress: 0,
    });
    const base = useMemo(() => {
        const reset = () =>
            setState({
                logs: [],
                progress: 0,
            });
        const onProgress = (entry: ProgressLogEntry) =>
            setState(({ logs }) => ({
                progress: entry.progress,
                logs: [entry, ...logs],
            }));
        return {
            reporter: new ProgressController({
                onProgress,
            }),
            onProgress,
            reset,
        };
    }, []);
    return {
        ...base,
        state: {
            logs: state.logs,
            progress: working ? state.progress : undefined,
        },
    };
}
