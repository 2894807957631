import { nanoid } from 'nanoid';

const lookup = new WeakMap();
export function getObjectId(object: Object): string {
    if (typeof object !== 'object' || object === null) {
        console.warn('Unexpected object id requested', object);
        return nanoid();
    }
    let id = lookup.get(object);
    if (!id) {
        id = nanoid();
        lookup.set(object, id);
    }
    return id;
}
