/**
 * このファイルはサーバーにもクライアントにも必要です。
 * This file needs to exist both on the server and client.
 * 同期にしてください。
 * Please keep in sync.
 */
import * as tess2 from 'tess2';

const { tesselate } = tess2.default ?? tess2;

const NO_TRANSFORM = {
    scaleX: 1,
    scaleY: 1,
};

/**
 * @param {import('./calculatePointArea.d.sts').CompressedPointWithId[]} points
 * @param {import('./calculatePointArea.d.sts').Transform | undefined} transform
 */
function* pointsToVertices(points, transform) {
    const { scaleX, scaleY } = transform ?? NO_TRANSFORM;
    /** @type {number | undefined} */
    let prevX;
    /** @type {number | undefined} */
    let prevY;
    for (const point of points) {
        const x = point[1] * scaleX;
        const y = point[2] * scaleY;
        if (
            prevX !== undefined &&
            prevY !== undefined &&
            prevX === x &&
            prevY === y
        ) {
            continue;
        }
        yield x;
        yield y;
        prevX = x;
        prevY = y;
    }
}

/**
 * @param {number} x1
 * @param {number} y1
 * @param {number} x2
 * @param {number} y2
 * @returns {number}
 */
function cross(x1, y1, x2, y2) {
    return x1 * y2 - x2 * y1;
}

/**
 * @param {number[]} vertices
 * @param {number[]} indices
 * @param {number} off
 * @returns {number}
 */
function calculateTriangleArea(vertices, indices, off) {
    let i = indices[off] << 1;
    const xa = vertices[i];
    const ya = vertices[i + 1];
    i = indices[off + 1] << 1;
    const xb = vertices[i];
    const yb = vertices[i + 1];
    i = indices[off + 2] << 1;
    const xc = vertices[i];
    const yc = vertices[i + 1];
    return (
        Math.abs(
            cross(xa, ya, xb, yb) +
                cross(xb, yb, xc, yc) +
                cross(xc, yc, xa, ya)
        ) * 0.5
    );
}

/**
 * @param {import('./ServerClientModel.d.ts').CompressedPoints} shape
 * @param {import('./ServerClientModel.d.ts').Transform} transform
 * @returns {number}
 */
export function calculatePointArea(shape, transform) {
    const contours = [];
    for (const points of shape) {
        if (points.length < 3) {
            continue;
        }
        contours.push(Array.from(pointsToVertices(points, transform)));
    }
    if (contours.length === 0) {
        return 0;
    }
    let result;
    try {
        result = tesselate({ contours });
    } catch (error) {
        // Tesslation can throw errors if a given geometry
        // can not be tesselated. This usually occurs when a
        // geometry has an area of value "zero".
        return 0;
    }
    const { vertices, elements: indices } = result;
    const l = indices.length;
    let sum = 0;
    for (let offset = 0; offset < l; offset += 3) {
        sum += calculateTriangleArea(vertices, indices, offset);
    }
    return sum;
}
