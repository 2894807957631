import { ReactElement, ReactNode, isValidElement } from 'react';

export type MatchForType<Type extends CheckType> = Type extends {
    new (props: infer Props, startState: any): any;
}
    ? ReactElement<Props, any>
    : Type extends (props: infer Props, startState: any) => any
    ? ReactElement<Props>
    : unknown;

export type CheckType =
    | {
          new (props: any, startState: any): any;
      }
    | ((props: any, startState: any) => any);

export function isConcreteElementType<Type extends CheckType>(
    child: ReactElement,
    type: Type
): child is MatchForType<Type> {
    return type.isPrototypeOf(child.type);
}

export function isElementType<Type extends CheckType>(
    child: ReactNode,
    type: Type
): child is MatchForType<Type> {
    if (!isValidElement(child)) return false;
    return isConcreteElementType(child, type);
}
