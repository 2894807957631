import {
    DependencyList,
    KeyboardEventHandler,
    MouseEventHandler,
    useMemo,
} from 'react';

export interface UseTabable {
    onKeyDown: KeyboardEventHandler;
    tabIndex: number;
}

export interface UseClickable extends UseTabable {
    onClick?: MouseEventHandler;
}

type Maybe<T extends Object | Function | string | number> = T | undefined;
type MaybeResult<T, Result> = T extends undefined
    ? undefined
    : T extends Object | Function | string | number
    ? Result
    : Result | undefined;

function toTabable<T extends Maybe<() => any>>(
    onClick: T
): MaybeResult<T, UseTabable> {
    if (!onClick) {
        return undefined as MaybeResult<T, UseTabable>;
    }
    const tabable: UseTabable = {
        onKeyDown: (event) => {
            if (event.code === 'Enter' || event.code === 'Space') {
                onClick();
            }
        },
        tabIndex: 0,
    };
    return tabable as MaybeResult<T, UseTabable>;
}

export const useTabable = <T extends Maybe<() => any>>(
    onClick: T,
    deps: DependencyList
) => useMemo((): MaybeResult<T, UseTabable> => toTabable(onClick), deps);

export const useClickable = <T extends Maybe<() => any>>(
    onClick: T,
    deps: DependencyList
) =>
    useMemo((): MaybeResult<T, UseClickable> => {
        if (!onClick) {
            return undefined as MaybeResult<T, UseTabable>;
        }
        return {
            onClick,
            ...toTabable(onClick),
        };
    }, deps);
