import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';

import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMapEvents,
} from 'react-leaflet';
import { useMap } from 'react-leaflet/hooks';
import Leaflet, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import { TagTypeModel, TagType } from '../../models/TagModel';
import { FolderByTagTagTypeIdModel } from '../../models/FolderModel';
import { getTagTypesByType } from '../../APIs/tagApi';
import { getFoldersByType } from '../../APIs/foldersApi';

import { SelectField } from '../atomic/forms';
import { TagIcon, FolderIcon } from '../atomic/icons';
import { getRoundedLocation, zoomToRound } from './utils';

// marker setting
let DefaultIcon = Leaflet.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
});
Leaflet.Marker.prototype.options.icon = DefaultIcon;

const Recenter = ({ center }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center);
    }, [center]);
    return null;
};
const Makers = ({ folder }) => {
    if (!folder.value) {
        return <></>;
    }
    const center = folder.value.split(',');
    const navigate = useNavigate();
    const onClick = React.useCallback(() => {
        navigate(`/app/folderlist/${encodeURIComponent(folder.folderId)}`);
    }, [folder]);
    return (
        <Marker position={center} eventHandlers={{ click: onClick }}>
            <Popup>
                <p>{folder.folderName}</p>
                <p>{folder.tenantTagName}</p>
                <p>{folder.value}</p>
            </Popup>
        </Marker>
    );
};
const MapControl = ({ onChangeZoom }) => {
    const map = useMap();
    useMapEvents({
        zoomend: (e) => {
            onChangeZoom(map.getZoom());
        },
        zoomstart: () => {
            map.closePopup();
        },
    });
    return <></>;
};
interface ViewMapProp {
    folder: FolderByTagTagTypeIdModel;
    folders: FolderByTagTagTypeIdModel[];
    defaultZoom: number;
}
const ViewMap = ({ folder, folders, defaultZoom }: ViewMapProp) => {
    const getLatLng = (folder) => {
        const s = folder?.value?.split(',');
        if (s && s.length >= 2) {
            const lat = parseFloat(s[0]);
            const lng = parseFloat(s[1]);
            if (!isNaN(lat) && !isNaN(lng)) {
                return new LatLng(lat, lng);
            }
        }
        return null;
    };
    const center: LatLng = useMemo(
        () => getLatLng(folder) ?? new LatLng(34.681873, 135.524734),
        [folder]
    );
    const [zoom, setZoom] = useState<number>(defaultZoom);
    const markers: {
        [key: string]: {
            position: LatLng;
            key: string;
            folders: FolderByTagTagTypeIdModel[];
        };
    } = useMemo(() => {
        const round = zoomToRound(zoom);
        const retVal = {};
        folders.forEach((folder) => {
            const latlng = getLatLng(folder);
            if (!latlng) {
                return;
            }
            const key = getRoundedLocation(
                latlng.lat,
                latlng.lng,
                round
            ).toString();
            if (key in retVal) {
                retVal[key].folders.push(folder);
            } else {
                retVal[key] = {
                    position: latlng,
                    key: folder.folderId,
                    folders: [folder],
                };
            }
        });
        return retVal;
    }, [zoom, folders]);
    return (
        <MapContainer className="map" center={center} zoom={defaultZoom}>
            <Recenter center={center} />
            <TileLayer
                attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
                url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            />
            {Object.keys(markers).map((key) => (
                <Marker position={markers[key].position} key={markers[key].key}>
                    <Popup>
                        <ul>
                            {markers[key].folders.map((folder, index) => {
                                return (
                                    <li key={index}>
                                        <FolderIcon />{' '}
                                        <Link
                                            to={`/app/folderlist/${folder.folderId}`}
                                        >
                                            {folder.folderName}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </Popup>
                </Marker>
            ))}
            <MapControl onChangeZoom={setZoom} />
        </MapContainer>
    );
};

const FolderList = ({ folders, tagDetailId, setTagDetailId }) => {
    if (!folders.length) {
        return <></>;
    }

    const onChange = (value) => {
        setTagDetailId(value);
    };

    return (
        <div className="select-group">
            <FolderIcon />
            <SelectField
                value={tagDetailId}
                _onChange={onChange}
                options={folders.map((folder) => ({
                    value: folder.tagDetailId,
                    text: folder.folderName,
                }))}
            />
        </div>
    );
};

const TagTypeList = ({ tagTypes, tagTypeId, setTagTypeId }) => {
    if (tagTypes.length === 0) {
        return <></>;
    }
    const onChange = (value) => {
        setTagTypeId(value);
    };
    return (
        <div className="select-group">
            <TagIcon />
            <SelectField
                value={tagTypeId}
                _onChange={onChange}
                options={tagTypes
                    .filter(({ type }) => type == TagType.Location)
                    .map((tagType) => ({
                        value: tagType.tagTypeId,
                        text: `${tagType.tenantTag.name}(${tagType.name})`,
                    }))}
            />
        </div>
    );
};
export const Map = () => {
    const urlParams = useParams<{ tagTypeId: string; tagDetailId?: string }>();
    const [tagTypes, setTagTypes] = useState<Array<TagTypeModel>>([]);
    const [tagTypeId, setTagTypeId] = useState<string | undefined>(
        urlParams.tagTypeId
    );
    const [tagDetailId, setTagDetailId] = useState<string | undefined>(
        urlParams.tagDetailId
    );
    const [allFolders, setAllFolders] = useState<
        Array<FolderByTagTagTypeIdModel>
    >([]);
    const [folders, setFolders] = useState<Array<FolderByTagTagTypeIdModel>>(
        []
    );
    const [location, setLocation] = useState<
        FolderByTagTagTypeIdModel | undefined
    >(undefined);

    useEffect(() => {
        getTagTypesByType(TagType.Location)
            .then((resp) => {
                console.log({ resp });
                if (resp.success && resp.data) {
                    setTagTypes(resp.data);
                }
            })
            .catch((err) => {
                console.error(err);
            });
        getFoldersByType(TagType.Location)
            .then((resp) => {
                console.log({ resp });
                if (resp.success && resp.data) {
                    // 親フォルダの属性値を子に伝搬させているので、フォルダの階層構造を表示しておく
                    const folders: FolderByTagTagTypeIdModel[] = resp.data;
                    setAllFolders(
                        folders
                            .map((folder) => {
                                const names = [folder.folderName];
                                const addName = (id: string | null) => {
                                    if (!id) {
                                        return;
                                    }
                                    const folder = folders.find(
                                        ({ folderId }) => folderId == id
                                    );
                                    if (folder) {
                                        names.unshift(folder.folderName);
                                        addName(folder.parentFolderId);
                                    }
                                };
                                addName(folder.parentFolderId);
                                return {
                                    ...folder,
                                    folderName: names.join(' > '),
                                };
                            })
                            .sort((a, b) => {
                                const _a = a.folderName;
                                const _b = b.folderName;
                                if (_a > _b) {
                                    return 1;
                                } else if (_a < _b) {
                                    return -1;
                                }
                                return 0;
                            })
                    );
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    useEffect(() => {
        if (tagTypeId === undefined) {
            setFolders([]);
            return;
        }
        const folders = allFolders.filter((folder) => {
            return folder.tagTypeId === tagTypeId && folder.value;
        });
        setFolders(folders);
    }, [allFolders, tagTypeId]);

    useEffect(() => {
        if (
            folders &&
            folders.length &&
            !folders.find((item) => item.tagDetailId == tagDetailId)
        ) {
            setTagDetailId(folders[0].tagDetailId);
        }
    }, [folders, tagDetailId]);

    useEffect(() => {
        if (tagDetailId) {
            const folder = folders.find(
                (item) => item.tagDetailId == tagDetailId
            );
            if (folder) {
                setLocation(folder);
                return;
            }
        }
        setLocation(undefined);
    }, [tagDetailId, folders]);

    return (
        <div className="app-main app-map">
            <div className="map-container">
                <div className="lists">
                    <TagTypeList
                        tagTypes={tagTypes}
                        tagTypeId={tagTypeId}
                        setTagTypeId={setTagTypeId}
                    />
                    <FolderList
                        folders={folders}
                        tagDetailId={tagDetailId}
                        setTagDetailId={setTagDetailId}
                    />
                </div>
                {location !== undefined ? (
                    <ViewMap
                        folder={location}
                        folders={folders}
                        key={location.tagDetailId}
                        defaultZoom={16}
                    />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};
