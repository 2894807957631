import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';
import { Reel } from './reel/Reel';
import { ErrorPanelEx } from './Errors';
import { projectApi } from '../APIs/projectApi';
import { useGet } from '../hooks/useApi';
import { Loading } from './atomic/effects';
import { safeUrl } from '../utils/safeUrl';
import { useErrors } from '../hooks/useErrors';
import { useAuth } from '../contexts/AuthContext';
import { NavigateToItemContext, NavigateToItemHandler } from './atomic/links';
import { FoldersAPI } from '../APIs/foldersApi';
import { FilesAPI } from '../APIs/filesApi';

export const RedirectToProject = () => {
    const { projectId } = useParams() as { projectId: string };
    const navigate = useNavigate();
    const project = useGet(projectApi.get.project(projectId), REFRESH_EVERY_5S);
    useEffect(() => {
        if (project.error) return;
        const folderId = project.data?.data?.folderId;
        if (!folderId) return;
        navigate(safeUrl`/app/f/${folderId}/p/${projectId!}`, {
            replace: true,
        });
    }, [project.data]);
    if (project.error) {
        return (
            <ErrorPanelEx to="/app/folderlist/" linkText="トップへ戻る">
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    return <Loading />;
};

export const RedirectToProjectOfFolder = () => {
    const { projectId, folderId } = useParams() as {
        projectId: string;
        folderId: string;
    };
    const navigate = useNavigate();
    const ids = useGet(FilesAPI.fileIds({ folderId }), REFRESH_EVERY_5S);
    const fileId = useMemo(() => ids.data?.data[0], [ids.data]);
    useEffect(() => {
        if (ids.error) return;
        if (!fileId) return;
        navigate(getProjectItemLink({ fileId, projectId, folderId }), {
            replace: true,
        });
    }, [fileId, ids.error]);
    if (ids.error) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId}`}
                linkText="トップへ戻る"
            >
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    if (ids.data && !fileId) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId!}`}
                linkText="フォルダへ戻る"
            >
                フォルダ内に画像ファイル・動画ファイルが存在しません
            </ErrorPanelEx>
        );
    }
    return <Loading />;
};

const REFRESH_EVERY_5S = {
    keepPreviousData: true,
    refreshInterval: 5000,
} as const;

export type GetProjectLinkProps = {
    fileId: string;
    projectId: string;
    folderId: string;
    videoTimeSeconds?: number;
    sourceId?: string;
};
export function getProjectItemLink({
    fileId,
    projectId,
    folderId,
    videoTimeSeconds,
    sourceId,
}: GetProjectLinkProps) {
    let url = safeUrl`/app/f/${folderId}/p/${projectId}/f/${fileId}`;
    if (sourceId) {
        url += safeUrl`/${sourceId}`;
    }
    if (videoTimeSeconds) {
        url += `?t=${videoTimeSeconds}ms`;
    }
    return url;
}

function nearestEntry<T>(items: T[], index: number) {
    if (index > 1) {
        index -= 1;
    }
    if (index < 0) {
        index = 0;
    } else if (index >= items.length) {
        index = items.length - 1;
    }
    return items[index];
}

export const Project = () => {
    const { projectId, folderId, fileId, sourceFileId } = useParams() as {
        projectId: string;
        folderId: string;
        fileId?: string;
        sourceFileId?: string;
    };
    const fileIds = useGet(FilesAPI.fileIds({ folderId }), REFRESH_EVERY_5S);
    const sourceFileIds = useGet(
        sourceFileId
            ? FilesAPI.fileIds({ folderId, sourceId: sourceFileId })
            : null,
        REFRESH_EVERY_5S
    );
    const [prevFileIndex, setPrevFileIndex] = useState(0);
    const [prevChildFileIndex, setPrevChildFileIndex] = useState(0);
    const [search] = useSearchParams();
    const { appendError } = useErrors();
    const videoTimeSeconds: number = useMemo(() => {
        if (!search) return 0;
        const t = search.get('t');
        if (!t) return 0;
        const parts = /^(\d+)ms$/.exec(t);
        if (!parts) return 0;
        return parseInt(parts[1], 10);
    }, [search]);
    const project = useGet(projectApi.get.project(projectId), REFRESH_EVERY_5S);
    const file = useGet(
        fileId ? FoldersAPI.get.file(fileId) : null,
        REFRESH_EVERY_5S
    );

    const navigate = useNavigate();
    const navigateToFile = useCallback<NavigateToItemHandler>(
        ({ fileId, videoTimeSeconds, replace, sourceId }) => {
            if (!fileId) {
                navigate(safeUrl`/app/f/${folderId}/p/${projectId!}`);
                return;
            }
            navigate(
                getProjectItemLink({
                    fileId,
                    folderId,
                    projectId,
                    videoTimeSeconds,
                    sourceId,
                }),
                { replace: !!replace, preventScrollReset: true }
            );
        },
        [navigate, folderId, projectId]
    );
    const index = useMemo(() => {
        if (fileIds.isLoading) return -3;
        const ids = fileIds.data?.data;
        if (!ids) return -2;
        if (!fileId) return 0;
        const effectiveId = sourceFileId ? sourceFileId : fileId;
        return ids.indexOf(effectiveId);
    }, [file.data, fileIds.data, fileIds.isLoading]);
    const subIndex = useMemo(() => {
        if (!sourceFileId) return -4;
        if (sourceFileIds.isLoading) return -3;
        const ids = sourceFileIds.data?.data;
        if (!ids) return -2;
        if (!fileId) return -1;
        return ids.indexOf(fileId);
    }, [sourceFileId, fileId, sourceFileIds.isLoading, sourceFileIds.data]);
    useEffect(() => {
        if (file.isLoading) return;
        if (fileIds.isLoading) return;
        if (sourceFileIds.isLoading) return;
        if (!fileIds.data) return;
        if (file.error || file.data?.folderId !== folderId) {
            appendError(
                `画像ファイルのロードに失敗しました。他のユーザによってファイルが削除された可能性があります`
            );
            navigate(
                getProjectItemLink({
                    projectId,
                    folderId,
                    fileId:
                        index > -1 && sourceFileIds.data?.data
                            ? nearestEntry(
                                  sourceFileIds.data.data,
                                  prevChildFileIndex
                              )
                            : nearestEntry(fileIds.data.data, prevFileIndex),
                    sourceId: subIndex > -1 ? sourceFileId : undefined,
                }),
                {
                    replace: true,
                }
            );
        } else if (!file.isLoading) {
            if (index > -1) {
                setPrevFileIndex(index);
            }
            if (subIndex >= -1) {
                setPrevChildFileIndex(index);
            }
        }
    }, [
        folderId,
        file.error,
        file.isLoading,
        index,
        subIndex,
        project.data?.data,
        !fileIds.isLoading,
        !sourceFileIds.isLoading,
    ]);
    useEffect(() => {
        if (!file.data) return;
        if (!fileId) return;
        if (!project.data?.data) return;
        const projectFolderId = project.data.data.folderId;
        if (projectFolderId === folderId && file.data.sourceId === sourceFileId)
            return;

        navigate(
            getProjectItemLink({
                projectId,
                folderId: projectFolderId,
                fileId,
                sourceId: file.data.sourceId,
                videoTimeSeconds,
            }),
            {
                replace: true,
            }
        );
    }, [project.data, folderId, sourceFileId, file.data, videoTimeSeconds]);
    useEffect(() => {
        if (!project.error) return;
        navigate(safeUrl`/app/project/${projectId}`);
    }, [project.error]);
    const { isExternalUser } = useAuth();
    if (!fileId) {
        return <Loading />;
    }
    if (!projectId || (!project.isLoading && !project.data?.data?.projectId)) {
        return (
            <ErrorPanelEx
                to={safeUrl`/app/folderlist/${folderId}`}
                linkText="トップへ戻る"
            >
                プロジェクトは見つかりません
            </ErrorPanelEx>
        );
    }
    return (
        <NavigateToItemContext value={navigateToFile}>
            <Reel
                projectId={projectId}
                folderId={folderId}
                fileId={fileId}
                sourceFileId={sourceFileId}
                videoTimeSeconds={videoTimeSeconds}
                readonly={isExternalUser()}
            />
        </NavigateToItemContext>
    );
};
