import { DialogBox } from '../atomic/dialog';
import { TextField } from '../atomic/forms';
import { FoldersAPI, BreadcrumbResource } from '../../APIs/foldersApi';
import { Loading } from '../atomic/effects';
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Icons } from '../atomic/icons';
import { IconButton } from '../atomic/buttons';
import { HttpClient } from '../../utils/HttpUilts';
import { useToast } from '../atomic/toast';
import { CopyFoldersModel, FolderViewCheckModel } from '../FolderView';
import { FolderViewTypes } from './consts';

export interface EditFolderDialogData {
    folderId: string;
    parentFolderId: string | null;
    name: string;
}
export interface EditFolderDialogProps extends EditFolderDialogData {
    onClose: (data: EditFolderDialogData | null | undefined) => void;
}

export interface FolderCopyControllerProp {
    checkList: FolderViewCheckModel[];
    folderId: string;
    breadcrumbs: BreadcrumbResource[] | null;
    onDone: () => void;
    hasCollaborator: boolean;
    viewType: FolderViewTypes;
    copyFolders: CopyFoldersModel | null;
    setCopyFolders: (state: CopyFoldersModel | null) => void;
}

export const FolderCopyController = (prop: FolderCopyControllerProp) => {
    const { isAdmin, isUser } = useAuth();
    const [loading, setLoading] = useState<boolean>(false);
    const [showPasteConfirm, setShowPasteConfirm] = useState<boolean>(false);
    const {
        folderId,
        checkList,
        breadcrumbs,
        onDone,
        hasCollaborator,
        viewType,
        copyFolders,
        setCopyFolders,
    } = prop;
    const toast = useToast();

    const folders: { id: string; name: string }[] = useMemo(
        () => checkList?.filter(({ isFile }) => !isFile) ?? [],
        [checkList]
    );

    const isPasetable: boolean = useMemo(() => {
        if (checkList.length) {
            return false;
        }
        if (!copyFolders?.folders?.length) {
            return false;
        }
        if (copyFolders?.parentFolderId == folderId) {
            return false;
        }
        if (
            breadcrumbs?.find(
                ({ folderId }) =>
                    !!copyFolders?.folders?.find(({ id }) => id == folderId)
            )
        ) {
            return false;
        }
        return true;
    }, [folders, breadcrumbs, copyFolders, folderId, checkList]);

    const isCopieable: boolean = useMemo(() => {
        return !!folders?.length;
    }, [folders]);

    const onCopyClick = useCallback(() => {
        setCopyFolders(
            folders?.length ? { parentFolderId: folderId, folders } : null
        );
        toast('フォルダをコピーしました');
    }, [folderId, folders, toast]);

    const onPasteClick = useCallback(() => {
        setShowPasteConfirm(true);
    }, []);

    const onPasteOk = useCallback(
        (dialog: DialogBox) => {
            if (!copyFolders?.folders?.length) {
                return false;
            }
            setLoading(true);
            HttpClient.get('/items', {
                ids: copyFolders.folders.map(({ id }) => id),
                limit: copyFolders.folders.length,
                folder: 1,
            })
                .then((resp) => {
                    console.log(resp);
                    if (!resp?.data) {
                        console.warn(resp);
                        dialog.addError(
                            resp,
                            'フォルダ情報の取得に失敗しました'
                        );
                        setLoading(false);
                        return false;
                    }
                    const folders = resp?.data
                        ?.filter(({ folder }) => !!folder)
                        ?.map(({ folder }) => folder);
                    if (folders?.length != copyFolders.folders.length) {
                        const remoedFolders = copyFolders.folders.filter(
                            ({ id }) =>
                                !folders.find(({ folderId }) => folderId == id)
                        );
                        let message = `「${remoedFolders[0].name}」`;
                        if (remoedFolders.length > 1) {
                            message += `（他 ${
                                remoedFolders.length - 1
                            } フォルダ）`;
                        }
                        dialog.addError(`${message} は既に削除されています`);
                        setLoading(false);
                        return false;
                    }
                    const movedFolders = folders.filter(
                        ({ parentFolderId }) =>
                            parentFolderId != copyFolders.parentFolderId
                    );
                    if (movedFolders.length) {
                        let message = `「${movedFolders[0].folderName}」`;
                        if (movedFolders.length > 1) {
                            message += `（他 ${
                                movedFolders.length - 1
                            } フォルダ）`;
                        }
                        dialog.addError(`${message} は既に移動しています`);
                        setLoading(false);
                        return false;
                    }
                    Promise.all(
                        folders.map((folder) =>
                            FoldersAPI.put(folder.folderId, {
                                parentFolderId: folderId,
                                folderName: folder.folderName,
                            })
                        )
                    )
                        .then((resp) => {
                            setShowPasteConfirm(false);
                            setCopyFolders(null);
                            onDone();
                        })
                        .catch((err) => {
                            console.warn(err);
                            dialog.addError(
                                err?.response?.status == 404
                                    ? '移動先のフォルダが削除されています'
                                    : err,
                                'フォルダの更新に失敗しました'
                            );
                            setLoading(false);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err, 'フォルダ情報の取得に失敗しました');
                    setLoading(false);
                });
        },
        [folderId, copyFolders]
    );

    const onPasteCancel = useCallback(() => {
        setShowPasteConfirm(false);
    }, []);

    // フォルダの移動はテナント管理者とユーザのみに許す
    if (!isAdmin() && !isUser()) {
        return <></>;
    }
    return (
        <>
            {viewType == FolderViewTypes.LIST && (
                <IconButton
                    icon={Icons.Copy}
                    onClick={onCopyClick}
                    disabled={!isCopieable}
                >
                    フォルダのコピー
                </IconButton>
            )}
            <IconButton
                icon={Icons.Paste}
                onClick={onPasteClick}
                disabled={!isPasetable}
            >
                フォルダの移動
            </IconButton>
            {showPasteConfirm && !!copyFolders?.folders?.length && (
                <DialogBox
                    onOK={onPasteOk}
                    onCancel={onPasteCancel}
                    className="paste-confirm-dialog"
                >
                    {hasCollaborator && (
                        <div className="collaborator-alert"></div>
                    )}
                    <p>
                        「{copyFolders.folders[0].name}」
                        {copyFolders.folders.length > 1 &&
                            `（他 ${copyFolders.folders.length - 1} フォルダ）`}
                        を移動しますか？
                    </p>
                </DialogBox>
            )}
            {loading && <Loading />}
        </>
    );
};

export const EditFolderDialog = ({
    folderId,
    parentFolderId,
    name,
    onClose,
}: EditFolderDialogProps) => {
    const [folderName, setFolderName] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const onOKHandle = React.useCallback(
        (dialog: DialogBox) => {
            setLoading(true);
            FoldersAPI.put(folderId, {
                parentFolderId: parentFolderId,
                folderName: folderName,
            })
                .then((resp) => {
                    console.log(resp);
                    if (resp.success) {
                        onClose({
                            parentFolderId,
                            folderId,
                            name: folderName,
                        });
                    } else {
                        dialog.addError(
                            resp.message || 'フォルダ情報の更新に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || 'フォルダ情報の更新に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [folderName]
    );
    const onCancelHandle = React.useCallback(() => {
        onClose(null);
    }, []);
    React.useEffect(() => {
        setFolderName(name);
    }, [name]);

    return (
        <>
            <DialogBox
                title="フォルダ情報変更"
                onOK={onOKHandle}
                onCancel={onCancelHandle}
                className="edit-folder-dialog"
            >
                <TextField
                    label="フォルダ名"
                    value={folderName}
                    _onChange={setFolderName}
                    required={true}
                />
            </DialogBox>
            {loading && <Loading />}
        </>
    );
};
