import React, {
    useCallback,
    useMemo,
    useRef,
    MouseEvent,
    forwardRef,
    Ref,
} from 'react';
import cloneDeep from 'lodash.clonedeep';
import { observer } from 'mobx-react-lite';
import { Box, CompiledTag } from '../../models/ProjectMetaModel';
import { ReelTagFocusRect } from './ReelTagFocusRect';
import classNames from 'classnames';

const ReelAnchor = forwardRef(
    (
        { x, y, className }: { x: number; y: number; className: string },
        ref: Ref<SVGRectElement>
    ) => (
        <rect
            ref={ref}
            x={Math.round(x - 3.5)}
            y={Math.round(y - 3.5)}
            className={classNames('reel--anchor', className)}
        />
    )
);

const resizeTop: AnchorAxis = (yDiff, rect, target) => {
    target.t = rect.tl.y + yDiff;
};
const resizeBottom: AnchorAxis = (yDiff, rect, target) => {
    target.b = rect.br.y + yDiff;
};
const resizeLeft: AnchorAxis = (xDiff, rect, target) => {
    target.l = rect.tl.x + xDiff;
};
const resizeRight: AnchorAxis = (xDiff, rect, target) => {
    target.r = rect.br.x + xDiff;
};
export enum Anchor {
    tl = 'tl',
    tc = 'tc',
    tr = 'tr',
    ml = 'ml',
    mr = 'mr',
    bl = 'bl',
    bc = 'bc',
    br = 'br',
}

const noop = () => {};
const tl = { name: Anchor.tl, vertical: resizeTop, horizontal: resizeLeft };
const tc = { name: Anchor.tc, vertical: resizeTop, horizontal: noop };
const tr = { name: Anchor.tr, vertical: resizeTop, horizontal: resizeRight };
const ml = { name: Anchor.ml, vertical: noop, horizontal: resizeLeft };
const mr = { name: Anchor.mr, vertical: noop, horizontal: resizeRight };
const bl = { name: Anchor.bl, vertical: resizeBottom, horizontal: resizeLeft };
const bc = { name: Anchor.bc, vertical: resizeBottom, horizontal: noop };
const br = { name: Anchor.br, vertical: resizeBottom, horizontal: resizeRight };

export interface AnchorUpdate {
    t: number | undefined;
    b: number | undefined;
    l: number | undefined;
    r: number | undefined;
}

type AnchorAxis = (axis: number, box: Box, target: AnchorUpdate) => void;

export interface ReelAnchorDir {
    name: Anchor;
    horizontal: AnchorAxis;
    vertical: AnchorAxis;
}
export type ReelAnchorDragHandler = (
    e: MouseEvent,
    name: Anchor,
    handler: (xDiff: number, yDiff: number) => AnchorUpdate
) => void;

export interface ReelAnchorsProps {
    tag?: CompiledTag;
    onDrag: ReelAnchorDragHandler;
}
export const ReelAnchors = ({ tag, onDrag }: ReelAnchorsProps) => {
    return tag ? <InternalReelAnchors tag={tag} onDrag={onDrag} /> : <></>;
};

const InternalReelAnchors = observer(
    ({ tag, onDrag }: Required<ReelAnchorsProps>) => {
        const { box } = tag;
        const tlRef = useRef<SVGRectElement | null>(null);
        const tcRef = useRef<SVGRectElement | null>(null);
        const trRef = useRef<SVGRectElement | null>(null);
        const mlRef = useRef<SVGRectElement | null>(null);
        const mrRef = useRef<SVGRectElement | null>(null);
        const blRef = useRef<SVGRectElement | null>(null);
        const bcRef = useRef<SVGRectElement | null>(null);
        const brRef = useRef<SVGRectElement | null>(null);

        const hInv = box.br.x < box.tl.x;
        const vInv = box.br.y < box.tl.y;
        const normBox = useMemo(() => {
            const normalized = {
                x: box.tl.x,
                y: box.tl.y,
                width: box.br.x - box.tl.x,
                height: box.br.y - box.tl.y,
            };
            if (vInv) {
                normalized.y += normalized.height;
                normalized.height *= -1.0;
            }
            if (hInv) {
                normalized.x += normalized.width;
                normalized.width *= -1.0;
            }
            return normalized;
        }, [hInv, vInv, box.tl.x, box.tl.y, box.br.x, box.br.y]);
        const getDir = useCallback(
            (e: MouseEvent): ReelAnchorDir => {
                if (e.target === tlRef.current)
                    return {
                        ...tl,
                        name: hInv
                            ? vInv
                                ? Anchor.br
                                : Anchor.tr
                            : vInv
                            ? Anchor.bl
                            : Anchor.tl,
                    };
                if (e.target === tcRef.current)
                    return { ...tc, name: vInv ? Anchor.bc : Anchor.tc };
                if (e.target === trRef.current)
                    return {
                        ...tr,
                        name: hInv
                            ? vInv
                                ? Anchor.bl
                                : Anchor.tl
                            : vInv
                            ? Anchor.br
                            : Anchor.tr,
                    };
                if (e.target === mlRef.current)
                    return { ...ml, name: hInv ? Anchor.mr : Anchor.ml };
                if (e.target === mrRef.current)
                    return { ...mr, name: hInv ? Anchor.ml : Anchor.mr };
                if (e.target === blRef.current)
                    return {
                        ...bl,
                        name: hInv
                            ? vInv
                                ? Anchor.tr
                                : Anchor.br
                            : vInv
                            ? Anchor.tl
                            : Anchor.bl,
                    };
                if (e.target === bcRef.current)
                    return { ...bc, name: vInv ? Anchor.tc : Anchor.bc };
                if (e.target === brRef.current)
                    return {
                        ...br,
                        name: hInv
                            ? vInv
                                ? Anchor.tl
                                : Anchor.bl
                            : vInv
                            ? Anchor.tr
                            : Anchor.br,
                    };
                throw new Error('Invalid direction');
            },
            [hInv, vInv]
        );
        const onStartDrag = useCallback(
            (e: MouseEvent) => {
                e.preventDefault();
                const dir = getDir(e);
                const startBox = cloneDeep(box);
                e.stopPropagation();

                onDrag(e, dir.name, (xDiff: number, yDiff: number) => {
                    const box = cloneDeep(startBox);
                    const target = {
                        t: undefined,
                        l: undefined,
                        b: undefined,
                        r: undefined,
                    };
                    dir.horizontal(xDiff, box, target);
                    dir.vertical(yDiff, box, target);
                    return target;
                });
            },
            [box]
        );
        return (
            <g onMouseDown={onStartDrag}>
                <ReelTagFocusRect tag={tag} />
                <ReelAnchor
                    ref={hInv ? (vInv ? brRef : trRef) : vInv ? blRef : tlRef}
                    className="reel--anchor--tl"
                    x={normBox.x}
                    y={normBox.y}
                />
                <ReelAnchor
                    ref={vInv ? bcRef : tcRef}
                    className="reel--anchor--tc"
                    x={normBox.x + normBox.width / 2}
                    y={normBox.y}
                />
                <ReelAnchor
                    ref={hInv ? (vInv ? blRef : tlRef) : vInv ? brRef : trRef}
                    className="reel--anchor--tr"
                    x={normBox.x + normBox.width}
                    y={normBox.y}
                />
                <ReelAnchor
                    ref={hInv ? mrRef : mlRef}
                    className="reel--anchor--ml"
                    x={normBox.x}
                    y={normBox.y + normBox.height / 2}
                />
                <ReelAnchor
                    ref={hInv ? mlRef : mrRef}
                    className="reel--anchor--mr"
                    x={normBox.x + normBox.width}
                    y={normBox.y + normBox.height / 2}
                />
                <ReelAnchor
                    ref={hInv ? (vInv ? trRef : brRef) : vInv ? tlRef : blRef}
                    className="reel--anchor--bl"
                    x={normBox.x}
                    y={normBox.y + normBox.height}
                />
                <ReelAnchor
                    ref={vInv ? tcRef : bcRef}
                    className="reel--anchor--bc"
                    x={normBox.x + normBox.width / 2}
                    y={normBox.y + normBox.height}
                />
                <ReelAnchor
                    ref={hInv ? (vInv ? tlRef : blRef) : vInv ? trRef : brRef}
                    className="reel--anchor--br"
                    x={normBox.x + normBox.width}
                    y={normBox.y + normBox.height}
                />
            </g>
        );
    }
);
