export class AbortError extends Error {
    type = 'aborted';
    name = 'AbortError';
    constructor(cause?: Error | undefined) {
        // @ts-expect-error 2554
        super('Aborted', cause);
    }
}

export function checkAbort(
    opts?: AbortSignal | { signal?: AbortSignal }
): void {
    if (!opts) return;
    const signal = opts instanceof AbortSignal ? opts : opts.signal;
    if (!signal) return;
    if (signal.aborted) throw new AbortError();
}

export function isAbortError(
    error: { name?: string } | string | undefined | null
) {
    if (typeof error !== 'object' || error === null) return false;
    return error.name === 'AbortError' || error.name === 'CanceledError';
}

export function rethrowAbort(error: Error | undefined) {
    if (isAbortError(error)) {
        throw error;
    }
}
