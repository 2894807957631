import { HttpClient, BaseApiResponse } from '../utils/HttpUilts';
import {
    TenantTagModel,
    TagTypeModel,
    TagDetailModel,
    TagModel,
} from '../models/TagModel';

export const getTenantTags = (): Promise<
    BaseApiResponse<Array<TenantTagModel>>
> => {
    return HttpClient.get('/tenanttags');
};

export const getTenantTag = (
    tenantTagId: string
): Promise<BaseApiResponse<TenantTagModel>> => {
    return HttpClient.get('/tenanttag/' + encodeURIComponent(tenantTagId));
};

export const getTagDetails = (): Promise<
    BaseApiResponse<Array<TagDetailModel>>
> => {
    return HttpClient.get('/tagdetails');
};

export const getTagTypes = (): Promise<
    BaseApiResponse<Array<TagTypeModel>>
> => {
    return HttpClient.get('/tagtypes');
};

export const getTagTypesByType = (
    type: number
): Promise<BaseApiResponse<Array<TagTypeModel>>> => {
    return HttpClient.get('/tagtypes', { type: type });
};

export const storeTagType = (
    tagTypeId: string,
    data: TagTypeModel
): Promise<BaseApiResponse<TagTypeModel>> => {
    return HttpClient.put('/tagtype/' + encodeURIComponent(tagTypeId), data);
};

export type StoreTagTypeModel = Omit<TagTypeModel, 'tenantTagId' | 'type'>;
export const storeTagTypes = (
    data: StoreTagTypeModel[]
): Promise<BaseApiResponse<Array<TagTypeModel>>> => {
    return HttpClient.put('/tagtypes/', { tagTypes: data });
};

export const storeTenantTag = (
    tenantTagId: string,
    data: TenantTagModel
): Promise<BaseApiResponse<TenantTagModel>> => {
    return HttpClient.put(
        '/tenanttag/' + encodeURIComponent(tenantTagId),
        data
    );
};

export const deleteTenantTag = (tenantTagId: string) => {
    return HttpClient.del('/tenanttag/' + encodeURIComponent(tenantTagId));
};

export const deleteTagType = (tagTypeId: string) => {
    return HttpClient.del('/tagtype/' + encodeURIComponent(tagTypeId));
};

export const addTenantTag = (
    data: Omit<TenantTagModel, 'tenantTagId' | 'tagTypes'>
): Promise<BaseApiResponse<TenantTagModel>> => {
    return HttpClient.post('/tenanttag', data);
};

export type AddTagTypeModel = Omit<TagTypeModel, 'tagTypeId'>;
export const addTagType = (
    data: AddTagTypeModel
): Promise<BaseApiResponse<TagTypeModel>> => {
    return HttpClient.post('/tagtype', data);
};

export const getTagDetail = (
    tagDetailId: string
): Promise<BaseApiResponse<TagDetailModel>> => {
    return HttpClient.get('/tagdetail/' + encodeURIComponent(tagDetailId));
};

export const storeTagDetail = (
    tagDetailId: string,
    data: { tagDetailId: string; value: string }
): Promise<BaseApiResponse<TagDetailModel>> => {
    return HttpClient.put(
        '/tagdetail/' + encodeURIComponent(tagDetailId),
        data
    );
};

export const getTag = (tagId: string): Promise<BaseApiResponse<TagModel>> => {
    return HttpClient.get('/tag/' + encodeURIComponent(tagId));
};

export const addTag = (data: {
    folderId: string;
    tenantTagId: string;
}): Promise<BaseApiResponse<TagModel>> => {
    return HttpClient.post('/tag', data);
};

export const addTagDetail = (
    data: Omit<TagDetailModel, 'tagDetailId'>
): Promise<BaseApiResponse<TagDetailModel>> => {
    return HttpClient.post('/tagdetail', data);
};

export const deleteTag = (tagId: string) => {
    return HttpClient.del('/tag/' + encodeURIComponent(tagId));
};

export const tagDescendants = (folderId: string, tenantTagId: string) => {
    return HttpClient.get('/tags/descendants', {
        folderId: folderId,
        tenantTagId: tenantTagId,
    });
};
