import React from 'react';
import classNames from 'classnames';
import { TagIcon } from '../atomic/icons';

export const Tag = (props) => {
    const {
        tag,
        className,
        elementType = 'div',
        clickable,
        children,
        ...rest
    } = props;
    const { highlight } = tag;
    const _props = {
        className: classNames('atomic tag', className, {
            highlight,
            clickable,
        }),
        ...rest,
    };
    const _children = children || (
        <span className="tag-name">
            <TagIcon />
            {tag.tagName || tag.name}
        </span>
    );
    return React.createElement(elementType, _props, _children);
};

export const TagList = (props) => {
    const { className, tags, highlight = [], onClick } = props;
    const clickable = onClick && typeof onClick === 'function';
    return (
        <ul className={classNames('atomic tag-list', className)}>
            {tags &&
                tags
                    .map((tag) => ({
                        ...tag,
                        highlight: highlight.includes(tag.tenantTagId),
                    }))
                    .map((tag) => (
                        <Tag
                            tag={tag}
                            elementType="li"
                            key={tag.tenantTagId}
                            clickable={clickable}
                            onClick={() => {
                                onClick && onClick(tag);
                            }}
                        />
                    ))}
        </ul>
    );
};
