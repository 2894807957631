import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import * as DateUtils from '../../utils/DateUtils';
import { MapIcon, FolderIcon, TagIcon } from '../atomic/icons';
import { TagList } from '../atomic/tags';

import { useTenant } from '../../contexts/TenantContext';
import { UserName } from '../atomic/text';

export const Cell = (props) => {
    const { className, children } = props;
    return <td className={classNames('cell', className)}>{children}</td>;
};

export const EmptyCell = (props) => <Cell {...props} />;

export const DateCell = (props) => {
    const { dateStr } = props;
    return <Cell>{DateUtils.reformat(dateStr)}</Cell>;
};

export const UserNameCell = (props) => {
    const { getTenantUser } = useTenant();
    const { id } = props;
    const user = getTenantUser(id);
    return (
        <Cell>
            <UserName user={user} />
        </Cell>
    );
};

export const FolderNameCell = (props) => {
    const { folder } = props;
    const { id, name } = folder;
    return (
        <Cell>
            <Link to={`/app/folderlist/${id}`}>
                <div className="folder name link-area">
                    <FolderIcon />
                    <span>{name}</span>
                </div>
            </Link>
        </Cell>
    );
};

export const TagListCell = (props) => {
    const { folder } = props;
    const { tags } = folder;
    return (
        <Cell>
            <TagList tags={tags} />
        </Cell>
    );
};

const defaultFormatter = (v) => v;
const GeoCoodinateFormetter = (v) => {
    const parts = /(\d+(?:\.\d*)?)\s*,\s*(\d+(?:\.\d*)?)/.exec(v);
    const [lat, lng] = parts ? [parts[1], parts[2]] : ['', ''];
    return (
        <>
            <div className="val lattitude">{lat}</div>
            <div className="val longitude">{lng}</div>
        </>
    );
};
const tagValueFormatters = {
    1: ['v-num', defaultFormatter],
    2: ['v-text', defaultFormatter],
    3: ['v-list', defaultFormatter],
    4: ['v-date', defaultFormatter],
    5: ['v-geo', GeoCoodinateFormetter],
};

const TagValue = ({ v }) => {
    const { tagType, tagTypeName, value, tagTypeId, tagDetailId } = v;
    const [vType, formatter] = tagValueFormatters[tagType];
    console.log({ v });
    return (
        <li className={classNames('tag-value', vType)}>
            <div className="key">
                {tagType == 5 && (
                    <Link to={`/app/folder/map/${tagTypeId}/${tagDetailId}`}>
                        <MapIcon />
                    </Link>
                )}
                {tagTypeName}
            </div>
            <div className="value">{formatter(value)}</div>
        </li>
    );
};

export const TagDetails = (props) => {
    const { className, folder, tagSelections } = props;
    const tags = (folder.tags || []).filter((tag) =>
        tagSelections.includes(tag.tenantTagId)
    );
    return (
        <ul className={classNames('tag-details', className)}>
            {tags.map((tag, i) => {
                const { tagName, values } = tag;
                return (
                    <li className="tag" key={i}>
                        <div className="tag-name">
                            <TagIcon />
                            {tagName}
                        </div>
                        <ul className="tag-values">
                            {values.map((v, j) => (
                                <TagValue v={v} key={j} />
                            ))}
                        </ul>
                    </li>
                );
            })}
            {(!tags || tags.length == 0) && (
                <div className="empty">
                    <p>該当属性なし</p>
                </div>
            )}
        </ul>
    );
};
