/**
 * このファイルはサーバーにもクライアントにも必要です。
 * This file needs to exist both on the server and client.
 * 同期にしてください。
 * Please keep in sync.
 */
import { calculatePointArea } from './calculatePointArea.mjs';
import { fixPHPArrayFilter } from './fixPHPArrayFilter.mjs';

/**
 * @param {import("./applyFileHistoryEntries").FileMetaHistoryEntry} entry
 * @returns {boolean} true if entry is ProjectTagHistoryEntry
 */
function isTagHistoryEntry(entry) {
    return tagHistoryTypes.has(entry.type);
}

export const TagHistoryType = /** @type {const} */ ({
    editTagType: 'edit-tag-type',
    addPoint: 'add-point',
    movePoint: 'move-point',
    deletePoint: 'delete-point',
    changeTransform: 'change-transform',
});

export const tagHistoryTypes = new Set(Object.values(TagHistoryType));

export const HistoryType = /** @type {const} */ ({
    deleteTag: 'delete-tag',
    undo: 'undo',
    addTag: 'add-tag',
    editLabel: 'edit-label',
    editSelection: 'edit-selection',
    editReport: 'edit-report',
    requestAI: 'request-ai',
    ...TagHistoryType,
});

/**
 * @param {import("./ServerClientModel").Tag} tag
 * @return {number}
 */
function recalculateSum(tag) {
    const shapes =
        tag.dataType === 'shapes'
            ? tag.shapes.map(({ points }) => points)
            : [tag.points];
    return calculatePointArea(shapes, tag.transform);
}

/**
 * @template {import("./applyFileHistoryEntries").ProjectTagHistoryEntry} T
 * @param {import("./ServerClientModel").Tag} tag
 * @param {T} entry
 * @returns {undefined}
 */
function applyTagHistoryEntry(tag, entry) {
    if (entry.type === TagHistoryType.editTagType) {
        tag.type = entry.tagType;
        return;
    } else if (entry.type === HistoryType.changeTransform) {
        const { moveX, moveY, scaleX, scaleY } = entry.transform;
        if (moveX === 0 && moveY === 0 && scaleX === 1 && scaleY === 1) {
            delete tag.transform;
        } else {
            tag.transform = entry.transform;
        }
        tag.sum = recalculateSum(tag);
        return;
    }
    /** @var CompressedPointsWithId[] - shape */
    let shape;
    let shapeIndex;
    if (tag.dataType === 'shapes') {
        shapeIndex = tag.shapes.findIndex(({ id }) => entry.shapeId === id);
        if (shapeIndex === -1) {
            return;
        }
        shape = tag.shapes[shapeIndex].points;
    } else {
        if (entry.shapeId) {
            throw new Error('Tried to use shapeId on an non-shapes tag.');
        }
        shape = tag.points;
    }
    if (entry.type === HistoryType.deletePoint) {
        const index = shape.findIndex((point) => point[0] === entry.pointId);
        if (index === -1) return;
        shape.splice(index, 1);
        if (tag.dataType === 'shapes' && shape.length === 0) {
            tag.shapes.splice();
        }
        tag.sum = recalculateSum(tag);
    } else if (entry.type === HistoryType.movePoint) {
        const point = shape.find((point) => point[0] === entry.pointId);
        if (!point) {
            throw new Error(
                'Tried to change a point but the point was not found on the input'
            );
        }
        point[1] = entry.x;
        point[2] = entry.y;
        tag.sum = recalculateSum(tag);
    } else if (entry.type === HistoryType.addPoint) {
        let index = shape.findIndex((point) => point[0] === entry.afterPointId);
        if (index === -1) {
            index = shape.findIndex(
                (point) => point[0] === entry.beforePointId
            );
        } else {
            index += 1;
        }
        shape.splice(index, 0, entry.point);
        tag.sum = recalculateSum(tag);
    } else {
        throw new Error(`Unsupported history tag type ${entry}`);
    }
}

/**
 * @param {import("./applyFileHistoryEntries").FileMeta} fileMeta
 * @param {import("./applyFileHistoryEntries").FileMetaHistoryEntry} entry
 * @returns {undefined}
 */
function applyFileHistoryEntry(fileMeta, entry) {
    if (entry.type === HistoryType.editLabel) {
        if (Array.isArray(fileMeta.labels)) {
            // Backwards compatibility fix
            fileMeta.labels = {};
        }
        if (entry.labelId) {
            fileMeta.labels[entry.rootLabelId] = entry.labelId;
        } else {
            delete fileMeta.labels[entry.rootLabelId];
        }
    } else if (entry.type === HistoryType.addTag) {
        /** @type {Tag[]} */
        const tags = [];
        for (const tag of entry.tags) {
            const shapes = [];
            if (tag.dataType === 'shapes') {
                for (const { points } of tag.shapes) {
                    if (points.length > 1) {
                        shapes.push(points);
                    }
                }
            } else {
                if (tag.points.length > 1) {
                    shapes.push(tag.points);
                }
            }
            if (shapes.length === 0) {
                continue;
            }
            tags.push({
                ...tag,
                sum: calculatePointArea(shapes, tag.transform),
            });
        }
        if (entry.clear) {
            fileMeta.tags = tags;
        } else {
            fileMeta.tags = fixPHPArrayFilter(fileMeta.tags);
            fileMeta.tags.push(...tags);
        }
    } else if (entry.type === HistoryType.deleteTag) {
        fileMeta.tags = fixPHPArrayFilter(fileMeta.tags).filter(
            (tag) => tag.id !== entry.tagId
        );
    } else if (entry.type === HistoryType.editReport) {
        fileMeta.report = entry.report;
    } else if (entry.type === HistoryType.editSelection) {
        fileMeta.selection = entry.rect;
    } else if (entry.type == HistoryType.undo) {
    } else if (isTagHistoryEntry(entry)) {
        const tag = fixPHPArrayFilter(fileMeta.tags).find(
            (tag) => tag.id === entry.tagId
        );
        if (!tag) {
            throw new Error(
                'Tried to change tag type but the tag was not found on the input'
            );
        }
        applyTagHistoryEntry(tag, entry);
    } else {
        throw new Error(`Unsupported history type ${entry.type}`);
    }
}

/**
 * @returns {import('./ServerClientModel').FileMeta}
 */
export function createFileHistoryRoot() {
    return {
        report: '',
        selection: null,
        tags: [],
        labels: {},
    };
}

/**
 * @param {import("./applyFileHistoryEntries").FileMeta} fileMeta
 * @param {import("./applyFileHistoryEntries").FileMetaHistoryEntry[]} entries
 * @returns {import("./applyFileHistoryEntries").FileMetaHistoryProcessError[]}
 */
export function applyFileHistoryEntries(fileMeta, entries) {
    const result = [];
    for (const [index, entry] of entries.entries()) {
        try {
            applyFileHistoryEntry(fileMeta, entry);
        } catch (error) {
            result.push({
                index,
                entry,
                error,
                message: `Entry #${index}: Error while processing entry: \n${JSON.stringify(
                    entry
                )}\n${error}`,
            });
        }
    }
    return result;
}
