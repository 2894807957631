import React, { useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ErrorDisplay, NotFound } from './Errors';
import { projectApi } from '../APIs/projectApi';
import { FoldersAPI } from '../APIs/foldersApi';
import { useGet } from '../hooks/useApi';
import { SpinnerIcon, FolderIcon, ExportIcon } from './atomic/icons';
import { Table } from './atomic/table';
import { FolderModel } from '../models/FolderModel';
import { Loading, Spinner } from './atomic/effects';
import classNames from 'classnames';
import { useClickable } from '../hooks/useClickable';
import { useReportDownloader } from './ReportDownloader';
import { CSVHeader, useCSV } from '../hooks/useCSV';
import { getProjectItemLink } from './Project';

interface SortableDivProps {
    header: CSVHeader;
}
const SortableDiv = ({ header }: SortableDivProps) => {
    const { sortOrder: sorted, text: children } = header;

    const props = useClickable(() => header.toggleSort(), [header.toggleSort]);
    return (
        <div className={classNames('sortable', { sorted }, sorted)} {...props}>
            {children}
        </div>
    );
};

const _Report = ({
    projectId,
    folder,
    projectName,
    folderId,
}: {
    projectId: string;
    folder: FolderModel;
    projectName: string;
    folderId: string;
}) => {
    const report = useCSV(
        projectApi.get.report({
            projectId,
            skipEmpty: false,
            includeUrl: false,
        }),
        {
            valueOf: (header, index, value) => {
                if (
                    index === 2 ||
                    index === 3 ||
                    header.endsWith('の数') ||
                    header.endsWith('の面積')
                ) {
                    if (value === '' || value === '-') return 0;
                    return parseFloat(value);
                }
                return value;
            },
        }
    );
    const reportWithoutEmpty = useCSV(
        projectApi.get.report({ projectId, skipEmpty: true, includeUrl: true })
    );
    const columns = useMemo(
        () =>
            report.header.map((header, index) => ({
                id: `column-${index}`,
                header: () => <SortableDiv header={header} />,
                cell:
                    index === 0
                        ? (d) => {
                              const { row } = d.row.original;
                              const fileId = row[index];
                              return (
                                  <Link
                                      to={getProjectItemLink({
                                          folderId,
                                          projectId,
                                          fileId,
                                      })}
                                  >
                                      {fileId}
                                  </Link>
                              );
                          }
                        : index === 4
                        ? (d) => {
                              const { row } = d.row.original;
                              const report = row[index];
                              return (report ?? '')
                                  .split(/\r?\n/g)
                                  .map((text, index) => (
                                      <div key={index}>{text}</div>
                                  ));
                          }
                        : (d) => {
                              const val = d.row.original.row[index];
                              return <div>{val === '-' ? '0' : val}</div>;
                          },
            })),
        [report.header]
    );
    const data = useMemo(
        () => report.rows.map((row) => ({ trClass: 'file', row })),
        [report.rows, report.sortedBy]
    );
    const activeFiles = useMemo(
        () =>
            reportWithoutEmpty.rows.map((row) => ({
                fileId: row[0],
                fileName: row[1],
                fileUrl: row[2],
            })),
        [reportWithoutEmpty.rows]
    );

    const downloader = useReportDownloader();

    if (report.error) {
        return <ErrorDisplay error={report.error} />;
    }

    return (
        <div className="app-main app-report">
            <h2>{projectName} の統計情報</h2>
            {folderId != '' && (
                <div>
                    <Link to={`/app/folderlist/${folderId}`}>
                        {report.isLoading ? <SpinnerIcon /> : <FolderIcon />}{' '}
                        フォルダページへ戻る
                    </Link>
                    <Link
                        to="#"
                        onClick={() =>
                            !reportWithoutEmpty.isLoading &&
                            report.blob &&
                            downloader.show({
                                folder,
                                csvBlob: report.blob,
                                projectName,
                                activeFiles,
                            })
                        }
                        style={{ marginLeft: '2em' }}
                    >
                        {reportWithoutEmpty.isLoading ? (
                            <SpinnerIcon />
                        ) : (
                            <ExportIcon />
                        )}{' '}
                        ダウンロード
                    </Link>
                </div>
            )}
            <div className="table-wrapper">
                <Table columns={columns} data={data} className="report-table" />
            </div>
            {downloader.dialog}
        </div>
    );
};

export const Report = () => {
    const { projectId } = useParams();
    const project = useGet(
        projectId ? projectApi.get.project(projectId) : null
    );
    const projectName: string = project?.data?.data?.name ?? '';
    const folderId: string = project?.data?.data?.folderId ?? '';
    const folder = useGet(folderId ? FoldersAPI.get.folder(folderId) : null);

    if (!projectId) {
        return <NotFound />;
    }
    if (folder?.isLoading || project?.isLoading) {
        return <Loading />;
    }
    const error = project?.error;
    if (error) {
        return <ErrorDisplay error={error} />;
    }
    if (!folder?.data?.data?.folder) {
        return (
            <ErrorDisplay
                error={new Error('フォルダ情報の取得に失敗しました')}
            />
        );
    }
    return (
        <_Report
            projectId={projectId}
            folder={folder.data.data.folder}
            projectName={projectName}
            folderId={folderId}
        />
    );
};
