import React, { FC, useState } from 'react';
import { SelectField, SelectFieldOption, TextField } from '../atomic/forms';
import { Button } from '../atomic/buttons';
import { DialogBox } from '../atomic/dialog';
import { UserRole, inviteUser } from '../../APIs/userApi';
import { Loading } from '../atomic/effects';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';

type Prop = {};

export const UserInviteModal: FC<Prop> = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<UserRole>('User');
    const [loading, setLoading] = useState<boolean>(false);
    const ref = React.useRef<DialogBox>();
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());

    const openModal = () => {
        setEmail('');
        setRole('User');
        setIsOpen(true);
    };

    const roles = [
        {
            text: '管理者',
            value: 'Admin',
        },
        {
            text: 'ユーザ',
            value: 'User',
        },
        {
            text: 'ゲスト',
            value: 'External',
        },
    ];

    const onOK = React.useCallback(() => {
        if (!userInfo?.tenantId) {
            ref?.current?.addError('テナントIDの取得に失敗しました');
            return false;
        }
        setLoading(true);
        inviteUser({
            email: email,
            role: role,
            tenantId: userInfo?.tenantId,
        })
            .then((resp) => {
                if (resp.success) {
                    setIsOpen(false);
                    setEmail('');
                    setRole('User');
                } else {
                    ref?.current?.addError(resp, 'ユーザの招待に失敗しました');
                }
            })
            .catch((err) => {
                console.warn(err);
                ref?.current?.addError(err, 'ユーザの招待に失敗しました');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [email, role, userInfo?.tenantId]);
    const onCancel = React.useCallback(() => {
        setIsOpen(false);
    }, []);

    const onChangeRole = React.useCallback(
        (val) => {
            setRole(val);
        },
        [role]
    );

    return (
        <>
            <div>
                <Button className="primary" onClick={openModal}>
                    メンバー招待
                </Button>
                {isOpen && (
                    <DialogBox
                        title="メンバ招待"
                        onOK={onOK}
                        onCancel={onCancel}
                        ref={ref}
                    >
                        <ul className="fields">
                            <li>
                                <TextField
                                    label="メールアドレス"
                                    _onChange={setEmail}
                                    value={email}
                                    required={true}
                                />
                            </li>
                            <li>
                                <SelectField
                                    label="Role"
                                    _onChange={onChangeRole}
                                    value={role}
                                    required={true}
                                    options={roles}
                                />
                            </li>
                        </ul>
                    </DialogBox>
                )}
            </div>
            {loading && <Loading />}
        </>
    );
};
