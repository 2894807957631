// import { format } from 'date-fns';
import { createMutation } from '../../hooks/useApi';
import { TenantTagModel } from '../../models/TagModel';
import { downloadJSON } from '../../utils/download';

const tenantTagJson = ({ name, tagTypes }: TenantTagModel) => ({
    name,
    tags: tagTypes
        .sort(({ sortOrder }) => sortOrder)
        .map(({ type, list, name }) => ({
            name,
            list: list ?? undefined,
            type,
        })),
});

export const exportTenantTag = createMutation<TenantTagModel, undefined>(
    async (apis, tenantTag, config) => {
        // const time = format(Date.now(), 'yyyyMMdd-HHdd');
        // const name = `${time}_tags_${tenantTag.name}.json`
        const name = 'tags.json';
        downloadJSON(name, tenantTagJson(tenantTag));
        return { status: 200, data: undefined };
    }
);
