import React from 'react';
import { Link } from 'react-router-dom';

export const SuperAdminIndex = () => {
    return (
        <div className="app-main admin-index">
            <h2>管理者ページ</h2>
            <ul>
                <li>
                    <Link to="./tenants">テナント管理</Link>
                </li>
            </ul>
        </div>
    );
};
