import React, { useCallback, ChangeEvent } from 'react';
import { observer } from 'mobx-react-lite';
import { ReelSession } from './model/ReelSession';
import { TextAreaField } from '../atomic/forms';

const ReelReportPlain = observer(({ value }: { value: string }) => {
    return (
        <div className="reel--report-plain">
            {(value || 'なし')
                .split(/\r?\n/g)
                .map((text: string, index: number) => (
                    <div key={index}>{text}</div>
                ))}
        </div>
    );
});
export const ReelReportEditor = observer(
    ({ session }: { session: ReelSession }) => {
        const onChange = useCallback(
            (e: ChangeEvent<HTMLTextAreaElement>) => {
                session.report = e.target.value;
            },
            [session]
        );
        return (
            <>
                <h2>記事</h2>

                {session.readonly ? (
                    <ReelReportPlain value={session.report} />
                ) : (
                    <TextAreaField value={session.report} onChange={onChange} />
                )}
            </>
        );
    }
);
