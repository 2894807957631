import React from 'react';
import classNames from 'classnames';

import { APP_VERSION, BUILD_TIME } from '../../config';
import { format } from '../../utils/DateUtils';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';

export const EnvRibbon = () => {
    const { hostname } = location;
    // const { hostname } = { hostname: 'dev-portal.v4dev.jiw.co.jp' };
    const envType = (() => {
        if (['localhost', '127.0.0.1'].includes(hostname)) return 'local';
        if (hostname.startsWith('192.168.')) return 'local';
        if (hostname.endsWith('.v4dev.jiw.co.jp')) return 'aws-dev';
    })();
    const envInfo =
        envType === 'aws-dev' ? hostname.replace('.jiw.co.jp', '') : hostname;
    return <div className={classNames('dev-ribbon', envType)}>{envInfo}</div>;
};

export const DevInfo = () => {
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());
    return (
        <div className="dev-area">
            <ul className="vertical">
                <li className="version">
                    <label>fe-ver</label>
                    {APP_VERSION}
                </li>
                <li className="buildtime">
                    <label>build:</label>
                    {format(BUILD_TIME, 'yyyy/MM/dd HH:dd:ss')}
                </li>
            </ul>
            <ul className="vertical">
                <li className="tenantId">
                    <label>tenantId:</label>
                    {userInfo?.tenantId}
                </li>
                <li className="userId">
                    <label>userId:</label>
                    {userInfo?.userId}
                </li>
            </ul>
        </div>
    );
};
