export type MosaicStatus = 'SUCCESS' | 'RUNNING' | 'PENDING' | 'ERROR';

export interface FileItemFile {
    fileId: string;
    folderId: string;
    fileName: string;
    status: string;
    mimeType: string;
    metadata?: any;
    url: string;
    thumbUrl: string;
    mosaic: null | MosaicStatus;
}

export interface FolderItemTenant {
    tenantId: string;
    name: string;
    internalTenantId: number;
    availableStorageSize: number;
    maxUserLicenses: number;
    createdAt: string;
    updatedAt: string;
}

export interface FolderItemTenantTag {
    tenantTagId: string;
    tenantId: string;
    name: string;
    createdAt: string;
    updatedAt: string;
}
export interface FolderItemTagType {
    tagTypeId: string;
    tenantTagId: string;
    name: string;
    type: number;
    sortOrder: number;
    // TODO: list: null;
    createdAt: string;
    updatedAt: string;
}
export interface FolderItemTagDetail {
    tagDetailId: string;
    tagId: string;
    tagTypeId: string;
    value: string;
    createdAt: string;
    updatedAt: string;
    tagType: FolderItemTagType;
}
export interface FolderItemTag {
    values: FolderItemTagDetail[];
    tagId: string;
    folderId: string;
    tenantTagId: string;
    createdAt: string;
    updatedAt: string;
    tenantTag: FolderItemTenantTag;
}

export interface FolderItemFolder {
    folderId: string;
    folderName: string;
    parentFolderId: string | null;
    tenantId: string;
    createdAt: string;
    updatedAt: string;
    createdUserId: string;
    updatedUserId: string;
    tenant: FolderItemTenant;
    tags: FolderItemTag[];
}

export interface ItemModelTenant {
    name: string;
    tenantId: string;
}

export interface BaseItemModel<
    type extends 'file' | 'folder' | 'frame' | 'never',
> {
    file: type extends 'file' | 'frame' ? FileItemFile : null;
    fileId: type extends 'file' | 'frame' ? string : null;
    folder: type extends 'folder' ? FolderItemFolder : [];
    folderId: type extends 'folder' ? string : null;
    name: string;
    parentFile: type extends 'frame' ? FileItemFile : null;
    parentFileId: type extends 'frame' ? string : null;
    parentFolder: FolderItemFolder | [];
    parentFolderId: string | null;
    tenant: ItemModelTenant;
    tenantId: string;
    mimeType: type extends 'frame' | 'file' ? string : null;
    videoTimeSeconds: type extends 'frame' ? number : null;
    mosaic: MosaicStatus | null;
}

export type FileItemModel = BaseItemModel<'file'>;
export type FrameItemModel = BaseItemModel<'frame'>;
export type FolderItemModel = BaseItemModel<'folder'>;

export type ItemModel = FileItemModel | FrameItemModel | FolderItemModel;

export function isFolderItem(item?: ItemModel): item is FolderItemModel {
    if (!item) return false;
    return item.folder != null;
}

export interface KintoneAppFolder {
    name: string;
    folderId: string;
}

export type KintoneAppsFolders = {
    appId: string;
    items: KintoneAppFolder[];
};
