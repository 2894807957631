import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

const DEFAULT_MENUS = [
    {
        menu: 'folderlist',
        text: 'フォルダ一覧',
        path: '/folderlist',
    },
    {
        menu: 'userList',
        text: 'ユーザー管理',
        path: '/admin/users',
    },
    {
        menu: 'tags',
        text: '属性管理',
        path: '/admin/tags',
    },
    {
        menu: 'templates',
        text: 'テンプレート管理',
        path: '/admin/templates',
    },
];

const isCurrent = (path) => location.pathname.startsWith('/app' + path);

export const NavBar = (props) => {
    const { menus = DEFAULT_MENUS } = props;
    return (
        <nav className="nav-bar">
            <ul className="menus">
                {menus.map((menu, i) => {
                    const { text, path } = menu;
                    return (
                        <li
                            className={classNames('menu', {
                                current: isCurrent(path),
                            })}
                            key={i}
                        >
                            <Link to={'/app' + path}>{text}</Link>
                        </li>
                    );
                })}
            </ul>
            {/* TODO: deleteme */}
        </nav>
    );
};
