import React from 'react';
import classNames from 'classnames';

import {
    DateCell,
    EmptyCell,
    FolderNameCell,
    UserNameCell,
    TagListCell,
    TagDetails,
} from './SearchResultComponents';

export const SearchResultTable = (props) => {
    const { currentFolder, detailVisible, tagSelections } = props;

    const list = currentFolder.subFolders.map((d) => {
        return {
            ...d,
            id: d.folderId,
            name: d.folderName,
        };
    });

    return (
        <table className="atomic table folder-table search-results">
            <thead>
                <tr>
                    <th />
                    <th>フォルダ名</th>
                    <th />
                    <th>作成日</th>
                    <th>作成者</th>
                    <th>属性</th>
                </tr>
            </thead>
            <tbody>
                {list.map((f, i) => {
                    return [
                        <tr
                            className="folder-summary-line"
                            key={`summ-${f.id}`}
                        >
                            <EmptyCell />
                            <FolderNameCell folder={f} />
                            <EmptyCell />
                            <DateCell dateStr={f.createdAt} />
                            <UserNameCell id={f.createdUserId} />
                            <TagListCell folder={f} />
                        </tr>,
                        <tr
                            className={classNames('folder-tag-detail-line', {
                                collapse: !detailVisible,
                            })}
                            key={`tags-${f.id}`}
                        >
                            <td colSpan={6}>
                                <TagDetails
                                    folder={f}
                                    tagSelections={tagSelections}
                                />
                            </td>
                        </tr>,
                    ];
                })}
            </tbody>
        </table>
    );
};
