import React, { useCallback, useMemo, MouseEvent } from 'react';
import Draggable, { DraggableEventHandler } from 'react-draggable';
import { ReelSession } from './model/ReelSession';
import { IconButton } from '../atomic/buttons';
import { useClickable } from '../../hooks/useClickable';
import { observer } from 'mobx-react-lite';
import { useFileType } from '../../hooks/useFileType';
import { action } from 'mobx';

export interface ReelLegendProps {
    fileId: string;
    session: ReelSession;
}

const preventDefault = (e: MouseEvent) => {
    e.stopPropagation();
};

interface LabelModel {
    id: number;
    color: string;
    nameJa: string;
    count: number;
}

const PhotoReelLegend = observer(({ session }: ReelLegendProps) => {
    const { compiledTags, aiLabels } = session;

    const labels: LabelModel[] = useMemo(() => {
        const counter: {
            [typeId: number]: number;
        } = {};
        const retVal: LabelModel[] = [];

        compiledTags.forEach((tag) => {
            if (!(tag.typeId in counter)) {
                counter[tag.typeId] = 1;
            } else {
                counter[tag.typeId]++;
            }
        });
        aiLabels.forEach(({ id, nameJa, color }) => {
            if (id in counter) {
                retVal.push({
                    id,
                    color,
                    nameJa,
                    count: counter[id],
                });
            }
        });
        return retVal;
    }, [compiledTags, aiLabels]);

    const { legend } = session;
    const onDrag = useCallback<DraggableEventHandler>(
        action((e, data) => {
            legend.point.x = data.x;
            legend.point.y = data.y;
        }),
        []
    );
    const icon = {
        ...useClickable(
            action(() => {
                legend.open = !legend.open;
            }),
            [legend]
        ),
        onMouseDownCapture: preventDefault,
    };
    return (
        <Draggable defaultPosition={legend.point} onDrag={onDrag}>
            <div className="reel--legend--wrapper">
                <div className="reel--legend">
                    {legend.open ? (
                        <>
                            <div className="reel--legend--header">
                                <div className="title">凡例</div>
                                <IconButton
                                    className="no-decoration"
                                    icon="fa-solid fa-minus"
                                    style={{ color: 'white' }}
                                    {...icon}
                                >
                                    閉じる
                                </IconButton>
                            </div>
                            <ul className="reel--legend--content">
                                {labels.length ? (
                                    labels.map((label) => (
                                        <li
                                            key={label.id}
                                            className="reel--legend--item"
                                        >
                                            <div
                                                className="color-block"
                                                style={{
                                                    backgroundColor:
                                                        label.color,
                                                }}
                                            ></div>
                                            {label.nameJa} ({label.count})
                                        </li>
                                    ))
                                ) : (
                                    <li>No Label</li>
                                )}
                            </ul>
                        </>
                    ) : (
                        <div className="reel--legend--icon">
                            <IconButton
                                className="no-decoration"
                                icon="fa-regular fa-rectangle-list"
                                {...icon}
                            >
                                凡例
                            </IconButton>
                        </div>
                    )}
                </div>
            </div>
        </Draggable>
    );
});

export const ReelLegend = observer(({ fileId, session }: ReelLegendProps) => {
    const { isPhoto } = useFileType(fileId);
    if (!isPhoto || session.hideTags) {
        return <></>;
    }
    return <PhotoReelLegend fileId={fileId} session={session} />;
});
