import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Icon, IconType } from './icons';
import { useClickable } from '../../hooks/useClickable';

export interface ButtonProps
    extends React.DetailedHTMLProps<
        React.ButtonHTMLAttributes<HTMLButtonElement>,
        HTMLButtonElement
    > {
    icon?: IconType;
}
export const Button = (props: ButtonProps) => {
    const { className, icon, children, ...rest } = props;
    const content = (
        <>
            {icon ? <Icon icon={icon} /> : null}
            {children}
        </>
    );
    return (
        <button className={classNames('atomic button', className)} {...rest}>
            {content}
        </button>
    );
};

export interface UnstyledButtonProps
    extends React.DetailedHTMLProps<
        React.AnchorHTMLAttributes<HTMLAnchorElement>,
        HTMLAnchorElement
    > {
    onClick: () => void;
    icon?: IconType;
    disabled?: boolean;
}
export const UnstyledButton = (props: UnstyledButtonProps) => {
    const { className, icon, children, onClick, disabled, ...rest } = props;
    const content = (
        <>
            {icon ? <Icon icon={icon} /> : null}
            {children}
        </>
    );
    const click = useClickable(onClick, [onClick]);
    const cls = classNames('atomic unstyled-button', className, { disabled });
    return (
        <a
            className={cls}
            {...{
                ...(disabled ? {} : click),
                ...rest,
            }}
        >
            {content}
        </a>
    );
};

export interface IconButtonProps extends ButtonProps {}
export const IconButton = (props: IconButtonProps) => {
    const { className, children, ...rest } = props;
    return (
        <div className="atomic icon-button-outer">
            <Button
                className={classNames('icon-button', className)}
                title={String(
                    children && typeof children === 'string' && children
                )}
                {...rest}
            />
        </div>
    );
};

export interface ButtonGroupProps<T> {
    className?: string;
    value?: T;
    onChange?: MouseEventHandler<HTMLElement | SVGElement>;
    children?: React.ReactElement | React.ReactElement[];
}
export const ButtonGroup = <T = any,>(props: ButtonGroupProps<T>) => {
    const { className, value, onChange = () => {}, children } = props;
    return (
        <div className={classNames('atomic button-group', className)}>
            {React.Children.map(children, (btn) => {
                if (!btn) return;
                const { className, value: btnVal, ...rest } = btn.props;
                return React.cloneElement(btn, {
                    className: classNames(className, {
                        selected: value === undefined ? false : btnVal == value,
                    }),
                    onClick: onChange,
                    ...rest,
                });
            })}
        </div>
    );
};
