import classNames from 'classnames';
import React, { memo } from 'react';
import { Icon, Icons } from '../atomic/icons';
import { useIsSyncing } from '../../SyncProcess';

export const ReelSyncIndicator = memo(() => {
    const syncing = useIsSyncing();
    return (
        <div
            title="同期中"
            className={classNames('reel--update', {
                'reel--update--active': syncing,
            })}
        >
            <Icon icon={Icons.UpdatePending} className="fa-spin" />
        </div>
    );
});
