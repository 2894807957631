import React from 'react';

export const Spinner = () => (
    <div className="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
);
export const Loading = () => (
    <div className="atomic loading spinner">
        <Spinner />
    </div>
);
