export const AuthenticatorFormFields = {
    signIn: {
        username: {
            label: 'メールアドレス *',
            labelHidden: false,
            placeholder: 'メールアドレスを入力',
            isRequired: true,
        },
        password: {
            label: 'パスワード *',
            labelHidden: false,
            placeholder: 'パスワードを入力',
            isRequired: true,
        },
    },
    confirmSignIn: {
        confirmation_code: {
            label: '認証コード *',
            labelHidden: false,
            placeholder: '認証コードを入力',
            isRequired: true,
        },
    },
    resetPassword: {
        username: {
            label: 'メールアドレス',
            labelHidden: false,
            placeholder: 'メールアドレスを入力',
            isRequired: true,
        },
    },
    confirmResetPassword: {
        confirmation_code: {
            label: 'コード',
            labelHidden: false,
            placeholder: '確認コードを入力してください',
            isRequired: false,
        },
        password: {
            label: '新しいパスワード',
            labelHidden: false,
            placeholder: '新しいパスワードを入力してください',
            isRequired: false,
        },
        confirm_password: {
            label: 'パスワードを認証する',
            labelHidden: false,
            placeholder: '新しいパスワードをもう一度入力してください',
            isRequired: false,
        },
    },
    forceNewPassword: {
        password: {
            label: '新しいパスワード',
            labelHidden: false,
            placeholder: '新しいパスワードを入力してください',
            isRequired: false,
        },
        confirm_password: {
            label: 'パスワードを認証する',
            labelHidden: false,
            placeholder: '新しいパスワードをもう一度入力してください',
            isRequired: false,
        },
    },
};
