import React, { useState } from 'react';

import { TenantModel } from '../../../models/TenantModel';
import { getTenantsForSuperAdmin } from '../../../APIs/tenants';
import { TenantCreate } from './TenantCreate';
import { TenantList } from './TenantList';
import { Loading } from '../../atomic/effects';

export const SuperAdminTenantIndex = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [tenants, setTenants] = React.useState<Array<TenantModel>>([]);

    const [update, setUpdata] = useState<boolean>(false);

    React.useEffect(() => {
        getTenantsForSuperAdmin()
            .then((resp) => {
                setTenants(resp.data);
            })
            .catch((err) => {
                console.warn(err);
            });
    }, []);

    if (!tenants.length) {
        return (
            <>
                <Loading />
            </>
        );
    }

    return (
        <div className="app-main admin-tenant">
            <div className="header">
                <h2 className="header-name">テナント一覧</h2>
                <TenantCreate isOpen={isOpen} setIsOpen={setIsOpen} />
                <TenantList tenants={tenants} />
            </div>
        </div>
    );
};
