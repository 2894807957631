export enum TagType {
    Number = 1,
    Text = 2,
    List = 3,
    Date = 4,
    Location = 5,
}

export const TagTypeNames = [
    { name: TagType.Text, label: 'テキスト' },
    { name: TagType.Number, label: '数値' },
    { name: TagType.List, label: 'リスト' },
    { name: TagType.Location, label: '位置情報' },
    { name: TagType.Date, label: '日付' },
];

export interface TagTypeModel {
    tenantTagId: string;
    tagTypeId: string;
    name: string;
    type: number;
    sortOrder: number;
    list: Array<string> | null;
}

export interface TagModel {
    tenantTagId: string;
    tagId: string;
    tagName: string;
    isOverWrite: boolean;
    folder?: {
        folderId: string;
        folderName: string;
    };
}

export interface TagDetailModel {
    tagDetailId: string;
    tagId: string;
    tagTypeId: string;
    value: string;
}

export interface TenantTagModel {
    tenantTagId: string;
    name: string;
    tenantId: string;
    tagTypes: TagTypeModel[];
}
