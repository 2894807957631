import { AxiosProgressEvent } from 'axios';
import { nanoid } from 'nanoid';

export interface ProgressLogEntry {
    id: string;
    message: string;
    progress: number;
    state: ProgressState;
    time: Date;
}

export interface ProgressReporter {
    report: (progress: number, message: string) => void;
    get state(): ProgressState;
    subsection: (amount: number) => ProgressReporter;
    onError: (error: Error) => void;
    onAxiosProgress: (
        template: (progressEvent: AxiosProgressEvent) => string
    ) => (progressEvent: AxiosProgressEvent) => void;
}

export type ProgressHandler = (entry: ProgressLogEntry) => void;
const noop = () => {};

export interface ProgressControllerOpts {
    onProgress?: ProgressHandler;
    parent?: ProgressController | null;
    amount?: number;
}
export enum ProgressState {
    RUNNING = 'running',
    SUCCESS = 'success',
    ERROR = 'error',
}

const INGORE_MAX_OVERLAP = 1.0000001;
export class ProgressController implements ProgressReporter {
    handler: ProgressHandler;
    progress: number = 0;
    allocated: number = 0;
    state: ProgressState = ProgressState.RUNNING;
    onError: (error: Error) => void;
    parent: ProgressController | null;
    amount: number;
    constructor(opts?: ProgressControllerOpts) {
        this.handler = opts?.onProgress ?? noop;
        this.onError = opts?.onProgress
            ? (error: Error) => {
                  console.warn(error);
                  if (this.state === ProgressState.SUCCESS) {
                      console.warn(
                          '[PROGRESS] Unexpected Error occurred after success',
                          error
                      );
                      return;
                  }
                  if (this.state === ProgressState.ERROR) {
                      return;
                  }
                  this.state = ProgressState.ERROR;
                  this.handler({
                      progress: this.progress,
                      message: error.message,
                      state: this.state,
                      id: nanoid(),
                      time: new Date(),
                  });
              }
            : noop;
        this.parent = opts?.parent ?? null;
        this.amount = opts?.amount ?? 0;
        if (this.parent && !this.amount) {
            throw new Error('amount を指定してください');
        }
    }
    report(progress: number, message: string): void {
        const prevProgress = this.progress;
        if (progress < prevProgress) {
            console.warn('[PROGRESS] Received old progress', progress, message);
        }
        if (progress > 1.0) {
            if (progress > INGORE_MAX_OVERLAP) {
                console.warn(
                    '[PROGRESS] Received progress bigger 1.0',
                    progress,
                    message
                );
            }
        }
        if (this.state === ProgressState.ERROR) {
            return;
        }

        this.progress = progress;
        this.state =
            this.progress === 1.0
                ? ProgressState.SUCCESS
                : ProgressState.RUNNING;

        if (this.progress > this.allocated) {
            this.allocated = this.progress;
        }

        if (this.parent) {
            this.parent.report(
                this.parent.progress + (progress - prevProgress) * this.amount,
                message
            );
            return;
        }
        this.handler({
            progress,
            message,
            state: this.state,
            id: nanoid(),
            time: new Date(),
        });
    }
    onAxiosProgress(template: (progressEvent: AxiosProgressEvent) => string) {
        return (event: AxiosProgressEvent) => {
            this.report(event.progress ?? 0, template(event));
        };
    }
    subsection(amount: number): ProgressController {
        let allocated = this.allocated + amount;
        if (allocated > 1.0) {
            if (allocated > INGORE_MAX_OVERLAP) {
                console.warn(
                    '[PROGRESS] subsection exceeds allowed amount',
                    amount
                );
            }
            amount = 1.0 - this.allocated;
        }
        if (amount <= 0.0) {
            console.warn('[PROGRESS] subsection is 0 sized', amount);
        }
        this.allocated += amount;
        return new ProgressController({
            onProgress: this.handler,
            parent: this,
            amount,
        });
    }
}
