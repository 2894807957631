import React, { useRef } from 'react';
import {
    getTenantTags,
    deleteTenantTag,
    addTenantTag,
} from '../../APIs/tagApi';
import { useNavigate, Link } from 'react-router-dom';
import { Icon } from '../atomic/icons';
import { DialogBox } from '../atomic/dialog';
import { TextField } from '../atomic/forms';
import { Loading } from '../atomic/effects';
import { AdminListTags } from './AdminUtils';
import { TenantTagModel } from '../../models/TagModel';
import { ErrorDisplay } from '../Errors';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { exportTenantTag } from '../../APIs/tag/exportTenantTag';
import { mutateAsync } from '../../hooks/useApi';
import { useTabable } from '../../hooks/useClickable';
import { importTenantTags } from '../../APIs/tag/importTenantTag';
import { ErrorContext } from '../../contexts';
import { useMutationContextDialog } from '../../hooks/useMutationDialog';
import { safeUrl } from '../../utils/safeUrl';
import { ImportTenantTagsDialog } from './importTenantTagsDialog';
import { asyncReadJsonFile } from '../../utils/asyncReadFile';
import { templateApi } from '../../APIs/templateApi';

export const AdminTags = () => {
    const [tagList, setTagList] = React.useState<Array<TenantTagModel> | null>(
        null
    );
    const [newTagName, setNewTagName] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const refDialog = React.useRef<DialogBox>(null);
    const [error, setError] = React.useState<Error | null>(null);
    const { fetchUser } = useAuth();
    const userInfo = useFetchUserSelf(fetchUser());
    const { setErrors } = React.useContext(ErrorContext);

    const importTag = useMutationContextDialog(
        templateApi.mutate.importTagsMutation,
        ImportTenantTagsDialog,
        {
            onError: '属性値の作成に失敗しました',
            onSuccess: ({ data }) => {
                navigate(safeUrl`/app/admin/tag/${data.tenantTagId}`);
            },
        }
    );
    React.useEffect(() => {
        getTenantTags()
            .then((resp) => {
                console.log(resp);
                if (resp.success && resp.data) {
                    setTagList(resp.data);
                } else {
                    setError(
                        Error(resp.message || 'タグ情報の取得に失敗しました')
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
                setError(err);
            });
    }, []);

    const onDeleteTagHandle = React.useCallback(
        (tenantTagId: string, dialog: DialogBox, closeDialog: () => void) => {
            setLoading(true);
            deleteTenantTag(tenantTagId)
                .then((resp) => {
                    if (resp.success) {
                        setTagList((prevState) => {
                            const nextState = JSON.parse(
                                JSON.stringify(prevState)
                            );
                            return nextState.filter(
                                (item) => item.tenantTagId != tenantTagId
                            );
                        });
                        closeDialog();
                    } else {
                        dialog.addError(
                            resp.message || '属性情報の削除に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.message || '属性情報の削除に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        []
    );

    const onAddTag = React.useCallback(() => {
        setNewTagName('');
    }, []);

    const onExport = React.useCallback(
        (tenantTagId: string) => {
            setLoading(true);
            mutateAsync(
                exportTenantTag,
                tagList?.find((tag) => tag.tenantTagId == tenantTagId)!
            )
                .catch((err) => {
                    console.warn(err);
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [tagList]
    );

    const onChangeImportFile = React.useCallback(
        (event) => {
            const file = event.target.files[0];
            if (!file) return;
            asyncReadJsonFile(file)
                .then((json) =>
                    importTenantTags(userInfo?.tenantId ?? '', json)
                )
                .then(
                    (request) => importTag.show({ request }),
                    (err) => {
                        console.warn(err);
                        setErrors((errors) => [
                            ...errors,
                            err.message ||
                                'テンプレートのインポートに失敗しました',
                        ]);
                    }
                );
        },
        [importTag.show, userInfo]
    );

    const importRef = useRef<HTMLInputElement | null>(null);
    const importButton = useTabable(() => {
        importRef.current?.click();
    }, []);

    if (error) {
        return <ErrorDisplay error={error} />;
    }

    if (!tagList) {
        return <Loading />;
    }

    return (
        <>
            <div className="app-main admin-tags">
                <h2>属性情報の設定一覧</h2>
                <AdminListTags
                    items={tagList.map((tag) => ({
                        id: tag.tenantTagId,
                        url:
                            '/app/admin/tag/' +
                            encodeURIComponent(tag.tenantTagId),
                        name: tag.name,
                    }))}
                    onExport={onExport}
                    onDelete={onDeleteTagHandle}
                    annotation="属性情報設定を削除すると、フォルダに付与済みの属性情報も削除されます。"
                />
                <div className="admin-tags-footer">
                    <Link to="#" onClick={() => onAddTag()}>
                        <Icon icon="fa-solid fa-folder-plus" />{' '}
                        属性情報を追加する
                    </Link>
                    <label
                        htmlFor="import-tags"
                        className="import-button"
                        {...importButton}
                    >
                        <Icon icon="fa-solid fa-upload" /> インポートする
                    </label>
                </div>
                <input
                    type="file"
                    ref={importRef}
                    style={{ display: 'none' }}
                    onChange={onChangeImportFile}
                    id="import-tags"
                    accept=".json"
                />
            </div>
            {newTagName !== null && (
                <DialogBox
                    title={'属性情報の追加'}
                    okText={'追加'}
                    onCancel={() => {
                        setNewTagName(null);
                    }}
                    onOK={() => {
                        setLoading(true);
                        addTenantTag({
                            name: newTagName,
                            tenantId: userInfo?.tenantId ?? '-',
                        })
                            .then((resp) => {
                                console.log(resp);
                                if (resp.success && resp.data) {
                                    navigate(
                                        '/app/admin/tag/' +
                                            encodeURIComponent(
                                                resp.data.tenantTagId
                                            )
                                    );
                                    setNewTagName(null);
                                } else {
                                    refDialog?.current?.addError(
                                        resp?.message ||
                                            '属性情報に追加に失敗しました'
                                    );
                                }
                            })
                            .catch((err) => {
                                console.warn(err);
                                refDialog?.current?.addError(
                                    err.message ||
                                        '属性情報に追加に失敗しました'
                                );
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                    }}
                    ref={refDialog}
                >
                    <ul className="fields">
                        <li>
                            <TextField
                                label="属性名"
                                _onChange={setNewTagName}
                                value={newTagName}
                                required={true}
                            />
                        </li>
                    </ul>
                </DialogBox>
            )}
            {importTag.dialog}
            {loading && <Loading />}
        </>
    );
};
