import React, {
    KeyboardEvent,
    useMemo,
    DetailedHTMLProps,
    InputHTMLAttributes,
    ChangeEvent,
    FocusEvent,
    MouseEvent,
    ReactNode,
    Children,
    LegacyRef,
    Component,
    RefObject,
} from 'react';
import classNames from 'classnames';

import { isEnterKey } from '../../utils/EventUtils';
import { Button } from './buttons';
import { getLocations } from '../../APIs/foldersApi';
import 'react-phone-number-input/style.css';
import PhoneInput, {
    DefaultInputComponentProps,
    Value as E164Number,
    Props,
    State,
} from 'react-phone-number-input';
import cloneDeep from 'lodash.clonedeep';

export interface LabelProps
    extends React.DetailedHTMLProps<
        React.LabelHTMLAttributes<HTMLLabelElement>,
        HTMLLabelElement
    > {
    text?: ReactNode | ReactNode[];
}
export const Label = (props: LabelProps) => {
    const { text, className, ...rest } = props;
    let content = Children.toArray(text);
    if (content.length === 0) {
        return;
    }
    return (
        <label className={classNames('input-label', className)} {...rest}>
            {text}
        </label>
    );
};

function useEnter<T extends HTMLElement>(
    onEnter?: (event: KeyboardEvent<T>) => void
) {
    return useMemo(() => {
        if (!onEnter) return () => {};
        return (event: KeyboardEvent<T>) => {
            if (isEnterKey(event)) {
                onEnter(event);
            }
        };
    }, [onEnter]);
}

export type Validator = (value: string) => boolean;

export interface FormFieldProps<
    Element extends HTMLInputElement | HTMLSelectElement =
        | HTMLInputElement
        | HTMLSelectElement,
> {
    ref?: RefObject<FormField>;
    label?: string;
    value?: string;
    name?: string;
    validator?: RegExp | Validator;
    className?: string;
    required?: boolean;
    maxLength?: number;
    errorMessage?: string;
    onChange?: (e: ChangeEvent<Element>) => any;
    onFocus?: (e: FocusEvent<Element>) => any;
    onBlur?: (e: FocusEvent<Element>) => any;
    onKeyPress?: (e: KeyboardEvent<Element>) => any;
    onMouseOver?: (e: MouseEvent<Element>) => any;
    onMouseOut?: (e: MouseEvent<Element>) => any;
    onMouseDown?: (e: MouseEvent<Element>) => any;
    _onChange?: (value: string) => any;
    _onValidChange?: (value: string) => any;
    onEnter?: (event: KeyboardEvent<HTMLElement>) => void;
}

export interface FormFieldState {
    error: string;
}

function toValidator(validator: RegExp | Validator): Validator {
    if (validator instanceof RegExp) {
        return validator.test.bind(validator);
    }
    return validator;
}

function toValidators(validator: RegExp | Validator | undefined): Validator[] {
    if (!validator) return [];
    return [toValidator(validator)];
}

export type PropElement<Type extends FormFieldProps<any>> =
    Type extends FormFieldProps<infer Elem> ? Elem : unknown;

export class FormField<
    Props extends FormFieldProps<any> = FormFieldProps,
    State extends FormFieldState = FormFieldState,
> extends React.Component<Props, State> {
    type: string;
    validators: Validator[];
    required: boolean;
    refInput: React.RefObject<PropElement<Props>> | undefined;
    errorMessage: string;

    static readonly defaultState = Object.freeze({
        error: '',
    });

    constructor(props: Props, startState: State) {
        super(props);
        this.state = cloneDeep(startState);
        this.required = this.props.required ?? false;
        this.type = 'text';
        this.refInput = React.createRef<PropElement<Props>>();
        this.validators = toValidators(this.props.validator);

        this.onChangeHandle = this.onChangeHandle.bind(this);
        this.onKeyUpHandle = this.onKeyUpHandle.bind(this);
        this.errorMessage = props.errorMessage || '不正な値です';
    }

    get value(): string {
        return (
            this.refInput?.current?.value?.replace(
                /^[\s\t　]+|[\s\t　]+$/g,
                ''
            ) ?? ''
        );
    }

    setError(message: string) {
        this.setState((state) => ({ ...state, error: message }));
    }

    clearError() {
        this.setError('');
    }

    get validationError(): string {
        const value = this.value;
        if (!value) {
            if (this.props.required) {
                return '値を入力してください';
            } else {
                return '';
            }
        }
        try {
            for (const validator of this.validators) {
                if (!validator(value)) {
                    return this.errorMessage;
                }
            }
        } catch (error) {
            return error.message;
        }
        return '';
    }

    get isValid(): boolean {
        return !this.validationError;
    }

    checkValue(): boolean {
        const error = this.validationError;
        this.setError(error);
        return !error;
    }

    onChangeHandle(e: ChangeEvent<PropElement<Props>>) {
        if (this.props.onChange) {
            this.props.onChange(e);
        }
        if (this.props._onValidChange && this.isValid) {
            this.props._onValidChange(e.target.value);
        }
        if (this.props._onChange) {
            e.stopPropagation();
            this.props._onChange(e.target.value);
        }
    }

    onKeyUpHandle(e: KeyboardEvent<PropElement<Props>>) {
        if (this.props.onEnter && isEnterKey(e)) {
            this.props.onEnter(e);
        }
    }
    render(): React.ReactNode {
        throw new Error('abstract');
    }
}

export interface InputFieldProps extends FormFieldProps<HTMLInputElement> {
    disabled?: boolean;
    placeholder?: string;
    autoFocus?: boolean;
    autoComplete?: 'on' | 'off';
    spellCheck?: boolean;
}

export abstract class InputField<
    Props extends InputFieldProps = InputFieldProps,
> extends FormField<Props> {
    render() {
        const {
            className,
            label,
            value,
            onEnter,
            onChange, // restに残さないために取り出すだけ
            _onChange, // restに残さないために取り出すだけ
            _onValidChange, // restに残さないために取り出すだけ
            maxLength, // restに残さないために取り出すだけ
            errorMessage, // restに残さないために取り出すだけ
            validator, // restに残さないために取り出すだけ
            required, // restに残さないために取り出すだけ
            ...rest
        } = this.props;
        return (
            <div className={classNames('atomic input-field text', className)}>
                <Label text={label} htmlFor={rest.name} />
                <input
                    type={this.type}
                    value={value ?? ''}
                    ref={this.refInput}
                    onChange={this.onChangeHandle}
                    maxLength={maxLength || 100}
                    onKeyUp={this.onKeyUpHandle}
                    {...rest}
                />
                {this.state.error && (
                    <div className="error">{this.state.error}</div>
                )}
            </div>
        );
    }
}

export class TextField extends InputField {
    constructor(props: InputFieldProps) {
        super(props, FormField.defaultState);
    }
}

export interface NumberFieldProps extends InputFieldProps {
    min?: number;
    max?: number;
    step?: number;
}

function validateNumber(
    { min, max, step }: { min?: number; max?: number; step?: number },
    value: string
): boolean {
    if (!/^-?\d+(\.\d+)?$/.test(value)) {
        return false;
    }
    const float = parseFloat(value);
    if (isNaN(float)) {
        return false;
    }
    if (max !== undefined && max !== null) {
        if (max < float) {
            return false;
        }
    }
    if (min !== undefined && min !== null) {
        if (min > float) {
            return false;
        }
        if (step !== undefined && step !== null) {
            const diff = float - min;
            const stepsFloat = diff / step;
            const stepsInt = stepsFloat | 0;
            if (stepsInt != stepsFloat) {
                return false;
            }
        }
    }
    return true;
}

export class NumberField<
    Props extends NumberFieldProps = NumberFieldProps,
> extends InputField<Props> {
    constructor(props: Props) {
        super(props, FormField.defaultState);
        this.type = 'number';
        this.validators.push(validateNumber.bind(null, props));
    }
}

export class DateField extends InputField {
    constructor(props: InputFieldProps) {
        super(props, FormField.defaultState);
        this.type = 'date';
        this.validators.push(toValidator(/^\d{4}-\d{1,2}-\d{1,2}$/));
    }
}

export interface LocationFieldProps extends InputFieldProps {
    folderId: string;
}

export class LocationField extends FormField<LocationFieldProps> {
    constructor(props: LocationFieldProps) {
        super(props, FormField.defaultState);
        this.onClickHandle = this.onClickHandle.bind(this);
        this.validators.push(
            toValidator(/^\s*\d+(?:\.\d+)?\s*,\s*\d+(?:\.\d+)?\s*$/)
        );
        if (this.props.onChange) {
            throw new Error(
                'LocationField では onChange ではなく _onChange を利用してください'
            );
        }
    }

    onClickHandle() {
        this.clearError();
        getLocations(this.props.folderId)
            .then((resp) => {
                console.log(resp);
                if (resp.success) {
                    if (resp?.data?.files?.length) {
                        const files = resp.data.files;
                        const len = files.length;
                        for (let i = 0; i < len; i++) {
                            if (
                                files[i]?.fileMetadata?.location?.latitude &&
                                files[i]?.fileMetadata?.location?.longitude
                            ) {
                                if (this.refInput?.current) {
                                    const value: string = `${files[i].fileMetadata.location.latitude},${files[i].fileMetadata.location.longitude}`;
                                    this.refInput.current.value = value;
                                    if (this.props._onChange) {
                                        this.props._onChange(value);
                                    }
                                }
                                break;
                            }
                        }
                    } else {
                        this.setError('位置情報が見つかりませんでした');
                    }
                } else {
                    this.setError(
                        resp.message || '位置情報の取得に失敗しました'
                    );
                }
            })
            .catch((err) => {
                console.log(err);
                this.setError(err.message || '位置情報の取得に失敗しました');
            });
    }

    render() {
        const {
            className,
            label,
            value,
            disabled,
            folderId, // restに残さないために取り出すだけ
            onChange, // restに残さないために取り出すだけ
            _onChange, // restに残さないために取り出すだけ
            maxLength, // restに残さないために取り出すだけ
            errorMessage, // restに残さないために取り出すだけ
            ref, // restに残さないために取り出すだけ
            ...rest
        } = this.props;
        return (
            <div
                className={classNames('atomic input-field location', className)}
            >
                <Label text={label} htmlFor={rest.name} />
                <div className="location-field-group">
                    <input
                        type={this.type}
                        value={value ?? ''}
                        ref={this.refInput}
                        onChange={this.onChangeHandle}
                        maxLength={maxLength || 100}
                        placeholder="ex) 34.67627,135.54438"
                        disabled={disabled ?? false}
                        {...rest}
                    />
                    <Button
                        onClick={this.onClickHandle}
                        disabled={disabled ?? false}
                    >
                        取得
                    </Button>
                </div>

                {this.state.error && (
                    <div className="error">{this.state.error}</div>
                )}
            </div>
        );
    }
}

type PhoneInputRef = LegacyRef<
    Component<
        Props<DefaultInputComponentProps>,
        State<Props<DefaultInputComponentProps>>
    >
>;
export class PhoneNumberField extends InputField {
    constructor(props: InputFieldProps) {
        super(props, FormField.defaultState);
        if (this.props.onChange) {
            throw new Error(
                'PhoneNumberField では onChange ではなく _onChange を利用してください'
            );
        }
        this.onPhoneChange = this.onPhoneChange.bind(this);
    }

    onPhoneChange(value?: E164Number) {
        this.props._onChange?.(value ? value.toString() : '');
    }
    render() {
        const {
            className,
            label,
            value,
            onEnter,
            onChange, // restに残さないために取り出すだけ
            _onChange, // restに残さないために取り出すだけ
            maxLength, // restに残さないために取り出すだけ
            errorMessage, // restに残さないために取り出すだけ
            validator, // restに残さないために取り出すだけ
            required, // restに残さないために取り出すだけ
            ref, // restに残さないために取り出すだけ
            ...rest
        } = this.props;
        return (
            <div className={classNames('atomic input-field text', className)}>
                <Label text={label} htmlFor={rest.name} />
                <PhoneInput
                    value={value ?? ''}
                    ref={this.refInput as any as PhoneInputRef}
                    onChange={this.onPhoneChange}
                    maxLength={maxLength || 100}
                    onKeyUp={this.onKeyUpHandle}
                    defaultCountry="JP"
                    {...rest}
                />
                {this.state.error && (
                    <div className="error">{this.state.error}</div>
                )}
            </div>
        );
    }
}

export class PasswordField extends InputField {
    constructor(props: InputFieldProps) {
        super(props, FormField.defaultState);
        this.type = 'password';
    }
}

export interface TextAreaFieldProps
    extends DetailedHTMLProps<
        InputHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
    > {
    label?: string;
    onEnter?: (event: KeyboardEvent<HTMLElement>) => void;
}
export const TextAreaField = (props: TextAreaFieldProps) => {
    const { className, label, onEnter, ...rest } = props;

    const onKeyUp = useEnter<HTMLTextAreaElement>(onEnter);

    return (
        <div className={classNames('atomic input-field textarea', className)}>
            <Label text={label} htmlFor={rest.name} />
            <textarea onKeyUp={onKeyUp} {...rest} />
        </div>
    );
};

export interface SelectFieldOption {
    value?: string;
    text?: string;
    key?: string;
    disabled?: boolean;
}
export interface SelectFieldOptionGroup {
    label: string;
    options: Array<SelectFieldOption>;
}

export interface SelectFieldOptionGroup {
    label: string;
    options: Array<SelectFieldOption>;
}

export interface SelectFieldProps extends FormFieldProps<HTMLSelectElement> {
    options: Array<SelectFieldOption | SelectFieldOptionGroup>;
    disabled?: boolean;
}

export class SelectField extends FormField<SelectFieldProps> {
    constructor(props: SelectFieldProps) {
        super(props, FormField.defaultState);
    }
    checkValue(): boolean {
        const value = this.value;

        if (value === '') {
            if (this.props.required) {
                this.setError('値を入力してください');
                return false;
            } else {
                return true;
            }
        }

        const imax = this.props.options.length;
        for (let i = 0; i < imax; i++) {
            const option = this.props.options[i];
            if ('label' in option && 'options' in option) {
                const jmax = option.options.length;
                for (let j = 0; j < jmax; j++) {
                    const options2 = option.options[j];
                    if (value == (options2.value ?? options2.text)) {
                        return true;
                    }
                }
            } else {
                if (value == (option.value ?? option.text)) {
                    return true;
                }
            }
        }
        this.setError(this.errorMessage);
        return false;
    }
    render() {
        const {
            className,
            label,
            options,
            onChange, // restに残さないために取り出すだけ
            _onChange, // restに残さないために取り出すだけ
            maxLength, // restに残さないために取り出すだけ
            errorMessage, // restに残さないために取り出すだけ
            ref, // restに残さないために取り出すだけ
            validator,
            required,
            ...rest
        } = this.props;

        let exists = false;

        const createOption = (
            { value, text, disabled }: SelectFieldOption,
            index: number
        ) => {
            const v = value ?? text;
            if (v == this.props.value) {
                exists = true;
            }
            return (
                <option value={v} key={index} disabled={disabled}>
                    {text}
                </option>
            );
        };

        return (
            <div className={classNames('atomic input-field select', className)}>
                <Label text={label} htmlFor={rest.name} />
                <select
                    onChange={this.onChangeHandle}
                    ref={this.refInput}
                    {...rest}
                >
                    {options.map(
                        (
                            option: SelectFieldOption | SelectFieldOptionGroup,
                            index: number
                        ) =>
                            'label' in option && 'options' in option ? (
                                <optgroup label={option.label} key={index}>
                                    {option.options.map(createOption)}
                                </optgroup>
                            ) : (
                                createOption(option, index)
                            )
                    )}
                    {!exists && (
                        <option value={this.props.value} disabled></option>
                    )}
                </select>
                {this.state.error && (
                    <div className="error">{this.state.error}</div>
                )}
            </div>
        );
    }
}

export interface PlainFieldProps {
    className?: string;
    label?: string;
    value: string;
}

export const PlainField: React.FC<PlainFieldProps> = ({
    className,
    label,
    value,
}) => {
    return (
        <div className={classNames('atomic input-field plain-text', className)}>
            <Label text={label} />
            <div className="text">{value}</div>
        </div>
    );
};
