import React, { useRef, useMemo, useEffect, useContext } from 'react';
import { ReelSession } from './model/ReelSession';
import Player from 'video.js/dist/types/player';
import { ReelScreenshotButton } from './ReelScreenshotButton';
import { ReelTags } from './ReelTags';
import { ReelTaskUI } from './ReelTaskUI';
import { ReelLabelEditor } from './ReelLabelEditor';
import { ReelReportEditor } from './ReelReportEditor';
import { ReelFileMenu } from './ReelFileMenu';
import { ReelPhotoModeMenu } from './ReelPhotoModeMenu';
import { ReelFolderLink } from './ReelFolderLink';
import { useAuth } from '../../contexts/AuthContext';
import { ReelLegend } from './ReelLegend';
import { useLocalState } from './model/LocalState';
import { ReelSyncIndicator } from './ReelSyncIndicator';
import { ReelFrameMenu } from './ReelFrameMenu';
import { ReelFileDisplay } from './ReelFileDisplay';
import { SyncContext, useIsSyncing } from '../../SyncProcess';
import { useGet } from '../../hooks/useApi';
import { projectApi } from '../../APIs/projectApi';
import { templateApi } from '../../APIs/templateApi';
import { AggregateProjectModel } from '../../models/AggregateProjectModel';
import { Label } from '../../models/AiTemplateModel';

export interface ReelProps {
    projectId: string;
    folderId: string;
    fileId: string;
    sourceFileId: string | undefined;
    videoTimeSeconds: number;
    readonly: boolean;
}

function useAggregatedProject(projectId: string) {
    const project = useGet(projectApi.get.project(projectId));
    const aiAnalysisTypes = useGet(projectApi.get.aiAnalysisTypes);
    const aiTemplateId = project.data?.data?.aiTemplateId;
    const aiTemplate = useGet(
        aiTemplateId ? templateApi.get.aiTemplate(aiTemplateId) : null
    );
    return useMemo((): AggregateProjectModel | undefined => {
        if (!project.data?.data) return;
        const types = aiAnalysisTypes.data?.data;
        if (!types) return;
        let aiAnalysisTypeId: string;
        let labels: Label[];
        if (project.data.data.aiTemplateId) {
            if (!aiTemplate.data) return;
            aiAnalysisTypeId = aiTemplate.data.data.aiAnalysisTypeId;
            labels = aiTemplate.data.data.labels;
        } else {
            aiAnalysisTypeId = project.data.data.aiAnalysisTypeId!;
            labels = [];
        }
        return {
            project: project.data.data,
            labels,
            aiAnalysisType: types.find(
                (type) => type?.aiAnalysisTypeId === aiAnalysisTypeId
            )!,
        };
    }, [project.data, aiAnalysisTypes.data, aiTemplate.data]);
}

export function Reel({
    projectId,
    folderId,
    fileId,
    sourceFileId,
    videoTimeSeconds,
    readonly,
}: ReelProps) {
    const { fetchUser } = useAuth();
    const localState = useLocalState();
    const syncProcess = useContext(SyncContext);
    const fileMeta = syncProcess.useFileMetaApi(projectId, fileId);
    const project = useAggregatedProject(projectId);
    const session = useMemo(
        () =>
            new ReelSession(
                project,
                fileId,
                fileMeta,
                localState,
                fetchUser()?.username ?? '',
                readonly
            ),
        []
    );

    const syncing = useIsSyncing();
    useEffect(() => {
        session.fileMeta = fileMeta;
        session.project = project;
        session.fileId = fileId;
    }, [fileMeta, project, fileId]);
    const video = useRef<Player | null>(null);

    return (
        <div className="reel" ref={session.reelContainer} tabIndex={-1}>
            <ReelSyncIndicator />
            <ReelFileDisplay
                fileId={fileId}
                session={session}
                videoTimeSeconds={videoTimeSeconds}
                videoRef={video}
            />
            <div className="reel--sidebar">
                <ReelFolderLink folderId={folderId} />
                <ReelFileMenu
                    fileId={sourceFileId ?? fileId}
                    folderId={folderId}
                />
                <ReelFrameMenu
                    sourceId={sourceFileId}
                    fileId={fileId}
                    folderId={folderId}
                />
                <ReelScreenshotButton
                    folderId={folderId}
                    sourceId={fileId}
                    video={video}
                />
                <ReelPhotoModeMenu fileId={fileId} session={session} />
                <div className="reel--details">
                    <ReelLabelEditor session={session} />
                    <ReelReportEditor session={session} />
                    {!session.readonly && (
                        <ReelTaskUI projectId={projectId} fileId={fileId} />
                    )}
                    <ReelTags fileId={fileId} session={session} />
                </div>
            </div>
            <ReelLegend fileId={fileId} session={session} />
        </div>
    );
}
