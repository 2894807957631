import { useCallback } from 'react';
import {
    LocalAspectConfig,
    LocalUserConfig,
    LocalVideoConfig,
} from './LocalConfig';
import { useLocalConfigVideoFields } from './useLocalConfigVideoFields';
import { useLocalConfigAspectFields } from './useLocalConfigAspectFields';

export interface ConfigDialogFieldsProps {
    config: LocalUserConfig;
    setConfig: (cb: (config: LocalUserConfig) => LocalUserConfig) => void;
}
export const useLocalConfigFields = ({ config, setConfig }) => {
    const setVideo = useCallback(
        (cb: (video: LocalVideoConfig) => LocalVideoConfig) =>
            setConfig((config) => ({
                ...config,
                video: cb(config.video),
            })),
        []
    );
    const setAspect = useCallback(
        (cb: (aspect: LocalAspectConfig) => LocalAspectConfig) =>
            setConfig((config) => ({
                ...config,
                aspect: cb(config.aspect),
            })),
        []
    );
    return [
        useLocalConfigAspectFields({
            aspect: config.aspect,
            setAspect,
        }),
        useLocalConfigVideoFields({
            video: config.video,
            setVideo,
            disable: false,
            showSliceSwitch: true,
        }),
    ];
};
