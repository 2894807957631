import React, { FC } from 'react';
import { UserList } from './user/UserList';
import { UserInviteModal } from './user/UserInviteModal';

type Prop = {};

export const UserView: FC<Prop> = () => {
    return (
        <div className="app-main user">
            <div className="header">
                <h2 className="header-name">メンバー</h2>
                <UserInviteModal />
            </div>
            <UserList />
        </div>
    );
};
