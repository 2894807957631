import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ROOT_FOLDER_NAME } from '../../consts';

const Separator = () => <span className="crumb-separator">/</span>;

export const Breadcrumbs = (props) => {
    const { folder, showCurrent = false } = props;
    const { breadcrumbs = [] } = folder;

    const ROOT = {
        folderId: undefined,
        foldeName: undefined,
        folderName: ROOT_FOLDER_NAME,
    };
    const list = [ROOT].concat(breadcrumbs);

    return (
        <ul className="breadcrumbs">
            {list.map((parent, i) => {
                // console.log(parent);
                // TODO: fix API
                const { folderId, foldeName, folderName } = parent;
                const name = foldeName || folderName;
                const root = i === 0;
                const current = i === list.length - 1;
                return (
                    (!current || showCurrent) && (
                        <li
                            className={classNames('crumb', { root, current })}
                            key={i}
                        >
                            {!current && (
                                <Link to={`/app/folderlist/${folderId || ''}`}>
                                    <span className="name">{name}</span>
                                </Link>
                            )}
                            {current && <span className="name">{name}</span>}
                            {!current && <Separator />}
                        </li>
                    )
                );
            })}
        </ul>
    );
};
