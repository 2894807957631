import React, { useCallback } from 'react';
import { LocalVideoConfig } from './LocalConfig';
import { NumberField } from '../atomic/forms';
import { CheckBox, SlideSwitch } from '../atomic/switches';

export interface LocalConfigVideoFieldsProps {
    video: LocalVideoConfig;
    setVideo: (cb: (opts: LocalVideoConfig) => LocalVideoConfig) => void;
    disable: boolean;
    showSliceSwitch: boolean;
}
export const useLocalConfigVideoFields = ({
    video,
    setVideo,
    disable,
    showSliceSwitch,
}: LocalConfigVideoFieldsProps) => {
    const setSliceFlg = useCallback(
        (sliceFlg: boolean) => {
            setVideo((opts) => ({
                ...opts,
                sliceFlg: sliceFlg,
            }));
        },
        [setVideo]
    );

    const setSliceTime = useCallback(
        (sliceTime: string) =>
            setVideo((opts) => ({
                ...opts,
                sliceTime: parseInt(sliceTime),
            })),
        [setVideo]
    );
    const setSliceM = useCallback(
        (sliceM: string) =>
            setVideo((opts) => ({
                ...opts,
                sliceM: parseInt(sliceM),
            })),
        [setVideo]
    );
    const setUseSliceM = useCallback(
        (useSliceM: boolean) =>
            setVideo((opts) => ({
                ...opts,
                useSliceM,
            })),
        [setVideo]
    );
    const setRemoveVideo = useCallback(
        (removeVideo: boolean) =>
            setVideo((opts) => ({
                ...opts,
                removeVideo,
            })),
        [setVideo]
    );
    return (
        <>
            <div className="select-container">
                <NumberField
                    label="スライス間隔（ミリ秒）"
                    required
                    name="slice-time"
                    value={String(video.sliceTime)}
                    min={50}
                    step={10}
                    max={200000}
                    _onChange={setSliceTime}
                    disabled={disable}
                />
            </div>
            <div className="atomic checkbox-group">
                <CheckBox
                    label="一定距離以上離れたフレームのみ保存する"
                    name="aspect-enabled"
                    checked={video.useSliceM}
                    _onChange={setUseSliceM}
                    disabled={disable}
                />
            </div>
            <div className="select-container">
                <NumberField
                    label="フレームをスライスする距離の閾値（メートル）"
                    name="slice-in-m"
                    required
                    min={1}
                    max={200000}
                    step={1}
                    value={String(video.sliceM || 1)}
                    _onChange={setSliceM}
                    disabled={!video.useSliceM || disable}
                />
            </div>
            <div
                className="atomic checkbox-group"
                style={{ marginBottom: '1em' }}
            >
                <CheckBox
                    label="分割完了後に動画ファイルを削除する"
                    name="remove-video"
                    checked={video.removeVideo ?? false}
                    _onChange={setRemoveVideo}
                    disabled={disable}
                />
            </div>
            {showSliceSwitch && (
                <>
                    <h3 style={{ marginBottom: '0.5em' }}>アップロード設定</h3>
                    <div className="atomic atomic slide-switch-box">
                        <SlideSwitch
                            defaultValue={video.sliceFlg}
                            name={'動画分割'}
                            text={'動画を分割する'}
                            _onChange={setSliceFlg}
                        />
                    </div>
                </>
            )}
        </>
    );
};
