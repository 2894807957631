import './styles/index.scss';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { ENABLE_MOCK_API } from './config';
import { App } from './App';

(async () => {
    if (ENABLE_MOCK_API) {
        const { worker } = await import('./mocks/router.js');
        worker.start();
    }

    createRoot(document.getElementById('app-root')).render(
        React.createElement(App)
    );
})();
