import React from 'react';
import { useTheme, View, Text, Heading } from '@aws-amplify/ui-react';

export const AuthenticatorComponent = {
    Header() {
        const { tokens } = useTheme();
        return <View padding={tokens.space.xxxl} />;
    },
    Footer() {
        const { tokens } = useTheme();
        return (
            <View textAlign="center" padding={tokens.space.xxxl}>
                <Text color={`${tokens.colors.neutral['80']}`}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },
    SignIn: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    fontSize={tokens.fontSizes.large}
                    marginTop={tokens.space.xl}
                    marginLeft={tokens.space.xl}
                >
                    Waymark Portal ログイン
                </Heading>
            );
        },
    },
    ConfirmSignIn: {
        Header() {
            return <Heading>認証コード入力</Heading>;
        },
    },
    ResetPassword: {
        Header() {
            return <Heading>パスワード再交付</Heading>;
        },
    },
    ConfirmResetPassword: {
        Header() {
            return <Heading>パスワード再交付</Heading>;
        },
    },
};
