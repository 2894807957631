import React from 'react';
import classNames from 'classnames';
import { CompiledTag } from '../../models/ProjectMetaModel';
import { observer } from 'mobx-react-lite';

export interface ReelTagProps extends React.SVGProps<SVGPathElement> {
    tag?: CompiledTag;
    readonly?: boolean;
}
export const ReelTag = observer(
    ({ tag, readonly, className, ...rest }: ReelTagProps) => {
        if (!tag) return <></>;
        return (
            <path
                id={tag.id}
                d={tag.path}
                fill={tag.fillColor}
                stroke={tag.strokeColor}
                className={classNames(
                    'reel--tag',
                    readonly ? 'readonly' : '',
                    className
                )}
                {...rest}
            />
        );
    }
);
