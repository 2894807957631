import React, { FC } from 'react';
import { TextField, PasswordField } from '../atomic/forms';
import { Button } from '../atomic/buttons';

type Prop = {};

export const SignUpForm: FC<Prop> = () => {
    return (
        <div className="edit-forms">
            <form>
                <ul>
                    <li>
                        <TextField label="姓" onChange={(e) => {}} value="" />
                    </li>
                    <li>
                        <TextField label="名" onChange={(e) => {}} value="" />
                    </li>
                    <li>
                        <TextField
                            label="電話番号"
                            onChange={(e) => {}}
                            value=""
                        />
                    </li>
                    <li>
                        <PasswordField
                            label="仮パスワード"
                            onChange={(e) => {}}
                            value=""
                        />
                    </li>
                    <li>
                        <PasswordField
                            label="パスワード"
                            onChange={(e) => {}}
                            value=""
                        />
                    </li>
                    <li>
                        <PasswordField
                            label="パスワード(確認)"
                            onChange={(e) => {}}
                            value=""
                        />
                    </li>
                </ul>
                <div className="register-button">
                    <Button className="primary" value="">
                        完了
                    </Button>
                </div>
            </form>
        </div>
    );
};
