/**
 * Starts process in background immediately but stores error and result
 * to be retreived form the result handler.
 *
 * @param method async function that should be run immediately
 * @returns a function handler that can be called to start at a later time
 */
export function bgAsync<T>(method: () => Promise<T>): () => Promise<T> {
    let p;
    try {
        p = method().then(
            (data) => ({ data, error: null }),
            (error) => ({ error, data: null })
        );
    } catch (error) {
        p = Promise.resolve({ error });
    }
    return async () => {
        const res = await p;
        if (res.data) {
            return res.data;
        } else {
            throw res.error;
        }
    };
}
