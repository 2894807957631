import React, { useCallback, useMemo, useState } from 'react';
import { ProgressLogEntry } from '../../utils/progress';
import { UnstyledButton } from './buttons';

const zpad = (num: number) => num.toString().padStart(2, '0');
const formatDate = (d: Date = new Date()) =>
    `${d.getHours()}:${zpad(d.getMinutes())}:${zpad(d.getSeconds())}`;

const LogEntry = ({ entry }: { entry: ProgressLogEntry }) => {
    const dateText = useMemo(() => formatDate(entry.time), [entry.time]);
    return (
        <li className="progress-log-list-item" data-type={entry.state}>
            <time>{dateText}</time>
            <div>{entry.message}</div>
        </li>
    );
};

export interface ProgressBarProps {
    percent: number;
}
export const ProgressBar = ({ percent }: ProgressBarProps) => {
    percent = percent < 0 ? 0 : percent > 1.0 ? 1.0 : percent;
    return (
        <div className="atomic progress">
            <div
                className="progress-bar"
                style={{
                    width: `${
                        Math.sin((Math.PI / 2) * percent) * 50 + percent * 50
                    }%`,
                }}
            />
        </div>
    );
};

export interface ProgressFooterProps {
    logs?: ProgressLogEntry[];
    progress?: number;
}

export const ProgressFooter = ({ logs, progress }: ProgressFooterProps) => {
    const [show, setShow] = useState(false);
    const toggleLog = useCallback(() => setShow((show) => !show), []);
    return (
        <>
            {logs && (
                <div className="progress-log">
                    {show ? (
                        <UnstyledButton
                            onClick={toggleLog}
                            icon="fa-solid fa-circle-chevron-up"
                        >
                            ログを閉じる
                        </UnstyledButton>
                    ) : (
                        <UnstyledButton
                            onClick={toggleLog}
                            icon="fa-solid fa-circle-chevron-down"
                        >
                            ログを開く
                        </UnstyledButton>
                    )}
                    {show && (
                        <ul className="progress-log-list">
                            {logs?.map((entry) => (
                                <LogEntry entry={entry} key={entry.id} />
                            ))}
                        </ul>
                    )}
                </div>
            )}
            {progress !== undefined && <ProgressBar percent={progress} />}
        </>
    );
};
