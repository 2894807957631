import React, { MutableRefObject } from 'react';
import { ReelImage } from './ReelImage';
import { ReelVideo } from './ReelVideo';
import { useGet } from '../../hooks/useApi';
import { FoldersAPI } from '../../APIs/foldersApi';
import { ReelSession } from './model/ReelSession';
import { isPhoto, isVideo } from '../../models/FileModel';
import { Loading } from '../atomic/effects';
import Player from 'video.js/dist/types/player';

export type ReelFileDisplayProps = {
    fileId: string;
    session: ReelSession;
    videoTimeSeconds?: number;
    videoRef: MutableRefObject<Player | null>;
};
export const ReelFileDisplay = ({
    fileId,
    session,
    videoTimeSeconds,
    videoRef,
}: ReelFileDisplayProps) => {
    const file = useGet(FoldersAPI.get.file(fileId));
    if (file.isLoading || !file.data) {
        videoRef.current = null;
        return <div className="reel--box-container reel--mode--loading"></div>;
    }
    if (isPhoto(file.data)) {
        videoRef.current = null;
        return (
            <ReelImage fileId={fileId} src={file.data.url} session={session} />
        );
    }
    if (isVideo(file.data))
        return (
            <ReelVideo
                src={file.data.url}
                videoTimeSeconds={videoTimeSeconds ?? 0}
                ref={videoRef}
            />
        );
    return <></>;
};
