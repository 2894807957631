import React, { useEffect } from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    useMapEvents,
} from 'react-leaflet';
import Leaflet, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { ThumbImage } from '../../folderview/FolderThumbnails';
import { Link } from 'react-router-dom';
import { projectApi } from '../../../APIs/projectApi';
import { useGet } from '../../../hooks/useApi';
import { SelectField, SelectFieldOption } from '../../atomic/forms';
import { IconButton } from '../../atomic/buttons';
import { Icons } from '../../atomic/icons';
import { getProjectItemLink } from '../../Project';

export interface PointData {
    fileId: string;
    name: string;
    position: LatLng;
    //label: string;
    //file?: File;
}

export interface MapProps {
    //points: PointData[];
    points: any;
    center: LatLng;
    onChangeZoom: (zoom: number) => void;
    defaultZoom: number;
}

Leaflet.Icon.Default.imagePath =
    '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/';

interface MapPointData {
    files: any[];
    key: string;
    position: LatLng;
    show: boolean;
}

interface MapControlProp {
    points: { [key: string]: MapPointData };
    onChangeZoom: (zoom: number) => void;
}

const MapControl = ({ points, onChangeZoom }: MapControlProp) => {
    const map = useMap();
    const onChangeCenter = React.useCallback(() => {
        let lat = 0;
        let lng = 0;
        let count = 0;
        for (let key in points) {
            const { position, show } = points[key];
            if (!show) {
                continue;
            }
            lat += position.lat;
            lng += position.lng;
            count++;
        }
        if (count > 0) {
            // 最も近くにあるポイントを探す
            lat /= count;
            lng /= count;
            let d = Number.MAX_VALUE;
            const clat = lat;
            const clng = lng;

            for (let key in points) {
                const { position, show, files } = points[key];
                if (!show) {
                    continue;
                }
                files.forEach(({ metadata }) => {
                    const location = metadata?.location;
                    if (!location) {
                        return;
                    }
                    const tmp =
                        Math.pow(location.latitude - lat, 2) +
                        Math.pow(location.longitude - lng, 2);
                    if (tmp < d) {
                        d = tmp;
                        lat = location.latitude;
                        lng = location.longitude;
                    }
                });
            }
            map.panTo([lat, lng]);
        }
    }, [points, map]);

    const disabled = React.useMemo(() => {
        for (let key in points) {
            const { show } = points[key];
            if (show) {
                return false;
            }
        }
        return true;
    }, [points]);

    useMapEvents({
        zoomend: (e) => {
            onChangeZoom(map.getZoom());
        },
        zoomstart: () => {
            map.closePopup();
        },
    });

    return (
        <div className="leaflet-bottom leaflet-right">
            <div className="leaflet-control">
                <IconButton
                    icon={Icons.Center}
                    onClick={onChangeCenter}
                    disabled={disabled}
                />
            </div>
        </div>
    );
};
export const Map: React.FC<MapProps> = ({
    points,
    center,
    onChangeZoom,
    defaultZoom,
}) => {
    return (
        <MapContainer center={center} zoom={defaultZoom}>
            <TileLayer
                attribution='&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>'
                url="https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
            />
            {Object.keys(points)
                .filter((key) => points[key].show)
                .map((key) => (
                    <Marker
                        position={points[key].position}
                        key={points[key].key}
                    >
                        <FilePopUp files={points[key].files} key={key} />
                    </Marker>
                ))}
            <MapControl points={points} onChangeZoom={onChangeZoom} />
        </MapContainer>
    );
};

const FilePopUp: React.FC<any> = ({ files }) => {
    const fileId: string =
        files.find((file) => file.show === true)?.fileId ?? '';
    const [showIndex, setShowIndex] = React.useState<string>(fileId);
    const [options, setOptions] = React.useState<SelectFieldOption[]>([]);
    const file = React.useMemo(
        () => files.find(({ fileId }) => fileId === showIndex),
        [showIndex, files]
    );

    const onLeftClick = React.useCallback(() => {
        const index = options.findIndex(({ value }) => value == showIndex);
        if (index > 0) {
            setShowIndex(options[index - 1].value ?? '');
        }
    }, [options, showIndex]);

    const onRightClick = React.useCallback(() => {
        const index = options.findIndex(({ value }) => value == showIndex);
        if (index < options.length - 1) {
            setShowIndex(options[index + 1].value ?? '');
        }
    }, [options, showIndex]);

    useEffect(() => {
        const options = files
            .filter((item) => item.show)
            .map(({ fileId, fileName }) => {
                return {
                    value: fileId,
                    text: fileName,
                };
            });
        setOptions(options);
        if (
            options &&
            options.length &&
            !options.some(({ fileId }) => fileId == showIndex)
        ) {
            setShowIndex(options[0].value);
        }
    }, [files]);

    if (!showIndex || !fileId || !file) {
        return <></>;
    }

    return (
        <Popup>
            <ViewFile file={file} />
            <div className="marker-file-list">
                <IconButton
                    onClick={onLeftClick}
                    icon={Icons.Left}
                    disabled={showIndex == options[0]?.value}
                    className="no-decoration left-button"
                />
                <SelectField
                    options={options}
                    value={showIndex}
                    _onChange={setShowIndex}
                />
                <IconButton
                    onClick={onRightClick}
                    icon={Icons.Right}
                    disabled={showIndex == options[options.length - 1]?.value}
                    className="no-decoration right-button"
                />
            </div>
        </Popup>
    );
};

const ViewFile: React.FC<any> = ({ file }) => {
    const projectHash = {};
    file?.projects?.forEach((_project) => {
        if (projectHash[_project.aiAnalysisTypeId]) {
            const tags = [
                ..._project.tags,
                ...projectHash[_project.aiAnalysisTypeId].tags,
            ];
            projectHash[_project.aiAnalysisTypeId].tags = tags;
        } else {
            projectHash[_project.aiAnalysisTypeId] = _project;
        }
    });

    return (
        <div className="view-file">
            <div className="file-info">
                <dl>
                    <dt>座標:</dt>
                    <dd>
                        {file.metadata.location.latitude},
                        {file.metadata.location.longitude}
                    </dd>
                    {Object.keys(projectHash).map((aiTypeId) => (
                        <ViewTags
                            file={file}
                            project={projectHash[aiTypeId]}
                            key={aiTypeId}
                        />
                    ))}
                </dl>
                <div>
                    <Link to={file.url} target="blank">
                        <div className="thumb-image">
                            <ThumbImage src={file.thumbUrl} sizePx={800} />
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

const ViewTags: React.FC<any> = ({ file, project }) => {
    const { data: aiAnalysisTypes } = useGet(projectApi.get.aiAnalysisTypes);
    const getAiType = (aiAnalysisTypeId) => {
        if (aiAnalysisTypes) {
            const _aiTypes = aiAnalysisTypes.data.filter(
                (type) => type.aiAnalysisTypeId === aiAnalysisTypeId
            );
            if (_aiTypes) {
                return _aiTypes[0];
            }
        }
        return null;
    };

    const getAiTypeName = (aiAnalysisTypeId) => {
        const aiType = getAiType(aiAnalysisTypeId);
        return aiType ? aiType.nameJa : null;
    };

    if (!aiAnalysisTypes) {
        return <></>;
    }

    const tagsHash = {};
    project.tags.forEach((tag) => {
        tagsHash[tag.type] = tag;
    });

    if (!Object.keys(tagsHash).length) {
        return <></>;
    }

    return (
        <>
            <dt>
                <Link
                    to={getProjectItemLink({
                        folderId: file.folderId,
                        projectId: project.projectId,
                        fileId: file.fileId,
                    })}
                    target="_blank"
                >
                    {getAiTypeName(project.aiAnalysisTypeId)}
                </Link>
                :
            </dt>
            <dd className="ai-type-list">
                <ul>
                    {Object.keys(tagsHash).map((key, index) => (
                        <ViewTag
                            key={index}
                            tag={tagsHash[key]}
                            aiType={getAiType(project.aiAnalysisTypeId)}
                        />
                    ))}
                </ul>
            </dd>
        </>
    );
};

const ViewTag: React.FC<any> = ({ tag, aiType }) => {
    const _label = aiType.labels.find((_tag) => tag.type === _tag.id);
    const tagName = _label ? _label.nameJa : null;
    return <li>{tagName}</li>;
};
