import { IComputedValue, computed, createAtom } from 'mobx';

const AppKey = 'JIW';

export const save = (data?: any, touch = true) => {
    if (typeof data === 'object' && data !== null) {
        if (touch) {
            data = {
                ...data,
                __meta: {
                    ...data.__meta,
                    time: Date.now(),
                },
            };
        }
    }
    if (data) {
        localStorage.setItem(AppKey, JSON.stringify(data));
    } else {
        localStorage.removeItem(AppKey);
    }
};

export const load = () => {
    const data = localStorage.getItem(AppKey);
    if (!data) return {};
    try {
        return JSON.parse(data);
    } catch (err) {
        return {};
    }
};

export const patch = (data) => {
    const ls = load();
    save({ ...ls, ...data });
    return load();
};

export interface LocalCodec<Type> {
    parse: (input: string | null) => Type;
    stringify: (input: Type) => string | null;
}

export function createLocalBox<Type>(
    key: string,
    codec: LocalCodec<Type>
): IComputedValue<Type> {
    let raw: string | null = localStorage.getItem(key);
    let data: Type = codec.parse(raw);
    const update = (newRaw: string | null) => {
        if (newRaw === raw) {
            return false;
        }
        raw = newRaw;
        data = codec.parse(raw);
        return true;
    };
    const listener = () => {
        if (update(localStorage.getItem(key))) {
            atom.reportChanged();
        }
    };
    const atomName = `localstoragebox#${key}`;
    const atom = createAtom(
        atomName,
        () => window.addEventListener('storage', listener),
        () => window.removeEventListener('storage', listener)
    );
    return {
        get() {
            if (!atom.reportObserved()) {
                listener();
            }
            return data;
        },
        set(newData: Type) {
            if (update(codec.stringify(newData))) {
                if (raw === null) {
                    localStorage.removeItem(key);
                } else {
                    localStorage.setItem(key, raw);
                }
                data = newData;
                atom.reportChanged();
            }
        },
    };
}

export const remove = (key) => {
    let data = load();
    delete data[key];
    save(data);
};
