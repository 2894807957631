import React from 'react';
import { FolderLink } from '../atomic/links';

export interface ReelFolderLinkProps {
    folderId: string;
}
export const ReelFolderLink = ({ folderId }: ReelFolderLinkProps) => {
    return (
        <div className="reel--folder">
            <div className="folder-name">
                <FolderLink folderId={folderId} />
            </div>
        </div>
    );
};
