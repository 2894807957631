import { safeUrl } from './safeUrl';

export function toDataUri(text: string | Blob, mimeType: string = 'text/json') {
    if (text instanceof Blob) {
        return (window.URL || window.webkitURL).createObjectURL(text);
    }
    return `data:${mimeType};charset=utf-8,${safeUrl`${text}`}`;
}

export function downloadJSON(fileName: string, json: any) {
    downloadText(fileName, JSON.stringify(json, null, 2), 'text/json');
}

export function downloadText(
    fileName: string,
    text: string | Blob,
    mimeType: string = 'text/text'
) {
    const a = document.createElement('a');
    a.setAttribute('href', toDataUri(text, mimeType));
    a.setAttribute('download', fileName);
    a.click();
}
