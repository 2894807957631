import React, { ReactNode, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AxiosError, isAxiosError } from 'axios';

export interface ErrorPanelExProp {
    to: string;
    linkText: string;
    className?: string;
    children?: ReactNode | ReactNode[];
}
export const ErrorPanelEx = (props: ErrorPanelExProp) => {
    const { to, linkText, className, children } = props;
    return (
        <div className={classNames('app-main error', className)}>
            <div className="error-icon">
                <i className="fa-solid fa-triangle-exclamation" />
            </div>
            <div className="error-display-content">
                <div className="inner-wrapper">{children}</div>
            </div>
            <div className="bottom">
                <Link to={to}>
                    <p className="back-link">
                        {linkText}
                        <i className="fa-solid fa-turn-up" />
                    </p>
                </Link>
            </div>
        </div>
    );
};

interface ErrorPanel {
    className?: string;
    children?: ReactNode[] | ReactNode;
}
const ErrorPanel = (props: ErrorPanel) => {
    const { className, children } = props;
    return (
        <ErrorPanelEx to="/" linkText="トップへ戻る" className={className}>
            {children}
        </ErrorPanelEx>
    );
};

export const NotFound = () => {
    return (
        <ErrorPanel className="http-404 not-found">
            <p>ご指定のページは見つかりませんでした</p>
        </ErrorPanel>
    );
};

export interface UnhandledErrorProps {
    error: {
        message: string;
    };
}
export const UnhandledError = ({ error }: UnhandledErrorProps) => {
    useEffect(() => {
        console.error('unhandled error:', error);
    }, [error]);
    return (
        <ErrorPanel className="unhandled">
            <p>
                Unhandled Error:
                <span className="message">"{error.message}"</span>
            </p>
            <p>(Should never apper in production!)</p>
        </ErrorPanel>
    );
};

export interface ErrorDisplayProps {
    error: Error | AxiosError | string;
}
export const ErrorDisplay = ({ error }: ErrorDisplayProps) => {
    console.warn(error);

    if (typeof error === 'string') {
        error = new Error(error);
    }

    if (!isAxiosError(error)) {
        return <UnhandledError error={error} />;
    }

    const { response } = error;
    if (!response) {
        return <UnhandledError error={error} />;
    }

    const { status } = response;
    if (!status) {
        return <UnhandledError error={error} />;
    }

    if (status == 404) {
        return <NotFound />;
    }

    return <UnhandledError error={error} />;
};
