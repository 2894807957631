import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { SHOW_EXTRA_INFO_IN_HEADER } from '../../config';
import { Icon, UserIcon, AdminUserIcon } from '../atomic/icons';

import { DevInfo, EnvRibbon } from './DevExtras';
import { NavBar } from './NavBar';
import { useAuth } from '../../contexts/AuthContext';
import { useFetchUserSelf } from '../../hooks/useUser';
import { useClickable } from '../../hooks/useClickable';
import { useTenant } from '../../contexts/TenantContext';

const isDev = SHOW_EXTRA_INFO_IN_HEADER;

const _dummyNotifications = [
    // {
    //     level: 'info',
    //     title: 'デザインについて',
    //     text: '現在のデザインは限りなくワイヤーフレームに近いものです。近日中に刷新します。',
    // },
];

const setPageTitle = () => {
    const $title = document.querySelector('head title');
    const loc = new URL(location.href);
    const firstPath = loc.pathname.split('/')[1];
    if (!$title) return;
    $title.textContent =
        'Waymark Portal' + (firstPath ? ` - ${firstPath}` : '');
};

const HeaderMenu = ({ onClose }) => {
    const { isExternalUser, isSuperAdmin } = useAuth();
    const { isExternalApi } = useTenant();
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    width: '100vw',
                    height: '100vh',
                    boxSizing: 'border-box',
                    padding: 0,
                    margin: 0,
                    top: 0,
                    left: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                }}
                onClick={onClose}
            />
            <div className="header-menu">
                <ul className="menu-list">
                    <li className="menu-item">
                        <Link to="/app/profile" onClick={onClose}>
                            プロフィール
                        </Link>
                    </li>
                    {!isExternalUser() && !isSuperAdmin() && (
                        <li className="menu-item">
                            <Link to="/app/config" onClick={onClose}>
                                個人設定
                            </Link>
                        </li>
                    )}
                    {isExternalApi && (
                        <li className="menu-item">
                            <Link to="/app/developer" onClick={onClose}>
                                開発者機能
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};
export const Header = () => {
    const { fetchUser, signOut, isAdmin } = useAuth();
    const [showMenu, setShowMenu] = React.useState<boolean>(false);
    const userInfo = useFetchUserSelf(fetchUser());
    const admin = isAdmin();

    const routerLocation = useLocation();
    React.useEffect(() => {
        setPageTitle();
    }, [routerLocation]);

    const notifications = _dummyNotifications;
    const showNotifications = notifications && notifications.length > 0;

    const toggleMenu = useCallback(
        () => setShowMenu((prevState) => !prevState),
        []
    );
    const menuButton = useClickable(toggleMenu, []);
    const logoutButton = useClickable(signOut, []);
    return (
        <header className={classNames({ admin })}>
            <div className="header-main">
                {isDev && <EnvRibbon />}
                <Link to="/app/">
                    <div className="header-title">
                        <img
                            src="/assets/JIW_icon.png"
                            className="header-logo"
                        />
                        <span>WAYMARK PORTAL</span>
                    </div>
                </Link>
                {isDev && <DevInfo />}
                {showNotifications && (
                    <div className="notifications">
                        <Icon icon="fa-solid fa-triangle-exclamation" />
                        {notifications.length}件のお知らせ
                    </div>
                )}
                {userInfo && (
                    <div className="user">
                        <div className="user-info" {...menuButton}>
                            {admin && <AdminUserIcon />}
                            {!admin && <UserIcon />}
                            <span className="username">
                                {userInfo.firstName} {userInfo.lastName}
                            </span>
                            {admin && ' (管理者)'}
                        </div>
                        {showMenu && <HeaderMenu onClose={toggleMenu} />}
                        <div className="logout" {...logoutButton}>
                            <i className="fa-solid fa-right-from-bracket" />
                        </div>
                    </div>
                )}
            </div>
            {admin && <NavBar />}
        </header>
    );
};
