/**
 * このファイルはサーバーにもクライアントにも必要です。
 * This file needs to exist both on the server and client.
 * 同期にしてください。
 * Please keep in sync.
 */
/**
 * PHP の array_filter 関数は配列を調整する時に JS と違って配列からアイテムを減らすだけではなくて、
 * 配列 → オブジェクトに変化しています。この関数で PHP を使って壊れたデータストラクチャーをまた配列に
 * 書き直しています。
 *
 * ```js
 * ['a', 'b', 'c'].filter(val => val != 'b'): // ['a', 'c']
 * ```
 *
 * ```php
 * array_filter(['a', 'b', 'c'], fn ($val) => $val != 'b'); // ['0'=>'a', '2'=>'c']
 * ```
 *
 * @template T
 * @param {import("./fixPHPArrayFilter").PHPFilterBugArray<T>} input
 * @returns {T[]}
 */
export function fixPHPArrayFilter(input) {
    if (Array.isArray(input)) return input;
    return Object.keys(input)
        .sort()
        .map((key) => input[key]);
}
