import React, {
    useRef,
    useEffect,
    useCallback,
    forwardRef,
    ForwardedRef,
    useState,
} from 'react';
import { fillRef } from '../../utils/fillRef';
import { formatVideoTime } from '../../utils/formatVideoTime';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import 'video.js/dist/video-js.min.css';

videojs.time.setFormatTime(formatVideoTime);

const options = {
    // https://videojs.com/guides/options/
    autoplay: false,
    controls: true,
    preload: 'auto',
    children: {
        // https://videojs.com/guides/components/
        MediaLoader: true,
        PosterImage: true,
        TextTrackDisplay: true,
        LoadingSpinner: true,
        // BigPlayButton: true,
        ControlBar: {
            children: {
                PlayToggle: true,
                ProgressControl: true,
                CurrentTimeDisplay: true,
                TimeDivider: true,
                DurationDisplay: true,
                VolumePanel: true,
                FullscreenToggle: true,
            },
        },
        ErrorDisplay: true,
        TextTrackSettings: true,
        ResizeManager: true,
    },
};

export interface ReelVideoProps {
    src: string;
    videoTimeSeconds: number;
}

// https://videojs.com/guides/react/
export const ReelVideo = forwardRef(
    ({ src, videoTimeSeconds }: ReelVideoProps, ref: ForwardedRef<Player>) => {
        const videoRef = useRef<HTMLDivElement | null>(null);
        const playerRef = useRef<Player | null>(null);
        const [loaded, setLoaded] = useState(false);
        const onReady = useCallback((player: Player) => {
            // Player ready...
            player.muted(true);
            player.on('loadeddata', () => {
                setLoaded(true);
            });
        }, []);
        useEffect(() => {
            const player = playerRef.current;
            if (!player) return;
            if (!loaded) return;
            player.hasStarted(true);
            player.controls(true);
        }, [playerRef.current, src, loaded]);

        useEffect(() => {
            if (!videoRef.current) return;
            // Make sure Video.js player is only initialized once
            if (!playerRef.current) {
                // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
                const videoElement = document.createElement('video');
                videoElement.setAttribute('type', 'video/mp3');
                videoElement.setAttribute('crossorigin', 'anonymous');
                if (videoTimeSeconds) {
                    videoElement.currentTime = videoTimeSeconds / 1000;
                }
                videoRef.current.appendChild(videoElement);

                const player = videojs(
                    videoElement,
                    {
                        ...options,
                        sources: [{ src }],
                    },
                    () => onReady(player)
                );
                playerRef.current = player;
            } else {
                const player = playerRef.current;
                setLoaded(false);
                if (player.currentSrc() !== src) {
                    player.src(src);
                } else {
                    player.currentTime(0);
                }
            }
        }, [ref, src, videoTimeSeconds]);
        useEffect(() => {
            fillRef(ref, playerRef.current);
        }, [ref, playerRef.current]);

        // Dispose the Video.js player when the functional component unmounts
        useEffect(
            () => () => {
                const player = playerRef.current;
                if (player && !player.isDisposed()) {
                    player.dispose();
                    playerRef.current = null;
                }
            },
            []
        );

        return (
            <div className="reel--box-container reel--mode--video">
                <div
                    data-vjs-player
                    className="reel--video video-js"
                    ref={videoRef}
                />
            </div>
        );
    }
);
