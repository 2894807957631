import classNames from 'classnames';
import React, { useRef } from 'react';
import { useSize } from '../../hooks/useSize';
import { useLocalState } from './model/LocalState';
import { ReelTag } from './ReelTag';
import { ReelTagFocusRect } from './ReelTagFocusRect';
import { ReelAnchors } from './ReelAnchors';
import { ReelMode, ReelSession } from './model/ReelSession';
import { observer } from 'mobx-react-lite';

type TagOverlaysProps = {
    session: ReelSession;
};
const TagOverlays = observer(({ session }: TagOverlaysProps) => (
    <>
        {session.inactiveTags.map((tag) => (
            <ReelTag
                key={tag.id}
                tag={tag}
                onMouseOver={session.onTagPathOver}
                onMouseOut={session.onTagPathOut}
                readonly={session.readonly}
            />
        ))}
        <ReelTag
            tag={session.focussedTag}
            className="reel--tag--focussed"
            onMouseDown={session.onRectCenterDrag}
            readonly={session.readonly}
        />
        <ReelTag
            tag={session.hoveredTag}
            className="reel--tag--focussed"
            onMouseOver={session.onTagPathOver}
            onMouseOut={session.onTagPathOut}
            readonly={session.readonly}
        />
        <ReelTagFocusRect tag={session.hoveredTag} />
    </>
));

export interface ReelImageProps {
    fileId: string;
    src: string;
    session: ReelSession;
}
export const ReelImage = observer(
    ({ fileId, src, session }: ReelImageProps) => {
        const boxContainer = useRef<HTMLDivElement | null>(null);
        const localState = useLocalState();
        const tempSelection = localState.tempSelection;
        useSize(boxContainer, session.updateBounds);
        return (
            <div
                className={classNames(
                    'reel--box-container',
                    `reel--mode--${session.photoMode}`,
                    `reel--anchor--${localState.resize?.dir ?? 'none'}`
                )}
                ref={boxContainer}
                onWheel={session.onWheel}
            >
                {boxContainer.current && (
                    <svg
                        className="reel--box"
                        view-box={`0 0 ${session.bounds.width} ${session.bounds.height}`}
                        onMouseUp={session.onUp}
                        onMouseDown={session.onDown}
                        onMouseMove={session.onMove}
                        onMouseLeave={session.onUp}
                    >
                        <image
                            key={fileId}
                            href={src}
                            onLoad={session.imgLoad}
                            transform={`translate(${session.rect.x} ${session.rect.y}) scale(${session.zoom}) `}
                        />
                        {session.showTags && <TagOverlays session={session} />}
                        {session.inverseSelectionPath && (
                            <path
                                className="reel--dark"
                                d={session.inverseSelectionPath}
                            />
                        )}
                        {tempSelection && (
                            <>
                                <rect
                                    className="reel--temp-selection--bright"
                                    x={tempSelection.x}
                                    y={tempSelection.y}
                                    width={tempSelection.width}
                                    height={tempSelection.height}
                                />
                                <rect
                                    className="reel--temp-selection--dark"
                                    x={tempSelection.x + 1}
                                    y={tempSelection.y + 1}
                                    width={tempSelection.width}
                                    height={tempSelection.height}
                                />
                            </>
                        )}
                        {session.photoMode === ReelMode.edit &&
                            session.compiledPoints &&
                            session.focussedTag &&
                            session.compiledPoints.map((point) => (
                                <line
                                    className="reel--tag--point-add"
                                    key={point.id}
                                    x1={point.x}
                                    y1={point.y}
                                    x2={point.next.x}
                                    y2={point.next.y}
                                    onMouseDown={point.addPoint}
                                />
                            ))}
                        {session.photoMode === ReelMode.edit &&
                            session.compiledPoints &&
                            session.compiledPoints.map((point) => (
                                <circle
                                    className="reel--point"
                                    key={point.id}
                                    cx={point.x}
                                    cy={point.y}
                                    r="5"
                                    onMouseDown={point.dragPoint}
                                />
                            ))}
                        {session.photoMode === 'navigate' && (
                            <ReelAnchors
                                tag={session.focussedTag}
                                onDrag={session.onRectCornerDrag}
                            />
                        )}
                    </svg>
                )}
            </div>
        );
    }
);
