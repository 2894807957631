import { createMutation, fakeAxiosSuccess } from '../../hooks/useApi';
import { FolderModel } from '../../models/FolderModel';
import { checkAbort, isAbortError } from '../../utils/abort';
import { CSVModel, createCSVBlob } from '../../utils/csv';
import { downloadText } from '../../utils/download';
import {
    createDirectory,
    directoryPicker,
    downloadFile,
    saveFile,
} from '../../utils/filesystem';
import { ProgressController } from '../../utils/progress';
import { Queue } from '../../utils/queue';

export interface ExportReportRequest {
    csvBlob: Blob;
    downloadFiles: boolean;
    folder: FolderModel;
    activeFiles: { fileId: string; fileName: string; fileUrl: string }[];
    projectName: string;
}
export interface ExportReportResponse {
    success: boolean;
}

export const exportReport = createMutation<
    ExportReportRequest,
    ExportReportResponse
>(async (_apis, request, opts) => {
    const { csvBlob, downloadFiles, activeFiles, folder, projectName } =
        request;
    const progress = new ProgressController(opts);
    if (!csvBlob) {
        throw new Error('CSVデータがありません');
    }

    if (downloadFiles) {
        checkAbort(opts);
        const dirHandle = await directoryPicker();
        const files: CSVModel<'fileId' | 'fileName'> = {
            header: [
                { id: 'fileId', text: 'ファイルID' },
                { id: 'fileName', text: 'ファイル名' },
            ],
            list: [],
        };
        const len = activeFiles.length + 3;
        const step = 1 / len;
        const progressBlock = async (
            callback: (progress: ProgressController, message?: string) => void,
            message?: string
        ) => {
            await callback(progress.subsection(step), message ?? '');
        };
        try {
            checkAbort(opts);
            const rootHandle = await createDirectory(dirHandle, 'report');
            await progressBlock(async (progress) => {
                progress.report(0, 'レポートを保存中...');
                await saveFile(rootHandle, 'report.csv', csvBlob);
                progress.report(1, 'レポートを保存しました');
            });

            checkAbort(opts);
            const filesHandle = await createDirectory(rootHandle, 'files');

            const queue = new Queue({ concurrency: 5, signal: opts.signal });
            for (let file of activeFiles) {
                queue.add(() =>
                    progressBlock(async (report) => {
                        const fileName: string = await downloadFile(
                            filesHandle,
                            file.fileUrl,
                            file.fileName,
                            file.fileName,
                            report
                        );
                        files.list.push({
                            fileId: file.fileId,
                            fileName,
                        });
                    })
                );
            }
            await queue.finish().catch((err) => {
                if (isAbortError(err)) return;
                console.warn(err);
            });

            files.list.sort((a, b) =>
                String(a.fileId).localeCompare(String(b.fileId))
            );
            checkAbort(opts);
            await progressBlock(async (progress) => {
                progress.report(0, 'ファイル一覧を保存中...');
                await saveFile(filesHandle, 'files.csv', createCSVBlob(files));
                progress.report(1, 'ファイル一覧を保存しました');
            });

            if (folder) {
                const folderCSV: CSVModel = {
                    header: [
                        {
                            id: 'primary',
                            text: '大項目',
                        },
                        {
                            id: 'secondary',
                            text: '中項目',
                        },
                        {
                            id: 'tertiary',
                            text: '小項目',
                        },
                        {
                            id: 'value',
                            text: '値',
                        },
                    ],
                    list: [
                        {
                            primary: '基本情報',
                            secondary: 'フォルダ名',
                            value: folder?.folderName ?? '',
                        },
                        {
                            primary: '基本情報',
                            secondary: 'プロジェクト名',
                            value: projectName,
                        },
                    ],
                };
                folder?.tags?.forEach(({ tagName, values }) => {
                    values.forEach(({ tagTypeName, value }) => {
                        folderCSV.list.push({
                            primary: '属性情報',
                            secondary: tagName,
                            tertiary: tagTypeName,
                            value: value ?? '',
                        });
                    });
                });

                checkAbort(opts);
                await progressBlock(async (progress) => {
                    progress.report(0, 'フォルダ情報を保存中...');
                    await saveFile(
                        rootHandle,
                        'folder.csv',
                        createCSVBlob(folderCSV)
                    );
                    progress.report(1, 'フォルダ情報を保存しました');
                });
            }
        } catch (error) {
            progress.onError(error);
        }
    } else {
        try {
            downloadText('report.csv', csvBlob, 'text/csv');
        } catch (err) {
            console.warn(err);
        }
    }
    return fakeAxiosSuccess({ success: true });
});
