import { RefObject, useEffect, useState } from 'react';

export interface Size {
    width: number;
    height: number;
}

export const useSize = (
    ref: RefObject<HTMLElement | null>,
    onChange?: (size: Size) => void
): Size => {
    let [lastUpdate, setLastUpdate] = useState(0);
    const [{ size, update }] = useState(() => {
        const size = {
            width: 0,
            height: 0,
        };
        return {
            size,
            update() {
                let newWidth = ref.current?.offsetWidth ?? 0;
                let newHeight = ref.current?.offsetHeight ?? 0;
                if (newWidth === size.width && newHeight === size.height) {
                    return;
                }
                size.width = newWidth;
                size.height = newHeight;
                onChange?.(size);
                lastUpdate += 1;
                setLastUpdate(lastUpdate);
            },
        };
    });
    useEffect(() => {
        update();
        if (!ref.current) return;
        window.addEventListener('resize', update);
        return () => window.removeEventListener('resize', update);
    }, [ref.current, onChange]);
    return size;
};
