import React from 'react';
import { Table, columnDefiner } from '../../atomic/table';
import { KintoneApp } from '../../../models/TenantModel';
import { IconButton } from '../../atomic/buttons';
import { Link } from 'react-router-dom';
import { Icon } from '../../atomic/icons';
import { AdminKintoneAppFolderList } from './AdminKintoneAppFolderList';
import { getKintoneAppFolders } from '../../../APIs/foldersApi';

interface AppData extends KintoneApp {
    trClass: string;
}

const definer = columnDefiner<AppData>();
const columns = [
    definer.accessor('appId', {
        header: 'AppId',
        cell: (d) => {
            const { appId } = d.row.original;
            return (
                <>
                    <Link to={`/app/admin/kintone/app/${appId}/edit`}>
                        <Icon icon="fa-solid fa-pencil"></Icon>
                        {appId}
                    </Link>
                </>
            );
        },
    }),
    {
        header: '詳細',
        cell: (d: { row: { original: { appDescription: any } } }) => {
            const appDescription =
                d.row.original.appDescription == null
                    ? ''
                    : d.row.original.appDescription;
            return `${appDescription}`;
        },
    },
    {
        header: '連携先フォルダ',
        cell: (d: {
            row: {
                original: {
                    items: any;
                    appId: any;
                };
            };
        }) => {
            const folders = d.row.original.items ? d.row.original.items : [];
            const appId = d.row.original.appId;
            return (
                <AdminKintoneAppFolderList appId={appId} folders={folders} />
            );
        },
    },
    {
        header: '削除',
        cell: (d: {
            row: { original: { appId: any } };
            table: { options: { onRemoveApp: any } };
        }) => {
            const { appId } = d.row.original;
            const { onRemoveApp } = d.table.options;
            return (
                <IconButton
                    icon="fa-solid fa-trash"
                    onClick={() => {
                        onRemoveApp(Number(appId));
                    }}
                ></IconButton>
            );
        },
    },
];

type AdminKintoneTableProp = {
    apps;
    setApps;
    onRemoveApp;
};

export const AdminKintoneTable: React.FC<AdminKintoneTableProp> = ({
    apps,
    setApps,
    onRemoveApp,
}) => {
    const options = {
        onRemoveApp,
    };

    React.useEffect(() => {
        const ids = apps.map((app) => app.appId);
        if (ids.length) {
            getKintoneAppFolders(ids)
                .then((resp) => {
                    if (resp.success) {
                        const appFolders = resp.data;
                        const newApps = apps.map((app) => {
                            const item = appFolders.map((_app) => {
                                if (_app.appId.toString() == app.appId) {
                                    app.items = _app.items;
                                }
                            });
                            return app;
                        });
                        setApps(newApps);
                    } else {
                        console.warn(resp);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                })
                .finally(() => {});
        }
    }, []);

    return (
        <>
            <Table
                className="app-table"
                data={apps}
                columns={columns}
                options={options}
            />
        </>
    );
};
