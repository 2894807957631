import classNames from 'classnames';
import React from 'react';

import { Icon } from '../atomic/icons';
import { ButtonGroup, Button } from '../atomic/buttons';
import { PAGER_LIMITS } from '../../consts';
import { useClickable } from '../../hooks/useClickable';

const Shifter = (props) => {
    const { onClick, prev, next, disabled } = props;
    return (
        <div
            className={classNames('page-shifter', { disabled, prev, next })}
            {...useClickable(disabled ? null : onClick, [disabled, onClick])}
        >
            <Icon
                icon={classNames('fa-solid', {
                    'fa-chevron-left': prev,
                    'fa-chevron-right': next,
                })}
            />
        </div>
    );
};

const Jumper = (props) => {
    const { onClick, disabled, first, last } = props;
    return (
        <div
            className={classNames('page-jumper', { disabled, first, last })}
            {...useClickable(disabled ? null : onClick, [disabled, onClick])}
        >
            <Icon
                icon={classNames('fa-solid', {
                    'fa-angles-left': first,
                    'fa-angles-right': last,
                })}
            />
        </div>
    );
};

const LimitSelector = (props) => {
    const { current, onChange } = props;
    return (
        <div className="pager-limit-selector">
            <label>表示数:</label>
            <ButtonGroup value={current} onChange={onChange}>
                {PAGER_LIMITS.map((limit, i) => {
                    return (
                        <Button value={limit} key={i}>
                            {limit}件
                        </Button>
                    );
                })}
            </ButtonGroup>
        </div>
    );
};

interface PageItemProps {
    page: {
        index: number;
        page: number;
        current: boolean;
    };
    setPage: (index: number) => void;
}

const PageItem = ({
    page: { index, page, current },
    setPage,
}: PageItemProps) => {
    return (
        <li
            className={classNames('page', { current })}
            key={index}
            {...useClickable(current ? undefined : () => setPage(index), [
                setPage,
                index,
            ])}
        >
            {page}
        </li>
    );
};

export const Pager = (props) => {
    const { pagination, setPage, setLimit } = props;
    const { page, total, limit, pageCount, hasPreviousPage, hasNextPage } =
        pagination;
    const pageLen = Math.ceil(total / limit);
    let pages = Array.from(Array(pageLen)).map((_, index) => ({
        key: `${index}/${page}`,
        index,
        page: index + 1,
        current: index === page - 1,
    }));
    if (!pages.find(({ current }) => current) && page > 0) {
        pages.push({
            key: `${page - 1}/${page}`,
            index: page - 1,
            page: page,
            current: true,
        });
    }
    pages = pages.filter(({ index }) => Math.abs(page - index - 1) < 5);
    const onLimitChange = (ev) => {
        setPage(0);
        setLimit(ev.target.value);
    };
    if (!pageCount && !hasPreviousPage && !hasNextPage) {
        return <></>;
    }
    return (
        <div className="pager">
            <div className="pager-left spacer" />
            <div className="pager-center">
                <Jumper
                    first
                    disabled={!hasPreviousPage}
                    onClick={() => setPage(0)}
                />
                <Shifter
                    prev
                    current={page}
                    disabled={!hasPreviousPage}
                    onClick={() => setPage(page - 2)}
                />
                <ul className="page-list">
                    {pages.map((page) => (
                        <PageItem
                            key={page.key}
                            page={page}
                            setPage={setPage}
                        />
                    ))}
                </ul>
                <Shifter
                    next
                    current={page}
                    disabled={!hasNextPage}
                    onClick={() => setPage(page)}
                />
                <Jumper
                    last
                    disabled={!hasNextPage}
                    onClick={() => setPage(pageLen - 1)}
                />
            </div>
            <div className="pager-right">
                <LimitSelector current={limit} onChange={onLimitChange} />
            </div>
        </div>
    );
};
