import { FolderViewTypes } from '../components/folderview/consts';
import { load, patch } from './LocalStrageUtils';

type UserPreferences = {
    folderViewType?: FolderViewTypes;
    showTagDetailsInSearchResults?: boolean;
};

const DEFAULT_PREFS: UserPreferences = {
    folderViewType: FolderViewTypes.THUMB,
    showTagDetailsInSearchResults: true,
};

export const gettUserPreferences = (): UserPreferences => {
    let ls = load();
    if (!ls.prefs) {
        ls = patch({ prefs: DEFAULT_PREFS });
    }
    return ls.prefs;
};

export const putUserPreferences = (prefs: UserPreferences) => {
    patch({ prefs });
};

export const patchUserPreferences = (prefs: UserPreferences) => {
    const original = gettUserPreferences();
    putUserPreferences({ ...original, ...prefs });
};
