import { HttpClient } from '../utils/HttpUilts';
import { AiAnalysisType } from '../models/AiAnalysisType';
import { AiTemplateModel, Label } from '../models/AiTemplateModel';
import {
    MutationResponse,
    createMutation,
    mutateAsync,
    simpleGet,
} from '../hooks/useApi';
import { safeUrl } from '../utils/safeUrl';
import { addTagType, addTenantTag } from './tagApi';
import { ImportTagRequest } from './tag/importTenantTag';
import { TenantTagModel } from '../models/TagModel';
import { Queue } from '../utils/queue';

export type UpdateTemplateData = {
    aiTemplateId: string;
    name?: string;
    aiAnalysisTypeId?: string;
    labels?: { updated: Array<Omit<Label, 'aiTemplateId'>> };
};

export const getTemplates = () => {
    return HttpClient.get('/ai-templates');
};

export const getAiAnalysisTypes = (): Promise<{
    success?: string;
    data: AiAnalysisType[];
}> => {
    return HttpClient.get('/ai-analysis-types');
};

export const getTemplate = (
    aiTemplateId: string
): Promise<{ data: AiTemplateModel }> => {
    return HttpClient.get('/ai-template/' + encodeURIComponent(aiTemplateId));
};

export const updateTemplate = (
    aiTemplateId: string,
    data: UpdateTemplateData
) => {
    return HttpClient.patch(
        '/ai-template/' + encodeURIComponent(aiTemplateId),
        data
    );
};

export const updateLabels = (aiTemplateId: string, labels: Array<Label>) => {
    return HttpClient.patch(
        '/ai-template/' + encodeURIComponent(aiTemplateId),
        {
            labels: {
                updated: labels,
            },
        }
    );
};

export const createLabels = (aiTemplateId: string, labels: Array<Label>) => {
    return HttpClient.patch(
        '/ai-template/' + encodeURIComponent(aiTemplateId),
        {
            aiTemplateId: aiTemplateId,
            labels: {
                created: labels,
            },
        }
    );
};

export const deleteLabels = (aiTemplateId: string, ids: Array<string>) => {
    return HttpClient.patch(
        '/ai-template/' + encodeURIComponent(aiTemplateId),
        {
            labels: {
                deleted: ids,
            },
        }
    );
};

export interface CreateTemplateRequest {
    aiAnalysisTypeId: string;
    name: string;
    labels?: {
        aiTemplateLabelId: string;
        name: string;
        order: number;
        parentAiTemplateLabelId?: string;
    }[];
}
export interface UpdateTemplateRequest {
    aiTemplateId: string;
    name?: string;
    labels: {
        updated?: {
            aiTemplateLabelId: string;
            name: string;
            order: number;
            parentAiTemplateLabelId?: string;
        }[];
        created?: {
            aiTemplateLabelId: string;
            name: string;
            order: number;
            parentAiTemplateLabelId?: string;
        }[];
        deleted?: string[];
    };
}
export const templateApi = {
    mutate: {
        createTemplate: createMutation<
            CreateTemplateRequest,
            { data: AiTemplateModel }
        >(async function ({ authenticated }, { labels, ...data }, opts) {
            let res = (await authenticated.post<{ data: AiTemplateModel }>(
                '/ai-template',
                data,
                opts
            )) as MutationResponse<{ data: AiTemplateModel }>;
            if (labels && labels.length > 0) {
                res = await mutateAsync(
                    templateApi.mutate.updateTemplate(
                        res.data.data.aiTemplateId
                    ),
                    {
                        labels: {
                            created: labels,
                        },
                    }
                );
            }
            return res;
        }),
        updateTemplate: (aiTemplateId: string) => {
            const getApi = templateApi.get.aiTemplate(aiTemplateId);
            return createMutation<
                Omit<UpdateTemplateRequest, 'aiTemplateId'>,
                { data: AiTemplateModel }
            >(
                async ({ authenticated }, data, opts) =>
                    authenticated.patch(
                        safeUrl`/ai-template/${aiTemplateId}`,
                        data,
                        opts
                    ),
                getApi
            );
        },
        importTagsMutation: createMutation<ImportTagRequest, TenantTagModel>(
            async function (
                { authenticated },
                { name, tagTypes, tenantId },
                opts
            ) {
                const res = await addTenantTag({ name, tenantId });
                if (!res.data) {
                    throw Error('属性の追加に失敗しました');
                }
                const tenantTagId = res?.data?.tenantTagId;
                if (!tenantTagId) {
                    throw Error('属性の追加に失敗しました');
                }
                const queue = new Queue({ concurrency: 5 });
                for (const tagType of tagTypes) {
                    queue.add(async () => {
                        await addTagType({
                            tenantTagId,
                            ...tagType,
                        });
                    });
                }
                await queue.finish();
                return {
                    status: 0,
                    data: res.data,
                } as MutationResponse<TenantTagModel>;
            }
        ),
    } as const,
    get: {
        aiTemplates: simpleGet<AiTemplateModel[]>`/ai-templates`,
        aiTemplate: (aiTemplateId: string) =>
            simpleGet<AiTemplateModel>`/ai-template/${aiTemplateId}`,
    } as const,
} as const;

export const deleteTemplate = (aiTemplateId: string) => {
    return HttpClient.del('/ai-template/' + encodeURIComponent(aiTemplateId));
};

export const tenantAiAnalysisTypes = (
    tenantId: string
): Promise<{ data: AiAnalysisType[] }> => {
    return HttpClient.get(`/admin/ai-analysis-types-tenants/${tenantId}`);
};
