import React, {
    createContext,
    useContext,
    useRef,
    FC,
    ReactNode,
    useState,
} from 'react';

export interface ToastContextValue {
    message: string;
    toast: (message: string, delay?: number) => void;
}
const ToastContext = createContext<ToastContextValue>({
    message: '',
    toast: () => {},
});
export const useToast = () => {
    const { toast } = useContext(ToastContext);
    return toast;
};

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [message, setMessage] = useState<string>('');
    const refTimerId = useRef<ReturnType<typeof setTimeout> | null>(null);

    return (
        <ToastContext.Provider
            value={{
                message,
                toast: (message: string, delay: number = 2000) => {
                    setMessage((prev) => {
                        if (prev != message) {
                            if (refTimerId.current) {
                                // 前のタイマを削除する
                                clearTimeout(refTimerId.current);
                                refTimerId.current = null;
                            }
                            refTimerId.current = setTimeout(() => {
                                setMessage('');
                                refTimerId.current = null;
                            }, delay);
                        }
                        return message;
                    });
                    setTimeout(() => {
                        setMessage('');
                    }, 3000);
                },
            }}
        >
            {children}
            {!!message && (
                <div className="toast-container">
                    <div className="toast">{message}</div>
                </div>
            )}
        </ToastContext.Provider>
    );
};
