import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { TenantModel } from '../../../models/TenantModel';
import { Table, columnDefiner } from '../../atomic/table';
import { reformat } from '../../../utils/DateUtils';
import { TenantEdit } from './TenantEdit';

interface TenantData extends TenantModel {
    trClass: string;
}
const definer = columnDefiner<TenantData>();

const columns = [
    definer.accessor('tenantId', {
        header: 'テナントID',
        cell: (d) => {
            const { openModal } = d.table.options; // ToDo 型の解決
            const { tenantId } = d.row.original;

            return (
                <>
                    <Link
                        to={`#`}
                        onClick={() => {
                            openModal(tenantId);
                        }}
                    >
                        <div className="tenant id">
                            <span>{tenantId}</span>
                        </div>
                    </Link>
                </>
            );
        },
    }),
    {
        header: 'テナント名',
        cell: (d) => {
            const { name } = d.row.original;
            return `${name}`;
        },
    },
    {
        header: '更新日',
        cell: (d) => {
            const { updatedAt } = d.row.original;
            return `${reformat(updatedAt)}`;
        },
    },
    {
        header: '作成日',
        cell: (d) => {
            const { createdAt } = d.row.original;
            return `${reformat(createdAt)}`;
        },
    },
];

type Prop = {
    tenants: TenantModel[];
};
export const TenantList: FC<Prop> = (props) => {
    const tenants = props.tenants;
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [tenantId, setTenantId] = React.useState<string | undefined>(
        undefined
    );
    const openModal = (tenantId: string) => {
        setTenantId(tenantId);
        setIsOpen(true);
    };
    const options = {
        openModal,
    };
    return (
        <>
            <div className="edit-forms">
                {tenantId ? (
                    <TenantEdit
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        tenantId={tenantId}
                    />
                ) : (
                    ''
                )}
                {tenants.length ? (
                    <Table
                        className="tenant-table"
                        data={tenants}
                        columns={columns}
                        options={options}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};
