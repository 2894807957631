import React, {
    FormEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
    gettUserPreferences,
    patchUserPreferences,
} from '../utils/UserPreferences';

import { FoldersAPI } from '../APIs/foldersApi';
import { getTenantTags } from '../APIs/tagApi';
import { TenantTagModel } from '../models/TagModel';

import { Loading } from './atomic/effects';
import { TextField } from './atomic/forms';
import { Button } from './atomic/buttons';
import { SlideSwitch } from './atomic/switches';
import { TagList } from './atomic/tags';
import { SearchResultFilter } from './foldersearch/SearchResultFilter';
import { SearchResultTable } from './foldersearch/SearchResultTable';
import { Pager } from './folderview/Pager';
import { PAGER_LIMITS } from '../consts';

export interface FilterStateItem {
    field: { tenantTagId: string; tagTypeId: string };
    value: string;
}

export interface FilterState {
    //key=tagTypeId
    [key: string]: FilterStateItem;
}
const splitKeywords = (str = '') =>
    str
        .split(/[\s\t　]/)
        .map((v = '') => v.trim())
        .filter((v) => v.length > 0);

interface Params {
    values: string;
    tenantTagIds: Set<string>;
}
const paramsFromQuery = (query: URLSearchParams): Params => ({
    values: (query.get('words') || '').toString(),
    tenantTagIds: new Set(query.getAll('tag')),
});
const paramsToQuery = (params: Params): URLSearchParams => {
    const query = new URLSearchParams(document.location.search);
    const words = splitKeywords(params.values).join(' ');
    const ids = new Set(params.tenantTagIds);
    let added = false;
    const entries = Array.from(query.entries())
        .filter(([key, value]) => {
            if (key === 'tag') {
                if (ids.has(value)) {
                    ids.delete(value);
                    return true;
                } else {
                    return false;
                }
            }
            if (key === 'words' && words === '') {
                return false;
            }
            return true;
        })
        .map((input) => {
            const [key, value] = input;
            if (key === 'words') {
                added = true;
                return [key, words];
            }
            return input;
        });
    if (!added && words) {
        entries.push(['words', words]);
    }
    for (const id of ids) {
        entries.push(['tag', id]);
    }
    return new URLSearchParams(entries);
};

function isEmpty(params: Params) {
    for (const tenantTagId of params.tenantTagIds) {
        return false;
    }
    return !params.values;
}

export const FolderSearch = () => {
    const [tags, setTags] = React.useState<TenantTagModel[]>([]);
    React.useEffect(() => {
        getTenantTags()
            .catch((err) => {
                console.error('failed fetching tenant tags', err);
            })
            .then((resp) => {
                if (!resp || !resp.success) return;
                setTags(resp?.data ?? []);
            });
    }, []);

    const [query, setQuery] = useSearchParams();
    const [params, setParams] = useState<Params>(() => paramsFromQuery(query));
    const queryParams = useMemo(() => paramsFromQuery(query), [query]);
    const [page, setPage] = useState<number>(0);
    const [limit, setLimit] = useState<number>(PAGER_LIMITS[0]);
    const [pagination, setPagination] = useState(null);
    const offset = useMemo(() => page * limit, [page, limit]);
    useEffect(() => setParams(queryParams), [queryParams]);

    const [searching, setSearching] = React.useState(false);
    const [folders, setFolders] = React.useState<any[]>([]); // TODO: 検索の結果は他のフォルダー API のリソースと別ですし、複雑です。まずいですが、とりあえず "any" にしています。

    const userPrefs = gettUserPreferences();
    const [detailVisible, showDetail] = React.useState(
        userPrefs.showTagDetailsInSearchResults
    );
    const onDetailViewToggle = (value) => {
        patchUserPreferences({ showTagDetailsInSearchResults: value });
        showDetail(value);
    };

    const [filters, setFilters] = React.useState<FilterState>({});
    const onFilterChange = ({ field, value }) => {
        let _new = { ...filters };
        if (value && value.length > 0) {
            _new[field.tagTypeId] = { field, value };
        } else {
            delete _new[field.tagTypeId];
        }
        setFilters(_new);
    };
    const activeTags = useMemo(
        () => tags.filter((t) => queryParams.tenantTagIds.has(t.tenantTagId)),
        [tags, queryParams]
    );

    const dummyCurrent = useMemo(() => {
        const activeTagTypes = activeTags.reduce((activeTagTypes, tag) => {
            for (const type of tag.tagTypes) {
                activeTagTypes.add(type.tagTypeId);
            }
            return activeTagTypes;
        }, new Set<string>());
        const filterTarget = Object.entries(filters).reduce(
            (obj, [, filter]) => {
                const { field } = filter;
                const { tenantTagId, tagTypeId } = field;
                if (activeTagTypes.has(tagTypeId)) {
                    obj.tags.add(tenantTagId);
                    obj.fields.add(tagTypeId);
                }
                return obj;
            },
            {
                tags: new Set<string>([]),
                fields: new Set<string>([]),
            }
        );
        const targetFields = Array.from(filterTarget.fields);
        const subFolders =
            targetFields.length === 0
                ? folders.concat()
                : folders.filter((folder) => {
                      const fields = folder.tags.map((t) => t.values).flat();
                      const matched = fields.filter((f) => {
                          const { tagTypeId, value } = f;
                          const targetValue = filters[tagTypeId]?.value;
                          return (
                              targetFields?.includes(tagTypeId) &&
                              value?.includes(targetValue)
                          );
                      });
                      return matched.length === targetFields.length;
                  });
        return {
            subFolders,
            files: [],
        };
    }, [folders, filters, activeTags]);

    const onReset = useCallback((e: FormEvent) => {
        e.preventDefault();
        setQuery(() => paramsToQuery({ tenantTagIds: new Set(), values: '' }));
        setFolders([]);
    }, []);

    const onSubmit = useCallback(
        (e?: FormEvent) => {
            e?.preventDefault();
            setQuery(() => paramsToQuery(params));
        },
        [params]
    );

    const onClickTag = useCallback(
        (tag) =>
            setQuery(() => {
                const { tenantTagIds } = params;
                const { tenantTagId: id } = tag;
                if (tenantTagIds.has(id)) {
                    tenantTagIds.delete(id);
                } else {
                    tenantTagIds.add(id);
                }
                return paramsToQuery(params);
            }),
        [params, onSubmit]
    );

    const keywords = useMemo(() => params.values, [params]);

    const setKeywords = useCallback(
        (keywords: string) =>
            setParams(({ values, ...rest }) => ({
                values: keywords,
                ...rest,
            })),
        [params, setParams]
    );

    React.useEffect(() => {
        const params = paramsFromQuery(query);
        if (isEmpty(params)) {
            // リクエストの中身が空の場合はリストをクリアする
            setFolders([]);
            return;
        }
        const { values, tenantTagIds } = params;
        setSearching(true);
        let nextSearchStarted = false;
        FoldersAPI.search({
            values: splitKeywords(values),
            tenantTagIds: Array.from(tenantTagIds),
            offset: offset,
            limit: limit,
        })
            .then((resp) => {
                if (nextSearchStarted) return false;
                const { success, data, pagination } = resp;
                if (success) {
                    setFolders(data.folders);
                    setPagination(pagination);
                }
            })
            .finally(() => {
                if (nextSearchStarted) return false;
                setSearching(false);
            });
        return () => {
            nextSearchStarted = true;
        };
    }, [query, offset, limit]);

    const pager = React.useMemo(() => {
        if (!pagination) {
            return <></>;
        }
        return (
            <Pager
                pagination={pagination}
                setPage={setPage}
                setLimit={setLimit}
            />
        );
    }, [pagination]);
    return (
        <div className="app-main folder-view folder-search">
            <h2>フォルダ検索</h2>
            <form className="search-form" onReset={onReset} onSubmit={onSubmit}>
                <div className="line">
                    <label>キーワード:</label>
                    <TextField value={keywords} _onChange={setKeywords} />
                </div>
                <div className="line">
                    <label>属性:</label>
                    <TagList
                        tags={tags}
                        onClick={onClickTag}
                        highlight={Array.from(params.tenantTagIds)}
                    />
                </div>
                <div className="controls">
                    <Button
                        className="btn clear cancel"
                        icon="fa-solid fa-eraser"
                        type="reset"
                    >
                        クリア
                    </Button>
                    <Button
                        className="btn ok primary"
                        icon="fa-solid fa-magnifying-glass"
                        type="submit"
                    >
                        検索
                    </Button>
                </div>
            </form>
            <div className="result-head">
                <h2 className="result-title">検索結果</h2>
                <div className="controls">
                    <div className="detail-toggle">
                        <SlideSwitch
                            name="detail-toggle"
                            defaultValue={detailVisible}
                            _onChange={onDetailViewToggle}
                            text="詳細とフィルター表示"
                            textPosition="left"
                        />
                    </div>
                </div>
            </div>
            {detailVisible && folders.length > 0 && (
                <SearchResultFilter
                    tags={activeTags}
                    filters={filters}
                    onChange={onFilterChange}
                />
            )}
            {searching && <Loading />}
            {!searching && folders.length > 0 && (
                <>
                    {pager}
                    <SearchResultTable
                        currentFolder={dummyCurrent}
                        tagSelections={Array.from(queryParams.tenantTagIds)}
                        detailVisible={detailVisible}
                    />
                    {pager}
                </>
            )}
            {!searching && folders.length === 0 && (
                <div className="no-result">
                    {isEmpty(params)
                        ? '検索条件が設定されていません'
                        : '該当するフォルダは見つかりませんでした'}
                </div>
            )}
        </div>
    );
};
