import Axios from 'axios';
import _useSWR from 'swr';

import { API_BASE_URL } from '../config';
import { getAuthHeader, isAuthorizedURL } from './getAuthHeader';

const axios = Axios.create({
    baseURL: API_BASE_URL,
});

const DEFAULT_HEADERS = {
    'Content-Type': 'application/json',
};

export interface BaseApiResponse<T> {
    success: boolean;
    data?: T;
    message?: string;
}

export const ajax = async (method, url, data, options?) => {
    const {
        disableSecretKey = false,
        headers: _headers,
        ...opts
    } = options || {};
    const headers = {
        ...DEFAULT_HEADERS,
        ...(!disableSecretKey && isAuthorizedURL(url)
            ? await getAuthHeader()
            : {}),
        ..._headers,
    };
    return axios(
        method === 'GET'
            ? { method, url, params: data, headers, ...opts }
            : { method, url, data, headers, ...opts }
    )
        .catch((err) => {
            console.error('ajax failed:', err);
            throw err;
        })
        .then((resp) => ({
            success: resp.data.status === 'success',
            ...resp.data,
        }));
};

const get = (path, query?, options?) => ajax('GET', path, query, options);
const post = (path, data, options?) => ajax('POST', path, data, options);
const put = (path, data, options?) => ajax('PUT', path, data, options);
const del = (path, data?, options?) => ajax('DELETE', path, data, options);
const patch = (path, data, options?) => ajax('PATCH', path, data, options);
const getStream = async function (url: string, init?: RequestInit) {
    const res = await fetch(url, {
        ...init,
        headers: {
            ...(await getAuthHeader()),
            ...init?.headers,
        },
    });
    if (!res.ok) {
        throw new Error('response not ok');
    }
    if (!res.body) {
        throw new Error('no body');
    }
    return res.body;
};

const upload = async (path, file: File, options?) =>
    put(path, file, {
        headers: {
            'Content-Type': file.type,
        },
        ...options,
    }).then((resp) => {
        // OK if 200 even if resp is empty
        resp.success = true;
        return resp;
    });

const fetcher = async (paths) => {
    const singleGet = async (path) =>
        axios
            .get(path, {
                headers: await getAuthHeader(),
            })
            .then((res) => res.data);
    return Promise.all(paths.map(singleGet));
};

const useSWR = (...paths) =>
    _useSWR(
        paths.filter((v) => v),
        fetcher
    );

const useFetchFetcher = async <T>(url: string) => {
    return axios
        .get(url, {
            headers: await getAuthHeader(),
        })
        .then((res) => {
            const result: T = {
                ...res.data.data,
            };
            return result;
        });
};

export const useFetch = <T = any>(url: string | null) =>
    _useSWR(url, useFetchFetcher<T>);

export const HttpClient = {
    get,
    post,
    put,
    del,
    upload,
    useSWR,
    useFetch,
    patch,
    getStream,
};
