import {
    InternalApis,
    MutationRequestOptions,
    createMutation,
    fakeAxiosSuccess,
    getAsync,
} from '../../hooks/useApi';
import {
    CompiledFileMeta,
    CompiledShape,
    CompiledTag,
    compileFileMeta,
} from '../../models/ProjectMetaModel';
import {
    AiAnalysisLabelType,
    AiAnalysisType,
} from '../../models/AiAnalysisType';
import { AxiosResponse } from 'axios';
import {
    createLabelTree,
    getRouteByRoots,
    Label,
    LabelTreeLookup,
} from '../../models/AiTemplateModel';
import { projectApi, eachProjectFiles } from '../projectApi';
import { checkAbort, isAbortError, rethrowAbort } from '../../utils/abort';
import { ProgressController, ProgressReporter } from '../../utils/progress';
import { FolderItemModel, isFolderItem } from '../../models/ItemModel';
import type { Point, Rect } from '../../utils/ServerClientModel';
import { templateApi } from '../templateApi';
import {
    createDirectory,
    directoryPicker,
    downloadFile,
    saveFile,
    saveJsonFile,
} from '../../utils/filesystem';
import { isPhoto, isVideo } from '../../models/FileModel';
import { ProjectModel } from '../../models/ProjectModel';
import {
    CleanProjectUpdatesModelEntry,
    ModifiedProjectUpdatesModelEntry,
    ProjectUpdatesModelEntry,
} from '../../models/ProjectUpdateModel';
import { Queue } from '../../utils/queue';
import { FilesAPI } from '../filesApi';

export interface ExportProjectRequest {
    projectId: string;
    targetFolder?: FileSystemDirectoryHandle;
    overlay?: boolean;
    saveAll?: boolean;
    saveVideo?: boolean;
    filter?: number[] | null;
}

export interface ExportProjectResponse {
    success: boolean;
}

export interface RenderResultSummary {
    [type: number]: {
        count: number;
        sum: number;
    };
}
interface CleanTag {
    id: string;
    type: number;
    points: Point[];
    holes: Point[][];
    sum: number;
    score: number | null;
}
export interface ExportFolderTagValue {
    name: string;
    id: string;
    value: string;
}
export interface ExportFolderTag {
    name: string;
    id: string;
    values: ExportFolderTagValue[];
}
export interface ExportData {
    name: string;
    tags: ExportFolderTag[];
    files: RenderResult[];
    aiLabels: AiAnalysisLabelType[];
}
export interface RenderResult {
    fileId: string;
    mimeType: string;
    fileName: string;
    url: string;
    metadata: any;
    labels?: string[][];
    report?: string;
    selection?: Rect;
    tags?: CleanTag[];
    summary?: RenderResultSummary;
}

export interface ProjectCompiledResult extends RenderResult {
    compiledTags: CompiledTag[];
}

function exportFolderTags({ folder }: FolderItemModel): ExportFolderTag[] {
    return folder.tags.map((item) => ({
        name: item.tenantTag.name,
        id: item.tagId,
        values: item.values
            .sort((a, b) => a.tagType.sortOrder - b.tagType.sortOrder)
            .map((item): ExportFolderTagValue => {
                return {
                    name: item.tagType.name,
                    id: item.tagTypeId,
                    value: item.value,
                };
            }),
    }));
}

function compileSummary(cleanTags: CleanTag[]) {
    const summary: RenderResultSummary = {};
    for (const { type, sum } of cleanTags) {
        const typeSummary = summary[type] ?? {
            count: 0,
            sum: 0,
        };
        typeSummary.count++;
        typeSummary.sum += sum;
        summary[type] = typeSummary;
    }
    return summary;
}
export type ModifiedCompileProjectUpdatesModelEntry =
    ModifiedProjectUpdatesModelEntry & {
        compiledMeta: CompiledFileMeta;
        labels: string[][];
        cleanTags: CleanTag[];
        summary: RenderResultSummary;
    };
export type CompiledProjectUpdatesEntry =
    | CleanProjectUpdatesModelEntry
    | ModifiedCompileProjectUpdatesModelEntry;

function compileEntry(
    entry: ProjectUpdatesModelEntry,
    aiAnalysisLabels: AiAnalysisLabelType[],
    labelTreeLookup: LabelTreeLookup
): CompiledProjectUpdatesEntry {
    if (!entry.update) {
        return entry;
    }
    const meta = entry.update.compiled.fileMeta;
    const compiledMeta = compileFileMeta(aiAnalysisLabels, meta);
    const cleanTags = compiledMeta.tags.map(cleanTag);
    const summary = compileSummary(cleanTags);
    return {
        ...entry,
        compiledMeta,
        labels: Object.values(
            getRouteByRoots(labelTreeLookup, Object.values(meta?.labels ?? {}))
        ).map((route) => route.map((label) => label.name)),
        cleanTags,
        summary,
    };
}

export interface EachCompiledProjectFilesOptions {
    signal?: AbortSignal;
    queue: Queue;
}

export async function eachCompiledProjectFiles(
    projectId: string,
    aiAnalysisLabels: AiAnalysisLabelType[],
    aiTemplateLabels: Label[] | null,
    callback: (
        project: ProjectModel,
        extended: CompiledProjectUpdatesEntry,
        index: number,
        total: number
    ) => Promise<void> | void,
    opts: EachCompiledProjectFilesOptions
) {
    const labelTreeLookup = createLabelTree(aiTemplateLabels ?? []).lookup;
    await eachProjectFiles(
        projectId,
        { points: true, ...opts },
        async (project, entry, index, total) => {
            await callback(
                project,
                compileEntry(entry, aiAnalysisLabels, labelTreeLookup),
                index,
                total
            );
        }
    );
}

function cleanShape(shape?: CompiledShape) {
    if (!shape) {
        return [];
    }
    return shape.points.map((point) => ({
        x: point.x,
        y: point.y,
    }));
}

function cleanTag(tag: CompiledTag): CleanTag {
    const hull = tag.shapes[0];
    const holes = tag.shapes.slice(1);
    return {
        id: tag.id,
        type: tag.typeId,
        points: cleanShape(hull),
        holes: holes.map(cleanShape),
        sum: tag.sum,
        score: tag?.score ?? null,
    };
}

async function renderFiles(
    progress: ProgressReporter,
    projectId: string,
    saveAll: boolean,
    saveVideos: boolean,
    filesDirHandle: FileSystemDirectoryHandle,
    overlaysDirHandle: FileSystemDirectoryHandle | null,
    aiLabels: AiAnalysisLabelType[],
    templateLabels: Label[],
    filter: number[] | null,
    signal?: AbortSignal
): Promise<RenderResult[]> {
    const results: RenderResult[] = [];
    let rest = 1.0;

    const queue = new Queue({ concurrency: 5, signal });
    await eachCompiledProjectFiles(
        projectId,
        aiLabels,
        templateLabels,
        async (project, entry, index, total) => {
            if (!entry.update && !saveAll) {
                return;
            }
            if (isVideo(entry.item) && !saveVideos) {
                return;
            }
            if (entry.item.file.status !== 'UPLOADED') {
                return;
            }
            if (!saveAll && filter?.length) {
                // 出力対象のラベルが指定されている場合
                if (!entry.update) {
                    // 変更の入っていないものは出力しない
                    return;
                }
                if (
                    !(() => {
                        for (let id of filter) {
                            if (id in entry.summary) {
                                return true;
                            }
                        }
                        return false;
                    })()
                ) {
                    // 所定のラベルが存在しないものは出力しない（面積が0であっても存在する場合は出力する）
                    return;
                }
            }

            const message = entry.item.file.fileName;
            const step = rest / (total - index);
            rest -= step;
            const subProgress = progress.subsection(step);
            try {
                const fileName = entry.item.file.fileName;
                if (isPhoto(entry.item) && overlaysDirHandle) {
                    subProgress.report(0.00001, `${message} ダウンロード中...`);
                    const res = await fetch(entry.item.file.url, {
                        redirect: 'follow',
                        signal,
                    });
                    const response = {
                        status: res.status,
                        data: await res.blob(),
                    };
                    if (response.status !== 200) {
                        console.warn(response);
                        throw new Error(
                            `${message} のダウンロードに失敗しました (${response.status})`
                        );
                    }
                    subProgress.report(
                        0.5,
                        `${message} をダウンロードしました`
                    );
                    await saveFile(filesDirHandle, fileName, response.data);
                    subProgress.report(0.8, `${message} を保存しました`);

                    try {
                        const overlay = entry.update
                            ? await renderFileOverlays(
                                  response.data,
                                  entry.compiledMeta,
                                  filter
                              )
                            : response.data;
                        await saveFile(overlaysDirHandle, fileName, overlay);
                    } catch (err) {
                        console.warn(err);
                        throw new Error('オーバーレイ画像作成に失敗しました');
                    }
                    subProgress.report(
                        1,
                        `${message} のオーバレイを保存しました`
                    );
                } else {
                    // ファイルの保存
                    await downloadFile(
                        filesDirHandle,
                        entry.item.file.url,
                        fileName,
                        message,
                        subProgress,
                        signal
                    );
                }

                results.push({
                    fileId: entry.item.fileId,
                    mimeType: entry.item.mimeType,
                    fileName: entry.item.file.fileName,
                    url: entry.item.file.url,
                    metadata: entry.item.file.metadata,
                    ...(entry.update
                        ? {
                              report: entry.update.compiled.fileMeta.report,
                              selection:
                                  entry.compiledMeta.selection ?? undefined,
                              labels: entry.labels,
                              tags: entry.cleanTags,
                              summary: entry.summary,
                          }
                        : {}),
                });
            } catch (err) {
                rethrowAbort(err);
                subProgress.report(1, `${message} ${err.message}`);
            }
        },
        { queue }
    );
    await queue.finish();

    return results;
}

async function renderFileOverlays(
    imageBlob: Blob,
    meta: CompiledFileMeta,
    filter: number[] | null
): Promise<Blob> {
    const image = document.createElement('img');
    image.crossOrigin = 'Anonymous'; // CROSS ORIGIN で利用可能にするフラグ
    await new Promise<any>((resolve, reject) => {
        image.addEventListener('load', resolve);
        image.addEventListener('error', (error) => {
            console.warn(error);
            reject(new Error('オーバーレイ画像作成に失敗しました'));
        });
        image.src = URL.createObjectURL(imageBlob);
    });
    return (await renderImageOverlays(image, meta, filter)).rendered;
}

async function renderImageOverlays(
    image: HTMLImageElement,
    compiledMeta: CompiledFileMeta,
    filter: number[] | null
): Promise<{ rendered: Blob; compiledMeta: CompiledFileMeta }> {
    const viewBox = compiledMeta.selection ?? {
        x: 0,
        y: 0,
        width: image.naturalWidth,
        height: image.naturalHeight,
    };
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', viewBox.width.toString());
    canvas.setAttribute('height', viewBox.height.toString());
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error('キャンバスの初期化に失敗しました');
    }
    ctx.drawImage(image, -viewBox.x, -viewBox.y);
    for (const tag of compiledMeta.tags) {
        if (filter && !filter.includes(tag.typeId)) {
            continue;
        }
        ctx.strokeStyle = tag.strokeColor;
        ctx.fillStyle = tag.fillColor;
        ctx.beginPath();
        for (const shape of tag.shapes) {
            ctx.moveTo(
                shape.points[0].x - viewBox.x,
                shape.points[0].y - viewBox.y
            );
            for (let j = 1; j < shape.points.length; j += 1) {
                const point = shape.points[j];
                ctx.lineTo(point.x - viewBox.x, point.y - viewBox.y);
            }
        }
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
    }
    return {
        compiledMeta,
        rendered: await new Promise((resolve, reject) => {
            try {
                canvas.toBlob(async (blob) => {
                    if (blob) resolve(blob);
                    else reject(new Error('No Blob received.'));
                }, 'image/jpeg');
            } catch (err) {
                console.warn(err);
            }
        }),
    };
}

export const exportProject = createMutation<
    ExportProjectRequest,
    ExportProjectResponse
>(async (apis, request, opts) => {
    const progress = new ProgressController(opts);
    try {
        return await _exportProject(progress, apis, request, opts);
    } catch (error) {
        rethrowAbort(error);
        progress.onError(error);
        throw error;
    }
});

async function _exportProject(
    progress: ProgressReporter,
    apis: InternalApis,
    {
        projectId,
        targetFolder,
        overlay,
        saveAll,
        saveVideo,
        filter,
    }: ExportProjectRequest,
    opts: MutationRequestOptions
): Promise<AxiosResponse<ExportProjectResponse>> {
    const baseFolder = targetFolder ?? (await directoryPicker());
    progress.report(0, 'プロジェクトデータを取得中...');
    const project = await getAsync(projectApi.get.project(projectId));
    checkAbort(opts);
    if (!project || !project.data) {
        return fakeAxiosSuccess({ success: false });
    }
    checkAbort(opts);
    const { folderId, aiTemplateId } = project.data;
    if (!folderId) {
        return fakeAxiosSuccess({ success: false });
    }
    const [folder, aiTemplate, aiTemplateTypes] = await Promise.all([
        await getAsync(FilesAPI.item(folderId), opts)
            .then(({ data }) => {
                if (!isFolderItem(data)) {
                    throw new Error('Item is not a folder');
                }
                return data;
            })
            .catch((err) => {
                if (isAbortError(err)) throw err;
                console.warn(err);
                throw new Error('フォルダ情報の取得に失敗しました');
            }),
        await getAsync(
            aiTemplateId ? templateApi.get.aiTemplate(aiTemplateId) : null,
            opts
        ).catch((err) => {
            if (isAbortError(err)) throw err;
            console.warn(err);
            throw new Error('テンプレート情報の取得に失敗しました');
        }),
        await getAsync(projectApi.get.aiAnalysisTypes, opts).catch((err) => {
            if (isAbortError(err)) throw err;
            console.warn(err);
            throw new Error('AI情報情報の取得に失敗しました');
        }),
    ]);
    checkAbort(opts);
    progress.report(0.1, '出力先フォルダーを準備中...');

    const rootDirHandle = await createDirectory(baseFolder, 'download');
    checkAbort(opts);

    const filesDirHandle = await createDirectory(rootDirHandle, 'files');
    checkAbort(opts);

    const overlaysDirHandle = overlay
        ? await createDirectory(rootDirHandle, 'overlays')
        : null;
    checkAbort(opts);

    const aiTemlateTypesById = aiTemplateTypes.data.reduce(
        (mapped, entry) => {
            mapped[entry.aiAnalysisTypeId] = entry;
            return mapped;
        },
        {} as { [aiAnalysisTypeId: string]: AiAnalysisType }
    );

    const aiLabels =
        aiTemlateTypesById[
            project.data.aiAnalysisTypeId
                ? project.data.aiAnalysisTypeId
                : aiTemplate?.data.aiAnalysisTypeId!
        ]?.labels || [];

    const exportData: ExportData = {
        name: project.data.name,
        tags: folder ? exportFolderTags(folder) : [],
        files: await renderFiles(
            progress.subsection(0.8),
            projectId,
            saveAll ?? false,
            saveVideo ?? false,
            filesDirHandle,
            overlaysDirHandle,
            aiLabels,
            aiTemplate?.data.labels ?? [],
            filter ?? null,
            opts?.signal
        ),
        aiLabels,
    };

    progress.report(0.91, 'プロジェクトデータの保存中...');

    await saveExportJsonFile(rootDirHandle, 'project.json', exportData);
    checkAbort(opts);

    progress.report(1.0, '完了しました');
    return fakeAxiosSuccess({ success: true });
}

async function saveExportJsonFile(
    dirHandle: FileSystemDirectoryHandle,
    fileName: string,
    data: any
) {
    const files = data['files'];
    delete data.files;
    const cleanJSON = JSON.stringify(data);
    const cleanObj = JSON.parse(cleanJSON);

    const jsonString = JSON.stringify(cleanObj, null, 2);

    const fileHandle = await dirHandle.getFileHandle(fileName, {
        create: true,
    });
    const writable = await fileHandle.createWritable();

    await writable.write(jsonString.replace(/\n}$/, ',\n  "files":[\n'));
    files.map(async (el, index) => {
        await writable.write((index ? ',' : '') + JSON.stringify(el));
    });
    await writable.write('\n  ]\n}');

    await writable.close();
}
