import React, { FC } from 'react';
import { SignUpForm } from './user/SignUpForm';

type Prop = {};

export const SignUpUser: FC<Prop> = () => {
    return (
        <div className="app-main user">
            <h2 className="header-name">ユーザー登録</h2>
            <SignUpForm />
        </div>
    );
};
