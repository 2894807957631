import React, { useCallback, useState, useMemo } from 'react';
import { projectApi } from '../APIs/projectApi';
import { CheckBox, SlideSwitch } from './atomic/switches';
import { RequestOfMutation, mutateAsync, useGet } from '../hooks/useApi';
import classNames from 'classnames';
import { DialogProps, useDialog } from '../hooks/useDialog';
import { DialogBox, DialogBoxFooter, DialogErrorObject } from './atomic/dialog';
import { ProgressFooter } from './atomic/progress';
import { useReporter } from '../hooks/useReporter';
import { ProgressHandler } from '../utils/progress';

export type ProjectDownloaderProps = DialogProps<
    { projectId: string },
    RequestOfMutation<typeof projectApi.mutate.exportProject> & {
        onProgress: ProgressHandler;
    }
>;

export const useProjectDownloader = () =>
    useDialog(
        ProjectDownloader,
        (options: { projectId: string }) => ({
            options,
            keepOpen: true,
            async onCommit({ onProgress, ...opts }, { signal }) {
                await mutateAsync(projectApi.mutate.exportProject, opts, {
                    onProgress(entry) {
                        if (signal.aborted) return;
                        onProgress(entry);
                    },
                    signal,
                });
            },
        }),
        []
    );

export const ProjectDownloader = (props: ProjectDownloaderProps) => {
    const {
        onOK: onCommit,
        loading,
        options: { projectId },
        ...rest
    } = props;
    const [downloadOverlay, setDownloadOverlay] = useState<boolean>(false);
    const [downloadAll, setDownloadAll] = useState<boolean>(false);
    const [downloadVideo, setDownloadVideo] = useState<boolean>(false);
    const [useFilter, setUseFilter] = useState<boolean>(false);
    const [aiFilter, setAiFilter] = useState<number[]>([]);
    const { state, onProgress, reset } = useReporter({ working: !!loading });
    const project = useGet(projectApi.get.project(projectId));
    const aiAnalysisTypes = useGet(projectApi.get.aiAnalysisTypes);
    const { errors: _errors, disabled, ..._rest } = rest;

    const onOK = useCallback(() => {
        reset();
        onCommit({
            projectId,
            overlay: downloadOverlay,
            saveAll: downloadAll,
            saveVideo: downloadVideo && downloadAll,
            filter: useFilter ? aiFilter : null,
            onProgress,
        });
    }, [
        projectId,
        downloadOverlay,
        downloadAll,
        downloadVideo,
        useFilter,
        aiFilter,
    ]);

    const errors: DialogErrorObject[] = useMemo(() => {
        const retVal: DialogErrorObject[] = _errors ?? [];
        if (project.error) {
            retVal.push(project.error);
        }
        if (aiAnalysisTypes.error) {
            retVal.push(aiAnalysisTypes.error);
        }
        return retVal;
    }, [project.error, aiAnalysisTypes.error, _errors]);

    const filters = useMemo(() => {
        const type = project?.data?.data?.aiAnalysisTypeId;
        if (!type || !aiAnalysisTypes?.data) {
            return <></>;
        }
        const aiAnalysisType = aiAnalysisTypes?.data?.data?.find(
            ({ aiAnalysisTypeId }) => aiAnalysisTypeId == type
        );
        const labels = aiAnalysisType?.labels;
        if (!labels?.length) {
            return <></>;
        }
        return (
            <li>
                <div
                    className={classNames('atomic checkbox-group', {
                        disabled: downloadAll || disabled,
                    })}
                >
                    <SlideSwitch
                        defaultValue={useFilter}
                        name={'filter'}
                        _onChange={setUseFilter}
                        disabled={downloadAll || disabled}
                    />
                    <label>
                        {useFilter
                            ? '特定のラベルの検出された画像のみエクスポートする'
                            : 'ラベルによるフィルタリングを実施しない'}
                    </label>
                </div>
                {useFilter && !downloadAll && (
                    <ul className="filter-items">
                        {labels.map(({ id, nameJa }) => (
                            <li key={id}>
                                <CheckBox
                                    _onChange={(val) => {
                                        setAiFilter((prevState) => {
                                            if (val) {
                                                if (!prevState.includes(id)) {
                                                    return [...prevState, id];
                                                }
                                            } else {
                                                return prevState.filter(
                                                    (val) => val != id
                                                );
                                            }
                                            return prevState;
                                        });
                                    }}
                                    label={nameJa}
                                    key={id}
                                    checked={aiFilter.includes(id)}
                                    disabled={disabled}
                                />
                            </li>
                        ))}
                    </ul>
                )}
            </li>
        );
    }, [
        project?.data,
        aiAnalysisTypes?.data,
        useFilter,
        aiFilter,
        downloadAll,
        disabled,
    ]);
    return (
        <DialogBox
            className="project-downloader"
            title="プロジェクト情報のエクスポート"
            okText="エクスポート"
            cancelText="キャンセル"
            onOK={onOK}
            errors={errors}
            disabled={
                disabled || (useFilter && !downloadAll && !aiFilter.length)
            }
            {..._rest}
        >
            <div className="option-list">
                <ul className="fields">
                    <li>
                        <div
                            className={classNames('atomic checkbox-group', {
                                disabled: disabled,
                            })}
                        >
                            <SlideSwitch
                                defaultValue={downloadAll}
                                name={'all'}
                                _onChange={setDownloadAll}
                                disabled={disabled}
                            />
                            <label>
                                {downloadAll
                                    ? 'すべてのファイルを保存する'
                                    : '操作を行ったファイルのみ保存する'}
                            </label>
                        </div>
                    </li>
                    <li>
                        <div
                            className={classNames('atomic checkbox-group', {
                                disabled: disabled,
                            })}
                        >
                            <SlideSwitch
                                defaultValue={downloadOverlay}
                                name={'overlay'}
                                _onChange={setDownloadOverlay}
                                disabled={disabled}
                            />
                            <label>
                                {downloadOverlay
                                    ? '加工した画像を保存する'
                                    : '加工した画像を保存しない'}
                            </label>
                        </div>
                    </li>
                    <li>
                        <div
                            className={classNames('atomic checkbox-group', {
                                disabled: !downloadAll || disabled,
                            })}
                        >
                            <SlideSwitch
                                defaultValue={downloadVideo}
                                name={'video'}
                                _onChange={setDownloadVideo}
                                disabled={!downloadAll || disabled}
                            />
                            <label>
                                {downloadVideo
                                    ? '動画をダウンロードする'
                                    : '動画をダウンロードしない'}
                            </label>
                        </div>
                    </li>
                    {filters}
                </ul>
            </div>
            <DialogBoxFooter>
                <ProgressFooter {...state} />
            </DialogBoxFooter>
        </DialogBox>
    );
};
