export function formatVideoTime(seconds: number, guide: number = 10): string {
    // handle invalid times
    if (isNaN(seconds) || seconds === Infinity) {
        // '-' is false for all relational operators (e.g. <, >=) so this setting
        // will add the minimum number of fields specified by the guide
        return '--:--:--.--';
    }
    seconds = seconds < 0 ? 0 : seconds;
    const ms = ((seconds - (seconds | 0)) * 100) | 0;
    const f = (ms / 29.97) | 0;
    const s = Math.floor(seconds % 60);
    const m = Math.floor((seconds / 60) % 60);
    const h = Math.floor(seconds / 3600);
    const gm = Math.floor((guide / 60) % 60);
    const gh = Math.floor(guide / 3600);

    // Check if we need to show hours
    const hStr = h > 0 || gh > 0 ? `${h}:` : '';

    // If hours are showing, we may need to add a leading zero.
    // Always show at least one digit of minutes.
    const mStr = `${(h || gm >= 10) && m < 10 ? '0' : ''}${m}`;

    // Check if leading zero is need for seconds
    const sStr = s.toString().padStart(2, '0');

    return `${hStr}${mStr}:${sStr}.${ms.toString().padStart(2, '0')}`;
}
