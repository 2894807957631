import React, { useEffect, useMemo, useState } from 'react';
import { ProjectModel } from '../../../models/ProjectModel';
import { AiAnalysisLabels } from './AiAnalysisLabels';
import { AiAnalysisType } from '../../../models/AiAnalysisType';
import { projectApi } from '../../../APIs/projectApi';
import { useGet } from '../../../hooks/useApi';

type Prop = {
    setPoints;
    points;
    projects: ProjectModel[];
    onLabelTypeChange;
};
export const AiTypes: React.FC<Prop> = (props) => {
    const { setPoints, points, projects, onLabelTypeChange } = props;

    const { data: aiAnalysisTypes } = useGet(projectApi.get.aiAnalysisTypes);

    if (!aiAnalysisTypes) {
        return <></>;
    }

    // プロジェクトがあるものだけ表示
    const aiFilter = (type) => {
        return projects.find((project) => {
            return type.aiAnalysisTypeId === project.aiAnalysisTypeId;
        })
            ? true
            : false;
    };

    return (
        <div className="ai-selector">
            {aiAnalysisTypes.data
                .filter((type) => {
                    return aiFilter(type);
                })
                .map((aiAnalysisType) => {
                    return (
                        <div key={aiAnalysisType.aiAnalysisTypeId}>
                            <div className="ai-selector-label">
                                {aiAnalysisType.nameJa}
                            </div>
                            <div className="checkbox-container">
                                <AiAnalysisLabels
                                    onLabelTypeChange={onLabelTypeChange}
                                    aiAnalysisType={aiAnalysisType}
                                    setPoints={setPoints}
                                    points={points}
                                ></AiAnalysisLabels>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
