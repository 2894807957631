import React from 'react';
import { observer } from 'mobx-react-lite';
import { CompiledTag } from '../../models/ProjectMetaModel';

export interface ReelTagFocusRectProps {
    tag?: CompiledTag;
}

export const ReelTagFocusRect = observer(({ tag }: ReelTagFocusRectProps) => {
    if (!tag) return <></>;
    const { tl, br } = tag.box;
    const d = `M ${tl.x} ${tl.y} L ${br.x} ${tl.y} L ${br.x} ${br.y} L ${tl.x} ${br.y} Z`;
    return (
        <>
            <path
                d={d}
                fill="none"
                stroke="rgba(255 255 255 / 0.8)"
                strokeDasharray="2,2"
            />
            <path
                d={d}
                fill="none"
                stroke="rgba(0 0 0 / 0.8)"
                strokeDasharray="2,2"
                strokeDashoffset="2"
            />
        </>
    );
});
