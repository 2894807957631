import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { ReelSession } from './model/ReelSession';
import { SelectField } from '../atomic/forms';
import {
    CompiledLabelChildOption,
    LabelTreeItem,
} from '../../models/AiTemplateModel';
import { Icon } from '../atomic/icons';

function sortTemplateLabels(a: LabelTreeItem, b: LabelTreeItem): number {
    return a.order - b.order;
}

function sortCompiledLabels(
    a: { label: LabelTreeItem },
    b: { label: LabelTreeItem }
): number {
    return sortTemplateLabels(a.label, b.label);
}

interface ReelLabelProps {
    root: LabelTreeItem;
    labels: CompiledLabelChildOption[];
    selectLabels: (e: ChangeEvent<HTMLSelectElement>, id: string) => void;
    readonly: boolean;
}
const ReelLabel = observer(
    ({ labels, root, selectLabels, readonly }: ReelLabelProps) => {
        const labelList = useMemo(() => {
            const items = readonly
                ? labels
                      .filter((child) => child?.selected?.name)
                      .map((child) => (
                          <div key={`label-${child.level.aiTemplateLabelId}`}>
                              <div className="reel--label-plain">
                                  <Icon icon="fa-solid fa-check" />
                                  {child.selected.name}
                              </div>
                          </div>
                      ))
                : labels
                      .filter((child) => child.options.length > 0)
                      .map((child) => (
                          <SelectField
                              key={`select-${child.level.aiTemplateLabelId}`}
                              value={child.selectedOption}
                              onChange={(e) =>
                                  selectLabels(e, root.aiTemplateLabelId)
                              }
                              options={[
                                  {
                                      value: child.level.aiTemplateLabelId,
                                      text: '',
                                  },
                                  ...child.options
                                      .sort(sortCompiledLabels)
                                      .map(({ label }) => ({
                                          key: `option-${label.aiTemplateLabelId}`,
                                          value: label.aiTemplateLabelId,
                                          text: label.name,
                                      })),
                              ]}
                          />
                      ));
            if (!items.length) {
                return (
                    <div className="reel--label-none">
                        <Icon icon="fa-solid fa-triangle-exclamation" />
                        なし
                    </div>
                );
            }
            return items;
        }, [labels, readonly]);

        return (
            <div className="reel--label-box">
                <h2>{root.name}</h2>
                {labelList}
            </div>
        );
    }
);

export interface ReelLabelEditorProps {
    session: ReelSession;
}
export const ReelLabelEditor = observer(({ session }: ReelLabelEditorProps) => {
    const selectLabels = useCallback(
        (e: ChangeEvent<HTMLSelectElement>, root: string) =>
            session.selectLabels(root, e.target.value),
        [session]
    );
    return (
        <>
            {session.compiledLabelsOptions.map(({ children, root }, idx) => (
                <ReelLabel
                    key={`label-${idx}`}
                    selectLabels={selectLabels}
                    labels={children}
                    root={root}
                    readonly={session.readonly}
                />
            ))}
        </>
    );
});
