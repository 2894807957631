import React, { ReactNode } from 'react';
import { InstanceStatusType, TaskResult } from '../../../models/TaskModel';
import { ReelFileLink } from '../ReelFileLink';

export interface LogStatement {
    message: string | ReactNode;
    type: 'info' | 'error' | 'warn' | 'success';
}

export function toLogStatement(
    update: TaskResult,
    subTasks: string[]
): LogStatement | undefined {
    const subTask = subTasks.indexOf(update.aiTaskId) + 1;
    const taskName = subTask ? `サブタスク#${subTask}` : `タスク`;
    const prefix = subTask ? `${taskName}: ` : ``;
    if (update.event === 'INSTANCE-STATUS') {
        const status =
            update.type === InstanceStatusType.pending
                ? '起動待ちです'
                : update.type === InstanceStatusType.running
                ? '起動中です'
                : update.type === InstanceStatusType.stopped
                ? '終了しています'
                : `未知の状態です (${update.type})`;
        return { type: 'info', message: `${prefix}AI サーバは${status}。` };
    }
    if (update.event === 'START') {
        return { type: 'info', message: `${taskName}を開始しました。` };
    }
    if (update.event === 'END') {
        if (update.success) {
            return {
                type: 'success',
                message: `${taskName}は正常に終了しました。`,
            };
        }
        const type = update.error?.type;
        const typeIndicator =
            type === 'resource-kill'
                ? ''
                : type === 'aws-error'
                ? 'AWSで問題が発生しました'
                : type === 'crash'
                ? 'プロセスが停止しました'
                : type === 'exit'
                ? 'AIプロセスがエラー応答で終了しました'
                : type === 'user-cancel'
                ? 'ユーザがキャンセルしました'
                : '未知のエラーが発生しました';
        return {
            type: 'error',
            message: `${prefix}${typeIndicator}`,
        };
    }
    if (update.event === 'FILE-UPDATE') {
        const hint =
            update.type === 'download'
                ? 'をダウンロード中'
                : update.type === 'process'
                ? 'を処理中'
                : `に対する未知のステータス (${update.type})`;
        return {
            type: 'info',
            message: (
                <>
                    {prefix}
                    <ReelFileLink fileId={update.fileId} />
                    {hint}：{(update.percent * 100) | 0}%
                </>
            ),
        };
    }
    if (update.event === 'FILE-END') {
        return {
            type: update.success ? 'success' : 'warn',
            message: (
                <>
                    {prefix}
                    <ReelFileLink fileId={update.fileId} />
                    {update.success
                        ? ' に対するAIの実行を完了しました。'
                        : update.error?.type
                        ? ` に対するAIの実行が途中で停止しました。→ [${
                              update.error.type
                          }] ${update.error.message ?? ''}`
                        : ' に対するAIの実行が途中で停止しました。'}
                </>
            ),
        };
    }
    if (update.event === 'VIDEO-FRAME') {
        return {
            type: 'info',
            message: (
                <>
                    {prefix}
                    <ReelFileLink fileId={update.fileId} />
                    のフレームを切り出しました（
                    {Math.trunc(update.videoTimeSeconds / 1000)}秒）
                </>
            ),
        };
    }
    if (
        update.event === 'VIDEO-START' ||
        update.event === 'VIDEO-END' ||
        update.event === 'VIDEO-METADATA'
    ) {
        return; // TODO
    }
    if (update.event === 'MAX-CAPACITY') {
        return {
            type: 'info',
            message: 'サーバーがビジー状態です。',
        };
    }
    if (update.event === 'REQUEST') {
        return; // REQUEST event は UI のみです。
    }
    if (update.event === 'EXTERNAL-TASK') {
        return; // API → AI Infra Manage のリクエストが完成のとき。
    }
    if (update.event === 'SUB-START') {
        if (update.success) {
            return; // API はサブタスクを作れた
        } else {
            console.log(update);
            return {
                type: 'error',
                message: 'サブタスクのスタート時に問題が発生しました。',
            };
        }
    }
    if (update.event === 'EXTERNAL-TASK-ERROR') {
        return; // リクエストは無事に呼ばれていなかった。
    }
    if (update.event === 'HEALTH-CHECK') {
        return; // Health Check リクエスト投げました。
    }
    console.warn('[WARN] Unexpected event processed in log:', update);
}
