import React, { useCallback, useState } from 'react';
import { DialogProps } from '../../hooks/useDialog';
import { DialogBox } from '../atomic/dialog';
import { PlainField, TextField } from '../atomic/forms';
import { AiAnalysisType } from '../../models/AiAnalysisType';
import { CreateTemplateRequest } from '../../APIs/templateApi';

export type ImportTemplateDialogProps = DialogProps<
    {
        request: CreateTemplateRequest;
        aiAnalysisTypes: AiAnalysisType[];
    },
    CreateTemplateRequest
>;
export const ImportTemplateDialog = ({
    options,
    onOK: onCommit,
    ...rest
}: ImportTemplateDialogProps) => {
    const [request, setRequest] = useState<CreateTemplateRequest>(
        options.request
    );
    const { aiAnalysisTypes } = options;
    const setName = useCallback(
        (name: string) => setRequest((request) => ({ ...request, name })),
        [setRequest]
    );
    const onOK = useCallback(() => onCommit(request), [onCommit, request]);
    return (
        <DialogBox
            title={'テンプレートのインポート'}
            okText={'インポート'}
            {...rest}
            onOK={onOK}
        >
            <p>この内容でテンプレートをインポートしますか？</p>
            <ul className="fields">
                <li>
                    <TextField
                        label="名前"
                        _onChange={setName}
                        value={request.name}
                        required={true}
                    />
                </li>
                <li>
                    <PlainField
                        label="AI種別"
                        value={
                            aiAnalysisTypes?.find(
                                ({ aiAnalysisTypeId }) =>
                                    aiAnalysisTypeId == request.aiAnalysisTypeId
                            )?.nameJa ?? ''
                        }
                    />
                </li>
                <li>
                    <PlainField
                        label="ラベル"
                        value={`${request.labels?.length}種類`}
                    />
                </li>
            </ul>
        </DialogBox>
    );
};
