import React, { useCallback, useState } from 'react';
import { projectApi } from '../APIs/projectApi';
import { SlideSwitch } from './atomic/switches';
import { mutateAsync } from '../hooks/useApi';
import { DialogProps, useDialog } from '../hooks/useDialog';
import { DialogBox, DialogBoxFooter } from './atomic/dialog';
import { ProgressFooter } from './atomic/progress';
import { useReporter } from '../hooks/useReporter';
import { ProgressHandler } from '../utils/progress';
import { ExportReportRequest } from '../APIs/project/exportReport';

export type ReportDownloaderProps = DialogProps<
    Omit<ExportReportRequest, 'downloadFiles'>,
    Pick<ExportReportRequest, 'downloadFiles'> & {
        onProgress: ProgressHandler;
    }
>;

export const useReportDownloader = () =>
    useDialog(
        ReportDownloader,
        (options: Omit<ExportReportRequest, 'downloadFiles'>) => ({
            options,
            keepOpen: true,
            async onCommit({ onProgress, ...opts }, { signal }) {
                await mutateAsync(
                    projectApi.mutate.exportReport,
                    {
                        ...options,
                        ...opts,
                    },
                    {
                        onProgress(entry) {
                            if (signal.aborted) return;
                            onProgress(entry);
                        },
                        signal,
                    }
                );
            },
        }),
        []
    );

export const ReportDownloader = (props: ReportDownloaderProps) => {
    const { onOK: onCommit, loading, ...rest } = props;
    const [downloadFiles, setDownloadFiles] = useState<boolean>(false);
    const { state, onProgress, reset } = useReporter({ working: !!loading });

    const onOK = useCallback(() => {
        reset();
        onCommit({
            downloadFiles,
            onProgress,
        });
    }, [downloadFiles]);

    return (
        <DialogBox
            className="project-downloader"
            title="レポートのエクスポート"
            okText="エクスポート"
            cancelText="キャンセル"
            onOK={onOK}
            {...rest}
        >
            <p>ダウンロード方法を選択してください</p>
            <br />
            <div className="atomic checkbox-group">
                <SlideSwitch
                    _onChange={setDownloadFiles}
                    defaultValue={downloadFiles}
                    name="downloadfiles"
                />
                <label>
                    {downloadFiles
                        ? 'すべての情報をダウンロードする'
                        : 'CSVファイルのみダウンロードする'}
                </label>
            </div>
            <DialogBoxFooter>
                <ProgressFooter {...state} />
            </DialogBoxFooter>
        </DialogBox>
    );
};
