import { useEffect, useMemo, useRef, useState } from 'react';
import {
    APIGetRequest,
    ResponseForRequest,
    UseGetResponse,
    useGet,
} from './useApi';
import { parse as createParser } from 'csv-parse';
import { HttpClient } from '../utils/HttpUilts';

export type SortOrder = 'asc' | 'desc';

export type SortedBy =
    | undefined
    | {
          index: number;
          order: SortOrder;
      };

export interface CSVHeader {
    text: string;
    index: number;
    sortOrder?: SortOrder;
    toggleSort: () => void;
}

export function sortLexical(a: any, b: any): number {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}
export type CSVRequest = APIGetRequest<{ data: { link: string } }>;
export type CSVResponse = ResponseForRequest<CSVRequest>;

export interface UseCSVReturn
    extends Pick<
        UseGetResponse<CSVResponse | undefined, Error>,
        'key' | 'error' | 'mutate' | 'isLoading' | 'isValidating'
    > {
    counter: number;
    header: CSVHeader[];
    rows: string[][];
    blob: Blob | undefined;
    sortedBy: SortedBy;
}
export function useCSV(
    request: CSVRequest | null,
    opts?: {
        valueOf?: (header: string, index: number, value: string) => any;
    }
): UseCSVReturn {
    const link = useGet(request);
    const [header, setHeader] = useState<string[]>([]);
    const [rows, setRows] = useState<{ labels: string[]; values: any[] }[]>([]);
    const [error, setError] = useState<Error>();
    const [isLoading, setIsLoading] = useState(false);
    const [counter, setCounter] = useState(0);
    const [sortedBy, setSortedBy] = useState<{
        index: number;
        order: 'asc' | 'desc';
    }>();

    const blob = useRef<Blob>();
    const uint8Array = useRef<Uint8Array>();
    useEffect(() => {
        setError(undefined);
        blob.current = undefined;
        uint8Array.current = undefined;
        const url = link.data?.data.link;
        if (!url) {
            setIsLoading(false);
            return;
        }
        const controller = new AbortController();
        const { signal } = controller;
        (async () => {
            const chunks: Uint8Array[] = [];
            setCounter((counter) => counter + 1);
            let first = true;
            const parser = createParser({});
            const valueOf = opts?.valueOf;
            let header: string[] = [];
            parser.on('data', (row) => {
                if (signal.aborted) return;
                if (first) {
                    first = false;
                    header = row;
                    setHeader(row);
                    setRows([]);
                } else {
                    setRows((rows) => [
                        ...rows,
                        {
                            labels: row,
                            values: valueOf
                                ? row.map((label, index) =>
                                      valueOf(header[index], index, label)
                                  )
                                : row,
                        },
                    ]);
                }
            });
            const res = await HttpClient.getStream(url, { signal });
            const reader = res.getReader();
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    parser.end();
                    break;
                } else {
                    chunks.push(value);
                    parser.write(value);
                }
            }
            blob.current = new Blob(chunks, { type: 'text/csv' });
        })()
            .catch((err) => {
                if (signal.aborted) return;
                setError(err);
            })
            .finally(() => {
                if (signal.aborted) return;
                setIsLoading(false);
            });
        return () => controller.abort();
    }, [link.data]);
    return {
        key: link.key,
        mutate: link.mutate,
        counter,
        header: useMemo(
            () =>
                header.map(
                    (text, index): CSVHeader => ({
                        text,
                        index,
                        sortOrder:
                            sortedBy?.index === index
                                ? sortedBy.order
                                : undefined,
                        toggleSort: () =>
                            setSortedBy((sortedBy) => {
                                if (!sortedBy || sortedBy.index !== index) {
                                    return {
                                        index,
                                        order: 'asc',
                                    };
                                }
                                if (sortedBy.order === 'asc') {
                                    return {
                                        index,
                                        order: 'desc',
                                    };
                                }
                                return undefined;
                            }),
                    })
                ),
            [header, sortedBy]
        ),
        sortedBy,
        rows: useMemo(() => {
            const index = sortedBy?.index;
            if (index === undefined) {
                return rows.map(({ labels }) => labels);
            }
            const base = (sortedBy?.order ?? 'asc') === 'asc' ? 1 : -1;
            return rows
                .concat()
                .sort(
                    (a, b) =>
                        base * sortLexical(a.values[index], b.values[index])
                )
                .map(({ labels }) => labels);
        }, [rows, sortedBy]),
        get blob() {
            return blob.current;
        },
        error: link.error ?? error,
        isValidating: link.isValidating,
        isLoading: link.isLoading ?? isLoading,
    };
}
