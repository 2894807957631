import React, { useEffect } from 'react';
import {
    InputField,
    InputFieldProps,
    NumberField,
    TextField,
} from '../../atomic/forms';
import { Button } from '../../atomic/buttons';
import { useTenant } from '../../../contexts/TenantContext';
import { KintoneApp } from '../../../models/TenantModel';
import {
    getTenantForKintoneAppFields,
    getTenantOptions,
    putTenantOptions,
} from '../../../APIs/tenants';
import { DialogBox } from '../../atomic/dialog';
import { Loading } from '../../atomic/effects';
import { useNavigate } from 'react-router-dom';
import { AdminKintoneTable } from './AdminKintoneTable';
import { safeUrl } from '../../../utils/safeUrl';

export const AdminKintoneApps: React.FC = () => {
    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
    const [confirmMessage, setConfirmMessage] = React.useState<string>('');

    const [loading, setLoading] = React.useState<boolean>(false);

    const { tenantId } = useTenant();
    const [userId, setUserId] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [endPoint, setEndPoint] = React.useState<string>('');
    const [apps, setApps] = React.useState<KintoneApp[]>([]);
    const [removeId, setRemoveId] = React.useState<number>(0);

    const [appId, setAppId] = React.useState<string>('');

    interface InputRef {
        appId: React.MutableRefObject<InputField<InputFieldProps>>;
    }
    const refs: InputRef = ['appId'].reduce((ret, key) => {
        ret[key] = React.useRef<InputField>();
        return ret;
    }, {} as InputRef);

    const resetForm = () => {
        setAppId('');
        setConfirmMessage('');
    };

    useEffect(() => {
        resetForm();
    }, []);

    useEffect(() => {
        if (tenantId) {
            getTenantOptions({
                tenantId: tenantId,
                category: 'kintone',
                keys: ['id'],
                array: ['apps'],
            })
                .then((resp) => {
                    if (resp.data) {
                        setUserId(resp.data.kintone?.id ?? '');
                        setPassword(resp.data.kintone?.pass ?? '');
                        setEndPoint(resp.data.kintone?.endpoint ?? '');
                        setApps(resp.data.kintone?.apps ?? []);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    }, [tenantId]);

    const openConfirmDialog = React.useCallback(() => {
        let ok = true;
        for (let key in refs) {
            if (!refs[key].current?.checkValue()) {
                console.log(key);
                ok = false;
            }
        }
        if (
            apps.some((app) => {
                return Number(app.appId) === Number(refs.appId.current.value);
            })
        ) {
            setConfirmMessage(
                `Kintoneアプリ${refs.appId.current.props.value}編集に移動します`
            );
        } else {
            setConfirmMessage('Kintoneアプリを追加しますか？');
        }

        if (ok) {
            setShowConfirm(true);
        }
    }, [apps]);

    const navigate = useNavigate();
    const updateTenantOption = (dialog: DialogBox, _app: KintoneApp[]) => {
        putTenantOptions(tenantId, {
            kintone: {
                apps: _app,
            },
        })
            .then((resp) => {
                if (resp.success) {
                    setShowConfirm(false);
                    resetForm();
                    navigate(safeUrl`/app/admin/kintone/app/${appId}/edit`);
                } else {
                    console.warn(resp);
                    dialog.addError(
                        resp.message || 'kintone連携情報の更新に失敗しました'
                    );
                }
            })
            .catch((err) => {
                console.warn(err);
                dialog.addError(
                    err.response?.data?.message ||
                        err.message ||
                        'kintone連携情報の更新に失敗しました'
                );
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onConfirmUpdateOK = React.useCallback(
        (dialog: DialogBox) => {
            setLoading(true);
            getTenantForKintoneAppFields(tenantId, Number(appId))
                .then((resp) => {
                    if (resp.success) {
                        updateTenantOption(dialog, [
                            {
                                appId: Number(appId),
                                appDescription: '',
                                fields: [],
                            },
                        ]);
                    } else {
                        console.warn(resp);
                        dialog.addError('kintoneアプリ連携に失敗しました');
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.response?.data?.message ||
                            err.message ||
                            'kintoneアプリ連携に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [appId]
    );
    const onConfirmCancel = React.useCallback(() => {
        setShowConfirm(false);
    }, []);

    const onConfirmRemoveAppOK = React.useCallback(
        (dialog: DialogBox) => {
            const _apps = apps
                .filter((app: KintoneApp) => {
                    return app.appId == removeId;
                })
                .map((app) => {
                    app.fields = null;
                    return app;
                });

            setLoading(true);
            putTenantOptions(tenantId, {
                kintone: {
                    apps: _apps,
                },
            })
                .then((resp) => {
                    if (resp.success) {
                        const _apps = apps
                            .filter((app: KintoneApp) => {
                                return app.appId !== removeId;
                            })
                            .map((app) => {
                                return app;
                            });
                        setApps(_apps);
                        setRemoveId(0);
                    } else {
                        console.warn(resp);
                        dialog.addError(
                            resp?.message,
                            'アプリ設定の削除に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(err, 'アプリ設定の削除に失敗しました');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [removeId]
    );

    const onConfirmRemoveAppCancel = React.useCallback(() => {
        setRemoveId(0);
    }, []);

    const onRemoveApp = (removeAppId: number) => {
        setRemoveId(removeAppId);
    };

    return (
        <>
            {tenantId && userId && (
                <div className="app-main admin-kintone">
                    <h2>Kintoneアプリ設定</h2>
                    <div className="edit-forms">
                        <div className="form">
                            <ul className="fields">
                                <li>
                                    <NumberField
                                        label="アプリの追加編集"
                                        _onChange={setAppId}
                                        value={appId}
                                        required={true}
                                        autoComplete="off"
                                        ref={refs.appId}
                                        placeholder="アプリID"
                                    />
                                </li>
                            </ul>
                            <div className="update-buttons">
                                <Button
                                    className="primary"
                                    onClick={openConfirmDialog}
                                >
                                    追加
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="app-list">
                        <AdminKintoneTable
                            apps={apps}
                            setApps={setApps}
                            onRemoveApp={onRemoveApp}
                        />
                    </div>

                    {showConfirm && (
                        <DialogBox
                            title="確認"
                            onOK={onConfirmUpdateOK}
                            onCancel={onConfirmCancel}
                        >
                            {confirmMessage}
                        </DialogBox>
                    )}
                    {removeId != 0 && (
                        <DialogBox
                            title="確認"
                            onOK={onConfirmRemoveAppOK}
                            onCancel={onConfirmRemoveAppCancel}
                        >
                            アプリID：{removeId} の設定を削除しますか？
                        </DialogBox>
                    )}
                    {loading && <Loading />}
                </div>
            )}
        </>
    );
};
