import React, { FC, useState, useEffect, useCallback } from 'react';
import { DialogBox } from './atomic/dialog';
import { Button } from './atomic/buttons';
import { useNavigate } from 'react-router-dom';
import { SimpleDialogProps, useDialog } from '../hooks/useDialog';
import { useUserConfig } from '../hooks/useUserConfig';
import { LocalUserConfig } from './config/LocalConfig';
import { useLocalConfigFields } from './config/useLocalConfigFields';
import { useAuth } from '../contexts/AuthContext';
import { NotFound } from './Errors';

const BackDialog = (opts: SimpleDialogProps) => (
    <DialogBox title="確認" {...opts}>
        個人設定を変更しました。
        <br />
        前のページに戻りますか？
    </DialogBox>
);

export interface ConfigProps {}
export const Config: FC<ConfigProps> = () => {
    const { isExternalUser } = useAuth();

    if (isExternalUser()) {
        return <NotFound />;
    }

    const navigate = useNavigate();
    const localConfig = useUserConfig();
    const [config, setConfig] = useState<LocalUserConfig>(localConfig.get());

    const reset = useCallback(() => {
        setConfig(localConfig.get());
    }, []);

    useEffect(reset, [localConfig]);

    const backDialog = useDialog(
        BackDialog,
        () => ({
            options: {},
            onCommit: async () => navigate(-1),
        }),
        [navigate]
    );

    const onOK = useCallback(() => {
        setConfig((tempConfig) => {
            localConfig.set(tempConfig);
            backDialog.show();
            return tempConfig;
        });
    }, [backDialog.show]);

    const [aspectFields, videoFields] = useLocalConfigFields({
        config,
        setConfig,
    });
    return (
        <div className="app-main config">
            <h2 className="header-name">個人設定</h2>
            <div className="form-container">
                <div className="config-form">
                    <h3>トリミング</h3>
                    <ul className="fields" style={{ marginTop: '1em' }}>
                        <li>{aspectFields}</li>
                    </ul>
                    <h3>動画の分割</h3>
                    <ul className="fields">
                        <li>{videoFields}</li>
                    </ul>
                    <div className="update-buttons">
                        <Button
                            className="atomic btn secondary"
                            onClick={reset}
                        >
                            元の状態に戻す
                        </Button>
                        <Button className="atomic btn primary" onClick={onOK}>
                            完了
                        </Button>
                    </div>
                </div>
            </div>
            {backDialog.dialog}
        </div>
    );
};
