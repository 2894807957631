import { FoldersAPI } from '../APIs/foldersApi';
import { FileModel, isPhoto, isVideo } from '../models/FileModel';
import {
    FileItemFile,
    FileItemModel,
    FrameItemModel,
} from '../models/ItemModel';
import { useGet } from './useApi';

export type UseFileTypeReturn = {
    type: 'photo' | 'video' | undefined;
    isPhoto: boolean;
    isVideo: boolean;
};
function fixedType<T extends { [key: string]: UseFileTypeReturn }>(
    input: T
): T {
    return Object.fromEntries(
        Object.entries(input).map(([key, value]) => [key, Object.freeze(value)])
    ) as T;
}
const types = fixedType({
    photo: {
        type: 'photo',
        isPhoto: true,
        isVideo: false,
    },
    video: {
        type: 'video',
        isPhoto: false,
        isVideo: true,
    },
    other: {
        type: undefined,
        isPhoto: false,
        isVideo: false,
    },
});
export function useFileType(
    file:
        | string
        | FileModel
        | FileItemModel
        | FrameItemModel
        | FileItemFile
        | undefined
): UseFileTypeReturn {
    const fileData = useGet(
        typeof file === 'string' ? FoldersAPI.get.file(file) : null
    );
    const actualFile =
        fileData.data ?? (typeof file !== 'string' ? file : undefined);
    if (isPhoto(actualFile)) {
        return types.photo;
    }
    if (isVideo(actualFile)) {
        return types.video;
    }
    return types.other;
}
