import React, { useEffect } from 'react';
import { useTenant } from '../../../contexts/TenantContext';
import { NotFound } from '../../Errors';
import {
    TextField,
    PasswordField,
    InputField,
    InputFieldProps,
} from '../../atomic/forms';
import { DialogBox } from '../../atomic/dialog';
import { Button } from '../../atomic/buttons';
import { Loading } from '../../atomic/effects';
import { getTenantOptions, putTenantOptions } from '../../../APIs/tenants';
import { KintoneApp, TenantOptionsModel } from '../../../models/TenantModel';
import { Icon, Icons } from '../../atomic/icons';
import { Link } from 'react-router-dom';

export const AdminKintone = () => {
    const { tenantId } = useTenant();
    const [userId, setUserId] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [endPoint, setEndPoint] = React.useState<string>('');
    const [apps, setApps] = React.useState<KintoneApp[]>([]);
    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [kintoneOptions, setKintoneOptions] =
        React.useState<TenantOptionsModel>({});

    interface InputRef {
        id: React.MutableRefObject<InputField<InputFieldProps>>;
        pass: React.MutableRefObject<InputField<InputFieldProps>>;
        endpoint: React.MutableRefObject<InputField<InputFieldProps>>;
    }
    const refs: InputRef = ['id', 'pass', 'endpoint'].reduce((ret, key) => {
        ret[key] = React.useRef<InputField>();
        return ret;
    }, {} as InputRef);

    const fetchTenantOptions = () => {
        if (tenantId) {
            getTenantOptions({
                tenantId: tenantId,
                category: 'kintone',
                keys: ['id', 'pass', 'endpoint'],
            })
                .then((resp) => {
                    if (resp.data.hasOwnProperty('kintone')) {
                        setKintoneOptions(resp.data);
                    }
                    if (resp.data) {
                        setUserId(resp.data.kintone?.id ?? '');
                        setPassword(resp.data.kintone?.pass ?? '');
                        setEndPoint(resp.data.kintone?.endpoint ?? '');
                        setApps(resp.data.kintone?.apps ?? []);
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    };

    useEffect(() => {
        fetchTenantOptions();
    }, [tenantId]);

    const openConfirmDialog = React.useCallback(() => {
        let ok = true;
        for (let key in refs) {
            if (!refs[key].current?.checkValue()) {
                ok = false;
            }
        }
        if (ok) {
            setShowConfirm(true);
        }
    }, []);
    const onConfirmOK = React.useCallback(
        (dialog: DialogBox) => {
            setLoading(true);
            putTenantOptions(tenantId, {
                kintone: {
                    id: userId,
                    pass: password,
                    endpoint: endPoint,
                    apps: apps,
                },
            })
                .then((resp) => {
                    if (resp.success) {
                        fetchTenantOptions();
                        setShowConfirm(false);
                    } else {
                        console.warn(resp);
                        dialog.addError(
                            resp.message ||
                                'kintone連携情報の更新に失敗しました'
                        );
                    }
                })
                .catch((err) => {
                    console.warn(err);
                    dialog.addError(
                        err.response?.data?.message ||
                            err.message ||
                            'kintone連携情報の更新に失敗しました'
                    );
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [userId, password, endPoint, tenantId]
    );
    const onConfirmCancel = React.useCallback(() => {
        setShowConfirm(false);
    }, []);

    return (
        <div className="app-main admin-kintone">
            <h2>Kintone連携アカウントの管理</h2>
            <div className="edit-forms">
                <div className="form">
                    <ul className="fields">
                        <li>
                            <TextField
                                label="ユーザID"
                                _onChange={setUserId}
                                value={userId}
                                required={true}
                                autoComplete="off"
                                ref={refs.id}
                            />
                        </li>
                        <li>
                            <PasswordField
                                label="パスワード"
                                _onChange={setPassword}
                                value={password}
                                required={true}
                                autoComplete="new-password"
                                ref={refs.pass}
                            />
                        </li>
                        <li>
                            <TextField
                                label="エンドポイントURL"
                                _onChange={setEndPoint}
                                value={endPoint}
                                required={true}
                                autoComplete="off"
                                ref={refs.endpoint}
                                validator={/^https?:\/\/.+/i}
                                placeholder="https://example.cybozu.com/k/v1/"
                            />
                        </li>
                    </ul>
                    <div className="update-buttons">
                        <Button className="primary" onClick={openConfirmDialog}>
                            完了
                        </Button>
                    </div>
                    {kintoneOptions &&
                        kintoneOptions.kintone &&
                        kintoneOptions.kintone.id && (
                            <Link to="/app/admin/kintone/apps">
                                kintoneアプリ設定
                            </Link>
                        )}
                </div>
            </div>
            {showConfirm && (
                <DialogBox
                    title="確認"
                    onOK={onConfirmOK}
                    onCancel={onConfirmCancel}
                >
                    Kintone連携の設定を変更しますか？
                </DialogBox>
            )}
            {loading && <Loading />}
        </div>
    );
};
