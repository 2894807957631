import React from 'react';
import classNames from 'classnames';

import { Link } from 'react-router-dom';
import { TagIcon, DeleteIcon, EditIcon, MapIcon } from '../atomic/icons';
import { TagTypeModel, TagType } from '../../models/TagModel';
import { FolderDetailTagModel } from './EditTagDialog';
import { useAuth } from '../../contexts/AuthContext';

interface TagInfoSectionProp {
    className?: string;
    table: boolean;
    tagTypes: Array<TagTypeModel>;
    tag: FolderDetailTagModel;
    isWrite: boolean;
    onEditClick: (tag: FolderDetailTagModel) => void;
    onDeleteClick: (tag: FolderDetailTagModel) => void;
}

interface TagInfoTable {
    tagTypes: Array<TagTypeModel>;
    tag: FolderDetailTagModel;
}

const TagInfoList = (props) => {
    const { tag } = props;
    return (
        <ul className="info-list">
            {tag.values.map((v) => {
                return (
                    <li className="tag-value" key={v.sortOrder}>
                        <label>{v.tagTypeName}</label>
                        <span className="value">{v.value}</span>
                    </li>
                );
            })}
        </ul>
    );
};

const TagInfoTable = (props: TagInfoTable) => {
    const { tag, tagTypes } = props;
    const values = tagTypes
        .filter(({ tenantTagId }) => tenantTagId == tag.tenantTagId)
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map(({ tagTypeId, name, type }) => {
            const value = tag.values.find(
                (value) => value.tagTypeId == tagTypeId
            );
            return {
                name,
                value: value?.value ?? '',
                type,
                tagTypeId,
                tagDetailId: value?.tagDetailId ?? '',
            };
        });

    return (
        <table className="info-table">
            <thead>
                <tr>
                    {values.map((v, index) => (
                        <th key={index}>{v.name}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr className="tag-values">
                    {values.map((v, index) => {
                        return (
                            <td className="value" key={index}>
                                {v.value}
                                {v.value && v.type == TagType.Location && (
                                    <Link
                                        to={`/app/folder/map/${v.tagTypeId}/${v.tagDetailId}`}
                                        style={{ marginLeft: '0.5rem' }}
                                    >
                                        <MapIcon />
                                    </Link>
                                )}
                            </td>
                        );
                    })}
                </tr>
            </tbody>
        </table>
    );
};

export const TagInfoSection = (props: TagInfoSectionProp) => {
    const {
        className,
        tag,
        table,
        onEditClick,
        onDeleteClick,
        tagTypes,
        isWrite,
    } = props;
    const { isExternalUser } = useAuth();
    return (
        <section className={classNames('tag-info-section', className)}>
            <label className="title tag">
                <div role="title">
                    <TagIcon />
                    {tag.tagName}
                </div>
                {table && (
                    <div role="buttons">
                        {isWrite && tag.isOverWrite && (
                            <>
                                <Link
                                    to="#"
                                    onClick={() => onEditClick(tag)}
                                    title="編集"
                                >
                                    <EditIcon />
                                </Link>
                                {!isExternalUser() && (
                                    <Link
                                        to="#"
                                        title="削除"
                                        onClick={() => onDeleteClick(tag)}
                                    >
                                        <DeleteIcon />
                                    </Link>
                                )}
                            </>
                        )}
                    </div>
                )}
            </label>
            {table && <TagInfoTable tag={tag} tagTypes={tagTypes} />}
            {!table && <TagInfoList tag={tag} />}
        </section>
    );
};
