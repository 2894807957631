import { action, observable } from 'mobx';
import last from 'lodash.last';
import {
    type AddPointHistoryEntry,
    type AddTagsHistoryEntry,
    type ChangeTransformHistoryEntry,
    type DeletePointHistoryEntry,
    type DeleteTagHistoryEntry,
    type EditReportHistoryEntry,
    type EditSelectionHistoryEntry,
    type FileMetaHistoryEntry,
    type MovePointHistoryEntry,
    type SetTagTypeHistoryEntry,
    type UndoHistoryEntry,
    applyFileHistoryEntries,
    createFileHistoryRoot,
    HistoryType,
} from '../utils/applyFileHistoryEntries';
import {
    type CompressedPointWithId,
    type FileMeta,
    type LabelID,
    type Point,
    type Rect,
    type Transform,
    ActionTag,
} from '../utils/ServerClientModel';

export class FileMetaApi {
    pending: FileMetaHistoryEntry[] = [];
    lastUpdateId: string | null = null;
    data: FileMeta;
    undoAble: boolean = false;

    constructor(public onPending: (fileMetaApi: FileMetaApi) => void) {
        this.data = observable(createFileHistoryRoot());
        this.downSync = action(this.downSync);
    }

    get hasPending() {
        return this.pending.length > 0;
    }

    downSync(data: FileMeta, undoAble: boolean) {
        this.data.labels = data.labels;
        this.data.report = data.report;
        this.data.selection = data.selection;
        this.data.tags = data.tags;
        this.undoAble = undoAble;
        // Manually locally merge the history entries that have
        // not been upsynced yet.
        applyFileHistoryEntries(this.data, this.pending);
    }

    upSync(amount: number): FileMetaHistoryEntry[] {
        // These entries will be sent to the server
        return this.pending.splice(0, amount);
    }
    applyEntry<T extends FileMetaHistoryEntry>(entry: T): T {
        for (const error of applyFileHistoryEntries(this.data, [entry])) {
            console.error(error);
        }
        const result = entry;
        const prev = last(this.pending);
        if (prev && isOverriding(prev, entry)) {
            this.pending.pop();
        }
        this.pending.push(entry);
        this.onPending(this);
        return result;
    }
    setLabel(rootLabelId: LabelID, labelId: LabelID | null) {
        return this.applyEntry({
            type: HistoryType.editLabel,
            rootLabelId,
            labelId,
        });
    }
    addTags(tags: ActionTag[]): AddTagsHistoryEntry {
        return this.applyEntry({
            type: HistoryType.addTag,
            tags,
        });
    }
    deleteTag(tagId: string): DeleteTagHistoryEntry {
        return this.applyEntry({
            type: HistoryType.deleteTag,
            tagId,
        });
    }
    undo(): UndoHistoryEntry {
        return this.applyEntry({
            type: HistoryType.undo,
        });
    }
    setReport(report: string): EditReportHistoryEntry {
        return this.applyEntry({
            type: HistoryType.editReport,
            report,
        });
    }
    setSelection(rect: Rect | null): EditSelectionHistoryEntry {
        return this.applyEntry({
            type: HistoryType.editSelection,
            rect,
        });
    }
    setTagType(tagId: string, tagType: number): SetTagTypeHistoryEntry {
        return this.applyEntry({
            type: HistoryType.editTagType,
            tagId,
            tagType,
        });
    }
    addPoint(
        tagId: string,
        shapeId: string | undefined,
        beforePointId: string,
        afterPointId: string,
        point: CompressedPointWithId
    ): AddPointHistoryEntry {
        return this.applyEntry({
            type: HistoryType.addPoint,
            tagId,
            shapeId,
            beforePointId,
            afterPointId,
            point,
        });
    }
    movePoint(
        tagId: string,
        shapeId: string | undefined,
        pointId: string,
        point: Point
    ): MovePointHistoryEntry {
        return this.applyEntry({
            type: HistoryType.movePoint,
            tagId,
            shapeId,
            pointId,
            ...point,
        });
    }
    deletePoint(
        tagId: string,
        shapeId: string | undefined,
        pointId: string
    ): DeletePointHistoryEntry {
        return this.applyEntry({
            type: HistoryType.deletePoint,
            tagId,
            shapeId,
            pointId,
        });
    }
    changeTransform(
        tagId: string,
        transform: Transform
    ): ChangeTransformHistoryEntry {
        return this.applyEntry({
            type: HistoryType.changeTransform,
            tagId,
            transform,
        });
    }
}

function sameType<T extends FileMetaHistoryEntry>(
    a: T,
    b: FileMetaHistoryEntry
): b is T {
    return a.type === b.type;
}

export function isOverriding(
    prev: FileMetaHistoryEntry,
    entry: FileMetaHistoryEntry
) {
    if (prev.type === HistoryType.movePoint) {
        return sameType(prev, entry) && prev.pointId === entry.pointId;
    }
    if (prev.type === HistoryType.changeTransform) {
        return sameType(prev, entry) && prev.tagId === entry.tagId;
    }
    if (prev.type === HistoryType.editTagType) {
        return sameType(prev, entry);
    }
    if (prev.type === HistoryType.editReport) {
        return sameType(prev, entry);
    }
    if (prev.type === HistoryType.editLabel) {
        return sameType(prev, entry) && prev.rootLabelId === entry.rootLabelId;
    }
    if (prev.type === HistoryType.editSelection) {
        return sameType(prev, entry);
    }
    return false;
}
