import React, { useEffect, useMemo, useState } from 'react';
import { projectApi } from '../../../APIs/projectApi';
import { getAsync, useGet } from '../../../hooks/useApi';
import { AiAnalysisLabelType } from '../../../models/AiAnalysisType';
import { CheckBox } from '../../atomic/switches';
import { ProjectModel } from '../../../models/ProjectModel';
import { FileMetaCompiledContent } from '../../../models/ProjectMetaModel';
import { FileModel } from '../../../models/FileModel';
type Prop = {
    aiAnalysisType;
    setPoints: any;
    points;
    onLabelTypeChange;
};
export const AiAnalysisLabels: React.FC<Prop> = (props) => {
    const { aiAnalysisType, points, setPoints, onLabelTypeChange } = props;

    // プロジェクトのフォルダIDからファイルIDのリストを作成
    const [files, setFiles] = useState<FileModel[]>([]);

    return (
        <>
            {aiAnalysisType.labels.map((label) => {
                return (
                    <label key={label.id}>
                        <input
                            type="checkbox"
                            onChange={(e) =>
                                onLabelTypeChange(
                                    aiAnalysisType.aiAnalysisTypeId,
                                    label.id,
                                    e.target.checked
                                )
                            }
                            defaultChecked={false}
                        />
                        {label.nameJa}
                    </label>
                );
            })}
        </>
    );
};
