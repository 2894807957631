import { AbortError } from './abort';

export type QueueHandler = (opts: { signal: AbortSignal }) => Promise<void>;
export interface QueueOptions {
    concurrency: number;
    signal?: AbortSignal;
}
export class Queue extends EventTarget {
    #waiting: QueueHandler[] = [];
    #running: number = 0;
    #error?: Error;
    #finish: Promise<void> | null = null;
    #finishDone?: (err?: Error) => void;
    #controller: AbortController = new AbortController();
    constructor(public opts: QueueOptions) {
        super();
        if (opts.concurrency < 1) {
            throw new Error('Concurrency needs to be >= 1');
        }
        this._handleDone = this._handleDone.bind(this);
        this._handleAbort = this._handleAbort.bind(this);
        this._handleError = this._handleError.bind(this);
        if (this.opts.signal) {
            if (this.opts.signal.aborted) {
                this._handleError(new AbortError());
            } else {
                this.opts.signal.addEventListener('abort', this._handleAbort);
            }
        }
    }

    _handleDone() {
        this.#running--;
        if (this.#error) {
            this.#checkDone();
            this.dispatchEvent(new Event('size'));
            return;
        }
        const next = this.#waiting.shift();
        if (next) {
            this.#start(next);
        } else {
            this.#checkDone();
            this.dispatchEvent(new Event('size'));
        }
    }

    _handleAbort() {
        this._handleError(new AbortError());
    }

    add(handler: QueueHandler): void {
        if (this.#finish && !this.#finishDone) {
            throw new Error('finished');
        }
        if (!!this.#error || this.#running === this.opts.concurrency) {
            this.#waiting.push(handler);
        } else {
            this.#start(handler);
        }
        this.dispatchEvent(new Event('size'));
    }

    async onSizeLessThan(max: number): Promise<void> {
        if (this.size < max) {
            return;
        }
        await new Promise<void>((resolve) => {
            const handler = () => {
                if (this.size < max || this.#controller.signal?.aborted) {
                    this.removeEventListener('size', handler);
                    this.#controller.signal?.removeEventListener(
                        'abort',
                        handler
                    );
                    resolve();
                }
            };
            this.addEventListener('size', handler);
            this.#controller.signal?.addEventListener('abort', handler);
        });
    }

    #start(handler: QueueHandler): void {
        let p: Promise<void>;
        try {
            p = handler({ signal: this.#controller.signal });
        } catch (err) {
            this._handleError(err);
            return;
        }
        this.#running++;
        p.catch(this._handleError).finally(this._handleDone);
    }

    get size() {
        return this.#running + this.#waiting.length;
    }

    _handleError(error: Error) {
        if (this.#error) return;
        this.#error = error;
        this.#controller.abort();
        this.opts.signal?.removeEventListener('abort', this._handleAbort);
    }

    addAll(handlers: QueueHandler[]): this {
        for (const handler of handlers) this.add(handler);
        return this;
    }

    #checkDone() {
        if (!this.#finishDone) return;
        if (this.#running > 0) return;
        if (!this.#error && this.#waiting.length > 0) return;
        this.#finishDone();
        this.#finishDone = undefined;
    }

    async finish() {
        if (!this.#finish) {
            this.#finish = new Promise((resolve, reject) => {
                this.#finishDone = () => {
                    this.opts.signal?.removeEventListener(
                        'abort',
                        this._handleAbort
                    );
                    this.#error ? reject(this.#error) : resolve();
                };
            });
            this.#checkDone();
        }
        return this.#finish;
    }
}
