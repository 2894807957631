import {
    MouseEvent,
    RefObject,
    WheelEvent,
    createRef,
    SyntheticEvent,
} from 'react';
import { makeAutoObservable } from 'mobx';
import { AggregateProjectModel } from '../../../models/AggregateProjectModel';
import { ulid } from 'ulidx';
import {
    Box,
    CompiledFileMeta,
    CompiledPoint,
    CompiledTag,
    boundsForTag,
    compileFileMeta,
} from '../../../models/ProjectMetaModel';
import {
    CompiledLabelOption,
    LabelTreeItem,
    createLabelTree,
    getRouteByRoots,
} from '../../../models/AiTemplateModel';
import { Anchor, AnchorUpdate } from '../ReelAnchors';
import cloneDeep from 'lodash.clonedeep';
import { LocalState } from './LocalState';
import { FileUiMeta } from './FileUiMeta';
import { createPointId } from '../../../utils/createPointId';
import { FileMetaApi } from '../../../models/ProjectHistoryModel';
import { IComputedValue } from 'mobx/dist/internal';
import {
    LocalUserConfig,
    createLocalUserConfig,
} from '../../config/LocalConfig';
import type {
    ActionTag,
    FileMeta,
    LabelID,
    Rect,
    Tag,
    TagID,
} from '../../../utils/ServerClientModel';
import { useIsSyncing } from '../../../SyncProcess';

type UIHistoryEntry = {
    redo(): void;
    undo(): void;
};

interface Size {
    width: number;
    height: number;
}

const MIN_ZOOM_LEVEL = -6;
const MAX_ZOOM_LEVEL = 4;

export enum ReelMode {
    navigate = 'navigate',
    select = 'select',
    edit = 'edit',
}

function bWidth(box: Box) {
    return box.br.x - box.tl.x;
}

function bHeight(box: Box) {
    return box.br.y - box.tl.y;
}
function limit(num: number, max: number) {
    return Math.min(Math.max(num, 0), max);
}

export class ReelSession {
    history: UIHistoryEntry[] = [];
    _index: number = 0;
    _project: AggregateProjectModel | undefined = undefined;
    _fileId: string;
    _fileMeta: FileMetaApi;
    _uiBounds: Size;
    _uiMeta: { [fileId: string]: FileUiMeta } = {};
    _photoMode = ReelMode.navigate;
    _localState: LocalState;
    reelContainer: RefObject<HTMLDivElement>;
    _config: IComputedValue<LocalUserConfig>;
    readonly: boolean;
    showTags: boolean;

    get photoMode() {
        return this._photoMode;
    }
    set photoMode(mode: ReelMode) {
        this._photoMode = mode;
    }
    constructor(
        project: AggregateProjectModel | undefined,
        fileId: string,
        fileMeta: FileMetaApi,
        localState: LocalState,
        userId: string,
        readonly: boolean
    ) {
        this._localState = localState;
        this.reelContainer = createRef();
        this._uiBounds = {
            width: 0,
            height: 0,
        };
        this.fileId = fileId;
        this.fileMeta = fileMeta;
        this.project = project;
        this._config = createLocalUserConfig(userId);
        this.readonly = readonly;
        this.showTags = true;
        makeAutoObservable(
            this,
            {},
            {
                deep: true,
                autoBind: true,
            }
        );
    }
    get hideTags() {
        return !this.showTags;
    }
    toggleShowTags(showTags?: boolean) {
        this.showTags = showTags ?? this.showTags;
    }
    set fileMeta(fileMeta: FileMetaApi) {
        if (this._fileMeta === fileMeta) {
            return;
        }
        this._fileMeta = fileMeta;
    }
    get fileMeta() {
        return this._fileMeta;
    }
    set fileId(fileId: string) {
        if (this._fileId === fileId) return;
        this._fileId = fileId;
    }
    set project(project: AggregateProjectModel | undefined) {
        if (this._project === project) return;
        this._project = project;
    }
    set config(config: LocalUserConfig) {
        this._config.set(config);
    }
    get config(): LocalUserConfig {
        return this._config.get();
    }
    get legend() {
        return this._localState.legend;
    }
    get localState() {
        return this._localState;
    }
    get readonlyFileMeta(): Readonly<FileMeta> {
        return this.fileMeta?.data;
    }
    get report(): string {
        return this.readonlyFileMeta.report;
    }
    set report(report: string) {
        this.fileMeta.setReport(report);
    }
    movePoint(
        tagId: string,
        pointId: string,
        shapeId: string | undefined,
        x: number,
        y: number
    ) {
        const { focussedTag } = this;
        if (!focussedTag) return;
        this.checkReadonly();
        const { tag } = focussedTag;
        this.fileMeta.movePoint(
            tagId,
            shapeId,
            pointId,
            this.applyTagPointCoordinates(tag, x, y)
        );
    }
    removePoint(shapeId: string | undefined, pointId: string) {
        const { focussedTag } = this;
        if (!focussedTag) return;
        this.checkReadonly();
        this.fileMeta.deletePoint(focussedTag.tag.id, shapeId, pointId);
    }
    deleteTag(tagId: TagID) {
        this.checkReadonly();
        this.fileMeta.deleteTag(tagId);
    }
    selectLabels(root: LabelID, target: LabelID) {
        this.checkReadonly();
        this.fileMeta.setLabel(root, target);
    }
    selectLabel(root: LabelID, selected: LabelID) {
        this.checkReadonly();
        this.fileMeta.setLabel(root, selected);
    }
    addTags(tags: ActionTag[]) {
        this.checkReadonly();
        this.fileMeta.addTags(tags);
    }
    changeTagType(tagId: TagID, typeId: number) {
        this.checkReadonly();
        this.fileMeta.setTagType(tagId, typeId);
    }
    addRandomTag() {
        this.checkReadonly();
        const { uiMeta } = this;
        const t = (uiMeta.height ?? 0) * 0.1;
        const l = (uiMeta.width ?? 0) * 0.1;
        const b = t + (uiMeta.height ?? 0) * 0.15;
        const r = l + (uiMeta.width ?? 0) * 0.15;
        this.addTag({
            id: ulid(),
            type: this.aiLabels[0].id,
            points: [
                [createPointId(), l, t],
                [createPointId(), r, t],
                [createPointId(), r, b],
                [createPointId(), l, b],
            ],
        });
    }
    addTag(tag: ActionTag) {
        this.checkReadonly();
        this.addTags([tag]);
    }
    checkReadonly() {
        if (this.readonly) {
            throw new Error('readonly');
        }
    }
    get allowsTagSelection() {
        return (
            this.photoMode === ReelMode.navigate ||
            this.photoMode === ReelMode.edit
        );
    }
    get allowAreaSelection() {
        return this.photoMode === ReelMode.select;
    }
    get labelTree() {
        return createLabelTree(this._project?.labels ?? []);
    }
    get uiMeta() {
        const fileId = this._fileId;
        let meta = this._uiMeta[fileId];
        if (!meta) {
            meta = new FileUiMeta();
            this._uiMeta[fileId] = meta;
        }
        return meta;
    }
    get rect() {
        const zoom = this.uiMeta.zoom;
        const x = this.uiMeta.x * zoom;
        const y = this.uiMeta.y * zoom;
        const width = (this.uiMeta.width ?? 0) * zoom;
        const height = (this.uiMeta.height ?? 0) * zoom;
        const xMax = this.bounds.width - width;
        const yMax = this.bounds.height - height;
        return {
            x:
                xMax > 0
                    ? Math.max(Math.min(xMax, x), 0)
                    : Math.min(Math.max(xMax, x), 0),
            y:
                yMax > 0
                    ? Math.max(Math.min(yMax, y), 0)
                    : Math.min(Math.max(yMax, y), 0),
            width,
            height,
        };
    }
    addPoint(
        tag: Tag,
        shapeId: string | undefined,
        afterPointId: string,
        beforePointId: string,
        x: number,
        y: number
    ) {
        if (this.readonly) {
            throw new Error('readonly');
        }
        const point = this.applyTagPointCoordinates(tag, x, y);
        return this.fileMeta.addPoint(
            tag.id,
            shapeId,
            beforePointId,
            afterPointId,
            [createPointId(), point.x, point.y]
        );
    }
    get selectedLabelsByRoots() {
        return this.readonlyFileMeta.labels;
    }
    resizeFocussedBox(update: AnchorUpdate) {
        const { focussedTag, zoom } = this;
        if (!focussedTag) return;

        const newBox = cloneDeep(focussedTag.box);
        if (update.t !== undefined) {
            newBox.tl.y = update.t;
        }
        if (update.b !== undefined) {
            newBox.br.y = update.b;
        }
        if (update.l !== undefined) {
            newBox.tl.x = update.l;
        }
        if (update.r !== undefined) {
            newBox.br.x = update.r;
        }
        const x = (newBox.tl.x - this.rect.x) / zoom;
        const y = (newBox.tl.y - this.rect.y) / zoom;
        const width = bWidth(newBox) / zoom;
        const height = bHeight(newBox) / zoom;
        const originalBox = boundsForTag(focussedTag.tag);
        const scaleX = width / bWidth(originalBox);
        const scaleY = height / bHeight(originalBox);
        this.fileMeta.changeTransform(focussedTag.tag.id, {
            moveX: x / scaleX - originalBox.tl.x,
            moveY: y / scaleY - originalBox.tl.y,
            scaleX,
            scaleY,
        });
    }
    get selectionRouteByRoots() {
        return getRouteByRoots(
            this.labelTree.lookup,
            Object.values(this.readonlyFileMeta.labels)
        );
    }
    applyTagPointCoordinates(tag: Tag, x: number, y: number) {
        const { zoom, rect } = this;
        const transform = tag.transform;
        const result = {
            x: (x - rect.x) / zoom,
            y: (y - rect.y) / zoom,
        };
        if (transform) {
            result.x = result.x / transform.scaleX - transform.moveX;
            result.y = result.y / transform.scaleY - transform.moveY;
        }
        return result;
    }
    get compiledPoints() {
        const { focussedTag } = this;
        if (!focussedTag) return;
        const points: CompiledPoint[] = [];
        for (const shape of focussedTag.shapes) {
            points.push(...shape.points);
        }
        return points;
    }
    get compiledLabelsOptions(): CompiledLabelOption[] {
        const { roots } = this.labelTree;
        const selectionRoots = this.selectionRouteByRoots;
        return roots.map((label) => {
            const allSelected = selectionRoots[label.aiTemplateLabelId] ?? [
                label,
            ];
            const children = allSelected.map(
                (selected: LabelTreeItem, index: number) => {
                    const selectedChild = allSelected[index + 1];
                    const options = selected.children.map(
                        (child: LabelTreeItem) => {
                            return {
                                label: child,
                                selected: child === selectedChild,
                            };
                        }
                    );
                    let selectedOption =
                        selectedChild?.aiTemplateLabelId ??
                        selected.aiTemplateLabelId;
                    return {
                        level: selected,
                        selectedOption,
                        selected: selectedChild,
                        options,
                    };
                }
            );
            return {
                root: label,
                children,
            };
        });
    }
    get aiLabels() {
        return this._project?.aiAnalysisType.labels ?? [];
    }
    get compiledFileMeta(): CompiledFileMeta {
        const { rect, readonlyFileMeta: fileMeta, zoom } = this;
        return compileFileMeta(
            this.aiLabels,
            fileMeta,
            rect,
            zoom,
            (shapeId, afterPointId, beforePointId, x, y) => {
                const tag = this.focussedTag?.tag;
                return this.addPoint(
                    tag!,
                    shapeId,
                    afterPointId,
                    beforePointId,
                    x,
                    y
                )?.point[0];
            },
            (e, shapeId, pointId: string) => {
                const tag = this.activeTag?.id!;
                const time = Date.now();
                if (
                    this._localState.isDoubleClick(tag, shapeId, pointId, time)
                ) {
                    this.removePoint(shapeId, pointId);
                    return;
                }
                const point = this.compiledPoints?.find(
                    (point) => point.id === pointId
                );
                if (!point) {
                    return;
                }
                this._localState.pointDrag = {
                    time,
                    tag,
                    e: {
                        x: e.screenX,
                        y: e.screenY,
                    },
                    pointId,
                    shapeId: point.shapeId,
                    point: {
                        x: point.x,
                        y: point.y,
                    },
                };
            }
        );
    }
    get compiledTags(): CompiledTag[] {
        return this.compiledFileMeta.tags;
    }
    get inverseSelectionPath() {
        const { selection } = this.readonlyFileMeta;
        if (!selection) return;
        const rect = this.rect;
        const { zoom } = this;
        const { x, y, width, height } = {
            x: rect.x,
            y: rect.y,
            width: rect.width,
            height: rect.height,
        };
        const { x2, y2, width2, height2 } = {
            x2: selection.x * zoom + rect.x,
            y2: selection.y * zoom + rect.y,
            width2: selection.width * zoom,
            height2: selection.height * zoom,
        };
        return `M ${x} ${y} l ${width} 0 l 0 ${height} l -${width} 0 z M ${x2} ${y2} l ${width2} 0 l 0 ${height2} l -${width2} 0 z`;
    }
    get canZoomIn() {
        return this.uiMeta.zoomLevel < MAX_ZOOM_LEVEL;
    }
    get canZoomOut() {
        return this.uiMeta.zoomLevel > MIN_ZOOM_LEVEL;
    }
    zoomIn() {
        this.changeZoomFromCenter(+0.2);
    }
    zoomOut() {
        this.changeZoomFromCenter(-0.2);
    }
    changeZoomFromCenter(delta: number) {
        this.changeZoom(delta, this.bounds.width / 2, this.bounds.height / 2);
    }
    maximize() {
        if (!this.uiMeta.width || !this.uiMeta.height || !this._uiBounds) {
            return;
        }
        const scaleX = this._uiBounds.width / this.uiMeta.width;
        const scaleY = this._uiBounds.height / this.uiMeta.height;
        const scale = Math.min(scaleX, scaleY);
        this.uiMeta.zoomLevel = Math.log2(scale);
        const viewWidth = this._uiBounds.width / this.zoom;
        const viewHeight = this._uiBounds.height / this.zoom;
        this.uiMeta.x = (viewWidth - this.uiMeta.width) / 2;
        this.uiMeta.y = (viewHeight - this.uiMeta.height) / 2;
    }
    changeZoom(delta: number, x: number, y: number) {
        const xOff = x / this.zoom - this.uiMeta.x;
        const yOff = y / this.zoom - this.uiMeta.y;
        const newDelta = this.uiMeta.zoomLevel + delta;
        this.uiMeta.zoomLevel = Math.max(
            Math.min(newDelta, MAX_ZOOM_LEVEL),
            MIN_ZOOM_LEVEL
        );
        const newX = (x - xOff * this.zoom) / this.zoom;
        const newY = (y - yOff * this.zoom) / this.zoom;
        this.uiMeta.x = newX;
        this.uiMeta.y = newY;
    }
    updateBounds(bounds: Size): void {
        this._uiBounds = bounds;
    }
    get bounds(): Size {
        return this._uiBounds ?? { width: 0, height: 0 };
    }
    get zoom() {
        return this.uiMeta.zoom;
    }
    memoSize(width: number, height: number) {
        if (this.uiMeta.width !== undefined) return;
        this.uiMeta.width = width;
        this.uiMeta.height = height;
        const size = this.bounds;
        const zoom =
            size.width / size.height < width / height
                ? size.width / width
                : size.height / height;
        this.uiMeta.zoom = zoom;
        this.uiMeta.x = (size.width / zoom - width) / 2;
        this.uiMeta.y = (size.height / zoom - height) / 2;
    }
    setFocussedTag(tagId: string | undefined) {
        if (this.readonly) {
            return;
        }
        this.uiMeta.focussedTagId = tagId;
        this.uiMeta.hoveredTagId = undefined;
    }
    setHoverTag(tagId: string | undefined) {
        if (this.readonly) {
            return;
        }
        if (this.uiMeta.focussedTagId === tagId) return;
        this.uiMeta.hoveredTagId = tagId;
    }
    get hoveredTag(): CompiledTag | undefined {
        if (!this.uiMeta.hoveredTagId) return;
        return this.compiledTags.find(
            (tag) => tag.id === this.uiMeta.hoveredTagId
        );
    }
    get focussedTag(): CompiledTag | undefined {
        if (!this.uiMeta.focussedTagId) return;
        return this.compiledTags.find(
            (tag) => tag.id === this.uiMeta.focussedTagId
        );
    }
    get activeTag(): CompiledTag | undefined {
        return this.hoveredTag ?? this.focussedTag;
    }
    get inactiveTags(): CompiledTag[] {
        return this.compiledTags.filter(
            (tag) =>
                tag.id !== this.uiMeta.hoveredTagId &&
                tag.id !== this.uiMeta.focussedTagId
        );
    }
    move(x: number, y: number) {
        this.uiMeta.x += x / this.zoom;
        this.uiMeta.y += y / this.zoom;
    }
    _getSelection(area: Rect | null) {
        if (!area) {
            return null;
        }
        const { zoom } = this;
        area = { x: area.x, y: area.y, height: area.height, width: area.width };
        let l = area.x - this.rect.x;
        let t = area.y - this.rect.y;
        const r = Math.round(limit(l + area.width, this.rect.width) / zoom);
        const b = Math.round(limit(t + area.height, this.rect.height) / zoom);
        l = Math.round(limit(l, this.rect.width) / zoom);
        t = Math.round(limit(t, this.rect.height) / zoom);
        const newRect = {
            x: l,
            y: t,
            width: r - l,
            height: b - t,
        };
        if (newRect.width === 0 || newRect.height === 0) {
            return null;
        }
        return newRect;
    }
    select(area: Rect | null) {
        this.fileMeta.setSelection(this._getSelection(area));
    }
    imgLoad(e: SyntheticEvent<SVGImageElement>) {
        const image = e.currentTarget;
        const bbox = image.getBBox();
        this.memoSize(bbox.width, bbox.height);
    }
    onRectCornerDrag(
        e: MouseEvent,
        dir: Anchor,
        handler: (xDiff: number, yDiff: number) => AnchorUpdate
    ) {
        this._localState.resize = {
            e,
            dir,
            handler: (xDiff: number, yDiff: number) => {
                this.resizeFocussedBox(handler(xDiff, yDiff));
            },
        };
    }
    onRectCenterDrag(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        const { focussedTag } = this;
        if (!focussedTag) return;
        const {
            box: { tl, br },
        } = focussedTag;
        this._localState.resize = {
            e,
            dir: 'center',
            handler: (xDiff: number, yDiff: number) => {
                this.resizeFocussedBox({
                    l: tl.x + xDiff,
                    t: tl.y + yDiff,
                    r: br.x + xDiff,
                    b: br.y + yDiff,
                });
            },
        };
    }
    onTagPathOver(e: MouseEvent<SVGElement>) {
        if (this._localState.isDragging) return;
        if (this.allowAreaSelection) return;
        if (this.readonly) {
            return false;
        }
        this.setHoverTag(e.currentTarget.id);
    }
    onTagPathOut() {
        if (this._localState.isDragging) return;
        if (this.allowAreaSelection) return;
        if (this.readonly) {
            return false;
        }
        this.setHoverTag(undefined);
    }
    onUp() {
        const localState = this._localState;
        if (localState.select) {
            this.select(localState.tempSelection!);
        }
        localState.clear();
    }
    onWheel(e: WheelEvent<HTMLDivElement>) {
        e.stopPropagation();
        const rect = this.reelContainer.current!.getBoundingClientRect();
        this.changeZoom(e.deltaY / 200, e.clientX - rect.x, e.clientY - rect.y);
    }
    selectForPath(e: MouseEvent) {
        if (!this.allowsTagSelection) return;
        const selector = `select[name="${(e.target as SVGPathElement).id}"]`;
        return this.reelContainer.current?.querySelector(selector) as
            | HTMLSelectElement
            | undefined;
    }
    onDown(e: MouseEvent) {
        e.preventDefault();
        const localState = this._localState;
        if (!this.readonly) {
            if (this.allowAreaSelection) {
                localState.tempSelection = null;
                const rect =
                    this.reelContainer.current?.getBoundingClientRect();
                if (!rect) return;
                localState.select = {
                    x: e.clientX - rect.x,
                    y: e.clientY - rect.y,
                };
                return;
            }
            const target = e.target as HTMLElement;
            if (target.classList.contains('reel--tag')) {
                this.selectForPath(e)?.focus();
                this.setFocussedTag(target.id);
                this.onRectCenterDrag(e);
                return;
            }
        }

        if (this.allowsTagSelection) {
            // If the uiMeta was out of the window
            // make sure that the meta starts from the current
            // position.
            this.uiMeta.x = this.rect.x / this.zoom;
            this.uiMeta.y = this.rect.y / this.zoom;
            localState.drag = e;
        }
    }
    get aspect() {
        return this._config.get().aspect;
    }
    onMove(e: MouseEvent) {
        const { drag, select, resize, pointDrag } = this._localState;
        if (drag) {
            this.move(e.clientX - drag.clientX, e.clientY - drag.clientY);
            this._localState.drag = e;
        }
        if (select) {
            const rect = this.reelContainer.current!.getBoundingClientRect();
            let x = select.x;
            let xEnd = e.clientX - rect.x;
            let y = select.y;
            let yEnd = e.clientY - rect.y;

            const aspect_w = this.aspect.w;
            const aspect_h = this.aspect.h;

            if (y > yEnd) {
                [y, yEnd] = [yEnd, y];
            }
            if (x > xEnd) {
                [x, xEnd] = [xEnd, x];
            }

            // アスペクト比が決められている場合は、選択した枠内でアスペクト比を守りながらフィットする範囲を決める
            if (this.aspect.use && aspect_w > 0 && aspect_h > 0) {
                if ((xEnd - x) * aspect_h > (yEnd - y) * aspect_w) {
                    // y にあわせて x を調整する
                    if (x == select.x) {
                        xEnd = x + ((yEnd - y) * aspect_w) / aspect_h;
                    } else {
                        x = xEnd - ((yEnd - y) * aspect_w) / aspect_h;
                    }
                } else {
                    // x にあわせて y を調整する
                    if (y == select.y) {
                        yEnd = y + ((xEnd - x) * aspect_h) / aspect_w;
                    } else {
                        y = yEnd - ((xEnd - x) * aspect_h) / aspect_w;
                    }
                }
            }

            this._localState.tempSelection = {
                x,
                y,
                width: xEnd - x,
                height: yEnd - y,
            };
        }
        if (resize) {
            resize.handler(
                e.screenX - resize.e.screenX,
                e.screenY - resize.e.screenY
            );
        }
        if (pointDrag) {
            this.movePoint(
                pointDrag.tag,
                pointDrag.pointId,
                pointDrag.shapeId,
                pointDrag.point.x + (e.screenX - pointDrag.e.x),
                pointDrag.point.y + (e.screenY - pointDrag.e.y)
            );
        }
    }
}
