import React from 'react';
import { Link } from 'react-router-dom';
type AdminKintoneFolderListProp = {
    appId;
    folders;
};

export const AdminKintoneAppFolderList: React.FC<
    AdminKintoneFolderListProp
> = ({ appId, folders }) => {
    return (
        <ul className="app-folder-list">
            {folders &&
                folders.map((folder) => {
                    return (
                        <li key={folder.folderId}>
                            <Link to={`/app/folderlist/${folder.folderId}`}>
                                {folder.name}
                            </Link>
                        </li>
                    );
                })}
        </ul>
    );
};
