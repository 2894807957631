import { HttpClient, BaseApiResponse } from '../utils/HttpUilts';
import {
    CanvasObject,
    FileUpload,
    FileUploadRequest,
    MoveSliceOptions,
} from '../models/FileModel';
import { MAX_UPLOAD_SIZE } from '../consts';
import { AbortError, checkAbort, rethrowAbort } from '../utils/abort';
import {
    APIGetRequest,
    MutationResponse,
    createGet,
    createMutation,
    fakeAxiosSuccess,
    getAsync,
    mutateCache,
    simpleGet,
} from '../hooks/useApi';
import { nanoid } from 'nanoid';
import { ProgressState } from '../utils/progress';
import { mutate } from 'swr';
import { safeUrl } from '../utils/safeUrl';
import { waitFor } from '../utils/timeDelay';
import { PaginationModel } from '../models/PaginationModel';
import {
    BaseItemModel,
    FileItemModel,
    FolderItemModel,
    FrameItemModel,
    ItemModel,
} from '../models/ItemModel';
import type { Pager } from './util';

const BASE_PATH = '/files';

export interface ItemOptions extends Pager {
    parentFolderId?: string;
    idsOnly?: boolean;
    parent_file_id?: string;
    file?: boolean;
    folder?: boolean;
    frame?: boolean;
}

type TypeCase<
    T extends ItemOptions,
    Field extends keyof T,
    TIf extends BaseItemModel<any>,
    TElse extends BaseItemModel<any>,
> = T[Field] extends true ? TIf | TElse : TElse;

type TypeForOptions<T extends ItemOptions> = TypeCase<
    T,
    'folder',
    FolderItemModel,
    TypeCase<
        T,
        'file',
        FileItemModel,
        TypeCase<T, 'frame', FrameItemModel, never>
    >
>;

type ItemsForOptions<T extends ItemOptions> = T['idsOnly'] extends true
    ? T['limit'] extends 0 | undefined
        ? { data: string[] }
        : { data: string[]; pagination?: PaginationModel }
    : T['idsOnly'] extends false | undefined | null
    ? { data: Array<TypeForOptions<T>>; pagination: PaginationModel }
    : { data: string[] | ItemModel[]; pagination?: PaginationModel };

type ItemRequestForOptions<T extends ItemOptions> = APIGetRequest<
    ItemsForOptions<T>
>;

// TODO: clean up when stable
const SimErr = { PROVISION: false, S3: false };
const failWithDelay = <T>(err) =>
    new Promise<T>((_, reject) => {
        setTimeout(() => {
            reject(err);
        }, 1000 * 2);
    });
const createProgress = (progress: number) => ({
    id: nanoid(),
    message: 'uploading',
    progress: Math.max(Math.min(progress, 1.0), 0.0),
    state: ProgressState.RUNNING,
    time: new Date(),
});
export interface SliceVideoRequest {
    fileId: string;
    videoTimeSeconds: number;
}
export interface PostFilesModel {
    folderId: string;
    fileName: string;
    mimeType: string;
    multipart?: boolean;
    sourceId?: string;
    videoTimeSeconds?: number;
    sliceOptions?: MoveSliceOptions;
}
const PROGRESS_START = 0.05;
const PROGRESS_UPLOAD = 0.95;
export type UploadStatus = {
    status: 'PENDING' | 'UPLOADED' | 'ERROR';
    pendingStatus:
        | 'uploading'
        | 'mosaic-image'
        | 'mosaic-video'
        | 'write-exif'
        | 'finishing';
};
export const FilesAPI = {
    mutate: {
        startVideoSlice: createMutation<SliceVideoRequest, FrameItemModel>({
            async mutate(
                { authenticated },
                { fileId, ...input },
                { onProgress, signal }
            ) {
                const {
                    data: {
                        data: { fileId: uploadFileId },
                    },
                    ...rest
                } = await authenticated.post<
                    MutationResponse<{
                        fileId: string;
                        sliceVideoResponse: {
                            success: true;
                        };
                    }>
                >(`/file/${fileId}/slice`, input, {
                    onUploadProgress: (upload) => {
                        onProgress?.(
                            createProgress(0.0 + (upload.progress ?? 0) * 0.08)
                        );
                    },
                    onDownloadProgress: (download) => {
                        onProgress?.(
                            createProgress(
                                0.08 + (download.progress ?? 0) * 0.08
                            )
                        );
                    },
                    signal,
                });
                await FilesAPI.waitForUpload(
                    uploadFileId,
                    (percentage) => {
                        onProgress?.(createProgress(0.16 + percentage * 0.84));
                    },
                    signal
                );
                const { data: item } = await getAsync(
                    FilesAPI.item(uploadFileId)
                );
                const res: MutationResponse<FrameItemModel> = {
                    ...rest,
                    data: item as FrameItemModel,
                };
                return res;
            },
            effect(item, {}) {
                mutateCache(FilesAPI.item(item.parentFileId).key);
                mutateCache(
                    FilesAPI.fileIds({
                        folderId: item.parentFolderId!,
                        sourceId: item.parentFileId,
                    }).key
                );
            },
        }),
    },
    createFile: (
        data: PostFilesModel
    ): Promise<BaseApiResponse<FileUpload>> => {
        if (SimErr.PROVISION) return failWithDelay('provision error');
        return HttpClient.post(BASE_PATH, data);
    },
    notifications: ({ fileId }) => {
        return HttpClient.post(BASE_PATH + '/nortifications', { fileId });
    },
    checkUpload: ({ fileId }, signal?: AbortSignal): Promise<UploadStatus> => {
        return HttpClient.post(
            BASE_PATH + '/upload-check',
            { fileId },
            { signal }
        );
    },
    waitForUpload: async (
        fileId: string,
        progress: (percentage: number) => void,
        signal?: AbortSignal
    ) => {
        let current = 0;
        const growth = 0.3;
        let baseOffset = 0;
        let prevPendingStatus = 'uploading';
        while (true) {
            checkAbort(signal);
            const { status, pendingStatus } = await FilesAPI.checkUpload(
                { fileId },
                signal
            );
            if (pendingStatus !== prevPendingStatus) {
                prevPendingStatus = pendingStatus;
                if (pendingStatus === 'mosaic-image') {
                    baseOffset = 0.4;
                }
                if (pendingStatus === 'mosaic-video') {
                    baseOffset = 0.6;
                }
                if (pendingStatus === 'write-exif') {
                    baseOffset = 0.8;
                }
                if (pendingStatus === 'finishing') {
                    baseOffset = 0.9;
                }
            }
            if (status === 'ERROR') {
                progress(1.0);
                return false;
            }
            if (status === 'UPLOADED') {
                progress(1.0);
                return true;
            }

            checkAbort(signal);
            await waitFor(5000, signal);
            current = Math.min(current + (1 - current) * growth, 1.0);
            progress(baseOffset + (1 - baseOffset) * current);
        }
    },
    getPresignedUrl: (
        fileId: string,
        partNumber: number,
        signal?: AbortSignal
    ): Promise<{ success: true; presignedUrl: string }> =>
        HttpClient.post(
            `${BASE_PATH}/${fileId}/presigned-url`,
            { partNumber },
            { signal }
        ),
    put: async (
        { presignedUrl, file, contentType },
        signal?: AbortSignal,
        onProgress?: (progress: number) => void
    ) => {
        if (SimErr.S3) return failWithDelay('s3 error');
        const res = await HttpClient.upload(presignedUrl, file, {
            headers: { 'Content-Type': contentType },
            signal,
            onUploadProgress(event) {
                if (event.progress === undefined) return;
                onProgress?.(event.progress);
            },
        });
        onProgress?.(1.0);
        return res;
    },
    delete: (ids: String[]) => {
        return HttpClient.post(
            `${BASE_PATH}/ids/delete`,
            { ids: ids },
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            }
        );
    },

    isUploadable(file: File): boolean {
        return (
            file.size > 0 &&
            (/^image\//i.test(file.type) || /^video\//i.test(file.type))
        );
    },
    uploadCanvas: createMutation<FileUploadRequest<CanvasObject>, string>(
        async (
            _apis,
            { folderId, file, sourceId, videoTimeSeconds },
            { signal, onProgress }
        ): Promise<MutationResponse<string>> => {
            console.log('waiting...');
            // UI はロックしています、次のぎょうだから一旦待てましょう。
            await new Promise((resolve) => setTimeout(resolve, 250));
            console.log('waited...');
            const blob = await new Promise<Blob>((resolve, reject) =>
                file.canvas.toBlob(
                    (blob) => {
                        if (!blob)
                            reject(new Error('画像データの作成に失敗しました'));
                        else resolve(blob);
                    },
                    file.mimeType,
                    file.imageCompression ?? 0.82
                )
            );
            onProgress?.(createProgress(0.0));
            const resp = await FilesAPI.createFile({
                folderId,
                fileName: file.fileName,
                mimeType: file.mimeType,
                multipart: false,
                sourceId,
                videoTimeSeconds,
            });
            if (!resp.data) {
                throw new Error(resp.message || '未知のエラー');
            }
            const { fileId } = resp.data;
            if (!fileId) {
                throw new Error('ファイルIDの取得に失敗しました');
            }
            onProgress?.(createProgress(PROGRESS_START));
            const { presignedUrl } = await FilesAPI.getPresignedUrl(
                fileId,
                1,
                signal
            );
            await FilesAPI.put(
                {
                    presignedUrl,
                    contentType: file.mimeType,
                    file: blob,
                },
                signal,
                (progress) =>
                    onProgress?.(
                        createProgress(
                            PROGRESS_START + progress * PROGRESS_UPLOAD
                        )
                    )
            );
            return fakeAxiosSuccess(fileId);
        },
        (response, { folderId }) => {
            mutate(
                (key) =>
                    typeof key === 'string' &&
                    key.startsWith(safeUrl`/items/${folderId}`)
            );
        }
    ),
    uploadFile: createMutation<FileUploadRequest<File>, string>(
        async (
            _apis,
            { folderId, file, sourceId, videoTimeSeconds, sliceOptions },
            { signal, onProgress }
        ): Promise<MutationResponse<string>> => {
            // const PROGRESS_CHECK = 0.02;
            const PART_URL = 0.05;
            const PART_REST = 0.95;
            const fileName = file.name;
            const contentType = file.type;
            let stage = 'チェック';
            if (!FilesAPI.isUploadable(file)) {
                throw new Error(
                    `[${fileName}] 不正なファイル形式です（${contentType})`
                );
            }
            const parts: number = Math.ceil(file.size / MAX_UPLOAD_SIZE);
            stage = '準備';
            let totalProgress = 0;
            onProgress?.(createProgress(totalProgress));
            const resp = await FilesAPI.createFile({
                folderId,
                fileName,
                mimeType: contentType,
                multipart: parts > 1,
                sourceId,
                videoTimeSeconds,
                sliceOptions,
            });
            totalProgress += PROGRESS_START;
            onProgress?.(createProgress(totalProgress));
            if (!resp.data) {
                throw new Error(
                    `[${fileName}] ${resp.message || '未知のエラー'}`
                );
            }
            const { fileId } = resp.data;
            if (!fileId) {
                throw new Error(`[${fileName}] ファイルIDの取得に失敗しました`);
            }
            try {
                for (
                    let partNumber = 1, offset = 0;
                    partNumber <= parts;
                    partNumber++, offset += MAX_UPLOAD_SIZE
                ) {
                    if (signal?.aborted) {
                        throw new AbortError();
                    }
                    const end = Math.min(offset + MAX_UPLOAD_SIZE, file.size);
                    stage = `リダイレクト (${partNumber})`;
                    const { presignedUrl } = await FilesAPI.getPresignedUrl(
                        fileId,
                        partNumber
                    );
                    const slice = file.slice(offset, end);
                    const partPercent =
                        (PROGRESS_UPLOAD / file.size) * slice.size;
                    onProgress?.(
                        createProgress(totalProgress + PART_URL * partPercent)
                    );
                    if (signal?.aborted) {
                        throw new AbortError();
                    }
                    stage = `アップロード (${partNumber})`;
                    await FilesAPI.put(
                        {
                            presignedUrl,
                            contentType,
                            file: slice,
                        },
                        signal,
                        (progress) =>
                            onProgress?.(
                                createProgress(
                                    totalProgress +
                                        partPercent * PART_URL +
                                        partPercent *
                                            PART_REST *
                                            Math.max(Math.min(progress, 1.0), 0)
                                )
                            )
                    );
                    totalProgress += partPercent;
                    onProgress?.(createProgress(totalProgress));
                }
                stage = '完了中';
                onProgress?.(createProgress(1.0));
                return fakeAxiosSuccess(fileId);
            } catch (err) {
                rethrowAbort(err);
                // @ts-ignore
                throw new Error(`[${fileId}/${fileName}] ${stage} ${err}`, err);
            }
        }
    ),
    item: (itemId: string) => simpleGet<ItemModel>`/item/${itemId}`,
    items: <T extends ItemOptions>(opts?: T): ItemRequestForOptions<T> => {
        const params = new URLSearchParams();
        params.append('file', opts?.file ? '1' : '0');
        params.append('folder', opts?.folder ? '1' : '0');
        params.append('frame', opts?.frame ? '1' : '0');
        params.append('idsOnly', opts?.idsOnly ? '1' : '0');
        if (opts?.limit) {
            params.append('limit', opts.limit.toString());
        }
        if (opts?.offset) {
            params.append('offset', opts.offset.toString());
        }
        if (opts?.parent_file_id) {
            params.append('parent_file_id', opts.parent_file_id);
        }
        const folderId = opts?.parentFolderId;
        const url = folderId
            ? safeUrl`/items/${folderId}?${params}`
            : safeUrl`/items?${params}`;
        return createGet(url);
    },
    fileIds: (opts?: { folderId?: string; sourceId?: string }) => {
        const sourceId = opts?.sourceId;
        return FilesAPI.items({
            parentFolderId: opts?.folderId,
            parent_file_id: sourceId,
            idsOnly: true,
            file: !sourceId,
            folder: false,
            frame: !!sourceId,
            limit: 0,
        });
    },
} as const;

export const getFiles = (ids: Array<string>) => {
    return HttpClient.post(
        '/files/ids',
        { ids: JSON.stringify(ids) },
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        }
    );
};
