import { makeAutoObservable } from 'mobx';
import { Size } from '../../../hooks/useSize';

const DEFAULT_ZOOM_LEVEL = 0;
const LOG_2 = Math.log(2);

export class FileUiMeta {
    public x: number = 0;
    public y: number = 0;
    public zoomLevel: number = DEFAULT_ZOOM_LEVEL;
    public width: number | undefined = undefined;
    public height: number | undefined = undefined;
    public videoScrubTime: number | undefined = 0;
    public focussedTagId: string | undefined = undefined;
    public hoveredTagId: string | undefined = undefined;
    constructor() {
        makeAutoObservable(
            this,
            {},
            {
                deep: true,
                autoBind: true,
            }
        );
    }
    get zoom() {
        return this.zoomLevel !== undefined ? Math.pow(2, this.zoomLevel) : 0;
    }
    set zoom(zoom: number) {
        this.zoomLevel = Math.log(zoom) / LOG_2;
    }
    rect(bounds: Size) {
        const meta = this;
        const zoom = meta.zoom;
        const x = meta.x * zoom;
        const y = meta.y * zoom;
        const width = (meta.width ?? 0) * zoom;
        const height = (meta.height ?? 0) * zoom;
        const xMax = bounds.width - width;
        const yMax = bounds.height - height;
        return {
            x:
                xMax > 0
                    ? Math.max(Math.min(xMax, x), 0)
                    : Math.min(Math.max(xMax, x), 0),
            y:
                yMax > 0
                    ? Math.max(Math.min(yMax, y), 0)
                    : Math.min(Math.max(yMax, y), 0),
            width,
            height,
        };
    }
}
