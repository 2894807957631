import { useContext, useEffect } from 'react';
import { ErrorContext, ErrorSetter } from '../contexts';
import { AxiosError } from 'axios';

export class ErrorApi {
    constructor(public setErrors: ErrorSetter) {
        this.appendError = this.appendError.bind(this);
        this.clear = this.appendError.bind(this);
    }
    appendError(error: string) {
        this.setErrors((errors) => [...errors, error]);
    }
    clear() {
        this.setErrors([]);
    }
}
const cache = new WeakMap<ErrorSetter, ErrorApi>();
export function useErrors() {
    const setter = useContext(ErrorContext).setErrors;
    let api = cache.get(setter);
    if (api === undefined) {
        api = new ErrorApi(setter);
        cache.set(setter, api);
    }
    return api;
}
export function useAppendError(
    ...errors: Array<AxiosError | Error | undefined | null>
) {
    const { appendError } = useErrors();
    for (const error of errors) {
        useEffect(() => {
            if (!error) return;
            appendError(String(error));
        }, [error]);
    }
}
