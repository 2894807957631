import { createMutation } from '../../hooks/useApi';
import {
    AiTemplateModel,
    ExportTemplateLabelModel,
    ExportTemplateModel,
} from '../../models/AiTemplateModel';
import { downloadJSON } from '../../utils/download';
import { templateApi } from '../templateApi';

function aiTemplateToJson(data: AiTemplateModel): any {
    const retVal: ExportTemplateModel = {
        name: '',
        aiAnalysisTypeId: '',
        labels: [],
    };
    const getChildren = (parentId: string | null) => {
        return data.labels
            .filter(
                ({ parentAiTemplateLabelId }) =>
                    parentAiTemplateLabelId == parentId
            )
            .sort((a, b) => a.order - b.order)
            .map((item) => {
                const retVal: ExportTemplateLabelModel = {
                    label: item.name,
                };
                const children = getChildren(item.aiTemplateLabelId);
                if (children.length) {
                    retVal.children = children;
                }
                return retVal;
            });
    };
    retVal.name = data.name;
    retVal.aiAnalysisTypeId = data.aiAnalysisTypeId;
    retVal.labels = getChildren(null);
    return retVal;
}

export const exportAiTemplate = createMutation<
    { aiTemplateId: string },
    undefined
>(async (apis, { aiTemplateId }, config) => {
    const getApi = templateApi.get.aiTemplate(aiTemplateId);
    const { data: template } = await getApi.get(apis, getApi.key, config);
    // const time = format(Date.now(), 'yyyyMMdd-HHdd');
    // const name = `${time}_template_${tenantTag.name}.json`;
    const name = 'template.json';
    downloadJSON(name, aiTemplateToJson(template));
    return { status: 200, data: undefined };
});
